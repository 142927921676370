import React from 'react'
import { Card, Row, CardHeader, Col, Container, CardBody } from 'reactstrap'
//Import Breadcrumb
import BreadCrumb from '../../../Components/Common/BreadCrumb'

const LineAwesomeIcons = () => {
  document.title = 'Line Awesome Icons | Photo Booth Admin Dashboard'
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Line Awesome' pageTitle='Icons' />
          <Row className='icon-demo-content'>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h4 className='card-title'>Examples</h4>
                  <p className='text-muted mb-0'>
                    Use <code>&lt;i className="lab la-*-*"&lt;/i</code> class.
                  </p>
                </CardHeader>
                <CardBody>
                  <h6 className='text-uppercase text-muted fw-semibold'>Accessibility</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-accessible-icon'></i> lab la-accessible-icon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-american-sign-language-interpreting'></i> las
                      la-american-sign-language-interpreting
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-assistive-listening-systems'></i> las
                      la-assistive-listening-systems
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-audio-description'></i> las la-audio-description
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-blind'></i> las la-blind
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-braille'></i> las la-braille
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-closed-captioning'></i> las la-closed-captioning
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-deaf'></i> las la-deaf
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-low-vision'></i> las la-low-vision
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-volume'></i> las la-phone-volume
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-question-circle'></i> las la-question-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sign-language'></i> las la-sign-language
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tty'></i> las la-tty
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-universal-access'></i> las la-universal-acces
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wheelchair'></i> las la-wheelchair
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Alert</h6>
                  <Row className='row icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell'></i> las la-bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell-slash'></i> las la-bell-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-exclamation'></i> las la-exclamation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-exclamation-circle'></i> las la-exclamation-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-exclamation-triangle'></i> las la-exclamation-triangle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-radiation'></i> las la-radiation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-radiation-alt'></i> las la-radiation-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skull-crossbones'></i> las la-skull-crossbones
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Animals</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cat'></i> las la-cat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-crow'></i> las la-crow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dog'></i> las la-dog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dove'></i> las la-dove
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dragon'></i> las la-dragon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-feather'></i> las la-feather
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-feather-alt'></i> las la-feather-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fish'></i> las la-fish
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-frog'></i> las la-frog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hippo'></i> las la-hippo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-horse'></i> las la-horse
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-horse-head'></i> las la-horse-head
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-kiwi-bird'></i> las la-kiwi-bird
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-otter'></i> las la-otter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paw'></i> las la-paw
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-spider'></i> las la-spider
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Arrows</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-angle-double-down'></i>las la-angle-double-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-angle-double-left'></i>las la-angle-double-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-angle-double-right'></i>las la-angle-double-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-angle-double-up'></i>las la-angle-double-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-angle-down'></i>las la-angle-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-angle-left'></i>las la-angle-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-angle-right'></i>las la-angle-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-angle-up'></i>las la-angle-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrow-alt-circle-down'></i>las la-arrow-alt-circle-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrow-alt-circle-left'></i>las la-arrow-alt-circle-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrow-alt-circle-right'></i>las la-arrow-alt-circle-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrow-alt-circle-up'></i>las la-arrow-alt-circle-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrow-circle-down'></i> las la-arrow-circle-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrow-circle-left'></i> las la-arrow-circle-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrow-circle-right'></i> las la-arrow-circle-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrow-circle-up'></i> las la-arrow-circle-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrow-down'></i> las la-arrow-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrow-left'></i> las la-arrow-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrow-right'></i> las la-arrow-righ
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrow-up'></i> las la-arrow-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrows-alt'></i> las la-arrows-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrows-alt-h'></i> las la-arrows-alt-h
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-arrows-alt-v'></i> las la-arrows-alt-v
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-caret-down'></i> las la-caret-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-caret-left'></i> las la-caret-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-caret-right'></i> las la-caret-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-caret-square-down'></i> las la-caret-square-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-caret-square-left'></i> las la-caret-square-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-caret-square-right'></i> las la-caret-square-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-caret-square-up'></i> las la-caret-square-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-caret-up'></i> las la-caret-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cart-arrow-down'></i> las la-cart-arrow-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chart-line'></i> las la-chart-line
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chevron-circle-down'></i> las la-chevron-circle-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chevron-circle-left'></i> las la-chevron-circle-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chevron-circle-right'></i> las la-chevron-circle-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chevron-circle-up'></i> las la-chevron-circle-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chevron-down'></i> las la-chevron-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chevron-left'></i> las la-chevron-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chevron-right'></i> las la-chevron-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chevron-up'></i> las la-chevron-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-download-alt'></i> las la-cloud-download-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-upload-alt'></i> las la-cloud-upload-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-compress-arrows-alt'></i> las la-compress-arrows-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-download'></i> las la-download
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-exchange-alt'></i> las la-exchange-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-expand-arrows-alt'></i> las la-expand-arrows-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-external-link-alt'></i> las la-external-link-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-external-link-square-alt'></i> las
                      la-external-link-square-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-point-down'></i> las la-hand-point-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-point-left'></i> las la-hand-point-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-point-right'></i> las la-hand-point-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-point-up'></i> las la-hand-point-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-pointer'></i> las la-hand-pointer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-history'></i> las la-history
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-level-down-alt'></i> las la-level-down-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-level-up-alt'></i> las la-level-up-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-location-arrow'></i> las la-location-arrow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-long-arrow-alt-down'></i> las la-long-arrow-alt-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-long-arrow-alt-left'></i> las la-long-arrow-alt-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-long-arrow-alt-right'></i> las la-long-arrow-alt-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-long-arrow-alt-up'></i> las la-long-arrow-alt-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mouse-pointer'></i> las la-mouse-pointer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-play'></i> las la-play
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-random'></i> las la-random
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-recycle'></i> las la-recycle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-redo'></i> las la-redo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-redo-alt'></i> las la-redo-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-reply'></i> las la-reply
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-reply-all'></i> las la-reply-all
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-retweet'></i> las la-retweet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-share'></i> las la-share
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-share-square'></i> las la-share-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sign-in-alt'></i> las la-sign-in-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sign-out-alt'></i> las la-sign-out-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort'></i> las la-sort
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-alpha-down'></i> las la-sort-alpha-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-alpha-down-alt'></i> las la-sort-alpha-down-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-alpha-up'></i> las la-sort-alpha-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-alpha-up-alt'></i> las la-sort-alpha-up-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-amount-down'></i> las la-sort-amount-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-amount-down-alt'></i> las la-sort-amount-down-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-amount-up'></i> las la-sort-amount-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-amount-up-alt'></i> las la-sort-amount-up-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-down'></i> las la-sort-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-numeric-down'></i> las la-sort-numeric-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-numeric-down-alt'></i> las la-sort-numeric-down-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-numeric-up'></i> las la-sort-numeric-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-numeric-up-alt'></i> las la-sort-numeric-up-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-up'></i> las la-sort-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sync'></i> las la-sync
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sync-alt'></i> las la-sync-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-text-height'></i> las la-text-height
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-text-width'></i> las la-text-width
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-undo'></i> las la-undo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-undo-alt'></i> las la-undo-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-upload'></i> las la-upload
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Audio & Video</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-audio-description'></i> las la-audio-description
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-backward'></i> las la-backward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-broadcast-tower'></i> las la-broadcast-tower
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-circle'></i> las la-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-closed-captioning'></i> las la-closed-captioning
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-compress'></i> las la-compress
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-compress-arrows-alt'></i> las la-compress-arrows-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eject'></i> las la-eject
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-expand'></i> las la-expand
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-expand-arrows-alt'></i> las la-expand-arrows-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fast-backward'></i> las la-fast-backward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fast-forward'></i> las la-fast-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-audio'></i> las la-file-audio
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-video'></i> las la-file-video
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-film'></i> las la-film
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-forward'></i> las la-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-headphones'></i> las la-headphones
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone'></i> las la-microphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-alt'></i> las la-microphone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-alt-slash'></i> las la-microphone-alt-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-slash'></i> las la-microphone-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-music'></i> las la-music
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pause'></i> las la-pause
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pause-circle'></i> las la-pause-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-volume'></i> las la-phone-volume
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-photo-video'></i> las la-photo-video
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-play'></i> las la-play
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-play-circle'></i> las la-play-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-podcast'></i> las la-podcast
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-random'></i> las la-random
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-redo'></i> las la-redo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-redo-alt'></i> las la-redo-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-rss'></i> las la-rss
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-rss-square'></i> las la-rss-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-step-backward'></i> las la-step-backward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-step-forward'></i> las la-step-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stop'></i> las la-stop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stop-circle'></i> las la-stop-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sync'></i> las la-sync
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sync-alt'></i> las la-sync-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tv'></i> las la-tv
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-undo'></i> las la-undo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-undo-alt'></i> las la-undo-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-video'></i> las la-video
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-down'></i> las la-volume-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-mute'></i> las la-volume-mute
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-off'></i> las la-volume-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-up'></i> las la-volume-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-youtube'></i> lab la-youtube
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Automotive</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-air-freshener'></i> las la-air-freshener
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ambulance'></i> las la-ambulance
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bus'></i> las la-bus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bus-alt'></i> las la-bus-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-car'></i> las la-car
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-car-alt'></i> las la-car-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-car-battery'></i> las la-car-battery
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-car-crash'></i> las la-car-crash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-car-side'></i> las la-car-side
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-charging-station'></i> las la-charging-station
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gas-pump'></i> las la-gas-pump
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-motorcycle'></i> las la-motorcycle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-oil-can'></i> las la-oil-can
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shuttle-van'></i> las la-shuttle-van
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tachometer-alt'></i> las la-tachometer-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-taxi'></i> las la-taxi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-truck'></i> las la-truck
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-truck-monster'></i> las la-truck-monster
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-truck-pickup'></i> las la-truck-pickup
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Autumn</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-apple-alt'></i> las la-apple-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-campground'></i> las la-campground
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-sun'></i> las la-cloud-sun
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-drumstick-bite'></i> las la-drumstick-bite
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-football-ball'></i> las la-football-ball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hiking'></i> las la-hiking
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mountain'></i> las la-mountain
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tractor'></i> las la-tractor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tree'></i> las la-tree
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wind'></i> las la-wind
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wine-bottle'></i> las la-wine-bottle
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Beverage</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-beer'></i> las la-beer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-blender'></i> las la-blender
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cocktail'></i> las la-cocktail
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-coffee'></i> las la-coffee
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-flask'></i> las la-flask
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-cheers'></i> las la-glass-cheers
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-martini'></i> las la-glass-martini
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-martini-alt'></i> las la-glass-martini-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-whiskey'></i> las la-glass-whiskey
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mug-hot'></i> las la-mug-hot
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wine-bottle'></i> las la-wine-bottle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wine-glass'></i> las la-wine-glas
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wine-glass-alt'></i>las la-wine-glass-alt
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Brand Icons</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-500px'></i> lab la-500px
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-accusoft'></i> lab la-accusoft
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-adn'></i> lab la-adn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-adobe'></i> lab la-adobe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-adversal'></i> lab la-adversa
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-affiliatetheme'></i> lab la-affiliatetheme
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-airbnb'></i> lab la-airbnb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-algolia'></i> lab la-algolia
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-amazon'></i> lab la-amazon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-amilia'></i> lab la-amilia
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-android'></i> lab la-android
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-angellist'></i> lab la-angellist
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-angrycreative'></i> lab la-angrycreative
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-angular'></i> lab la-angular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-app-store'></i> lab la-app-store
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-app-store-ios'></i> lab la-app-store-ios
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-apper'></i> lab la-apper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-apple'></i> lab la-apple
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-artstation'></i> lab la-artstation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-asymmetrik'></i> lab la-asymmetrik
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-atlassian'></i> lab la-atlassian
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-audible'></i> lab la-audible
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-autoprefixer'></i> lab la-autoprefixer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-avianex'></i> lab la-avianex
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-aviato'></i> lab la-aviato
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-aws'></i> lab la-aws
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bandcamp'></i> lab la-bandcamp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-battle-net'></i> lab la-battle-net
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-behance'></i> lab la-behance
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-behance-square'></i> lab la-behance-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bimobject'></i> lab la-bimobject
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bitbucket'></i> lab la-bitbucket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bity'></i> lab la-bity
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-black-tie'></i> lab la-black-tie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-blackberry'></i> lab la-blackberry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-blogger'></i> lab la-blogger
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-blogger-b'></i> lab la-blogger-b
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bootstrap'></i> lab la-bootstrap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-buffer'></i> lab la-buffer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-buromobelexperte'></i> lab la-buromobelexperte
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-buy-n-large'></i> lab la-buy-n-large
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-buysellads'></i> lab la-buysellads
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-canadian-maple-leaf'></i> lab la-canadian-maple-leaf
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-centercode'></i> lab la-centercode
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-centos'></i> lab la-centos
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-chrome'></i> lab la-chrome
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-chromecast'></i> lab la-chromecast
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cloudscale'></i> lab la-cloudscale
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cloudsmith'></i> lab la-cloudsmith
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cloudversify'></i> lab la-cloudversify
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-codepen'></i> lab la-codepen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-codiepie'></i> lab la-codiepie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-confluence'></i> lab la-confluence
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-connectdevelop'></i> lab la-connectdevelop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-contao'></i> lab la-contao
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cotton-bureau'></i> lab la-cotton-bureau
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cpanel'></i> lab la-cpanel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons'></i>lab la-creative-commons
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-by'></i>lab la-creative-commons-by
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-nc'></i>lab la-creative-commons-nc
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-nc-eu'></i>lab la-creative-commons-nc-eu
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-nc-jp'></i>lab la-creative-commons-nc-jp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-nd'></i>lab la-creative-commons-nd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-pd'></i>lab la-creative-commons-pd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-pd-alt'></i>lab
                      la-creative-commons-pd-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-remix'></i>lab la-creative-commons-remix
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-sa'></i>lab la-creative-commons-sa
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-sampling'></i>lab
                      la-creative-commons-sampling
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-sampling-plus'></i>
                      lab la-creative-commons-sampling-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-share'></i>lab la-creative-commons-share
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-zero'></i>lab la-creative-commons-zero
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-css3'></i> lab la-css3
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-css3-alt'></i> lab la-css3-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cuttlefish'></i> lab la-cuttlefish
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dashcube'></i> lab la-dashcube
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-delicious'></i> lab la-delicious
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-deploydog'></i> lab la-deploydog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-deskpro'></i> lab la-deskpro
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dev'></i> lab la-dev
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-deviantart'></i> lab la-deviantart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dhl'></i> lab la-dhl
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-diaspora'></i> lab la-diaspora
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-digg'></i> lab la-digg
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-digital-ocean'></i> lab la-digital-ocean
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-discord'></i> lab la-discord
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-discourse'></i> lab la-discourse
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dochub'></i> lab la-dochub
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-docker'></i> lab la-docker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-draft2digital'></i> lab la-draft2digital
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dribbble'></i> lab la-dribbble
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dribbble-square'></i> lab la-dribbble-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dropbox'></i> lab la-dropbox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-drupal'></i> lab la-drupal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dyalog'></i> lab la-dyalog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-earlybirds'></i> lab la-earlybirds
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ebay'></i> lab la-ebay
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-edge'></i> lab la-edge
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-elementor'></i> lab la-elementor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ello'></i> lab la-ello
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ember'></i> lab la-ember
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-empire'></i> lab la-empire
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-envira'></i> lab la-envira
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-erlang'></i> lab la-erlang
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-etsy'></i> lab la-etsy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-evernote'></i> lab la-evernote
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-expeditedssl'></i> lab la-expeditedssl
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-facebook'></i> lab la-facebook
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-facebook-f'></i> lab la-facebook-k
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-facebook-messenger'></i> lab la-facebook-messenger
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-facebook-square'></i> lab la-facebook-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fedex'></i> lab la-fedex
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fedora'></i> lab la-fedora
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-figma'></i> lab la-figma
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-firefox'></i> lab la-firefox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-first-order'></i> lab la-first-order
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-first-order-alt'></i> lab la-first-order-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-firstdraft'></i> lab la-firstdraft
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-flickr'></i> lab la-flickr
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-flipboard'></i> lab la-flipboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fly'></i> lab la-fly
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-font-awesome'></i> lab la-font-awesome
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-font-awesome-alt'></i> lab la-font-awesome-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-font-awesome-flag'></i> lab la-font-awesome-flog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fonticons'></i> lab la-fonticons
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fonticons-fi'></i> lab la-fonticons-fi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fort-awesome'></i> lab la-fort-awesome
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fort-awesome-alt'></i> lab la-fort-awesome-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-forumbee'></i> lab la-forumbee
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-foursquare'></i> lab la-foursquare
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-free-code-camp'></i> lab la-free-code-camp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-freebsd'></i> lab la-freebsd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fulcrum'></i> lab la-fulcrum
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-get-pocket'></i> lab la-get-pocket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-git'></i> lab la-git
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-git-alt'></i>lab la-git-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-git-square'></i> lab la-git-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-github'></i>lab la-github
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-github-alt'></i>lab la-github-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-github-square'></i>lab la-github-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gitkraken'></i>lab la-gitkraken
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gitlab'></i> lab la-gitlab
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gitter'></i> lab la-gitter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-glide'></i> lab la-glide
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-glide-g'></i> lab la-glide-g
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gofore'></i> lab la-gofore
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-goodreads'></i> lab la-goodreads
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-goodreads-g'></i> lab la-goodreads-v
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-google'></i> lab la-google
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-google-drive'></i>lab la-google-drive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-google-play'></i>lab la-google-play
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-google-plus'></i>lab la-google-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-google-plus-g'></i>lab la-google-plus-g
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-google-plus-square'></i>lab la-google-plus-squar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gratipay'></i> lab la-gratipay
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-grav'></i> lab la-grav
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gripfire'></i> lab la-gripfire
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-grunt'></i> lab la-grunt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gulp'></i> lab la-gulp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hacker-news'></i> lab la-hacker-news
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hacker-news-square'></i> lab la-hacker-news-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hackerrank'></i> lab la-hackerrank
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hips'></i> lab la-hips
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hire-a-helper'></i> lab la-hire-a-helper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hooli'></i> lab la-hooli
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hornbill'></i> lab la-hornbill
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hotjar'></i> lab la-hotjar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-houzz'></i> lab la-houzz
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-html5'></i> lab la-html5
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hubspot'></i> lab la-hubspot
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-imdb'></i> lab la-imdb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-instagram'></i> lab la-instagram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-intercom'></i> lab la-intercom
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-internet-explorer'></i> lab la-internet-explorer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-invision'></i> lab la-invisio
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ioxhost'></i> lab la-ioxhost
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-itch-io'></i> lab la-itch-io
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-itunes'></i> lab la-itunes
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-itunes-note'></i> lab la-itunes-note
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-java'></i> lab la-java
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-jenkins'></i> lab la-jenkins
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-jira'></i> lab la-jira
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-joget'></i> lab la-joget
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-joomla'></i> lab la-joomla
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-js'></i> lab la-js
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-js-square'></i> lab la-js-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-jsfiddle'></i> lab la-jsfiddle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-kaggle'></i> lab la-kaggle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-keybase'></i> lab la-keybase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-keycdn'></i> lab la-keycdn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-kickstarter'></i> lab la-kickstarter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-kickstarter-k'></i> lab la-kickstarter-
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-korvue'></i> lab la-korvue
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-laravel'></i> lab la-laravel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-lastfm'></i> lab la-lastfm
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-lastfm-square'></i> lab la-lastfm-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-leanpub'></i> lab la-leanpub
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-less'></i> lab la-less
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-line'></i> lab la-line
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-linkedin'></i> lab la-linkedin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-linkedin-in'></i> lab la-linkedin-in
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-linode'></i> lab la-linode
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-linux'></i> lab la-linux
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-lyft'></i> lab la-lyft
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-magento'></i> lab la-magento
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mailchimp'></i> lab la-mailchimp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mandalorian'></i> lab la-mandalorian
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-markdown'></i> lab la-markdown
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mastodon'></i> lab la-mastodon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-maxcdn'></i> lab la-maxcdn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mdb'></i> lab la-mdb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-medapps'></i> lab la-medapps
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-medium'></i> lab la-medium
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-medium-m'></i> lab la-medium-m
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-medrt'></i> lab la-medrt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-meetup'></i> lab la-meetup
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-megaport'></i> lab la-megaport
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mendeley'></i> lab la-mendeley
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-microsoft'></i> lab la-microsoft
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mix'></i> lab la-mix
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mixcloud'></i> lab la-mixclou
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mizuni'></i> lab la-mizuni
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-modx'></i> lab la-modx
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-monero'></i> lab la-monero
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-neos'></i> lab la-neos
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-nimblr'></i> lab la-nimblr
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-node'></i> lab la-node
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-node-js'></i> lab la-node-js
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-npm'></i> lab la-npm
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ns8'></i> lab la-ns8
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-nutritionix'></i> lab la-nutritionix
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-odnoklassniki'></i> lab la-odnoklassniki
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-odnoklassniki-square'></i> lab la-odnoklassniki-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-opencart'></i> lab la-opencart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-openid'></i> lab la-openid
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-opera'></i> lab la-opera
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-optin-monster'></i> lab la-optin-monster
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-orcid'></i> lab la-orcid
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-osi'></i> lab la-osi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-page4'></i> lab la-page4
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pagelines'></i> lab la-pagelines
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-palfed'></i> lab la-palfed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-patreon'></i> lab la-patreon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-periscope'></i> lab la-periscope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-phabricator'></i> lab la-phabricator
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-phoenix-framework'></i> lab la-phoenix-framework
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-phoenix-squadron'></i> lab la-phoenix-squadron
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-php'></i> lab la-php
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pied-piper'></i> lab la-pied-piper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pied-piper-alt'></i> lab la-pied-piper-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pied-piper-hat'></i> lab la-pied-piper-hat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pied-piper-pp'></i> lab la-pied-piper-pp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pinterest'></i> lab la-pinterest
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pinterest-p'></i> lab la-pinterest-p
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pinterest-square'></i> lab la-pinterest-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-product-hunt'></i> lab la-product-hunt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pushed'></i> lab la-pushed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-python'></i> lab la-python
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-qq'></i> lab la-qq
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-quinscape'></i> lab la-quinscape
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-quora'></i> lab la-quora
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-r-project'></i> lab la-r-project
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-raspberry-pi'></i> lab la-raspberry-p
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ravelry'></i> lab la-ravelry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-react'></i> lab la-react
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-reacteurope'></i> lab la-reacteurope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-readme'></i> lab la-readme
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-rebel'></i> lab la-rebel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-red-river'></i> lab la-red-river
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-reddit'></i> lab la-reddit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-reddit-alien'></i>lab la-reddit-alien
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-reddit-square'></i>lab la-reddit-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-redhat'></i>lab la-redhat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-renren'></i>lab la-renren
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-researchgate'></i> lab la-researchgate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-resolving'></i> lab la-resolving
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-rev'></i> lab la-rev
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-rocketchat'></i> lab la-rocketchat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-rockrms'></i> lab la-rockrms
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-safari'></i> lab la-safari
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-salesforce'></i> lab la-salesforce
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sass'></i> lab la-sass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-schlix'></i> lab la-schlix
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-scribd'></i> lab la-scribd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-searchengin'></i> lab la-searchengin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sellcast'></i> lab la-sellcast
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sellsy'></i> lab la-sells
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-servicestack'></i> lab la-servicestack
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-shirtsinbulk'></i> lab la-shirtsinbulk
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-shopware'></i> lab la-shopware
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-simplybuilt'></i> lab la-simplybuilt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sith'></i> lab la-sith
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sketch'></i> lab la-sketch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-skyatlas'></i> lab la-skyatlas
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-skype'></i> lab la-skype
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-slack'></i> lab la-slack
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-slack-hash'></i> lab la-slack-has
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-slideshare'></i> lab la-slideshare
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-snapchat'></i> lab la-snapchat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-snapchat-ghost'></i> lab la-snapchat-ghos
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-snapchat-square'></i> lab la-snapchat-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sourcetree'></i> lab la-sourcetree
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-speakap'></i> lab la-speakap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-speaker-deck'></i> lab la-speaker-deck
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-squarespace'></i> lab la-squarespace
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-stack-exchange'></i> lab la-stack-exchange
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-stack-overflow'></i> lab la-stack-overflow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-stackpath'></i> lab la-stackpath
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-staylinked'></i> lab la-staylinked
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sticker-mule'></i> lab la-sticker-mule
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-strava'></i> lab la-strava
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-studiovinari'></i> lab la-studiovinari
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-stumbleupon'></i> lab la-stumbleupon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-stumbleupon-circle'></i> lab la-stumbleupon-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-superpowers'></i> lab la-superpowers
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-supple'></i> lab la-supple
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-suse'></i> lab la-suse
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-swift'></i> lab la-swift
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-symfony'></i> lab la-symfony
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-teamspeak'></i> lab la-teamspeak
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-telegram'></i> lab la-telegram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-telegram-plane'></i> lab la-telegram-plane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-tencent-weibo'></i> lab la-tencent-weibo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-the-red-yeti'></i> lab la-the-red-yeti
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-themeco'></i> lab la-themeco
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-themeisle'></i> lab la-themeisle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-think-peaks'></i> lab la-think-peaks
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-trade-federation'></i> lab la-trade-federation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-trello'></i> lab la-trello
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-tripadvisor'></i> lab la-tripadvisor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-tumblr'></i> lab la-tumblr
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-tumblr-square'></i> lab la-tumblr-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-twitter'></i>lab la-twitter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-twitter-square'></i>lab la-twitter-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-typo3'></i> lab la-typo3
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-uber'></i> lab la-uber
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ubuntu'></i> lab la-ubuntu
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-uikit'></i> lab la-uikit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-umbraco'></i> lab la-umbraco
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-uniregistry'></i> lab la-uniregistry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-untappd'></i> lab la-untappd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ups'></i> lab la-ups
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-usb'></i> lab la-usb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-usps'></i> lab la-usps
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ussunnah'></i> lab la-ussunnah
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vaadin'></i> lab la-vaadin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-viacoin'></i> lab la-viacoin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-viadeo'></i> lab la-viadeo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-viadeo-square'></i> lab la-viadeo-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-viber'></i> lab la-viber
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vimeo'></i> lab la-vimeo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vimeo-square'></i> lab la-vimeo-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vimeo-v'></i> lab la-vimeo-v
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vine'></i> lab la-vine
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vk'></i> lab la-vk
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vnv'></i> lab la-vnv
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vuejs'></i> lab la-vuejs
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-waze'></i> lab la-waze
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-weebly'></i> lab la-weebly
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-weibo'></i> lab la-weibo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-weixin'></i> lab la-weixin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-whatsapp'></i> lab la-whatsapp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-whatsapp-square'></i> lab la-whatsapp-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-whmcs'></i> lab la-whmcs
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wikipedia-w'></i> lab la-wikipedia-w
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-windows'></i> lab la-windows
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wix'></i> lab la-wix
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wolf-pack-battalion'></i> lab la-wolf-pack-battalion
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wordpress'></i> lab la-wordpress
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wordpress-simple'></i> lab la-wordpress-simple
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wpbeginner'></i> lab la-wpbeginner
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wpexplorer'></i> lab la-wpexplorer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wpforms'></i> lab la-wpforms
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wpressr'></i> lab la-wpressr
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-xing'></i> lab la-xing
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-xing-square'></i> lab la-xing-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-y-combinator'></i> lab la-y-combinator
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yahoo'></i> lab la-yahoo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yammer'></i> lab la-yammer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yandex'></i> lab la-yandex
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yandex-international'></i> lab la-yandex-international
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yarn'></i> lab la-yarn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yelp'></i> lab la-yelp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yoast'></i> lab la-yoast
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-youtube-square'></i> lab la-youtube-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-zhihu'></i> lab la-zhihu
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Buildings</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-archway'></i> las la-archway
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-building'></i> las la-building
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-campground'></i> las la-campgroun
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-church'></i> las la-church
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-city'></i> las la-city
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clinic-medical'></i> las la-clinic-medical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dungeon'></i> las la-dungeon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gopuram'></i> las la-gopuram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-home'></i> las la-home
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hospital'></i> las la-hospital
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hospital-alt'></i> las la-hospital-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hotel'></i> las la-hotel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-house-damage'></i> las la-house-damage
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-igloo'></i> las la-igloo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-industry'></i> las la-industry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-kaaba'></i> las la-kaaba
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-landmark'></i> las la-landmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-monument'></i> las la-monument
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mosque'></i> las la-mosque
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-place-of-worship'></i> las la-place-of-worship
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-school'></i> las la-school
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-store'></i> las la-store
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-store-alt'></i> las la-store-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-synagogue'></i> las la-synagogue
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-torii-gate'></i> las la-torii-gate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-university'></i> las la-university
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-vihara'></i> las la-vihara
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-warehouse'></i> las la-warehouse
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Business</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-address-book'></i> las la-address-book
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-address-card'></i> las la-address-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-archive'></i> las la-archive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale'></i> las la-balance-scale
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale-left'></i> las la-balance-scale-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale-right'></i> las la-balance-scale-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-birthday-cake'></i> las la-birthday-cake
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-book'></i> las la-book
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-briefcase'></i> las la-briefcase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-building'></i> las la-building
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bullhorn'></i> las la-bullhorn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bullseye'></i> las la-bullseye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-business-time'></i> las la-business-tim
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calculator'></i> las la-calculator
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar'></i> las la-calendar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-alt'></i> las la-calendar-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-certificate'></i> las la-certificate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chart-area'></i> las la-chart-area
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chart-bar'></i> las la-chart-ba
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chart-line'></i> las la-chart-line
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chart-pie'></i> las la-chart-pie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-city'></i> las la-city
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clipboard'></i> las la-clipboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-coffee'></i> las la-coffee
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-columns'></i> las la-columns
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-compass'></i> las la-compass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-copy'></i> las la-copy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-copyright'></i> las la-copyright
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cut'></i> las la-cut
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-edit'></i> las la-edit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-envelope'></i> las la-envelop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-envelope-open'></i> las la-envelope-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-envelope-square'></i> las la-envelope-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eraser'></i> las la-eraser
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fax'></i> las la-fax
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file'></i> las la-file
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-alt'></i> las la-file-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-folder'></i> las la-folder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-folder-minus'></i> las la-folder-minus"
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-folder-open'></i> las la-folder-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-folder-plus'></i> las la-folder-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glasses'></i> las la-glasses
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-globe'></i> las la-globe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-highlighter'></i> las la-highlighter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-industry'></i> las la-industry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-landmark'></i> las la-landmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-marker'></i> las la-marker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paperclip'></i> las la-paperclip
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paste'></i> las la-paste
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen'></i> las la-pen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen-alt'></i> las la-pen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen-fancy'></i> las la-pen-fancy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen-nib'></i> las la-pen-nib
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen-square'></i> las la-pen-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pencil-alt'></i> las la-pencil-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-percent'></i> las la-percent
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone'></i> las la-phone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-alt'></i> las la-phone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-slash'></i> las la-phone-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-square'></i> las la-phone-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-square-alt'></i> las la-phone-square-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-volume'></i> las la-phone-volume
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-print'></i> las la-print
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-project-diagram'></i> las la-project-diagram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-registered'></i> las la-registered
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-save'></i> las la-save
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sitemap'></i> las la-sitemap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-socks'></i> las la-socks
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sticky-note'></i> las la-sticky-not
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stream'></i> las la-stream
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-table'></i> las la-table
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tag'></i> las la-tag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tags'></i> las la-tags
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tasks'></i> las la-tasks
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbtack'></i> las la-thumbtack
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trademark'></i> las la-trademark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wallet'></i> las la-wallet
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Camping</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-binoculars'></i> las la-binoculars
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-campground'></i> las la-campground
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-compass'></i> las la-compass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fire'></i> las la-fire
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      {' '}
                      las la-fire-alt
                      <i className='las la-fire-alt'></i>
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-first-aid'></i> las la-first-aid
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-frog'></i> las la-frog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hiking'></i> las la-hiking
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map'></i> las la-map
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-marked'></i> las la-map-marked
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-signs'></i> las la-map-signs
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mountain'></i> las la-mountain
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-route'></i> las la-route
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-toilet-paper'></i> las la-toilet-paper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tree'></i> las la-tree
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Charity</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dollar-sign'></i> las la-dollar-sign
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-donate'></i> las la-donate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dove'></i> las la-dove
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gift'></i> las la-gift
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-globe'></i> las la-globe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-holding-heart'></i> las la-hand-holding-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-holding-usd'></i> las la-hand-holding-usd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hands-helping'></i> las la-hands-helping
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-handshake'></i> las la-handshake
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heart'></i> las la-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-leaf'></i> las la-leaf
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-parachute-box'></i> las la-parachute-box
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-piggy-bank'></i> las la-piggy-bank
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ribbon'></i> las la-ribbon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-seedling'></i> las la-seedling
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Chat</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment'></i> las la-comment
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment-alt'></i> las la-comment-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment-dots'></i> las la-comment-dots
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment-medical'></i> las la-comment-medical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment-slash'></i> las la-comment-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comments'></i> las la-comments
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-frown'></i> las la-frown
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-icons'></i> las la-icons
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-meh'></i> las la-meh
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone'></i> las la-phone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-alt'></i> las la-phone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-slash'></i> las la-phone-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-poo'></i> las la-poo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-quote-left'></i> las la-quote-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-quote-right'></i> las la-quote-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-smile'></i> las la-smile
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sms'></i> las la-sms
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-video'></i> las la-video
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-video-slash'></i> las la-video-slash
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Chess</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess'></i> las la-chess
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-bishop'></i> las la-chess-bishop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-board'></i> las la-chess-board
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-king'></i> las la-chess-king
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-knight'></i> las la-chess-knight
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-pawn'></i> las la-chess-pawn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-queen'></i> las la-chess-queen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-rook'></i> las la-chess-rook
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-square-full'></i> las la-square-full
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Childhood</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-apple-alt'></i> las la-apple-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-baby'></i> las la-baby
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-baby-carriage'></i> las la-baby-carriage
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bath'></i> las la-bath
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-biking'></i> las la-biking
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-birthday-cake'></i> las la-birthday-cake
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cookie'></i> las la-cookie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cookie-bite'></i> las la-cookie-bite
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gamepad'></i> las la-gamepad
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ice-cream'></i> las la-ice-cream
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mitten'></i> las la-mitten
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-robot'></i> las la-robot
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-school'></i> las la-school
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shapes'></i> las la-shapes
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-snowman'></i> las la-snowman
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Clothing</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-graduation-cap'></i> las la-graduation-cap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hat-cowboy'></i> las la-hat-cowboy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hat-cowboy-side'></i> las la-hat-cowboy-side
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hat-wizard'></i> las la-hat-wizard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mitten'></i> las la-mitten
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shoe-prints'></i> las la-shoe-prints
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-socks'></i> las la-socks
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tshirt'></i> las la-tshirt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-tie'></i> las la-user-tie
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Code</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-archive'></i> las la-archive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-barcode'></i> las la-barcode
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bath'></i> las la-bath
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bug'></i> las la-bug
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-code'></i> las la-code
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-code-branch'></i> las la-code-branch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-coffee'></i> las la-coffee
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file'></i> las la-file
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-alt'></i> las la-file-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-code'></i> las la-file-code
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-filter'></i> las la-filter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fire-extinguisher'></i> las la-fire-extinguisher
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-folder'></i> las la-folder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-folder-open'></i> las la-folder-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-keyboard'></i> las la-keyboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-laptop-code'></i> las la-laptop-code
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microchip'></i> las la-microchip
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-project-diagram'></i> las la-project-diagram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-qrcode'></i> las la-qrcode
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shield-alt'></i> las la-shield-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sitemap'></i> las la-sitemap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stream'></i> las la-stream
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-terminal'></i> las la-terminal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-secret'></i> las la-user-secret
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-window-close'></i> las la-window-close
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-window-maximize'></i> las la-window-maximize
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-window-minimize'></i> las la-window-minimize
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-window-restore'></i> las la-window-restore
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Communication</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-address-book'></i> las la-address-book
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-address-card'></i> las la-address-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-american-sign-language-interpreting'></i> las
                      la-american-sign-language-interpreting
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-assistive-listening-systems'></i> las
                      la-assistive-listening-systems
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-at'></i> las la-at
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell'></i> las la-bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell-slash'></i> las la-bell-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bluetooth'></i> lab la-bluetooth
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bluetooth-b'></i> lab la-bluetooth-b
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-broadcast-tower'></i> las la-broadcast-tower
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bullhorn'></i> las la-bullhorn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chalkboard'></i> las la-chalkboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment'></i> las la-comment
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment-alt'></i> las la-comment-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comments'></i> las la-comments
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-envelope'></i> las la-envelope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-envelope-open'></i> las la-envelope-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-envelope-square'></i> las la-envelope-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fax'></i> las la-fax
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-inbox'></i> las la-inbox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-language'></i> las la-language
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone'></i> las la-microphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-alt'></i> las la-microphone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-alt-slash'></i> las la-microphone-alt-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-slash'></i> las la-microphone-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mobile'></i> las la-mobile
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mobile-alt'></i> las la-mobile-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paper-plane'></i> las la-paper-plane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone'></i> las la-phone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-alt'></i> las la-phone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-slash'></i> las la-phone-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-square'></i> las la-phone-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-square-alt'></i> las la-phone-square-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-volume'></i> las la-phone-volume
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-rss'></i> las la-rss
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-rss-square'></i> las la-rss-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tty'></i> las la-tty
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-voicemail'></i> las la-voicemail
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wifi'></i> las la-wifi
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Computers</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-database'></i> las la-database
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-desktop'></i> las la-desktop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-download'></i> las la-download
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ethernet'></i> las la-ethernet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hdd'></i> las la-hdd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-headphones'></i> las la-headphones
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-keyboard'></i> las la-keyboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-laptop'></i> las la-laptop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-memory'></i> las la-memory
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microchip'></i> las la-microchip
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mobile'></i> las la-mobile
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mobile-alt'></i> las la-mobile-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mouse'></i> las la-mouse
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plug'></i> las la-plug
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-power-off'></i> las la-power-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-print'></i> las la-print
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-satellite'></i> las la-satellite
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-satellite-dish'></i> las la-satellite-dish
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-save'></i> las la-save
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sd-card'></i> las la-sd-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-server'></i> las la-server
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sim-card'></i> las la-sim-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stream'></i> las la-stream
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tablet'></i> las la-tablet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tablet-alt'></i> las la-tablet-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tv'></i> las la-tv
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-upload'></i> las la-upload
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Construction</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-brush'></i> las la-brush
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-drafting-compass'></i> las la-drafting-compass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dumpster'></i> las la-dumpster
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hammer'></i> las la-hammer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hard-hat'></i> las la-hard-hat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paint-roller'></i> las la-paint-roller
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pencil-alt'></i> las la-pencil-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pencil-ruler'></i> las la-pencil-ruler
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ruler'></i> las la-ruler
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ruler-combined'></i> las la-ruler-combined
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ruler-horizontal'></i> las la-ruler-horizontal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ruler-vertical'></i> las la-ruler-vertical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-screwdriver'></i> las la-screwdriver
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-toolbox'></i> las la-toolbox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tools'></i> las la-tools
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-truck-pickup'></i> las la-truck-pickup
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wrench'></i> las la-wrench
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Currency</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bitcoin'></i> lab la-bitcoin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-btc'></i> lab la-btc
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dollar-sign'></i> las la-dollar-sign
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ethereum'></i> lab la-ethereum
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-euro-sign'></i> las la-euro-sign
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gg'></i> lab la-gg
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gg-circle'></i> lab la-gg-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hryvnia'></i> las la-hryvnia
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lira-sign'></i> las la-lira-sign
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-bill'></i> las la-money-bill
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-bill-alt'></i> las la-money-bill-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-bill-wave'></i> las la-money-bill-wave
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-bill-wave-alt'></i> las la-money-bill-wave-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-check'></i> las la-money-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-check-alt'></i> las la-money-check-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pound-sign'></i> las la-pound-sign
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ruble-sign'></i> las la-ruble-sign
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-rupee-sign'></i> las la-rupee-sign
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shekel-sign'></i> las la-shekel-sign
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tenge'></i> las la-tenge
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-won-sign'></i> las la-won-sign
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-yen-sign'></i> las la-yen-sign
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Date & Time</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell'></i> las la-bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell-slash'></i> las la-bell-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar'></i> las la-calendar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-alt'></i> las la-calendar-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-check'></i> las la-calendar-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-minus'></i> las la-calendar-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-plus'></i> las la-calendar-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-times'></i> las la-calendar-times
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clock'></i> las la-clock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hourglass'></i> las la-hourglass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hourglass-end'></i> las la-hourglass-end
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hourglass-half'></i> las la-hourglass-half
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hourglass-start'></i> las la-hourglass-start
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stopwatch'></i> las la-stopwatch
                    </Col>
                  </Row>
                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Design</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-adjust'></i> las la-adjust
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bezier-curve'></i> las la-bezier-curve
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-brush'></i> las la-brush
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clone'></i> las la-clone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-copy'></i> las la-copy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-crop'></i> las la-crop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-crop-alt'></i> las la-crop-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-crosshairs'></i> las la-crosshairs
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cut'></i> las la-cut
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-drafting-compass'></i> las la-drafting-compass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-draw-polygon'></i> las la-draw-polygon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-edit'></i> las la-edit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eraser'></i> las la-eraser
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye'></i> las la-eye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye-dropper'></i> las la-eye-dropper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye-slash'></i> las la-eye-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fill'></i> las la-fill
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fill-drip'></i> las la-fill-drip
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-highlighter'></i> las la-highlighter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-icons'></i> las la-icons
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-layer-group'></i> las la-layer-group
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-magic'></i> las la-magic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-marker'></i> las la-marker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-object-group'></i> las la-object-group
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-object-ungroup'></i> las la-object-ungroup
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paint-brush'></i> las la-paint-brush
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paint-roller'></i> las la-paint-roller
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-palette'></i> las la-palette
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paste'></i> las la-paste
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen'></i> las la-pen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen-alt'></i> las la-pen-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen-fancy'></i> las la-pen-fancy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen-nib'></i> las la-pen-nib
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pencil-alt'></i> las la-pencil-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pencil-ruler'></i> las la-pencil-ruler
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ruler-combined'></i> las la-ruler-combined
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ruler-horizontal'></i> las la-ruler-horizontal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ruler-vertical'></i> las la-ruler-vertical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-save'></i> las la-save
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-splotch'></i> las la-splotch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-spray-can'></i> las la-spray-can
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stamp'></i> las la-stamp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-swatchbook'></i> las la-swatchbook
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tint'></i> las la-tint
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tint-slash'></i> las la-tint-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-vector-square'></i> las la-vector-square
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Editors</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-align-center'></i> las la-align-center
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-align-justify'></i> las la-align-justify
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-align-left'></i> las la-align-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-align-right'></i> las la-align-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bold'></i> las la-bold
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-border-all'></i> las la-border-all
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-border-none'></i> las la-border-none
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-border-style'></i> las la-border-style
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clipboard'></i> las la-clipboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clone'></i> las la-clone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-columns'></i> las la-columns
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-copy'></i> las la-copy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cut'></i> las la-cut
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-edit'></i> las la-edit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eraser'></i> las la-eraser
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file'></i> las la-file
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-alt'></i> las la-file-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-font'></i> las la-font
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glasses'></i> las la-glasses
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heading'></i> las la-heading
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-highlighter'></i> las la-highlighter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-i-cursor'></i> las la-i-cursor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-icons'></i> las la-icons
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-indent'></i> las la-indent
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-italic'></i> las la-italic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-link'></i> las la-link
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-list'></i> las la-list
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-list-alt'></i> las la-list-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-list-ol'></i> las la-list-ol
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-list-ul'></i> las la-list-ul
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-marker'></i> las la-marker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-outdent'></i> las la-outdent
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paper-plane'></i> las la-paper-plane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paperclip'></i> las la-paperclip
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paragraph'></i> las la-paragraph
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paste'></i> las la-paste
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen'></i> las la-pen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen-alt'></i> las la-pen-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen-fancy'></i> las la-pen-fancy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen-nib'></i> las la-pen-nib
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pencil-alt'></i> las la-pencil-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-print'></i> las la-print
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-quote-left'></i> las la-quote-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-quote-right'></i> las la-quote-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-reply-all'></i> las la-reply-all
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-screwdriver'></i> las la-screwdriver
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-share'></i> las la-share
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-spell-check'></i> las la-spell-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-strikethrough'></i> las la-strikethrough
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-subscript'></i> las la-subscript
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sync'></i> las la-sync
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-text-width'></i> las la-text-width
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-th'></i> las la-th
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-th-large'></i> las la-th-large
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-th-list'></i> las la-th-list
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tools'></i> las la-tools
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trash'></i> las la-trash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trash-alt'></i> las la-trash-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trash-restore'></i> las la-trash-restore
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trash-restore-alt'></i> las la-trash-restore-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-underline'></i> las la-underline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-undo'></i>
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-undo-alt'></i> las la-undo-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-unlink'></i> las la-unlink
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wrench'></i> las la-wrench
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Education</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-apple-alt'></i> las la-apple-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-atom'></i> las la-atom
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-award'></i> las la-award
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell'></i> las la-bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell-slash'></i> las la-bell-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-book-open'></i> las la-book-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-book-reader'></i> las la-book-reader
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chalkboard'></i> las la-chalkboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chalkboard-teacher'></i> las la-chalkboard-teacher
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-graduation-cap'></i> las la-graduation-cap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-laptop-code'></i> las la-laptop-code
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microscope'></i> las la-microscope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-music'></i> las la-music
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-school'></i> las la-school
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shapes'></i> las la-shapes
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-theater-masks'></i> las la-theater-masks
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-graduate'></i> las la-user-graduate
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Emoji</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-angry'></i> las la-angry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dizzy'></i> las la-dizzy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-flushed'></i> las la-flushed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-frown'></i> las la-frown
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-frown-open'></i> las la-frown-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grimace'></i> las la-grimace
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grin'></i> las la-grin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grin-alt'></i> las la-grin-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grin-beam'></i> las la-grin-beam
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grin-beam-sweat'></i> las la-grin-beam-sweat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grin-hearts'></i> las la-grin-hearts
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grin-squint'></i> las la-grin-squint
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grin-squint-tears'></i> las la-grin-squint-tears
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grin-stars'></i> las la-grin-stars
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grin-tears'></i> las la-grin-tears
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grin-tongue'></i> las la-grin-tongue
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grin-tongue-squint'></i> las la-grin-tongue-squint
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grin-tongue-wink'></i> las la-grin-tongue-wink
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grin-wink'></i> las la-grin-wink
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-kiss'></i> las la-kiss
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-kiss-beam'></i> las la-kiss-beam
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-kiss-wink-heart'></i> las la-kiss-wink-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-laugh'></i> las la-laugh
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-laugh-beam'></i> las la-laugh-beam
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-meh-rolling-eyes'></i> las la-meh-rolling-eyes
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sad-cry'></i> las la-sad-cry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sad-tear'></i> las la-sad-tear
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-smile'></i> las la-smile
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-smile-beam'></i> las la-smile-beam
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-smile-wink'></i> las la-smile-wink
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-surprise'></i> las la-surprise
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tired'></i> las la-tired
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Energy</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-atom'></i> las la-atom
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-battery-empty'></i> las la-battery-empty
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-battery-full'></i> las la-battery-full
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-battery-half'></i> las la-battery-half
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-battery-quarter'></i> las la-battery-quarter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-battery-three-quarters'></i> las
                      la-battery-three-quarters
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-broadcast-tower'></i> las la-broadcast-tower
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-burn'></i> las la-burn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-charging-station'></i> las la-charging-station
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fire'></i> las la-fire
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fire-alt'></i> las la-fire-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gas-pump'></i> las la-gas-pump
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-industry'></i> las la-industry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-leaf'></i> las la-leaf
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lightbulb'></i> las la-lightbulb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plug'></i> las la-plug
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-poop'></i> las la-poop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-power-off'></i> las la-power-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-radiation'></i> las la-radiation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-radiation-alt'></i> las la-radiation-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-seedling'></i> las la-seedling
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-solar-panel'></i> las la-solar-panel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sun'></i> las la-sun
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-water'></i> las la-water
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wind'></i> las la-wind
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Files</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-archive'></i> las la-archive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clone'></i> las la-clone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-copy'></i> las la-copy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cut'></i> las la-cut
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file'></i> las la-file
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-alt'></i> las la-file-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-archive'></i> las la-file-archive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-audio'></i> las la-file-audio
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-code'></i> las la-file-code
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-excel'></i> las la-file-excel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-image'></i> las la-file-image
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-pdf'></i> las la-file-pdf
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-powerpoint'></i> las la-file-powerpoint
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-video'></i> las la-file-video
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-word'></i> las la-file-word
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-folder'></i> las la-folder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-folder-open'></i> las la-folder-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paste'></i> las la-paste
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-photo-video'></i> las la-photo-video
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-save'></i> las la-save
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sticky-note'></i> las la-sticky-note
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Finance</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale'></i> las la-balance-scale
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale-left'></i> las la-balance-scale-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale-right'></i> las la-balance-scale-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-book'></i> las la-book
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cash-register'></i> las la-cash-register
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chart-line'></i> las la-chart-line
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chart-pie'></i> las la-chart-pie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-coins'></i> las la-coins
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment-dollar'></i> las la-comment-dollar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comments-dollar'></i> las la-comments-dollar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-credit-card'></i> las la-credit-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-donate'></i> las la-donate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-invoice'></i> las la-file-invoice
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-invoice-dollar'></i> las la-file-invoice-dollar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-holding-usd'></i> las la-hand-holding-usd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-landmark'></i> las la-landmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-bill'></i> las la-money-bill
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-bill-alt'></i> las la-money-bill-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-bill-wave'></i> las la-money-bill-wave
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-bill-wave-alt'></i> las la-money-bill-wave-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-check'></i> las la-money-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-check-alt'></i> las la-money-check-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-percentage'></i> las la-percentage
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-piggy-bank'></i> las la-piggy-bank
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-receipt'></i> las la-receipt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stamp'></i> las la-stamp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wallet'></i> las la-wallet
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Fitness</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bicycle'></i> las la-bicycle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bacon'></i> las la-bacon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bone'></i> las la-bone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bread-slice'></i> las la-bread-slice
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-candy-cane'></i> las la-candy-cane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-carrot'></i> las la-carrot
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cheese'></i> las la-cheese
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-meatball'></i> las la-cloud-meatball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cookie'></i> las la-cookie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-drumstick-bite'></i> las la-drumstick-bite
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-egg'></i> las la-egg
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fish'></i> las la-fish
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hamburger'></i> las la-hamburger
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hotdog'></i> las la-hotdog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ice-cream'></i> las la-ice-cream
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lemon'></i> las la-lemon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pepper-hot'></i> las la-pepper-hot
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pizza-slice'></i> las la-pizza-slice
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-seedling'></i> las la-seedling
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stroopwafel'></i> las la-stroopwafel
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Games</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess'></i> las la-chess
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-bishop'></i> las la-chess-bishop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-board'></i> las la-chess-board
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-king'></i> las la-chess-king
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-knight'></i> las la-chess-knight
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-pawn'></i> las la-chess-pawn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-queen'></i> las la-chess-queen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chess-rook'></i> las la-chess-rook
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice'></i> las la-dice
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-d20'></i> las la-dice-d20
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-d6'></i> las la-dice-d6
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-five'></i> las la-dice-five
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-four'></i> las la-dice-four
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-one'></i> las la-dice-one
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-six'></i> las la-dice-six
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-three'></i> las la-dice-three
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-two'></i> las la-dice-two
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gamepad'></i> las la-gamepad
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ghost'></i> las la-ghost
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-headset'></i> las la-headset
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heart'></i> las la-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-playstation'></i> lab la-playstation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-puzzle-piece'></i> las la-puzzle-piece
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-steam'></i> lab la-steam
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-steam-square'></i> lab la-steam-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-steam-symbol'></i> lab la-steam-symbol
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-twitch'></i> lab la-twitch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-xbox'></i> lab la-xbox
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Genders</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-genderless'></i> las la-genderless
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mars'></i> las la-mars
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mars-double'></i> las la-mars-double
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mars-stroke'></i> las la-mars-stroke
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mars-stroke-h'></i> las la-mars-stroke-h
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mars-stroke-v'></i> las la-mars-stroke-v
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mercury'></i> las la-mercury
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-neuter'></i> las la-neuter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-transgender'></i> las la-transgender
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-transgender-alt'></i> las la-transgender-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-venus'></i> las la-venus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-venus-double'></i> las la-venus-double
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-venus-mars'></i> las la-venus-mars
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Halloween</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-book-dead'></i> las la-book-dead
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-broom'></i> las la-broom
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cat'></i> las la-cat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-moon'></i> las la-cloud-moon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-crow'></i> las la-crow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ghost'></i> las la-ghost
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hat-wizard'></i> las la-hat-wizard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mask'></i> las la-mask
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skull-crossbones'></i> las la-skull-crossbones
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-spider'></i> las la-spider
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-toilet-paper'></i> las la-toilet-paper
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Hands</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-allergies'></i> las la-allergies
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fist-raised'></i> las la-fist-raised
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-holding'></i> las la-hand-holding
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-holding-heart'></i> las la-hand-holding-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-holding-usd'></i> las la-hand-holding-usd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-lizard'></i> las la-hand-lizard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-middle-finger'></i> las la-hand-middle-finger
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-paper'></i> las la-hand-paper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-peace'></i> las la-hand-peace
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-point-down'></i> las la-hand-point-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-point-left'></i> las la-hand-point-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-point-right'></i> las la-hand-point-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-point-up'></i> las la-hand-point-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-pointer'></i> las la-hand-pointer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-rock'></i> las la-hand-rock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-scissors'></i> las la-hand-scissors
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hand-spock'></i> las la-hand-spock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hands'></i> las la-hands
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hands-helping'></i> las la-hands-helping
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-handshake'></i> las la-handshake
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-praying-hands'></i> las la-praying-hands
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbs-down'></i> las la-thumbs-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbs-up'></i> las la-thumbs-up
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Health</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-accessible-icon'></i> lab la-accessible-icon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ambulance'></i> las la-ambulance
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-h-square'></i> las la-h-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heart'></i> las la-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heartbeat'></i> las la-heartbeat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hospital'></i> las la-hospital
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-medkit'></i> las la-medkit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plus-square'></i> las la-plus-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-prescription'></i> las la-prescription
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stethoscope'></i> las la-stethoscope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-md'></i> las la-user-md
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wheelchair'></i> las la-wheelchair
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Holiday</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-candy-cane'></i> las la-candy-cane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-carrot'></i> las la-carrot
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cookie-bite'></i> las la-cookie-bite
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gift'></i> las la-gift
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gifts'></i> las la-gifts
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-cheers'></i> las la-glass-cheers
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-holly-berry'></i> las la-holly-berry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mug-hot'></i> las la-mug-hot
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sleigh'></i> las la-sleigh
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-snowman'></i> las la-snowman
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Hotel</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-baby-carriage'></i> las la-baby-carriage
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bath'></i> las la-bath
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bed'></i> las la-bed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-briefcase'></i> las la-briefcase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-car'></i> las la-car
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cocktail'></i> las la-cocktail
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-coffee'></i> las la-coffee
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-concierge-bell'></i> las la-concierge-bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice'></i> las la-dice
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-five'></i> las la-dice-five
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-door-closed'></i> las la-door-closed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-door-open'></i> las la-door-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dumbbell'></i> las la-dumbbell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-martini'></i> las la-glass-martini
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-martini-alt'></i> las la-glass-martini-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hot-tub'></i> las la-hot-tub
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hotel'></i> las la-hotel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-infinity'></i> las la-infinity
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-key'></i> las la-key
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-luggage-cart'></i> las la-luggage-cart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shower'></i> las la-shower
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shuttle-van'></i> las la-shuttle-van
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-smoking'></i> las la-smoking
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-smoking-ban'></i> las la-smoking-ban
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-snowflake'></i> las la-snowflake
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-spa'></i> las la-spa
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-suitcase'></i> las la-suitcase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-suitcase-rolling'></i> las la-suitcase-rolling
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-swimmer'></i> las la-swimmer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-swimming-pool'></i> las la-swimming-pool
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tv'></i> las la-tv
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-umbrella-beach'></i> las la-umbrella-beach
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-utensils'></i> las la-utensils
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wheelchair'></i> las la-wheelchair
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wifi'></i> las la-wifi
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Household</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bath'></i> las la-bath
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bed'></i> las la-bed
                    </Col>
                    <Col xl={3} lg={4} sm={6}></Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-blender'></i> las la-blender
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chair'></i> las la-chair
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-couch'></i> las la-couch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-door-closed'></i> las la-door-closed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-door-open'></i> las la-door-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dungeon'></i> las la-dungeon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fan'></i> las la-fan
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shower'></i> las la-shower
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-toilet-paper'></i> las la-toilet-paper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tv'></i> las la-tv
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Images</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-adjust'></i> las la-adjust
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bolt'></i> las la-bolt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-camera'></i> las la-camera
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-camera-retro'></i> las la-camera-retro
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chalkboard'></i> las la-chalkboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clone'></i> las la-clone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-compress'></i> las la-compress
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-compress-arrows-alt'></i> las la-compress-arrows-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-expand'></i> las la-expand
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye'></i> las la-eye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye-dropper'></i> las la-eye-dropper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye-slash'></i> las la-eye-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-image'></i> las la-file-image
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-film'></i> las la-film
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-id-badge'></i> las la-id-badge
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-id-card'></i> las la-id-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-image'></i> las la-image
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-images'></i> las la-images
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-photo-video'></i> las la-photo-video
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-portrait'></i> las la-portrait
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sliders-h'></i> las la-sliders-h
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tint'></i> las la-tint
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Interfaces</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-award'></i> las la-award
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ban'></i> las la-ban
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-barcode'></i> las la-barcode
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bars'></i> las la-bars
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-beer'></i> las la-beer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell'></i> las la-bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell-slash'></i> las la-bell-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-blog'></i> las la-blog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bug'></i> las la-bug
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bullhorn'></i> las la-bullhorn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bullseye'></i> las la-bullseye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calculator'></i> las la-calculator
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar'></i> las la-calendar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-alt'></i> las la-calendar-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-check'></i> las la-calendar-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-minus'></i> las la-calendar-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-plus'></i> las la-calendar-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-times'></i> las la-calendar-times
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-certificate'></i> las la-certificate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-check'></i> las la-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-check-circle'></i> las la-check-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-check-double'></i> las la-check-double
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-check-square'></i> las la-check-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-circle'></i> las la-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clipboard'></i> las la-clipboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clone'></i> las la-clone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-download-alt'></i> las la-cloud-download-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-upload-alt'></i> las la-cloud-upload-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-coffee'></i> las la-coffee
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cog'></i> las la-cog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cogs'></i> las la-cogs
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-copy'></i> las la-copy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cut'></i> las la-cut
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-database'></i> las la-database
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dot-circle'></i> las la-dot-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-download'></i> las la-download
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-edit'></i> las la-edit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ellipsis-h'></i> las la-ellipsis-h
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ellipsis-v'></i> las la-ellipsis-v
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-envelope'></i> las la-envelope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-envelope-open'></i> las la-envelope-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eraser'></i> las la-eraser
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-exclamation'></i> las la-exclamation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-exclamation-circle'></i> las la-exclamation-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-exclamation-triangle'></i> las la-exclamation-triangle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-external-link-alt'></i> las la-external-link-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-external-link-square-alt'></i> las
                      la-external-link-square-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye'></i> las la-eye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye-slash'></i> las la-eye-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file'></i> las la-file
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-alt'></i> las la-file-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-download'></i> las la-file-download
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-export'></i> las la-file-export
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-import'></i> las la-file-import
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-upload'></i> las la-file-upload
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-filter'></i> las la-filter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fingerprint'></i> las la-fingerprint
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-flag'></i> las la-flag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-flag-checkered'></i> las la-flag-checkered
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-folder'></i> las la-folder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-folder-open'></i> las la-folder-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-frown'></i> las la-frown
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glasses'></i> las la-glasses
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grip-horizontal'></i> las la-grip-horizontal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grip-lines'></i> las la-grip-lines
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grip-lines-vertical'></i> las la-grip-lines-vertical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-grip-vertical'></i> las la-grip-vertical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hashtag'></i> las la-hashtag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heart'></i> las la-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-history'></i> las la-history
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-home'></i> las la-home
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-i-cursor'></i> las la-i-cursor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-info'></i> las la-info
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-info-circle'></i> las la-info-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-language'></i> las la-language
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-magic'></i> las la-magic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-marker'></i> las la-marker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-medal'></i> las la-medal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-meh'></i> las la-meh
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone'></i> las la-microphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-alt'></i> las la-microphone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-slash'></i> las la-microphone-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-minus'></i> las la-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-minus-circle'></i> las la-minus-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-minus-square'></i> las la-minus-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paste'></i> las la-paste
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen'></i> las la-pen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen-alt'></i> las la-pen-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen-fancy'></i> las la-pen-fancy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pencil-alt'></i> las la-pencil-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plus'></i> las la-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plus-circle'></i> las la-plus-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plus-square'></i> las la-plus-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-poo'></i> las la-poo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-qrcode'></i> las la-qrcode
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-question'></i> las la-question
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-question'></i> las la-question
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-question-circle'></i> las la-question-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-quote-left'></i> las la-quote-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-quote-right'></i> las la-quote-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-redo'></i> las la-redo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-redo-alt'></i> las la-redo-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-reply'></i> las la-reply
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-reply-all'></i> las la-reply-all
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-rss'></i> las la-rss
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-rss-square'></i> las la-rss-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-save'></i> las la-save
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-screwdriver'></i> las la-screwdriver
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-search'></i> las la-search
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-search-minus'></i> las la-search-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-search-plus'></i> las la-search-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-share'></i> las la-share
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-share-alt'></i> las la-share-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-share-alt-square'></i> las la-share-alt-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-share-square'></i> las la-share-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shield-alt'></i> las la-shield-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sign-in-alt'></i> las la-sign-in-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sign-out-alt'></i> las la-sign-out-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-signal'></i> las la-signal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sitemap'></i> las la-sitemap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sliders-h'></i> las la-sliders-h
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-smile'></i> las la-smile
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort'></i> las la-sort
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-alpha-down'></i> las la-sort-alpha-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-alpha-down-alt'></i> las la-sort-alpha-down-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-alpha-up'></i> las la-sort-alpha-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-alpha-up-alt'></i> las la-sort-alpha-up-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-amount-down'></i> las la-sort-amount-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-amount-down-alt'></i> las la-sort-amount-down-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-numeric-up'></i> las la-sort-numeric-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-numeric-up-alt'></i> las la-sort-numeric-up-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sort-up'></i> las la-sort-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-star'></i> las la-star
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-star-half'></i> las la-star-half
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sync'></i> las la-sync
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sync-alt'></i> las la-sync-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbs-down'></i> las la-thumbs-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbs-up'></i> las la-thumbs-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-times'></i> las la-times
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-times-circle'></i> las la-times-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-toggle-off'></i> las la-toggle-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-toggle-on'></i> las la-toggle-on
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tools'></i> las la-tools
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trash'></i> las la-trash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trash-alt'></i> las la-trash-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trash-restore'></i> las la-trash-restore
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trash-restore-alt'></i> las la-trash-restore-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trophy'></i> las la-trophy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-undo'></i> las la-undo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-undo-alt'></i> las la-undo-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-upload'></i> las la-upload
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user'></i> las la-user
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-alt'></i> las la-user-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-circle'></i> las la-user-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-down'></i> las la-volume-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-mute'></i> las la-volume-mute
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-off'></i> las la-volume-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-up'></i> las la-volume-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wifi'></i> las la-wifi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wrench'></i> las la-wrench
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Logistics</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-box'></i> las la-box
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-boxes'></i> las la-boxes
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clipboard-check'></i> las la-clipboard-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clipboard-list'></i> las la-clipboard-list
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dolly'></i> las la-dolly
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dolly-flatbed'></i> las la-dolly-flatbed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hard-hat'></i> las la-hard-hat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pallet'></i> las la-pallet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shipping-fast'></i> las la-shipping-fast
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-truck'></i> las la-truck
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-warehouse'></i> las la-warehouse
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Maps</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ambulance'></i> las la-ambulance
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-anchor'></i> las la-anchor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale'></i> las la-balance-scale
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale-left'></i> las la-balance-scale-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale-right'></i> las la-balance-scale-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bath'></i> las la-bath
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bed'></i> las la-bed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-beer'></i> las la-beer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell'></i> las la-bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell-slash'></i> las la-bell-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bicycle'></i> las la-bicycle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-binoculars'></i> las la-binoculars
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-birthday-cake'></i> las la-birthday-cake
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-blind'></i> las la-blind
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bomb'></i> las la-bomb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-book'></i> las la-book
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bookmark'></i> las la-bookmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-briefcase'></i> las la-briefcase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-building'></i> las la-building
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-car'></i> las la-car
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-coffee'></i> las la-coffee
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-crosshairs'></i> las la-crosshairs
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-directions'></i> las la-directions
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dollar-sign'></i> las la-dollar-sign
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-draw-polygon'></i> las la-draw-polygon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye'></i> las la-eye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye-slash'></i> las la-eye-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fighter-jet'></i> las la-fighter-jet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fire'></i> las la-fire
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fire-alt'></i> las la-fire-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fire-extinguisher'></i> las la-fire-extinguisher
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-flag'></i> las la-flag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-flag-checkered'></i> las la-flag-checkered
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-flask'></i> las la-flask
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gamepad'></i> las la-gamepad
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gavel'></i> las la-gavel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gift'></i> las la-gift
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-martini'></i> las la-glass-martini
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-globe'></i> las la-globe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-graduation-cap'></i> las la-graduation-cap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-h-square'></i> las la-h-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heart'></i> las la-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heartbeat'></i> las la-heartbeat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-helicopter'></i> las la-helicopter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-home'></i> las la-home
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hospital'></i> las la-hospital
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-image'></i> las la-image
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-images'></i> las la-images
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-industry'></i> las la-industry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-info'></i> las la-info
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-info-circle'></i> las la-info-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-key'></i> las la-key
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-landmark'></i> las la-landmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-layer-group'></i> las la-layer-group
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-leaf'></i> las la-leaf
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lemon'></i> las la-lemon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-life-ring'></i> las la-life-ring
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lightbulb'></i> las la-lightbulb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-location-arrow'></i> las la-location-arrow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-low-vision'></i> las la-low-vision
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-magnet'></i> las la-magnet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-male'></i> las la-male
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map'></i> las la-map
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-marker'></i> las la-map-marker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-marker-alt'></i> las la-map-marker-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-pin'></i> las la-map-pin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-signs'></i> las la-map-signs
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-medkit'></i> las la-medkit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-bill'></i> las la-money-bill
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-bill-alt'></i> las la-money-bill-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-motorcycle'></i> las la-motorcycle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-music'></i> las la-music
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-newspaper'></i> las la-newspaper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-parking'></i> las la-parking
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paw'></i> las la-paw
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone'></i> las la-phone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-alt'></i> las la-phone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-square'></i> las la-phone-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-square-alt'></i> las la-phone-square-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-volume'></i> las la-phone-volume
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plane'></i> las la-plane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plug'></i> las la-plug
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plus'></i> las la-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plus-square'></i> las la-plus-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-print'></i> las la-print
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-recycle'></i> las la-recycle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-restroom'></i> las la-restroom
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-road'></i> las la-road
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-rocket'></i> las la-rocket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-route'></i> las la-route
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-search'></i> las la-search
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-search-minus'></i> las la-search-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-search-plus'></i> las la-search-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ship'></i> las la-ship
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shoe-prints'></i> las la-shoe-prints
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shopping-bag'></i> las la-shopping-bag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shopping-basket'></i> las la-shopping-basket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shopping-cart'></i> las la-shopping-cart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shower'></i> las la-shower
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-snowplow'></i> las la-snowplow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-street-view'></i> las la-street-view
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-subway'></i> las la-subway
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-suitcase'></i> las la-suitcase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tag'></i> las la-tag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tags'></i> las la-tags
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-taxi'></i> las la-taxi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbtack'></i> las la-thumbtack
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ticket-alt'></i> las la-ticket-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tint'></i> las la-tint
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-traffic-light'></i> las la-traffic-light
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-train'></i> las la-train
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tram'></i> las la-tram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tree'></i> las la-tree
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trophy'></i> las la-trophy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-truck'></i> las la-truck
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-truck'></i> las la-truck
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tty'></i> las la-tty
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-umbrella'></i> las la-umbrella
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-university'></i> las la-university
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-utensil-spoon'></i> las la-utensil-spoon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-utensils'></i> las la-utensils
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wheelchair'></i> las la-wheelchair
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wifi'></i> las la-wifi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wine-glass'></i> las la-wine-glass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wrench'></i> las la-wrench
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Maritime</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-anchor'></i> las la-anchor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-binoculars'></i> las la-binoculars
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-compass'></i> las la-compass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dharmachakra'></i> las la-dharmachakra
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-frog'></i> las la-frog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ship'></i> las la-ship
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skull-crossbones'></i> las la-skull-crossbones
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-swimmer'></i> las la-swimmer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-water'></i> las la-water
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wind'></i> las la-wind
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Marketing</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ad'></i> las la-ad
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bullhorn'></i> las la-bullhorn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bullseye'></i> las la-bullseye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment-dollar'></i> las la-comment-dollar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      3<i className='las la-comments-dollar'></i> las la-comments-dollar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-envelope-open-text'></i> las la-envelope-open-text
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-funnel-dollar'></i> las la-funnel-dollar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lightbulb'></i> las la-lightbulb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mail-bulk'></i> las la-mail-bulk
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-poll'></i> las la-poll
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-poll-h'></i> las la-poll-h
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-search-dollar'></i> las la-search-dollar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-search-location'></i> las la-search-location
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Mathematics</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calculator'></i> las la-calculator
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-divide'></i> las la-divide
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-equals'></i> las la-equals
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-greater-than'></i> las la-greater-than
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-greater-than-equal'></i> las la-greater-than-equal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-infinity'></i> las la-infinity
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-less-than'></i> las la-less-than
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-less-than-equal'></i> las la-less-than-equal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-minus'></i> las la-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-not-equal'></i> las la-not-equal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-percentage'></i> las la-percentage
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plus'></i> las la-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-square-root-alt'></i> las la-square-root-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-subscript'></i> las la-subscript
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-superscript'></i> las la-superscript
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-times'></i> las la-times
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wave-square'></i> las la-wave-square
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Medical</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-allergies'></i> las la-allergies
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ambulance'></i> las la-ambulance
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-band-aid'></i> las la-band-aid
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-biohazard'></i> las la-biohazard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bone'></i> las la-bone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bong'></i> las la-bong
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-book-medical'></i> las la-book-medical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-brain'></i> las la-brain
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-briefcase-medical'></i> las la-briefcase-medical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-burn'></i> las la-burn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cannabis'></i> las la-cannabis
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-capsules'></i> las la-capsules
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clinic-medical'></i> las la-clinic-medical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment-medical'></i> las la-comment-medical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-crutch'></i> las la-crutch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-diagnoses'></i> las la-diagnoses
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dna'></i> las la-dna
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-medical'></i> las la-file-medical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-medical-alt'></i> las la-file-medical-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-prescription'></i> las la-file-prescription
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-first-aid'></i> las la-first-aid
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heart'></i> las la-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heartbeat'></i> las la-heartbeat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hospital'></i> las la-hospital
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hospital-alt'></i> las la-hospital-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hospital-symbol'></i> las la-hospital-symbol
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-id-card-alt'></i> las la-id-card-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-joint'></i> las la-joint
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-laptop-medical'></i> las la-laptop-medical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microscope'></i> las la-microscope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mortar-pestle'></i> las la-mortar-pestle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-notes-medical'></i> las la-notes-medical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pager'></i> las la-pager
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pills'></i> las la-pills
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plus'></i> las la-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-poop'></i> las la-poop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-prescription'></i> las la-prescription
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-prescription-bottle'></i> las la-prescription-bottle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-prescription-bottle-alt'></i> las
                      la-prescription-bottle-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-procedures'></i> las la-procedures
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-radiation'></i> las la-radiation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-radiation-alt'></i> las la-radiation-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-smoking'></i> las la-smoking
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-smoking-ban'></i> las la-smoking-ban
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-star-of-life'></i> las la-star-of-life
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stethoscope'></i> las la-stethoscope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-syringe'></i> las la-syringe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tablets'></i> las la-tablets
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-teeth'></i> las la-teeth
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-teeth-open'></i> las la-teeth-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thermometer'></i> las la-thermometer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tooth'></i> las la-tooth
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-md'></i> las la-user-md
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-nurse'></i> las la-user-nurse
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-vial'></i> las la-vial
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-vials'></i> las la-vials
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-weight'></i> las la-weight
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-x-ray'></i> las la-x-ray
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Moving</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-archive'></i> las la-archive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-box-open'></i> las la-box-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-couch'></i> las la-couch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dolly'></i> las la-dolly
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-people-carry'></i> las la-people-carry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-route'></i> las la-route
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sign'></i> las la-sign
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-suitcase'></i> las la-suitcase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tape'></i> las la-tape
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-truck-loading'></i> las la-truck-loading
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-truck-moving'></i> las la-truck-moving
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wine-glass'></i> las la-wine-glass
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Music</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-drum'></i> las la-drum
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-drum-steelpan'></i> las la-drum-steelpan
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-audio'></i> las la-file-audio
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-guitar'></i> las la-guitar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-headphones'></i> las la-headphones
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-headphones-alt'></i> las la-headphones-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone'></i> las la-microphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-alt'></i> las la-microphone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-alt-slash'></i> las la-microphone-alt-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-slash'></i> las la-microphone-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-music'></i> las la-music
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-napster'></i> lab la-napster
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-play'></i> las la-play
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-record-vinyl'></i> las la-record-vinyl
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sliders-h'></i> las la-sliders-h
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-soundcloud'></i> lab la-soundcloud
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-spotify'></i> lab la-spotify
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-down'></i> las la-volume-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-mute'></i> las la-volume-mute
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-off'></i> las la-volume-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-up'></i> las la-volume-up
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Objects</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ambulance'></i> las la-ambulance
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-anchor'></i> las la-anchor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-archive'></i> las la-archive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-award'></i> las la-award
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-baby-carriage'></i> las la-baby-carriage
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale'></i> las la-balance-scale
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale-left'></i> las la-balance-scale-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale-right'></i> las la-balance-scale-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bath'></i> las la-bath
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bed'></i> las la-bed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-beer'></i> las la-beer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell'></i> las la-bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bicycle'></i> las la-bicycle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-binoculars'></i> las la-binoculars
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-birthday-cake'></i> las la-birthday-cake
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-blender'></i> las la-blender
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bomb'></i> las la-bomb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-book'></i> las la-book
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-book-dead'></i> las la-book-dead
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bookmark'></i> las la-bookmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-briefcase'></i> las la-briefcase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-broadcast-tower'></i> las la-broadcast-tower
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bug'></i> las la-bug
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-building'></i> las la-building
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bullhorn'></i> las la-bullhorn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bullseye'></i> las la-bullseye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bus'></i> las la-bus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calculator'></i> las la-calculator
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar'></i> las la-calendar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-alt'></i> las la-calendar-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-camera'></i> las la-camera
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-camera-retro'></i> las la-camera-retro
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-candy-cane'></i> las la-candy-cane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-car'></i> las la-car
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-carrot'></i> las la-carrot
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-church'></i> las la-church
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clipboard'></i> las la-clipboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud'></i> las la-cloud
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-coffee'></i> las la-coffee
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cog'></i> las la-cog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cogs'></i> las la-cogs
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-compass'></i> las la-compass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cookie'></i> las la-cookie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cookie-bite'></i> las la-cookie-bite
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-copy'></i> las la-copy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cube'></i> las la-cube
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cubes'></i> las la-cubes
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cut'></i> las la-cut
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice'></i> las la-dice
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-d20'></i> las la-dice-d20
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-d6'></i> las la-dice-d6
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-five'></i> las la-dice-five
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-four'></i> las la-dice-four
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-one'></i> las la-dice-one
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-six'></i> las la-dice-six
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-three'></i> las la-dice-three
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-two'></i> las la-dice-two
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-digital-tachograph'></i> las la-digital-tachograph
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-door-closed'></i> las la-door-closed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-door-open'></i> las la-door-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-drum'></i> las la-drum
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-drum-steelpan'></i> las la-drum-steelpan
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-envelope'></i> las la-envelope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-envelope-open'></i> las la-envelope-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eraser'></i> las la-eraser
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye'></i> las la-eye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye-dropper'></i> las la-eye-dropper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fax'></i> las la-fax
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-feather'></i> las la-feather
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-feather-alt'></i> las la-feather-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fighter-jet'></i> las la-fighter-jet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file'></i> las la-file
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-alt'></i> las la-file-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-prescription'></i> las la-file-prescription
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-film'></i> las la-film
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fire'></i> las la-fire
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fire-alt'></i> las la-fire-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fire-extinguisher'></i> las la-fire-extinguisher
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-flag'></i> las la-flag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-flag-checkered'></i> las la-flag-checkered
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-flask'></i> las la-flask
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-futbol'></i> las la-futbol
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gamepad'></i> las la-gamepad
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gavel'></i> las la-gavel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gem'></i> las la-gem
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gift'></i> las la-gift
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gifts'></i> las la-gifts
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-cheers'></i> las la-glass-cheers
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-martini'></i> las la-glass-martini
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-whiskey'></i> las la-glass-whiskey
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glasses'></i> las la-glasses
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-globe'></i> las la-globe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-graduation-cap'></i> las la-graduation-cap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-guitar'></i> las la-guitar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hat-wizard'></i> las la-hat-wizard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hdd'></i> las la-hdd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-headphones'></i> las la-headphones
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-headphones-alt'></i> las la-headphones-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-headset'></i> las la-headset
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heart'></i> las la-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heart-broken'></i> las la-heart-broken
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-helicopter'></i> las la-helicopter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-highlighter'></i> las la-highlighter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-holly-berry'></i> las la-holly-berry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-home'></i> las la-home
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hospital'></i> las la-hospital
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hourglass'></i> las la-hourglass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-igloo'></i> las la-igloo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-image'></i> las la-image
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-images'></i> las la-images
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-industry'></i> las la-industry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-key'></i> las la-key
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-keyboard'></i> las la-keyboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-laptop'></i> las la-laptop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-leaf'></i> las la-leaf
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lemon'></i> las la-lemon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-life-ring'></i> las la-life-ring
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lightbulb'></i> las la-lightbulb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lock'></i> las la-lock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lock-open'></i> las la-lock-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-magic'></i> las la-magic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-magnet'></i> las la-magnet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map'></i> las la-map
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-marker'></i> las la-map-marker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-marker-alt'></i> las la-map-marker-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-pin'></i> las la-map-pin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-signs'></i> las la-map-signs
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-marker'></i> las la-marker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-medal'></i> las la-medal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-medkit'></i> las la-medkit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-memory'></i> las la-memory
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microchip'></i> las la-microchip
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone'></i> las la-microphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-alt'></i> las la-microphone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mitten'></i> las la-mitten
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mobile'></i> las la-mobile
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mobile-alt'></i> las la-mobile-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-bill'></i> las la-money-bill
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-bill-alt'></i> las la-money-bill-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-check'></i> las la-money-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-check-alt'></i> las la-money-check-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-moon'></i> las la-moon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-motorcycle'></i> las la-motorcycle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mug-hot'></i> las la-mug-hot
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-newspaper'></i> las la-newspaper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paint-brush'></i> las la-paint-brush
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paper-plane'></i> las la-paper-plane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paperclip'></i> las la-paperclip
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paste'></i> las la-paste
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-paw'></i> las la-paw
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pen'></i> las la-pen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone'></i> las la-phone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-alt'></i> las la-phone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plane'></i> las la-plane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plug'></i> las la-plug
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-print'></i> las la-print
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-puzzle-piece'></i> las la-puzzle-piece
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ring'></i> las la-ring
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-road'></i> las la-road
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-rocket'></i> las la-rocket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ruler-combined'></i> las la-ruler-combined
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ruler-horizontal'></i> las la-ruler-horizontal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ruler-vertical'></i> las la-ruler-vertical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-satellite'></i> las la-satellite
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-satellite-dish'></i> las la-satellite-dish
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-save'></i> las la-save
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-school'></i> las la-school
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-screwdriver'></i> las la-screwdriver
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-scroll'></i> las la-scroll
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sd-card'></i> las la-sd-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-search'></i> las la-search
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shield-alt'></i> las la-shield-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shopping-bag'></i> las la-shopping-bag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shopping-basket'></i> las la-shopping-basket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shopping-cart'></i> las la-shopping-cart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shower'></i> las la-shower
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sim-card'></i> las la-sim-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skull-crossbones'></i> las la-skull-crossbones
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sleigh'></i> las la-sleigh
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-snowflake'></i> las la-snowflake
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-snowplow'></i> las la-snowplow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-space-shuttle'></i> las la-space-shuttle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-star'></i> las la-star
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sticky-note'></i> las la-sticky-note
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stopwatch'></i> las la-stopwatch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stroopwafel'></i> las la-stroopwafel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-subway'></i> las la-subway
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-suitcase'></i> las la-suitcase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sun'></i> las la-sun
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tablet'></i> las la-tablet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tablet-alt'></i> las la-tablet-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tachometer-alt'></i> las la-tachometer-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tag'></i> las la-tag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tags'></i> las la-tags
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-taxi'></i> las la-taxi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbtack'></i> las la-thumbtack
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ticket-alt'></i> las la-ticket-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-toilet'></i> las la-toilet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-toolbox'></i> las la-toolbox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tools'></i> las la-tools
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-train'></i> las la-train
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tram'></i> las la-tram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trash'></i> las la-trash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trash-alt'></i> las la-trash-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tree'></i> las la-tree
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trophy'></i> las la-trophy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-truck'></i> las la-truck
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tv'></i> las la-tv
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-umbrella'></i> las la-umbrella
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-university'></i> las la-university
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-unlock'></i> las la-unlock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-unlock-alt'></i> las la-unlock-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-utensil-spoon'></i> las la-utensil-spoon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-utensils'></i> las la-utensils
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wallet'></i> las la-wallet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-weight'></i> las la-weight
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wheelchair'></i> las la-wheelchair
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wine-glass'></i> las la-wine-glass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wrench'></i> las la-wrench
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Other</h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-500px'></i> lab la-500px
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-accusoft'></i> lab la-accusoft
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-adn'></i> lab la-adn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-adobe'></i> lab la-adobe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-adversal'></i> lab la-adversal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-affiliatetheme'></i> lab la-affiliatetheme
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-airbnb'></i> lab la-airbnb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-algolia'></i> lab la-algolia
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-amazon'></i> lab la-amazon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-amilia'></i> lab la-amilia
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-android'></i> lab la-android
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-angellist'></i> lab la-angellist
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-angrycreative'></i> lab la-angrycreative
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-angular'></i> lab la-angular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-app-store'></i> lab la-app-store
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-app-store-ios'></i> lab la-app-store-ios
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-apper'></i> lab la-apper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-apple'></i> lab la-apple
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-artstation'></i> lab la-artstation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-asymmetrik'></i> lab la-asymmetrik
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-atlassian'></i> lab la-atlassian
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-audible'></i> lab la-audible
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-autoprefixer'></i> lab la-autoprefixer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-avianex'></i> lab la-avianex
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-aviato'></i> lab la-aviato
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-aws'></i> lab la-aws
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-backspace'></i> las la-backspace
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bandcamp'></i> lab la-bandcamp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-battle-net'></i> lab la-battle-net
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-behance'></i> lab la-behance
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-behance-square'></i> lab la-behance-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-behance-square'></i> lab la-behance-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bimobject'></i> lab la-bimobject
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bitbucket'></i> lab la-bitbucket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bity'></i> lab la-bity
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-black-tie'></i> lab la-black-tie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-blackberry'></i> lab la-blackberry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-blender-phone'></i> las la-blender-phone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-blogger'></i> lab la-blogger
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-blogger-b'></i> lab la-blogger-b
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bootstrap'></i> lab la-bootstrap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-buffer'></i> lab la-buffer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-buromobelexperte'></i> lab la-buromobelexperte
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-buy-n-large'></i> lab la-buy-n-large
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-buysellads'></i> lab la-buysellads
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-canadian-maple-leaf'></i> lab la-canadian-maple-leaf
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-centercode'></i> lab la-centercode
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-centos'></i> lab la-centos
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-chrome'></i> lab la-chrome
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-chromecast'></i> lab la-chromecast
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cloudscale'></i> lab la-cloudscale
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cloudsmith'></i> lab la-cloudsmith
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cloudversify'></i> lab la-cloudversify
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-codepen'></i> lab la-codepen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-codiepie'></i> lab la-codiepie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-confluence'></i> lab la-confluence
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-connectdevelop'></i> lab la-connectdevelop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-contao'></i> lab la-contao
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cotton-bureau'></i> lab la-cotton-bureau
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cpanel'></i> lab la-cpanel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons'></i> lab la-creative-commons
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-by'></i> lab la-creative-commons-by
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-nc'></i> lab la-creative-commons-nc
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-nc-eu'></i> lab
                      la-creative-commons-nc-eu
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-nc-jp'></i> lab
                      la-creative-commons-nc-jp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-nd'></i> lab la-creative-commons-nd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-pd'></i> lab la-creative-commons-pd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-pd-alt'></i> lab
                      la-creative-commons-pd-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-remix'></i> lab
                      la-creative-commons-remix
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-sa'></i> lab la-creative-commons-sa
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-sampling'></i> lab
                      la-creative-commons-sampling
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-sampling-plus'></i> lab
                      la-creative-commons-sampling-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-share'></i> lab
                      la-creative-commons-share
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-creative-commons-zero'></i> lab la-creative-commons-zero
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-crown'></i> las la-crown
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-css3'></i> lab la-css3
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-css3-alt'></i> lab la-css3-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cuttlefish'></i> lab la-cuttlefish
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dashcube'></i> lab la-dashcube
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-delicious'></i> lab la-delicious
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-deploydog'></i> lab la-deploydog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-deskpro'></i> lab la-deskpro
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dev'></i> lab la-dev
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-deviantart'></i> lab la-deviantart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dhl'></i> lab la-dhl
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-diaspora'></i> lab la-diaspora
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-digg'></i> lab la-digg
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-digital-ocean'></i> lab la-digital-ocean
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-discord'></i> lab la-discord
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-discourse'></i> lab la-discourse
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dochub'></i> lab la-dochub
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-docker'></i> lab la-docker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-draft2digital'></i> lab la-draft2digital
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dribbble'></i> lab la-dribbble
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dribbble-square'></i> lab la-dribbble-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dropbox'></i> lab la-dropbox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-drupal'></i> lab la-drupal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dumpster-fire'></i> las la-dumpster-fire
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-dyalog'></i> lab la-dyalog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-earlybirds'></i> lab la-earlybirds
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ebay'></i> lab la-ebay
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-edge'></i> lab la-edge
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-elementor'></i> lab la-elementor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ello'></i> lab la-ello
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ember'></i> lab la-ember
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-empire'></i> lab la-empire
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-envira'></i> lab la-envira
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-erlang'></i> lab la-erlang
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-etsy'></i> lab la-etsy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-evernote'></i> lab la-evernote
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-expeditedssl'></i> lab la-expeditedssl
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-facebook'></i> lab la-facebook
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-facebook-f'></i> lab la-facebook-f
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-facebook-messenger'></i> lab la-facebook-messenger
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-facebook-square'></i> lab la-facebook-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fedex'></i> lab la-fedex
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fedora'></i> lab la-fedora
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-figma'></i> lab la-figma
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-csv'></i> las la-file-csv
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-firefox'></i> lab la-firefox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-first-order'></i> lab la-first-order
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-first-order-alt'></i> lab la-first-order-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-firstdraft'></i> lab la-firstdraft
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-flickr'></i> lab la-flickr
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-flipboard'></i> lab la-flipboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fly'></i> lab la-fly
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-font-awesome'></i> lab la-font-awesome
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-font-awesome-alt'></i> lab la-font-awesome-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-font-awesome-flag'></i> lab la-font-awesome-flag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fonticons'></i> lab la-fonticons
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fonticons-fi'></i> lab la-fonticons-fi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fort-awesome'></i> lab la-fort-awesome
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fort-awesome-alt'></i> lab la-fort-awesome-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-forumbee'></i> lab la-forumbee
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-foursquare'></i> lab la-foursquare
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-free-code-camp'></i> lab la-free-code-camp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-freebsd'></i> lab la-freebsd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fulcrum'></i> lab la-fulcrum
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-get-pocket'></i> lab la-get-pocket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-git'></i> lab la-git
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-git-alt'></i> lab la-git-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-git-square'></i> lab la-git-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gitlab'></i> lab la-gitlab
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gitter'></i> lab la-gitter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-glide'></i> lab la-glide
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-glide-g'></i> lab la-glide-g
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gofore'></i> lab la-gofore
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-goodreads'></i> lab la-goodreads
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-goodreads-g'></i> lab la-goodreads-g
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-google'></i> lab la-google
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-google-drive'></i> lab la-google-drive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-google-play'></i> lab la-google-play
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-google-plus'></i> lab la-google-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-google-plus-g'></i> lab la-google-plus-g
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-google-plus-square'></i> lab la-google-plus-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gratipay'></i> lab la-gratipay
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-grav'></i> lab la-grav
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gripfire'></i> lab la-gripfire
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-grunt'></i> lab la-grunt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-gulp'></i> lab la-gulp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hacker-news'></i> lab la-hacker-news
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hacker-news-square'></i> lab la-hacker-news-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hackerrank'></i> lab la-hackerrank
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hips'></i> lab la-hips
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hire-a-helper'></i> lab la-hire-a-helper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hooli'></i> lab la-hooli
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hornbill'></i> lab la-hornbill
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hotjar'></i> lab la-hotjar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-houzz'></i> lab la-houzz
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-html5'></i> lab la-html5
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-hubspot'></i> lab la-hubspot
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-imdb'></i> lab la-imdb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-instagram'></i> lab la-instagram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-intercom'></i> lab la-intercom
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-internet-explorer'></i> lab la-internet-explorer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-invision'></i> lab la-invision
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ioxhost'></i> lab la-ioxhost
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-itch-io'></i> lab la-itch-io
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-itunes'></i> lab la-itunes
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-itunes-note'></i> lab la-itunes-note
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-java'></i> lab la-java
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-jenkins'></i> lab la-jenkins
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-jira'></i> lab la-jira
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-joget'></i> lab la-joget
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-joomla'></i> lab la-joomla
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-js'></i> lab la-js
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-js-square'></i> lab la-js-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-jsfiddle'></i> lab la-jsfiddle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-kaggle'></i> lab la-kaggle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-keybase'></i> lab la-keybase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-keycdn'></i> lab la-keycdn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-kickstarter'></i> lab la-kickstarter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-kickstarter-k'></i> lab la-kickstarter-k
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-korvue'></i> lab la-korvue
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-laravel'></i> lab la-laravel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-lastfm'></i> lab la-lastfm
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-lastfm-square'></i> lab la-lastfm-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-leanpub'></i> lab la-leanpub
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-less'></i> lab la-less
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-line'></i> lab la-line
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-linkedin'></i> lab la-linkedin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-linkedin-in'></i> lab la-linkedin-in
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-linode'></i> lab la-linode
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-linux'></i> lab la-linux
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-lyft'></i> lab la-lyft
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-magento'></i> lab la-magento
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mailchimp'></i> lab la-mailchimp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mandalorian'></i> lab la-mandalorian
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-markdown'></i> lab la-markdown
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mastodon'></i> lab la-mastodon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-maxcdn'></i> lab la-maxcdn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mdb'></i> lab la-mdb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-medapps'></i> lab la-medapps
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-medium'></i> lab la-medium
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-medium-m'></i> lab la-medium-m
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-medrt'></i> lab la-medrt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-meetup'></i> lab la-meetup
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-megaport'></i> lab la-megaport
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mendeley'></i> lab la-mendeley
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-microsoft'></i> lab la-microsoft
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-microsoft'></i> lab la-microsoft
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mix'></i> lab la-mix
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mixcloud'></i> lab la-mixcloud
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-mizuni'></i> lab la-mizuni
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-modx'></i> lab la-modx
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-monero'></i> lab la-monero
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-neos'></i> lab la-neos
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-network-wired'></i> las la-network-wired
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-nimblr'></i> lab la-nimblr
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-node'></i> lab la-node
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-node-js'></i> lab la-node-js
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-npm'></i> lab la-npm
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ns8'></i> lab la-ns8
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-nutritionix'></i> lab la-nutritionix
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-odnoklassniki'></i> lab la-odnoklassniki
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-odnoklassniki-square'></i> lab la-odnoklassniki-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-opencart'></i> lab la-opencart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-openid'></i> lab la-openid
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-opera'></i> lab la-opera
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-optin-monster'></i> lab la-optin-monster
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-orcid'></i> lab la-orcid
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-osi'></i> lab la-osi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-page4'></i> lab la-page4
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pagelines'></i> lab la-pagelines
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-palfed'></i> lab la-palfed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-patreon'></i> lab la-patreon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-periscope'></i> lab la-periscope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-phabricator'></i> lab la-phabricator
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-phoenix-framework'></i> lab la-phoenix-framework
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-phoenix-squadron'></i> lab la-phoenix-squadron
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-php'></i> lab la-php
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pied-piper'></i> lab la-pied-piper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pied-piper-alt'></i> lab la-pied-piper-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pied-piper-hat'></i> lab la-pied-piper-hat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pied-piper-pp'></i> lab la-pied-piper-pp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pinterest'></i> lab la-pinterest
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pinterest-p'></i> lab la-pinterest-p
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pinterest-square'></i> lab la-pinterest-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-product-hunt'></i> lab la-product-hunt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-pushed'></i> lab la-pushed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-python'></i> lab la-python
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-qq'></i> lab la-qq
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-quinscape'></i> lab la-quinscape
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-quora'></i> lab la-quora
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-r-project'></i> lab la-r-project
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-raspberry-pi'></i> lab la-raspberry-pi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ravelry'></i> lab la-ravelry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-react'></i> lab la-react
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-reacteurope'></i> lab la-reacteurope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-readme'></i> lab la-readme
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-rebel'></i> lab la-rebel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-red-river'></i> lab la-red-river
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-reddit'></i> lab la-reddit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-reddit-alien'></i> lab la-reddit-alien
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-reddit-square'></i> lab la-reddit-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-redhat'></i> lab la-redhat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-renren'></i> lab la-renren
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-replyd'></i> lab la-replyd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-researchgate'></i> lab la-researchgate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-resolving'></i> lab la-resolving
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-rev'></i> lab la-rev
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-rocketchat'></i> lab la-rocketchat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-rockrms'></i> lab la-rockrms
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-safari'></i> lab la-safari
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-salesforce'></i> lab la-salesforce
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sass'></i> lab la-sass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-schlix'></i> lab la-schlix
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-scribd'></i> lab la-scribd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-searchengin'></i> lab la-searchengin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sellcast'></i> lab la-sellcast
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sellsy'></i> lab la-sellsy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-servicestack'></i> lab la-servicestack
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-shirtsinbulk'></i> lab la-shirtsinbulk
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-shopware'></i> lab la-shopware
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-signature'></i> las la-signature
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-simplybuilt'></i> lab la-simplybuilt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sistrix'></i> lab la-sistrix
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sith'></i> lab la-sith
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sketch'></i> lab la-sketch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skull'></i> las la-skull
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-skyatlas'></i> lab la-skyatlas
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-skype'></i> lab la-skype
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-slack'></i> lab la-slack
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-slack-hash'></i> lab la-slack-hash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-slideshare'></i> lab la-slideshare
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-snapchat'></i> lab la-snapchat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-snapchat-ghost'></i> lab la-snapchat-ghost
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-snapchat-square'></i> lab la-snapchat-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sourcetree'></i> lab la-sourcetree
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-speakap'></i> lab la-speakap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-speaker-deck'></i> lab la-speaker-deck
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-squarespace'></i> lab la-squarespace
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-stack-exchange'></i> lab la-stack-exchange
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-stack-overflow'></i> lab la-stack-overflow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-stackpath'></i> lab la-stackpath
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-staylinked'></i> lab la-staylinked
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-sticker-mule'></i> lab la-sticker-mule
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-strava'></i> lab la-strava
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-studiovinari'></i> lab la-studiovinari
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-stumbleupon'></i> lab la-stumbleupon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-stumbleupon-circle'></i> lab la-stumbleupon-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-superpowers'></i> lab la-superpowers
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-supple'></i> lab la-supple
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-suse'></i> lab la-suse
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-swift'></i> lab la-swift
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-symfony'></i> lab la-symfony
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-teamspeak'></i> lab la-teamspeak
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-telegram'></i> lab la-telegram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-telegram-plane'></i> lab la-telegram-plane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-tencent-weibo'></i> lab la-tencent-weibo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-the-red-yeti'></i> lab la-the-red-yeti
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-themeco'></i> lab la-themeco
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-themeisle'></i> lab la-themeisle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-think-peaks'></i> lab la-think-peaks
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-trade-federation'></i> lab la-trade-federation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-trello'></i> lab la-trello
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-tripadvisor'></i> lab la-tripadvisor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-tumblr'></i> lab la-tumblr
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-tumblr-square'></i> lab la-tumblr-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-twitter'></i> lab la-twitter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-twitter-square'></i> lab la-twitter-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-typo3'></i> lab la-typo3
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-uber'></i> lab la-uber
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ubuntu'></i> lab la-ubuntu
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-uikit'></i> lab la-uikit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-umbraco'></i> lab la-umbraco
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-uniregistry'></i> lab la-uniregistry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-untappd'></i> lab la-untappd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ups'></i> lab la-ups
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-usb'></i> lab la-usb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-usps'></i> lab la-usps
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ussunnah'></i> lab la-ussunnah
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vaadin'></i> lab la-vaadin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-viacoin'></i> lab la-viacoin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-viadeo'></i> lab la-viadeo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-viadeo-square'></i> lab la-viadeo-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-viber'></i> lab la-viber
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vimeo'></i> lab la-vimeo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vimeo-square'></i> lab la-vimeo-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vimeo-v'></i> lab la-vimeo-v
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vine'></i> lab la-vine
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vk'></i> lab la-vk
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vnv'></i> lab la-vnv
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-vr-cardboard'></i> las la-vr-cardboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-vuejs'></i> lab la-vuejs
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-waze'></i> lab la-waze
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-weebly'></i> lab la-weebly
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-weibo'></i> lab la-weibo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-weight-hanging'></i> las la-weight-hanging
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-weixin'></i> lab la-weixin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-whatsapp'></i> lab la-whatsapp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-whatsapp-square'></i> lab la-whatsapp-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-whmcs'></i> lab la-whmcs
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wikipedia-w'></i> lab la-wikipedia-w
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-windows'></i> lab la-windows
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wix'></i> lab la-wix
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wolf-pack-battalion'></i> lab la-wolf-pack-battalion
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wordpress'></i> lab la-wordpress
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wordpress-simple'></i> lab la-wordpress-simple
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wpbeginner'></i> lab la-wpbeginner
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wpexplorer'></i> lab la-wpexplorer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wpforms'></i> lab la-wpforms
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wpressr'></i> lab la-wpressr
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-xing'></i> lab la-xing
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-xing-square'></i> lab la-xing-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-y-combinator'></i> lab la-y-combinator
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yahoo'></i> lab la-yahoo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yammer'></i> lab la-yammer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yandex'></i> lab la-yandex
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yandex-international'></i> lab la-yandex-international
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yarn'></i> lab la-yarn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yelp'></i> lab la-yelp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-yoast'></i> lab la-yoast
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-youtube-square'></i> lab la-youtube-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-zhihu'></i> lab la-zhihu
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>
                    Payments & Shopping
                  </h6>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-alipay'></i> lab la-alipay
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-amazon-pay'></i> lab la-amazon-pay
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-apple-pay'></i> lab la-apple-pay
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell'></i> las la-bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-bitcoin'></i> lab la-bitcoin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bookmark'></i> las la-bookmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-btc'></i> lab la-btc
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bullhorn'></i> las la-bullhorn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-camera'></i> las la-camera
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-camera-retro'></i> las la-camera-retro
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cart-arrow-down'></i> las la-cart-arrow-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cart-plus'></i> las la-cart-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cc-amazon-pay'></i> lab la-cc-amazon-pay
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cc-amex'></i> lab la-cc-amex
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cc-apple-pay'></i> lab la-cc-apple-pay
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cc-diners-club'></i> lab la-cc-diners-club
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cc-discover'></i> lab la-cc-discover
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cc-jcb'></i> lab la-cc-jcb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cc-mastercard'></i> lab la-cc-mastercard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cc-paypal'></i> lab la-cc-paypal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cc-stripe'></i> lab la-cc-stripe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-cc-visa'></i> lab la-cc-visa
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-certificate'></i> las la-certificate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-credit-card'></i> las la-credit-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-ethereum'></i> lab la-ethereum
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gem'></i> las la-gem
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gift'></i> las la-gift
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-google-wallet'></i> lab la-google-wallet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-handshake'></i> las la-handshake
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heart'></i> las la-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-key'></i> las la-key
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-check'></i> las la-money-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-money-check-alt'></i> las la-money-check-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-paypal'></i> lab la-paypal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-receipt'></i> las la-receipt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shopping-bag'></i> las la-shopping-bag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shopping-basket'></i> las la-shopping-basket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shopping-cart'></i> las la-shopping-cart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-star'></i> las la-star
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-stripe'></i> lab la-stripe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-stripe-s'></i> lab la-stripe-s
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tag'></i> las la-tag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tags'></i> las la-tags
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbs-down'></i> las la-thumbs-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbs-up'></i> las la-thumbs-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-trophy'></i> las la-trophy
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Political</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-award'></i> las la-award
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale'></i> las la-balance-scale
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale-left'></i> las la-balance-scale-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-balance-scale-right'></i> las la-balance-scale-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bullhorn'></i> las la-bullhorn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-check-double'></i> las la-check-double
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-democrat'></i> las la-democrat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-donate'></i> las la-donate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dove'></i> las la-dove
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fist-raised'></i> las la-fist-raised
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-flag-usa'></i> las la-flag-usa
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-handshake'></i> las la-handshake
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-person-booth'></i> las la-person-booth
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-piggy-bank'></i> las la-piggy-bank
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-republican'></i> las la-republican
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-vote-yea'></i> las la-vote-yea
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Religion</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ankh'></i> las la-ankh
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-atom'></i> las la-atom
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bible'></i> las la-bible
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-church'></i> las la-church
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cross'></i> las la-cross
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dharmachakra'></i> las la-dharmachakra
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dove'></i> las la-dove
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gopuram'></i> las la-gopuram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hamsa'></i> las la-hamsa
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hanukiah'></i> las la-hanukiah
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-haykal'></i> las la-haykal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-jedi'></i> las la-jedi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-journal-whills'></i> las la-journal-whills
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-kaaba'></i> las la-kaaba
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-khanda'></i> las la-khanda
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-menorah'></i> las la-menorah
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mosque'></i> las la-mosque
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-om'></i> las la-om
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pastafarianism'></i> las la-pastafarianism
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-peace'></i> las la-peace
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-place-of-worship'></i> las la-place-of-worship
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pray'></i> las la-pray
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-praying-hands'></i> las la-praying-hands
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-quran'></i> las la-quran
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-star-and-crescent'></i> las la-star-and-crescent
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-star-of-david'></i> las la-star-of-david
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-synagogue'></i> las la-synagogue
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-torah'></i> las la-torah
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-torii-gate'></i> las la-torii-gate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-vihara'></i> las la-vihara
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-yin-yang'></i> las la-yin-yang
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Science</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-atom'></i> las la-atom
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-biohazard'></i> las la-biohazard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-brain'></i> las la-brain
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-burn'></i> las la-burn
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-capsules'></i> las la-capsules
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-clipboard-check'></i> las la-clipboard-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dna'></i> las la-dna
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye-dropper'></i> las la-eye-dropper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-filter'></i> las la-filter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fire'></i> las la-fire
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fire-alt'></i> las la-fire-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-flask'></i> las la-flask
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-frog'></i> las la-frog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-magnet'></i> las la-magnet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microscope'></i> las la-microscope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mortar-pestle'></i> las la-mortar-pestle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pills'></i> las la-pills
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-prescription-bottle'></i> las la-prescription-bottle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-radiation'></i> las la-radiation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-radiation-alt'></i> las la-radiation-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-seedling'></i> las la-seedling
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skull-crossbones'></i> las la-skull-crossbones
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-syringe'></i> las la-syringe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tablets'></i> las la-tablets
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-temperature-high'></i> las la-temperature-high
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-temperature-low'></i> las la-temperature-low
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-vial'></i> las la-vial
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-vials'></i> las la-vials
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Religion</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-galactic-republic'></i> lab la-galactic-republic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-galactic-senate'></i> lab la-galactic-senate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-globe'></i> las la-globe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-jedi'></i> las la-jedi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-jedi-order'></i> lab la-jedi-order
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-journal-whills'></i> las la-journal-whills
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-meteor'></i> las la-meteor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-moon'></i> las la-moon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-old-republic'></i> lab la-old-republic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-robot'></i> las la-robot
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-rocket'></i> las la-rocket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-satellite'></i> las la-satellite
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-satellite-dish'></i> las la-satellite-dish
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-space-shuttle'></i> las la-space-shuttle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-astronaut'></i> las la-user-astronaut
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Religion</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ban'></i> las la-ban
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bug'></i> las la-bug
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-door-closed'></i> las la-door-closed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-door-open'></i> las la-door-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dungeon'></i> las la-dungeon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye'></i> las la-eye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye-slash'></i> las la-eye-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-contract'></i> las la-file-contract
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-signature'></i> las la-file-signature
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fingerprint'></i> las la-fingerprint
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-id-badge'></i> las la-id-badge
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-id-card'></i> las la-id-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-id-card-alt'></i> las la-id-card-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-key'></i> las la-key
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lock'></i> las la-lock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lock-open'></i> las la-lock-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mask'></i> las la-mask
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-passport'></i> las la-passport
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shield-alt'></i> las la-shield-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-unlock'></i> las la-unlock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-unlock-alt'></i> las la-unlock-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-lock'></i> las la-user-lock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-secret'></i> las la-user-secret
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-shield'></i> las la-user-shield
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Religion</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bookmark'></i> las la-bookmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar'></i> las la-calendar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-certificate'></i> las la-certificate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-circle'></i> las la-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud'></i> las la-cloud
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment'></i> las la-comment
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file'></i> las la-file
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-folder'></i> las la-folder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heart'></i> las la-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heart-broken'></i> las la-heart-broken
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-marker'></i> las la-map-marker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-play'></i> las la-play
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shapes'></i> las la-shapes
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-square'></i> las la-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-star'></i> las la-star
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Religion</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell'></i> las la-bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-birthday-cake'></i> las la-birthday-cake
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-camera'></i> las la-camera
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment'></i> las la-comment
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment-alt'></i> las la-comment-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-envelope'></i> las la-envelope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hashtag'></i> las la-hashtag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-heart'></i> las la-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-icons'></i> las la-icons
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-image'></i> las la-image
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-images'></i> las la-images
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-marker'></i> las la-map-marker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-marker-alt'></i> las la-map-marker-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-photo-video'></i> las la-photo-video
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-poll'></i> las la-poll
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-poll-h'></i> las la-poll-h
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-retweet'></i> las la-retweet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-share'></i> las la-share
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-share-alt'></i> las la-share-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-share-square'></i> las la-share-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-star'></i> las la-star
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbs-down'></i> las la-thumbs-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbs-up'></i> las la-thumbs-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbtack'></i> las la-thumbtack
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user'></i> las la-user
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-circle'></i> las la-user-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-friends'></i> las la-user-friends
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-plus'></i> las la-user-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-users'></i> las la-users
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-video'></i> las la-video
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Religion</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-asterisk'></i> las la-asterisk
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-atom'></i> las la-atom
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-certificate'></i> las la-certificate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-circle-notch'></i> las la-circle-notch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cog'></i> las la-cog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-compact-disc'></i> las la-compact-disc
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-compass'></i> las la-compass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-crosshairs'></i> las la-crosshairs
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dharmachakra'></i> las la-dharmachakra
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fan'></i> las la-fan
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-haykal'></i> las la-haykal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-life-ring'></i> las la-life-ring
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-palette'></i> las la-palette
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ring'></i> las la-ring
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-slash'></i> las la-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-snowflake'></i> las la-snowflake
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-spinner'></i> las la-spinner
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stroopwafel'></i> las la-stroopwafel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sun'></i> las la-sun
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sync'></i> las la-sync
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sync-alt'></i> las la-sync-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-yin-yang'></i> las la-yin-yang
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Religion</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-baseball-ball'></i> las la-baseball-ball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-basketball-ball'></i> las la-basketball-ball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-biking'></i> las la-biking
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bowling-ball'></i> las la-bowling-ball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dumbbell'></i> las la-dumbbell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-football-ball'></i> las la-football-ball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-futbol'></i> las la-futbol
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-golf-ball'></i> las la-golf-ball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hockey-puck'></i> las la-hockey-puck
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-quidditch'></i> las la-quidditch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-running'></i> las la-running
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skating'></i> las la-skating
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skiing'></i> las la-skiing
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skiing-nordic'></i> las la-skiing-nordic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-snowboarding'></i> las la-snowboarding
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-swimmer'></i> las la-swimmer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-table-tennis'></i> las la-table-tennis
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volleyball-ball'></i> las la-volleyball-ball
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Religion</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-allergies'></i> las la-allergies
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-broom'></i> las la-broom
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-sun'></i> las la-cloud-sun
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-sun-rain'></i> las la-cloud-sun-rain
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-frog'></i> las la-frog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-rainbow'></i> las la-rainbow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-seedling'></i> las la-seedling
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-umbrella'></i> las la-umbrella
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Religion</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ban'></i> las la-ban
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-battery-empty'></i> las la-battery-empty
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-battery-full'></i> las la-battery-full
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-battery-half'></i> las la-battery-half
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-battery-quarter'></i> las la-battery-quarter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-battery-three-quarters'></i> las
                      la-battery-three-quarters
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell'></i> las la-bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bell-slash'></i> las la-bell-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar'></i> las la-calendar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-alt'></i> las la-calendar-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-check'></i> las la-calendar-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-day'></i> las la-calendar-day
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-minus'></i> las la-calendar-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-plus'></i> las la-calendar-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-times'></i> las la-calendar-times
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-calendar-week'></i> las la-calendar-week
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cart-arrow-down'></i> las la-cart-arrow-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cart-plus'></i> las la-cart-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment'></i> las la-comment
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment-alt'></i> las la-comment-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-comment-slash'></i> las la-comment-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-compass'></i> las la-compass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-door-closed'></i> las la-door-closed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-door-open'></i> las la-door-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-exclamation'></i> las la-exclamation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-exclamation-circle'></i> las la-exclamation-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-exclamation-triangle'></i> las la-exclamation-triangle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye'></i> las la-eye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-eye-slash'></i> las la-eye-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file'></i> las la-file
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-file-alt'></i> las la-file-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-folder'></i> las la-folder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-folder-open'></i> las la-folder-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-gas-pump'></i> las la-gas-pump
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-info'></i> las la-info
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-info-circle'></i> las la-info-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lightbulb'></i> las la-lightbulb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lock'></i> las la-lock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-lock-open'></i> las la-lock-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-marker'></i> las la-map-marker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-marker-alt'></i> las la-map-marker-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone'></i> las la-microphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-alt'></i> las la-microphone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-alt-slash'></i> las la-microphone-alt-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-microphone-slash'></i> las la-microphone-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-minus'></i> las la-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-minus-circle'></i> las la-minus-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-minus-square'></i> las la-minus-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-parking'></i> las la-parking
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone'></i> las la-phone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-alt'></i> las la-phone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-phone-slash'></i> las la-phone-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plus'></i> las la-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plus-circle'></i> las la-plus-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plus-square'></i> las la-plus-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-print'></i> las la-print
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-question'></i> las la-question
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-question-circle'></i> las la-question-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shield-alt'></i> las la-shield-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shopping-cart'></i> las la-shopping-cart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sign-in-alt'></i> las la-sign-in-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sign-out-alt'></i> las la-sign-out-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-signal'></i> las la-signal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-smoking-ban'></i> las la-smoking-ban
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-star'></i> las la-star
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-star-half'></i> las la-star-half
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-star-half-alt'></i> las la-star-half-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-stream'></i> las la-stream
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thermometer-empty'></i> las la-thermometer-empty
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thermometer-full'></i> las la-thermometer-full
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thermometer-half'></i> las la-thermometer-half
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thermometer-quarter'></i> las la-thermometer-quarter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thermometer-three-quarters'></i> las
                      la-thermometer-three-quarters
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbs-down'></i> las la-thumbs-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-thumbs-up'></i> las la-thumbs-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tint'></i> las la-tint
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tint-slash'></i> las la-tint-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-toggle-off'></i> las la-toggle-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-toggle-on'></i> las la-toggle-on
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-unlock'></i> las la-unlock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-unlock-alt'></i> las la-unlock-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user'></i> las la-user
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-alt'></i> las la-user-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-alt-slash'></i> las la-user-alt-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-slash'></i> las la-user-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-video'></i> las la-video
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-video-slash'></i> las la-video-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-down'></i> las la-volume-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-mute'></i> las la-volume-mute
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-off'></i> las la-volume-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-volume-up'></i> las la-volume-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wifi'></i> las la-wifi
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Religion</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-acquisitions-incorporated'></i> lab
                      la-acquisitions-incorporated
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-book-dead'></i> las la-book-dead
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-critical-role'></i> lab la-critical-role
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-d-and-d'></i> lab la-d-and-d
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-d-and-d-beyond'></i> lab la-d-and-d-beyond
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-d20'></i> las la-dice-d20
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dice-d6'></i> las la-dice-d6
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dragon'></i> las la-dragon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dungeon'></i> las la-dungeon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-fantasy-flight-games'></i> lab la-fantasy-flight-games
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-fist-raised'></i> las la-fist-raised
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hat-wizard'></i> las la-hat-wizard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-penny-arcade'></i> lab la-penny-arcade
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-ring'></i> las la-ring
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-scroll'></i> las la-scroll
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skull-crossbones'></i> las la-skull-crossbones
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-wizards-of-the-coast'></i> lab la-wizards-of-the-coast
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Travel</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-archway'></i> las la-archway
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-atlas'></i> las la-atlas
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bed'></i> las la-bed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bus'></i> las la-bus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bus-alt'></i> las la-bus-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cocktail'></i> las la-cocktail
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-concierge-bell'></i> las la-concierge-bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-dumbbell'></i> las la-dumbbell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-martini'></i> las la-glass-martini
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-martini-alt'></i> las la-glass-martini-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-globe-africa'></i> las la-globe-africa
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-globe-americas'></i> las la-globe-americas
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-globe-asia'></i> las la-globe-asia
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-globe-europe'></i> las la-globe-europe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hot-tub'></i> las la-hot-tub
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hotel'></i> las la-hotel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-luggage-cart'></i> las la-luggage-cart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map'></i> las la-map
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-marked'></i> las la-map-marked
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-map-marked-alt'></i> las la-map-marked-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-monument'></i> las la-monument
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-passport'></i> las la-passport
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plane'></i> las la-plane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plane-arrival'></i> las la-plane-arrival
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-plane-departure'></i> las la-plane-departure
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-shuttle-van'></i> las la-shuttle-van
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-spa'></i> las la-spa
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-suitcase'></i> las la-suitcase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-suitcase-rolling'></i> las la-suitcase-rolling
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-swimmer'></i> las la-swimmer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-swimming-pool'></i> las la-swimming-pool
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-taxi'></i> las la-taxi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tram'></i> las la-tram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tv'></i> las la-tv
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-umbrella-beach'></i> las la-umbrella-beach
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wine-glass'></i> las la-wine-glass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wine-glass-alt'></i> las la-wine-glass-alt
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Users & People</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='lab la-accessible-icon'></i> lab la-accessible-icon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-address-book'></i> las la-address-book
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-address-card'></i> las la-address-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-baby'></i> las la-baby
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bed'></i> las la-bed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-biking'></i> las la-biking
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-blind'></i> las la-blind
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-chalkboard-teacher'></i> las la-chalkboard-teacher
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-child'></i> las la-child
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-female'></i> las la-female
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-frown'></i> las la-frown
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-hiking'></i> las la-hiking
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-id-badge'></i> las la-id-badge
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-id-card'></i> las la-id-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-id-card-alt'></i> las la-id-card-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-male'></i> las la-male
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-meh'></i> las la-meh
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-people-carry'></i> las la-people-carry
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-person-booth'></i> las la-person-booth
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-poo'></i> las la-poo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-portrait'></i> las la-portrait
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-power-off'></i> las la-power-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-pray'></i> las la-pray
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-restroom'></i> las la-restroom
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-running'></i> las la-running
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skating'></i> las la-skating
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skiing'></i> las la-skiing
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skiing-nordic'></i> las la-skiing-nordic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-smile'></i> las la-smile
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-snowboarding'></i> las la-snowboarding
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-street-view'></i> las la-street-view
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-swimmer'></i> las la-swimmer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user'></i> las la-user
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-alt'></i> las la-user-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-alt-slash'></i> las la-user-alt-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-astronaut'></i> las la-user-astronaut
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-check'></i> las la-user-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-circle'></i> las la-user-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-clock'></i> las la-user-clock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-cog'></i> las la-user-cog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-edit'></i> las la-user-edit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-friends'></i> las la-user-friends
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-graduate'></i> las la-user-graduate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-injured'></i> las la-user-injured
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-lock'></i> las la-user-lock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-md'></i> las la-user-md
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-minus'></i> las la-user-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-ninja'></i> las la-user-ninja
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-nurse'></i> las la-user-nurse
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-plus'></i> las la-user-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-secret'></i> las la-user-secret
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-shield'></i> las la-user-shield
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-slash'></i> las la-user-slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-tag'></i> las la-user-tag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-tie'></i> las la-user-tie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-user-times'></i> las la-user-times
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-users'></i> las la-users
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-users-cog'></i> las la-users-cog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-walking'></i> las la-walking
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wheelchair'></i> las la-wheelchair
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Weather</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-bolt'></i> las la-bolt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud'></i> las la-cloud
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-meatball'></i> las la-cloud-meatball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-moon'></i> las la-cloud-moon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-moon-rain'></i> las la-cloud-moon-rain
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-rain'></i> las la-cloud-rain
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-showers-heavy'></i> las la-cloud-showers-heavy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-sun'></i> las la-cloud-sun
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-cloud-sun-rain'></i> las la-cloud-sun-rain
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-meteor'></i> las la-meteor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-moon'></i> las la-moon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-poo-storm'></i> las la-poo-storm
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-rainbow'></i> las la-rainbow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-smog'></i> las la-smog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-snowflake'></i> las la-snowflake
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-sun'></i> las la-sun
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-temperature-high'></i> las la-temperature-high
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-temperature-low'></i> las la-temperature-low
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-umbrella'></i> las la-umbrella
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-water'></i> las la-water
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-wind'></i> las la-wind
                    </Col>
                  </Row>

                  <h6 className='text-uppercase text-muted fw-semibold mt-4'>Winter</h6>
                  <Row>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-glass-whiskey'></i> las la-glass-whiskey
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-icicles'></i> las la-icicles
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-igloo'></i> las la-igloo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-mitten'></i> las la-mitten
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skating'></i> las la-skating
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skiing'></i> las la-skiing
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-skiing-nordic'></i> las la-skiing-nordic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-snowboarding'></i> las la-snowboarding
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-snowplow'></i> las la-snowplow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className='las la-tram'></i> las la-tram
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LineAwesomeIcons
