import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Form,
  FormGroup,
  FormFeedback
} from 'reactstrap'
import { ILocation } from './index'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isEqual } from 'lodash'

interface IProps {
  locationRecord: ILocation | undefined
  isOpen: boolean
  editMode: boolean
  setOpenPopup: (arg: boolean) => void
  handleCreateOrUpdateLocation: (arg: any) => Promise<string>
  canEdit: boolean
}

const initLocation: ILocation = {
  name: '',
  city: '',
  address: '',
  info1: '',
  info2: ''
}

const LocationModal: React.FC<IProps> = ({
  locationRecord,
  isOpen,
  editMode,
  handleCreateOrUpdateLocation,
  setOpenPopup,
  canEdit
}) => {
  const [locationState, setLocationState] = useState<ILocation>(initLocation)
  const hasEdit = (editMode && locationRecord) || false
  const [disableSave, setDisableSave] = useState<boolean>(editMode)
  const [initLocationState, setInitLocationState] = useState<ILocation>(initLocation)

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: hasEdit ? locationRecord?.name : '',
      city: hasEdit ? locationRecord?.city : '',
      address: hasEdit ? locationRecord?.address : '',
      info1: hasEdit ? locationRecord?.info1 : '',
      info2: hasEdit ? locationRecord?.info2 : ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Name'),
      city: Yup.string().required('Please Enter City'),
      address: Yup.string().required('Please Enter Address'),
      info1: Yup.string().required('Please Enter Info 1'),
      info2: Yup.string().required('Please Enter Info 2')
    }),
    onSubmit: (values) => {
      handleSubmit()
    }
  })

  useEffect(() => {
    if (editMode && locationRecord) {
      setLocationState(locationRecord)
      setInitLocationState(locationRecord)
      setDisableSave(true)
    } else {
      setLocationState(initLocation)
      setDisableSave(false)
    }

    if (isOpen) {
      formik.resetForm()
    }
  }, [isOpen])

  useEffect(() => {
    setDisableSave(isEqual(locationState, initLocationState))
  }, [locationState])

  const togglePopup = () => {
    setOpenPopup(!isOpen)
  }

  const handleOnChange = (e: { target: { name: any; value: any } }) => {
    const name = e.target.name
    let value = e.target.value
    setLocationState({
      ...locationState,
      [name]: value
    })
    formik.setFieldValue(name, value)
  }

  const handleSubmit = async () => {
    const res = handleCreateOrUpdateLocation(locationState)
    const message = await res
    if (message === 'SUCCESSFULL') {
      toast.success(
        editMode ? 'Updated a location successfully' : 'Created a new location successfully'
      )
      setOpenPopup(false)
    } else {
      toast.error(message)
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          togglePopup()
        }}
        scrollable={true}
        id='locationModal'
      >
        <ModalHeader
          className='modal-title'
          id='locationModalTitle'
          toggle={() => {
            togglePopup()
          }}
        >
          {editMode ? 'Booth Record Details' : 'Create a new Booth Record'}
        </ModalHeader>
        <ModalBody>
          <Form
            className='needs-validation'
            onSubmit={(e) => {
              e.preventDefault()
              formik.handleSubmit()
              return false
            }}
          >
            <Row>
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div>
                    <Label htmlFor='name' className='form-label mb-1 fw-semibold'>
                      Name
                    </Label>
                    <Input
                      type='text'
                      maxlength='50'
                      className='form-control'
                      id='name'
                      name='name'
                      placeholder='Name'
                      value={locationState?.name}
                      onChange={(e) => handleOnChange(e)}
                      invalid={formik.touched.name && formik.errors.name ? true : false}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <FormFeedback type='invalid'>{formik.errors.name}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div>
                    <Label htmlFor='city' className='form-label mb-1 fw-semibold'>
                      City
                    </Label>
                    <Input
                      type='text'
                      maxlength='50'
                      className='form-control'
                      id='city'
                      name='city'
                      placeholder='City'
                      value={locationState?.city}
                      onChange={(e) => handleOnChange(e)}
                      invalid={formik.touched.city && formik.errors.city ? true : false}
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <FormFeedback type='invalid'>{formik.errors.city}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div>
                    <Label htmlFor='address' className='form-label mb-1 fw-semibold'>
                      Address
                    </Label>
                    <Input
                      type='text'
                      className='form-control'
                      id='address'
                      name='address'
                      placeholder='Address'
                      value={locationState?.address}
                      onChange={(e) => handleOnChange(e)}
                      invalid={formik.touched.address && formik.errors.address ? true : false}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <FormFeedback type='invalid'>{formik.errors.address}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div>
                    <Label htmlFor='info-1' className='form-label mb-1 fw-semibold'>
                      Info 1
                    </Label>
                    <Input
                      type='text'
                      className='form-control'
                      id='infor1'
                      name='info1'
                      placeholder='Info 1'
                      maxlength='50'
                      value={locationState?.info1}
                      onChange={(e) => handleOnChange(e)}
                      invalid={formik.touched.info1 && formik.errors.info1 ? true : false}
                    />
                    {formik.touched.info1 && formik.errors.info1 ? (
                      <FormFeedback type='invalid'>{formik.errors.info1}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div>
                    <Label htmlFor='info-2' className='form-label mb-1 fw-semibold'>
                      Infor 2
                    </Label>
                    <Input
                      type='text'
                      className='form-control'
                      id='info2'
                      name='info2'
                      placeholder='Info 2'
                      maxlength='50'
                      value={locationState?.info2}
                      onChange={(e) => handleOnChange(e)}
                      invalid={formik.touched.info2 && formik.errors.info2 ? true : false}
                    />
                    {formik.touched.info2 && formik.errors.info2 ? (
                      <FormFeedback type='invalid'>{formik.errors.info2}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </FormGroup>
            </Row>
            <div className='modal-footer'>
              <Button color='light' onClick={() => setOpenPopup(false)}>
                Close
              </Button>
              {
                canEdit &&
                <Button color='primary' type='submit' disabled={disableSave}>
                  Save
                </Button>
              }
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default LocationModal
