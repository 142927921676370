import React, { useEffect, useState } from 'react'
import {
  fetchLocations,
  deleteLocation,
  updateLocationRecord,
  createLocationRecord
} from 'helpers/backend_helper'
import { Container } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { toast } from 'react-toastify'
import Pagination from 'Components/Common/Pagination'
import LocationsTable from './LocationsTable'
import LocationModal from './LocationModal'
import { PERPAGE_DEFAULT } from 'lib/constant'
import { ISort } from 'lib/type'

export interface ILocation {
  id?: number
  name: string
  city: string
  address: string
  info1: string
  info2: string
}

const Locations = () => {
  document.title = 'Location Record | Photo Booth Admin Dashboard'

  //state data
  const [fetching, setFetching] = useState<boolean>(false)
  const [locationRecords, setLocationRecords] = useState<ILocation[]>([])
  const [openLocationPopup, setOpenLocationPopup] = useState<boolean>(false)
  const [currentLocation, setCurrentLocation] = useState<ILocation>()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [sortAction, setSortAction] = useState<ISort>({ sortBy: '', sortDirection: 'ACS' })

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [valueSearch, setValueSearch] = useState<string>('')
  const [canEdit, setCanEdit] = useState<boolean>(false)

  const fetchLocationRecords = async (params: any) => {
    try {
      setFetching(true)
      const res = fetchLocations(params)
      const response = await res
      const data = response.data

      setLocationRecords(data)
      setFetching(false)
      setTotalPage(response.totalPages)
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
    }
  }

  useEffect(() => {
    fetchLocationRecords({ pageNo: currentPage, pageSize: PERPAGE_DEFAULT })
  }, [])

  const handleOpenDetails = (id: number) => {
    const selectedLocation = locationRecords.find((location) => location.id === id)
    if (selectedLocation) {
      setCurrentLocation(selectedLocation)
      setOpenLocationPopup(true)
    }
  }

  const handleCreateOrUpdateLocation = async (params: any) => {
    try {
      const res = editMode ? updateLocationRecord(params, params.id) : createLocationRecord(params)
      const response = await res
      const data = response.data

      if (response.message === 'SUCCESSFULL') {
        if (editMode) {
          let updateLocations = locationRecords.map((location: ILocation) => {
            if (location.id === data.id) {
              return data
            }
            return location
          })

          setLocationRecords(updateLocations)
        } else {
          setLocationRecords([data, ...locationRecords])
        }
        return response.message
      }

      return response.data
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      return 'FALSE'
    }
  }

  const handleDeleteLocation = async (id: number) => {
    try {
      const res = deleteLocation(id)
      const response = await res

      if (response.message === 'SUCCESSFULL') {
        setLocationRecords(
          locationRecords.filter((location) => {
            return location.id !== id
          })
        )
      }

      return response.message
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      return 'FALSE'
    }
  }

  const searchLocation = (value: string) => {
    const trimmedValue = value.trim()
    const queryParams = {
      pageNo: 1,
      pageSize: PERPAGE_DEFAULT,
      ...(trimmedValue && { keyWord: trimmedValue })
    }

    fetchLocationRecords(queryParams)
    setValueSearch(trimmedValue)
  }

  useEffect(() => {
    fetchLocationRecords(getParams(currentPage, valueSearch))
  }, [currentPage])

  useEffect(() => {
    if (sortAction.sortBy.length > 0) {
      fetchLocationRecords(getParams(currentPage, valueSearch))
    }
  }, [sortAction])

  const getParams = (page: number = 1, value: string) => {
    return {
      pageNo: page,
      pageSize: PERPAGE_DEFAULT,
      ...(value.trim() && { keyWord: value }),
      ...(sortAction.sortBy.length > 0 && sortAction)
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Location Record index' pageTitle='Location Record' />
          <LocationsTable
            locationRecords={locationRecords}
            setOpenLocationPopup={setOpenLocationPopup}
            handleOpenDetails={handleOpenDetails}
            setEditMode={setEditMode}
            handleDeleteLocation={handleDeleteLocation}
            searchLocation={searchLocation}
            fetching={fetching}
            sortAction={sortAction}
            setSortAction={setSortAction}
            setCanCreate={setCanEdit}
          />
          <LocationModal
            isOpen={openLocationPopup}
            setOpenPopup={setOpenLocationPopup}
            handleCreateOrUpdateLocation={handleCreateOrUpdateLocation}
            locationRecord={currentLocation}
            editMode={editMode}
            canEdit={canEdit}
          />
          {totalPage > 1 && (
            <Pagination
              perPageData={PERPAGE_DEFAULT}
              data={locationRecords}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={totalPage}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Locations
