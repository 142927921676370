import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap'
import { IBoothRecord } from './index'
import { toast } from 'react-toastify'
import DeleteModal from 'Components/Common/DeleteModal'
import { useDebouncedCallback } from 'use-debounce'
import { Spinner } from 'reactstrap'
import { ISort } from 'lib/type'
import { useEffect } from 'react'

interface IProds {
  actionOptions: any[]
  boothRecords: IBoothRecord[]
  handleOpenDetails: (arg: number) => void
  setOpenBoothPopup: (arg: boolean) => void
  setEditMode: (arg: boolean) => void
  handleDeleteBooth: (arg: number) => Promise<string>
  searchBooth: (arg: string) => void
  fetching: boolean
  sortAction: ISort
  setSortAction: (arg: ISort) => void
  setCanEdit: (arg: boolean) => void
}

const BoothsTable: React.FC<IProds> = ({
  boothRecords,
  handleOpenDetails,
  setOpenBoothPopup,
  setEditMode,
  actionOptions,
  handleDeleteBooth,
  searchBooth,
  fetching,
  sortAction,
  setSortAction,
  setCanEdit
}) => {
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
  const [boothSelectedId, setBoothSelectedId] = useState<number | undefined>(undefined)
  const [query, setQuery] = useState('')
  const [permissions, setPermissions] = useState({
    canCreate: false,
    canEdit: false,
    canDelete: false,
  })

  const handleAddBooth = () => {
    setEditMode(false)
    setOpenBoothPopup(true)
  }

  const handleEditBooth = (boothId: number | undefined) => {
    const id = boothId as number
    setEditMode(true)
    setOpenBoothPopup(true)
    handleOpenDetails(id)
  }

  const handleDeleteBoothRecord = async (boothId: number | undefined) => {
    const id = boothId as number
    const res = handleDeleteBooth(id)

    const message = await res
    if (message === 'SUCCESSFULL') {
      toast.success('Deleted a booth successfully')
    }
  }

  const handleShowModalDelete = (boothId: number | undefined) => {
    const id = boothId as number
    setShowModalDelete(true)
    setBoothSelectedId(id)
  }

  const handleDelete = () => {
    handleDeleteBoothRecord(boothSelectedId)
    setShowModalDelete(false)
  }

  const showPaymentMethods = (boothRecord: IBoothRecord) => {
    let methods: string[] = []
    if (boothRecord.isCash) {
      methods.push('Cash')
    }
    if (boothRecord.isCreditCard) {
      methods.push('Credit Card')
    }
    if (boothRecord.isQrcode) {
      methods.push('Qrcode')
    }

    return methods.join(', ')
  }

  const showCameraType = (type: number) => {
    switch (type) {
      case 1:
        return 'Canon'
      case 2:
        return 'Webcam'
      default:
        return 'Unknown'
    }
  }

  const showActiveActions = (boothRecord: IBoothRecord) => {
    let isAct = [
      boothRecord.isAct1,
      boothRecord.isAct2,
      boothRecord.isAct3,
      boothRecord.isAct4,
      boothRecord.isAct1
    ]
    let activeActions = actionOptions
      .filter((action, index) => isAct[index])
      .map((action) => action.name)
      .join(', ')

    return activeActions
  }

  const debouncedSearch = useDebouncedCallback((searchTerm: string) => {
    searchBooth(searchTerm)
  }, 1500)

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const value = e.target.value
    setQuery(value)
    debouncedSearch(value)
  }

  const handleActionSort = (sortBy: string) => {
    if (sortAction.sortBy === sortBy) {
      setSortAction({
        ...sortAction,
        sortDirection: sortAction.sortDirection === 'DESC' ? 'ASC' : 'DESC'
      })
    } else {
      setSortAction({
        sortDirection: 'DESC',
        sortBy: sortBy
      })
    }
  }

  useEffect(() => {
    const userRoles = sessionStorage.getItem('userRoles') ? JSON.parse(atob(sessionStorage.getItem('userRoles')!)) : null
    userRoles.forEach((role: any) => {
      if (role.page.name === 'Booths') {
        setPermissions({
          canCreate: role.canCreate,
          canEdit: role.canEdit,
          canDelete: role.canDelete,
        })
      }
    })
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader className='align-items-center d-flex justify-content-between'>
              <div className='search-box d-inline-block col-8 d-flex align-items-center'>
                <input
                  id='search-bar-booth'
                  className='form-control search'
                  placeholder='Search booths'
                  onChange={(e) => handleInputChange(e)}
                  value={query}
                />
                <i className='bx bx-search-alt search-icon'></i>
              </div>
              {
                permissions.canCreate &&
                <Button className='p-2' color='primary' onClick={(e) => handleAddBooth()}>
                  <div className='flex align-items-center'>
                    {fetching ? (
                      <Spinner size='sm'> Loading... </Spinner>
                    ) : (
                      <>
                        <i className='mdi mdi-plus'></i>
                        <span className='fw-semibold ms-1'>New Booth</span>
                      </>
                    )}
                  </div>
                </Button>
              }
            </CardHeader>
            <CardBody>
              <div className='table-responsive table-card'>
                <table className='table align-middle table-nowrap table-striped-columns mb-0'>
                  <thead className='table-light'>
                    <tr>
                      <th scope='col' style={{ width: '46px' }}>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='cardtableCheck'
                          />
                          <label className='form-check-label' htmlFor='cardtableCheck'></label>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('id')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>ID</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'id' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'id' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('name')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Name</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'name' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'name' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col'>Location</th>
                      <th scope='col'>Active Actions</th>
                      <th scope='col'>Payment Methods</th>
                      <th scope='col'>Default Currency</th>
                      <th scope='col'>Default Language</th>
                      <th scope='col'>Camera Type</th>
                      <th scope='col' onClick={() => handleActionSort('iPAddress')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>IP Address</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'iPAddress' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                              ${sortAction.sortBy !== 'iPAddress' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col'>Printer Active</th>
                      <th scope='col'>Booth Active</th>
                      <th scope='col' style={{ width: '150px' }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {boothRecords.map((boothRecord, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value=''
                                id={`check-${boothRecord.id}`}
                              />
                              <label
                                className='form-check-label'
                                htmlFor={`check-${boothRecord.id}`}
                              ></label>
                            </div>
                          </td>
                          <td>
                            <Link to='#' className='fw-medium'>
                              {boothRecord.id}
                            </Link>
                          </td>
                          <td>{boothRecord.name}</td>
                          <td>{boothRecord.location.name}</td>
                          <td>{showActiveActions(boothRecord)}</td>
                          <td>{showPaymentMethods(boothRecord)}</td>
                          <td>{boothRecord.currency.name}</td>
                          <td>{boothRecord.language.name}</td>
                          <td>{showCameraType(boothRecord.camType)}</td>
                          <td>{boothRecord.iPAddress}</td>
                          <td>
                            <Input
                              checked={boothRecord.isPrinterActive || false}
                              className='form-check-input code-switcher'
                              type='checkbox'
                            />
                          </td>
                          <td>
                            <Input
                              checked={boothRecord.isActive || false}
                              className='form-check-input code-switcher'
                              type='checkbox'
                            />
                          </td>
                          <td>
                            <button
                              type='button'
                              className='btn btn-sm btn-light'
                              onClick={(e) => handleEditBooth(boothRecord?.id)}
                            >
                              Details
                            </button>
                            {
                              permissions.canDelete &&
                              <button
                                type='button'
                                className='btn btn-sm btn-danger ms-2'
                                onClick={(e) => handleShowModalDelete(boothRecord?.id)}
                              >
                                Delete
                              </button>
                            }
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={showModalDelete}
        onDeleteClick={() => handleDelete()}
        onCloseClick={() => setShowModalDelete(false)}
      />
    </React.Fragment>
  )
}
export default BoothsTable
