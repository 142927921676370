import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { useDebouncedCallback } from 'use-debounce'
import { Spinner } from 'reactstrap'
import { ISort } from 'lib/type'
import Select from 'react-select'
import { IDataSubmit, IPromotion } from './index'
import { customStylesLabel } from 'lib/util'
import { isEqual } from 'lodash'

interface IProds {
  fetching: boolean
  booths: any[]
  promotions: any[]
  sortAction: ISort
  setSortAction: (arg: ISort) => void
  handleSubmit: (arg: IDataSubmit[]) => void
  searchPromotion: (arg: string) => void
  setCanEdit: (arg: boolean) => void
}

const PromotionBoothsTable: React.FC<IProds> = ({
  booths,
  promotions,
  fetching,
  handleSubmit,
  sortAction,
  setSortAction,
  searchPromotion,
  setCanEdit
}) => {
  const [statePromotionBooths, setStatePromotionBooths] = useState<IPromotion[]>([])
  const [initPromotionBooths, setInitPromotionBooths] = useState<any>([])
  const [disableSave, setDisableSave] = useState<boolean>(false)
  const [query, setQuery] = useState('')
  const [permissions, setPermissions] = useState({
    canCreate: false,
    canEdit: false,
    canDelete: false,
  })

  const debouncedSearch = useDebouncedCallback((searchTerm: string) => {
    searchPromotion(searchTerm)
  }, 1000)

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const value = e.target.value
    setQuery(value)
    debouncedSearch(value)
  }

  const handleActionSort = (sortBy: string) => {
    if (sortAction.sortBy === sortBy) {
      setSortAction({
        ...sortAction,
        sortDirection: sortAction.sortDirection === 'DESC' ? 'ASC' : 'DESC'
      })
    } else {
      setSortAction({
        sortDirection: 'DESC',
        sortBy: sortBy
      })
    }
  }

  useEffect(() => {
    setDisableSave(isEqual(statePromotionBooths, initPromotionBooths))
  }, [statePromotionBooths])

  useEffect(() => {
    setStatePromotionBooths(promotions)
    setInitPromotionBooths(promotions)
  }, [promotions])

  const handleSelect = (promotionId: number, selectValues: any) => {
    setStatePromotionBooths(
      statePromotionBooths.map((state) => {
        if (promotionId === state.id) {
          return { ...state, booths: selectValues }
        }
        return state
      })
    )
  }

  const handleSave = async () => {
    const params: IDataSubmit[] = statePromotionBooths.map((state: IPromotion) => ({
      promotionId: state.id,
      boothIds: state.booths.map((book: any) => book.value)
    }))

    handleSubmit(params)
  }

  useEffect(() => {
    const userRoles = sessionStorage.getItem('userRoles') ? JSON.parse(atob(sessionStorage.getItem('userRoles')!)) : null
    userRoles.forEach((role: any) => {
      if (role.page.name === 'Promotion Booths') {
        setPermissions({
          canCreate: role.canCreate,
          canEdit: role.canEdit,
          canDelete: role.canDelete,
        })
      }
    })
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader className='align-items-center d-flex justify-content-between'>
              <div className='search-box d-inline-block col-8 d-flex align-items-center'>
                <input
                  id='search-bar-action-price'
                  className='form-control search'
                  placeholder='Search Promotion'
                  onChange={(e) => handleInputChange(e)}
                  value={query}
                />
                <i className='bx bx-search-alt search-icon'></i>
              </div>
              {
                permissions.canEdit &&
                <Button className='p-2' color='primary' onClick={handleSave} disabled={disableSave}>
                  <div className='flex align-items-center'>
                    {fetching ? (
                      <Spinner size='sm'> Loading... </Spinner>
                    ) : (
                      <>
                        <span className='fw-semibold mx-3'>Save</span>
                      </>
                    )}
                  </div>
                </Button>
              }
            </CardHeader>
            <CardBody>
              <div className='table-responsive table-card overflow-visible'>
                <table className='table align-middle table-nowrap table-striped-columns mb-0'>
                  <thead className='table-light'>
                    <tr>
                      <th scope='col' style={{ width: '46px' }}>
                        <div className='form-check'>
                          <input className='form-check-input' type='checkbox' id='cardtableCheck' />
                          <label className='form-check-label' htmlFor='cardtableCheck'></label>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('name')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Promotion</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'name' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'name' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col'>Booths</th>
                    </tr>
                  </thead>
                  <tbody>
                    {statePromotionBooths.map((promotion, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value=''
                                id={`check-${promotion.id}`}
                              />
                              <label
                                className='form-check-label'
                                htmlFor={`check-${promotion.id}-${promotion.id}`}
                              ></label>
                            </div>
                          </td>
                          <td>{promotion.name}</td>
                          <td className='w-75'>
                            <Col col={12}>
                              <Select
                                value={promotion.booths}
                                isMulti={true}
                                isClearable={true}
                                onChange={(selectedMulti2: any) => {
                                  handleSelect(promotion.id, selectedMulti2)
                                }}
                                options={booths}
                                styles={customStylesLabel}
                              />
                            </Col>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default PromotionBoothsTable
