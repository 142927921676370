import React, { useState, useEffect } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { IActionPrice } from './index'
import { toast } from 'react-toastify'
import DeleteModal from 'Components/Common/DeleteModal'
import { useDebouncedCallback } from 'use-debounce'
import { Spinner } from 'reactstrap'
import { ISort } from 'lib/type'

interface IProds {
  fetching: boolean
  actionPriceRecords: IActionPrice[]
  handleOpenDetails: (arg1: number, arg2: number) => void
  setOpenActionPricePopup: (arg: boolean) => void
  setEditMode: (arg: boolean) => void
  handleDeleteActionPrice: (arg1: number, arg2: number) => Promise<string>
  searchActionPrice: (arg: string) => void
  sortAction: ISort
  setSortAction: (arg: ISort) => void
  setCanEdit: (arg: boolean) => void
}

const ActionPricesTable: React.FC<IProds> = ({
  actionPriceRecords,
  handleOpenDetails,
  setOpenActionPricePopup,
  setEditMode,
  fetching,
  handleDeleteActionPrice,
  searchActionPrice,
  sortAction,
  setSortAction,
  setCanEdit
}) => {
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
  const [actionPriceSelected, setActionPriceSelected] = useState<IActionPrice | null>(null)
  const [query, setQuery] = useState('')
  const [permissions, setPermissions] = useState({
    canCreate: false,
    canEdit: false,
    canDelete: false,
  })

  const handleAddActionPrice = () => {
    setEditMode(false)
    setOpenActionPricePopup(true)
  }

  const handleEditActionPrice = (actionId: number, currencyId: number) => {
    setEditMode(true)
    setOpenActionPricePopup(true)
    handleOpenDetails(actionId, currencyId)
  }

  const handleDeletActionPriceRecord = async (actionPrice: IActionPrice | null) => {
    const res = handleDeleteActionPrice(
      actionPrice?.actionId as number,
      actionPrice?.currencyId as number
    )

    const message = await res
    if (message === 'SUCCESSFULL') {
      toast.success('Deleted a Action Price successfully')
    }
  }

  const handleShowModalDelete = (actionPrice: IActionPrice) => {
    setShowModalDelete(true)
    setActionPriceSelected(actionPrice)
  }

  const handleDelete = () => {
    handleDeletActionPriceRecord(actionPriceSelected)
    setShowModalDelete(false)
  }

  const debouncedSearch = useDebouncedCallback((searchTerm: string) => {
    searchActionPrice(searchTerm)
  }, 1000)

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const value = e.target.value
    setQuery(value)
    debouncedSearch(value)
  }

  const handleActionSort = (sortBy: string) => {
    if (sortAction.sortBy === sortBy) {
      setSortAction({
        ...sortAction,
        sortDirection: sortAction.sortDirection === 'DESC' ? 'ASC' : 'DESC'
      })
    } else {
      setSortAction({
        sortDirection: 'DESC',
        sortBy: sortBy
      })
    }
  }

  useEffect(() => {
    const userRoles = sessionStorage.getItem('userRoles') ? JSON.parse(atob(sessionStorage.getItem('userRoles')!)) : null
    userRoles.forEach((role: any) => {
      if (role.page.name === 'Action Prices') {
        setPermissions({
          canCreate: role.canCreate,
          canEdit: role.canEdit,
          canDelete: role.canDelete,
        })
      }
    })
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader className='align-items-center d-flex justify-content-between'>
              <div className='search-box d-inline-block col-8 d-flex align-items-center'>
                <input
                  id='search-bar-action-price'
                  className='form-control search'
                  placeholder='Search Action Prices'
                  onChange={(e) => handleInputChange(e)}
                  value={query}
                />
                <i className='bx bx-search-alt search-icon'></i>
              </div>
              {
                permissions.canCreate &&
                <Button className='p-2' color='primary' onClick={(e) => handleAddActionPrice()}>
                  <div className='flex align-items-center'>
                    {fetching ? (
                      <Spinner size='sm'> Loading... </Spinner>
                    ) : (
                      <>
                        <i className='mdi mdi-plus'></i>
                        <span className='fw-semibold ms-1'>New Action Price</span>
                      </>
                    )}
                  </div>
                </Button>
              }
            </CardHeader>
            <CardBody>
              <div className='table-responsive table-card'>
                <table className='table align-middle table-nowrap table-striped-columns mb-0'>
                  <thead className='table-light'>
                    <tr>
                      <th scope='col' style={{ width: '46px' }}>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={query}
                            id='cardtableCheck'
                          />
                          <label className='form-check-label' htmlFor='cardtableCheck'></label>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('actionId')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Action</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'actionId' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'actionId' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('currencyId')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Currency</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'currencyId' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'currencyId' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('priceFor1')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Price For 1</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'priceFor1' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'priceFor1' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('priceFor2')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Price For 2</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'priceFor2' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'priceFor2' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('priceFor3')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Price For 3</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'priceFor3' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'priceFor3' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' style={{ width: '150px' }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {actionPriceRecords.map((actionPrice, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value=''
                                id={`check-${actionPrice.actionId}-${actionPrice.currencyId}`}
                              />
                              <label
                                className='form-check-label'
                                htmlFor={`check-${actionPrice.actionId}-${actionPrice.currencyId}`}
                              ></label>
                            </div>
                          </td>
                          <td>{actionPrice.action.name}</td>
                          <td>{actionPrice.currency.name}</td>
                          <td>{actionPrice.priceFor1}</td>
                          <td>{actionPrice.priceFor2}</td>
                          <td>{actionPrice.priceFor3}</td>
                          <td>
                            <button
                              type='button'
                              className='btn btn-sm btn-light'
                              onClick={(e) =>
                                handleEditActionPrice(
                                  actionPrice?.actionId,
                                  actionPrice?.currencyId
                                )
                              }
                            >
                              Details
                            </button>
                            {
                              permissions.canDelete &&
                              <button
                                type='button'
                                className='btn btn-sm btn-danger ms-2'
                                onClick={(e) => handleShowModalDelete(actionPrice)}
                              >
                                Delete
                              </button>
                            }
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={showModalDelete}
        onDeleteClick={() => handleDelete()}
        onCloseClick={() => setShowModalDelete(false)}
      />
    </React.Fragment>
  )
}
export default ActionPricesTable
