import React, { useEffect, useState } from 'react'
import {
  fetchEvents,
  deleteEvent,
  updateEventRecord,
  createEventRecord,
  searchBoothRecords
} from 'helpers/backend_helper'
import { Container } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { toast } from 'react-toastify'
import Pagination from 'Components/Common/Pagination'
import { PERPAGE_DEFAULT } from 'lib/constant'
import EventsTable from './EventsTable'
import EventModal from './EventModal'
import { ISort } from 'lib/type'
import { mapBoothOptions } from 'lib/util'

export interface IEvent {
  id?: number
  name: string
  startDateTime: string
  finishDateTime: string
  discountType: number
  discountValue: number
  isActive: boolean
  boothEvents: any
}

const Events = () => {
  document.title = 'Event Record | Photo Booth Admin Dashboard'

  //state data
  const [fetching, setFetching] = useState<boolean>(false)
  const [eventRecords, setEventRecords] = useState<IEvent[]>([])
  const [openEventPopup, setOpenEventPopup] = useState<boolean>(false)
  const [currentEvent, setCurrentEvent] = useState<IEvent>()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [sortAction, setSortAction] = useState<ISort>({ sortBy: '', sortDirection: 'ACS' })
  const [booths, setBooths] = useState<any[]>([])

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [valueSearch, setValueSearch] = useState<string>('')
  const [canEdit, setCanEdit] = useState<boolean>(false)

  const fetchEventRecords = async (params: any) => {
    try {
      setFetching(true)
      const res = fetchEvents(params)
      const response = await res
      const data = response.data

      setEventRecords(data)
      setFetching(false)
      setTotalPage(response.totalPages)
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
    }
  }

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const res = searchBoothRecords({})
        const response = await res
        const data = response.data

        setBooths(mapBoothOptions(data))
      } catch (e) {
        const errMsg = e as string
        toast.error(errMsg)
      }
    }

    fetchResources()
  }, [])

  const handleOpenDetails = (id: number) => {
    const selectedEvent = eventRecords.find((event) => event.id === id)
    if (selectedEvent) {
      setCurrentEvent(selectedEvent)
      setOpenEventPopup(true)
    }
  }

  const handleCreateOrUpdateEvent = async (params: any) => {
    try {
      const res = editMode ? updateEventRecord(params, params.id) : createEventRecord(params)
      const response = await res
      const data = response.data

      if (response.message === 'SUCCESSFULL') {
        if (editMode) {
          let updateEvents = eventRecords.map((event: IEvent) => {
            if (event.id === data.id) {
              return data
            }
            return event
          })

          setEventRecords(updateEvents)
        } else {
          setEventRecords([data, ...eventRecords])
        }
        return response.message
      }

      return response.data
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      return 'FALSE'
    }
  }

  const handleDeleteEvent = async (id: number) => {
    try {
      const res = deleteEvent(id)
      const response = await res

      if (response.message === 'SUCCESSFULL') {
        setEventRecords(
          eventRecords.filter((event) => {
            return event.id !== id
          })
        )
      }

      return response.message
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      return 'FALSE'
    }
  }

  const searchEvent = (value: string) => {
    const trimmedValue = value.trim()
    fetchEventRecords(getParams(1, trimmedValue))
    setValueSearch(trimmedValue)
  }

  useEffect(() => {
    fetchEventRecords(getParams(currentPage, valueSearch))
  }, [currentPage])

  useEffect(() => {
    if (sortAction.sortBy.length > 0) {
      fetchEventRecords(getParams(currentPage, valueSearch))
    }
  }, [sortAction])

  const getParams = (page: number = 1, value: string) => {
    return {
      pageNo: page,
      pageSize: PERPAGE_DEFAULT,
      ...(value.trim() && { keyWord: value }),
      ...(sortAction.sortBy.length > 0 && sortAction)
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Event Record index' pageTitle='Event Record' />
          <EventsTable
            eventRecords={eventRecords}
            setOpenEventPopup={setOpenEventPopup}
            handleOpenDetails={handleOpenDetails}
            setEditMode={setEditMode}
            handleDeleteEvent={handleDeleteEvent}
            searchEvent={searchEvent}
            fetching={fetching}
            sortAction={sortAction}
            setSortAction={setSortAction}
            setCanEdit={setCanEdit}
          />
          <EventModal
            isOpen={openEventPopup}
            setOpenPopup={setOpenEventPopup}
            handleCreateOrUpdateEvent={handleCreateOrUpdateEvent}
            eventRecord={currentEvent}
            editMode={editMode}
            booths={booths}
            canEdit={canEdit}
          />
          {totalPage > 1 && (
            <Pagination
              perPageData={PERPAGE_DEFAULT}
              data={eventRecords}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={totalPage}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Events
