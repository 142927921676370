//Include Both Helper File with needed methods

import { postLogin } from 'helpers/backend_helper'
import { jwtDecode } from 'jwt-decode'
import { toast } from 'react-toastify'

import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from './reducer'

export const loginUser = (user: any, history: any) => async (dispatch: any) => {
  try {
    let response
    if (process.env.REACT_APP_API_URL) {
      response = postLogin({
        email: user.email,
        password: user.password
      })
    }

    const res = await response
    let data = res?.data

    if (res?.statusCode === 200) {
      let decodedData = jwtDecode(data.token)
      sessionStorage.setItem('authUser', JSON.stringify(decodedData))
      sessionStorage.setItem('jwtToken', data.token)
      // TODO decode token to get user data here
      sessionStorage.setItem('userRoles', btoa(JSON.stringify(data.userInformation.pagePermissions)))
      dispatch(loginSuccess(data))
      history('/dashboard')
    } else {
      const errMsg = res?.message || ('' as any)
      dispatch(apiError(errMsg))
      toast.error('You have entered an invalid email or password. Please try again!')
    }
  } catch (error: any) {
    dispatch(apiError(error))
    toast.error('You have entered an invalid email or password. Please try again!')
  }
}

export const logoutUser = () => async (dispatch: any) => {
  try {
    sessionStorage.removeItem('authUser')
    sessionStorage.removeItem('jwtToken')
    dispatch(logoutUserSuccess(true))
  } catch (error: any) {
    dispatch(apiError(error))
  }
}

export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    const response = dispatch(reset_login_flag())
    return response
  } catch (error: any) {
    dispatch(apiError(error))
  }
}
