import React, { useEffect } from 'react'
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Form,
  FormGroup,
  FormFeedback
} from 'reactstrap'
import { IActionPrice } from './index'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'

interface IProps {
  actionPriceRecord: IActionPrice | undefined
  isOpen: boolean
  editMode: boolean
  setOpenPopup: (arg: boolean) => void
  handleCreateOrUpdateActionPrice: (arg: any) => Promise<string>
  actionOptions: any[]
  currencyOptions: any[]
  canEdit: boolean
}

const ActionPriceModal: React.FC<IProps> = ({
  actionPriceRecord,
  isOpen,
  editMode,
  handleCreateOrUpdateActionPrice,
  setOpenPopup,
  actionOptions,
  currencyOptions,
  canEdit
}) => {
  const hasEdit = (editMode && actionPriceRecord) || false

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      actionId: hasEdit ? actionPriceRecord?.actionId : '',
      currencyId: hasEdit ? actionPriceRecord?.currencyId : '',
      priceFor1: hasEdit ? actionPriceRecord?.priceFor1 : '',
      priceFor2: hasEdit ? actionPriceRecord?.priceFor2 : '',
      priceFor3: hasEdit ? actionPriceRecord?.priceFor3 : ''
    },
    validationSchema: Yup.object({
      actionId: Yup.string().required('Please Select Action'),
      currencyId: Yup.string().required('Please Select Currency'),
      priceFor1: Yup.string()
        .required('Please Enter Price For 1')
        .matches(/^\d+$/, 'Price For 1 must be a number'),
      priceFor2: Yup.string()
        .required('Please Enter Price For 2')
        .matches(/^\d+$/, 'Price For 2 must be a number'),
      priceFor3: Yup.string()
        .required('Please Enter Price For 3')
        .matches(/^\d+$/, 'Price For 3 must be a number')
    }),
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  useEffect(() => {
    if (isOpen) {
      formik.resetForm()
    }
  }, [isOpen])

  const togglePopup = () => {
    setOpenPopup(!isOpen)
  }

  const handleOnChange = (e: { target: { name: any; value: any } }) => {
    const name = e.target.name
    let value = parseInt(e.target.value)
    formik.setFieldValue(name, value)
  }

  const handleSubmit = async (values: any) => {
    if (editMode) {
      delete values.id
    }

    const res = handleCreateOrUpdateActionPrice(values)
    const message = await res
    if (message === 'SUCCESSFULL') {
      toast.success(
        editMode ? 'Updated a Action Price successfully' : 'Created a new Action Price successfully'
      )
      setOpenPopup(false)
    } else {
      toast.error(message)
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          togglePopup()
        }}
        scrollable={true}
        id='actionPriceModal'
      >
        <ModalHeader
          className='modal-title'
          id='actionPriceModalTitle'
          toggle={() => {
            togglePopup()
          }}
        >
          {editMode ? 'Action Price Record Details' : 'Create a new Action Price Record'}
        </ModalHeader>
        <ModalBody>
          <Form
            className='needs-validation'
            onSubmit={(e) => {
              e.preventDefault()
              formik.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col md={6} className='mb-3'>
                <FormGroup>
                  <div>
                    <Label htmlFor='action' className='form-label mb-1 fw-semibold'>
                      Action
                    </Label>
                    <select
                      className={`form-select ${formik.touched.actionId && formik.errors.actionId ? 'is-invalid' : ''}`}
                      name='actionId'
                      onChange={(e) => handleOnChange(e)}
                      disabled={editMode}
                    >
                      <option value=''>Select action</option>
                      {actionOptions.map((action, index) => {
                        return (
                          <option
                            key={index}
                            value={parseInt(action.id)}
                            selected={parseInt(action.id) === formik.values.actionId}
                          >
                            {action.name}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.actionId && formik.errors.actionId ? (
                      <FormFeedback type='invalid'>{formik.errors.actionId}</FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              <Col md={6} className='mb-3'>
                <FormGroup>
                  <div>
                    <Label htmlFor='currency' className='form-label mb-1 fw-semibold'>
                      Currency
                    </Label>
                    <select
                      className={`form-select ${formik.touched.currencyId && formik.errors.currencyId ? 'is-invalid' : ''}`}
                      name='currencyId'
                      onChange={(e) => handleOnChange(e)}
                      disabled={editMode}
                    >
                      <option value=''>Select currency</option>
                      {currencyOptions.map((currency, index) => {
                        return (
                          <option
                            key={index}
                            value={parseInt(currency.id)}
                            selected={parseInt(currency.id) === formik.values.currencyId}
                          >
                            {currency.name}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.currencyId && formik.errors.currencyId ? (
                      <FormFeedback type='invalid'>{formik.errors.currencyId}</FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              <Col md={12} className='mb-3'>
                <FormGroup>
                  <div>
                    <Label htmlFor='price-for-1' className='form-label mb-1 fw-semibold'>
                      Price For 1
                    </Label>
                    <Input
                      type='number'
                      min={0}
                      className='form-control'
                      id='price-for-1'
                      name='priceFor1'
                      placeholder='Price For 1'
                      value={formik.values.priceFor1}
                      onChange={(e) => handleOnChange(e)}
                      invalid={formik.touched.priceFor1 && formik.errors.priceFor1 ? true : false}
                    />
                    {formik.touched.priceFor1 && formik.errors.priceFor1 ? (
                      <FormFeedback type='invalid'>{formik.errors.priceFor1}</FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              <Col md={12} className='mb-3'>
                <FormGroup>
                  <div>
                    <Label htmlFor='price-for-2' className='form-label mb-1 fw-semibold'>
                      Price For 2
                    </Label>
                    <Input
                      type='number'
                      min={0}
                      className='form-control'
                      id='price-for-2'
                      name='priceFor2'
                      placeholder='Price For 2'
                      value={formik.values.priceFor2}
                      onChange={(e) => handleOnChange(e)}
                      invalid={formik.touched.priceFor2 && formik.errors.priceFor2 ? true : false}
                    />
                    {formik.touched.priceFor2 && formik.errors.priceFor2 ? (
                      <FormFeedback type='invalid'>{formik.errors.priceFor2}</FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              <Col md={12} className='mb-3'>
                <FormGroup>
                  <div>
                    <Label htmlFor='price-for-3' className='form-label mb-1 fw-semibold'>
                      Price For 3
                    </Label>
                    <Input
                      type='number'
                      min={0}
                      className='form-control'
                      id='price-for-3'
                      name='priceFor3'
                      placeholder='Price For 3'
                      value={formik.values.priceFor3}
                      onChange={(e) => handleOnChange(e)}
                      invalid={formik.touched.priceFor3 && formik.errors.priceFor3 ? true : false}
                    />
                    {formik.touched.priceFor3 && formik.errors.priceFor3 ? (
                      <FormFeedback type='invalid'>{formik.errors.priceFor3}</FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <div className='modal-footer'>
              <Button color='light' onClick={() => setOpenPopup(false)}>
                Close
              </Button>
              {
                canEdit &&
                <Button color='primary' type='submit' disabled={!formik.dirty && editMode}>
                  Save
                </Button>
              }
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default ActionPriceModal
