import { GET_LANGUAGES } from './url_helper'
// This is the clone of fakebackend_helper.ts

import { APIClient } from './api_helper'
import * as url from './url_helper'

const api = new APIClient()

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem('user')
  if (user) return JSON.parse(user)
  return null
}

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Login Method
export const postLogin = (data: any) => api.create(url.POST_LOGIN, data)

// search booth records
export const searchBoothRecords = (params: any) => {
  return api.get(url.BOOTH_RECORD_PATH, params)
}

// create booth record
export const createBoothRecord = (params: any) => {
  return api.create(url.BOOTH_RECORD_PATH, params)
}

//update booth record
export const updateBoothRecord = (params: any, id: number) => {
  return api.put(`${url.BOOTH_RECORD_PATH}/${id}`, params)
}

//delete booth record
export const deleteBoothRecord = (id: number) => {
  return api.delete(`${url.BOOTH_RECORD_PATH}/${id}`)
}

//search & get locations
export const fetchLocations = (params: any) => {
  return api.get(url.LOCATION_PATHS, params)
}

// create booth location
export const createLocationRecord = (params: any) => {
  return api.create(url.LOCATION_PATHS, params)
}

//update booth location
export const updateLocationRecord = (params: any, id: number) => {
  return api.put(`${url.LOCATION_PATHS}/${id}`, params)
}

//delete location
export const deleteLocation = (id: number) => {
  return api.delete(`${url.LOCATION_PATHS}/${id}`)
}

//search & get action price
export const fetchActionPrices = (params: any) => {
  return api.get(url.ACTION_PRICE_PATHS, params)
}

// create booth action price
export const createActionPriceRecord = (params: any) => {
  return api.create(url.ACTION_PRICE_PATHS, params)
}

//update booth action price
export const updateActionPriceRecord = (params: any) => {
  return api.put(
    `${url.ACTION_PRICE_PATHS}/action/${params.actionId}/currency/${params.currencyId}`,
    params
  )
}

//delete action price
export const deleteActionPrice = (actionId: number, currencyId: number) => {
  return api.delete(`${url.ACTION_PRICE_PATHS}/action/${actionId}/currency/${currencyId}`)
}

//search & get events
export const fetchEvents = (params: any) => {
  return api.get(url.EVENT_PATHS, params)
}

// create events
export const createEventRecord = (params: any) => {
  return api.create(url.EVENT_PATHS, params)
}

//update events
export const updateEventRecord = (params: any, id: number) => {
  return api.put(`${url.EVENT_PATHS}/${id}`, params)
}

//delete events
export const deleteEvent = (id: number) => {
  return api.delete(`${url.EVENT_PATHS}/${id}`)
}

//update multi event booths
export const updateEventBooths = (params: any) => {
  return api.create(`${url.EVENT_BOOTH_PATHS}/bulk`, params)
}

//search & get promotions
export const fetchPromotions = (params: any) => {
  return api.get(url.PROMOTION_PATHS, params)
}

// create promotions
export const createPromotionRecord = (params: any) => {
  return api.create(url.PROMOTION_PATHS, params)
}

//update promotions
export const updatePromotionRecord = (params: any, id: number) => {
  return api.put(`${url.PROMOTION_PATHS}/${id}`, params)
}

//delete promotions
export const deletePromotion = (id: number) => {
  return api.delete(`${url.PROMOTION_PATHS}/${id}`)
}

//search & get promotion booths
export const fetchPromotionBooths = (params: any) => {
  return api.get(url.PROMOTION_BOOTH_PATHS, params)
}

//update multi promotion booths
export const updatePromotionBooths = (params: any) => {
  return api.create(`${url.PROMOTION_BOOTH_PATHS}/bulk`, params)
}

//search & get users
export const fetchUsers = (params: any) => {
  return api.get(url.USER_PATHS, params)
}

// create user
export const createUserRecord = (params: any) => {
  return api.create(url.USER_PATHS, params)
}

//update user
export const updateUserRecord = (params: any, id: number) => {
  return api.put(`${url.USER_PATHS}/${id}`, params)
}

//delete user
export const deleteUser = (id: number) => {
  return api.delete(`${url.USER_PATHS}/${id}`)
}

//get page permissions
export const fetchPagePermissions = () => {
  return api.get(url.PAGE_PERMISSION_PATHS, null)
}

// search resources
export const searchResources = (type: string, params: any) => {
  switch (type) {
    case 'action':
      return api.get(url.GET_ACTIONS, params)
    case 'language':
      return api.get(url.GET_LANGUAGES, params)
    case 'currency':
      return api.get(url.GET_CURRENCIES, params)
    case 'location':
      return api.get(url.LOCATION_PATHS, params)
    case 'user':
      return api.get(url.USER_PATHS, params)
    case 'page':
      return api.get(url.USER_PATHS, params)
    default:
      return api.get(url.GET_ACTIONS, params)
  }
}

// search actions
export const searchActions = (params: any) => {
  return
}
