import React, { useEffect, useState } from 'react'
import {
  fetchPromotions,
  deletePromotion,
  updatePromotionRecord,
  createPromotionRecord,
  searchBoothRecords
} from 'helpers/backend_helper'
import { Container } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { toast } from 'react-toastify'
import Pagination from 'Components/Common/Pagination'
import { PERPAGE_DEFAULT } from 'lib/constant'
import PromotionsTable from './PromotionsTable'
import PromotionModal from './PromotionModal'
import { mapBoothOptions } from 'lib/util'

export interface IPromotion {
  id?: number
  name: string
  code: string
  startDateTime: string
  finishDateTime: string
  discountType: number
  discountValue: number
  isActive: boolean
  boothPromotions: any
}

export interface ISort {
  sortBy: string
  sortDirection: string
}

const Promotions = () => {
  document.title = 'Promotion Record | Photo Booth Admin Dashboard'

  //state data
  const [fetching, setFetching] = useState<boolean>(false)
  const [promotionRecords, setPromotionRecords] = useState<IPromotion[]>([])
  const [openPromotionPopup, setOpenPromotionPopup] = useState<boolean>(false)
  const [currentPromotion, setCurrentPromotion] = useState<IPromotion>()
  const [booths, setBooths] = useState<any[]>([])
  const [editMode, setEditMode] = useState<boolean>(false)
  const [sortAction, setSortAction] = useState<ISort>({ sortBy: '', sortDirection: 'ACS' })

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [valueSearch, setValueSearch] = useState<string>('')
  const [canEdit, setCanEdit] = useState<boolean>(false)

  const fetchPromotionRecords = async (params: any) => {
    try {
      setFetching(true)
      const res = fetchPromotions(params)
      const response = await res
      const data = response.data

      setPromotionRecords(data)
      setFetching(false)
      setTotalPage(response.totalPages)
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
    }
  }

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const res = searchBoothRecords({})
        const response = await res
        const data = response.data

        setBooths(mapBoothOptions(data))
      } catch (e) {
        const errMsg = e as string
        toast.error(errMsg)
      }
    }

    fetchResources()
  }, [])

  const handleOpenDetails = (id: number) => {
    const selectedPromotion = promotionRecords.find((promotion) => promotion.id === id)
    if (selectedPromotion) {
      setCurrentPromotion(selectedPromotion)
      setOpenPromotionPopup(true)
    }
  }

  const handleCreateOrUpdatePromotion = async (params: any) => {
    try {
      const res = editMode
        ? updatePromotionRecord(params, params.id)
        : createPromotionRecord(params)
      const response = await res
      const data = response.data

      if (response.message === 'SUCCESSFULL') {
        if (editMode) {
          let updatePromotions = promotionRecords.map((promotion: IPromotion) => {
            if (promotion.id === data.id) {
              return data
            }
            return promotion
          })

          setPromotionRecords(updatePromotions)
        } else {
          setPromotionRecords([data, ...promotionRecords])
        }
        return response.message
      }

      return response.data
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      return 'FALSE'
    }
  }

  const handleDeletePromotion = async (id: number) => {
    try {
      const res = deletePromotion(id)
      const response = await res

      if (response.message === 'SUCCESSFULL') {
        setPromotionRecords(
          promotionRecords.filter((promotion) => {
            return promotion.id !== id
          })
        )
      }

      return response.message
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      return 'FALSE'
    }
  }

  const searchPromotion = (value: string) => {
    const trimmedValue = value.trim()
    fetchPromotionRecords(getParams(1, trimmedValue))
    setValueSearch(trimmedValue)
  }

  useEffect(() => {
    fetchPromotionRecords(getParams(currentPage, valueSearch))
  }, [currentPage])

  useEffect(() => {
    if (sortAction.sortBy.length > 0) {
      fetchPromotionRecords(getParams(currentPage, valueSearch))
    }
  }, [sortAction])

  const getParams = (page: number = 1, value: string) => {
    return {
      pageNo: page,
      pageSize: PERPAGE_DEFAULT,
      ...(value.trim() && { keyWord: value }),
      ...(sortAction.sortBy.length > 0 && sortAction)
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Promotion Record index' pageTitle='Promotion Record' />
          <PromotionsTable
            promotionRecords={promotionRecords}
            setOpenPromotionPopup={setOpenPromotionPopup}
            handleOpenDetails={handleOpenDetails}
            setEditMode={setEditMode}
            handleDeletePromotion={handleDeletePromotion}
            searchPromotion={searchPromotion}
            fetching={fetching}
            sortAction={sortAction}
            setSortAction={setSortAction}
            setCanEdit={setCanEdit}
          />
          <PromotionModal
            isOpen={openPromotionPopup}
            setOpenPopup={setOpenPromotionPopup}
            handleCreateOrUpdatePromotion={handleCreateOrUpdatePromotion}
            promotionRecord={currentPromotion}
            editMode={editMode}
            booths={booths}
            canEdit={canEdit}
          />
          {totalPage > 1 && (
            <Pagination
              perPageData={PERPAGE_DEFAULT}
              data={promotionRecords}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={totalPage}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Promotions
