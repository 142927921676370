import React, { useEffect, useState } from 'react'
import {
  fetchActionPrices,
  deleteActionPrice,
  updateActionPriceRecord,
  createActionPriceRecord,
  searchResources
} from 'helpers/backend_helper'
import { Container } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { toast } from 'react-toastify'
import Pagination from 'Components/Common/Pagination'
import { PERPAGE_DEFAULT } from 'lib/constant'
import { Currency } from '../Booths'
import ActionPricesTable from './ActionPricesTable'
import ActionPriceModal from './ActionPriceModal'
import { ISort } from 'lib/type'
export interface IActionPrice {
  actionId: number
  currencyId: number
  priceFor1: number
  priceFor2: number
  priceFor3: number
  currency: Currency
  action: {
    id: number
    name: string
  }
}

const ActionPrices = () => {
  document.title = 'Action Price Record | Photo Booth Admin Dashboard'

  //state data
  const [fetching, setFetching] = useState<boolean>(false)
  const [actionPriceRecords, setActionPriceRecords] = useState<IActionPrice[]>([])
  const [openActionPricePopup, setOpenActionPricePopup] = useState<boolean>(false)
  const [currentActionPrice, setCurrentActionPrice] = useState<IActionPrice>()
  const [actions, setActions] = useState<any[]>([])
  const [currencies, setCurrencies] = useState<any[]>([])
  const [editMode, setEditMode] = useState<boolean>(false)
  const [sortAction, setSortAction] = useState<ISort>({ sortBy: '', sortDirection: 'ACS' })

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [valueSearch, setValueSearch] = useState<string>('')
  const [canEdit, setCanEdit] = useState<boolean>(false)

  const fetchActionPriceRecords = async (params: any) => {
    try {
      setFetching(true)
      const res = fetchActionPrices(params)
      const response = await res
      const data = response.data

      setActionPriceRecords(data)
      setFetching(false)
      setTotalPage(response.totalPages)
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
    }
  }

  useEffect(() => {
    const fetchResources = async (type: string, callback: (arg: any) => void) => {
      try {
        const res = searchResources(type, {})
        const response = await res
        const data = response.data
        if (data.length) {
          callback(data)
        }
      } catch (e) {
        const errMsg = e as string
        toast.error(errMsg)
      }
    }

    fetchResources('action', setActions)
    fetchResources('currency', setCurrencies)
    fetchActionPriceRecords({ pageNo: currentPage, pageSize: PERPAGE_DEFAULT })
  }, [])

  const handleOpenDetails = (actionId: number, currencyId: number) => {
    const selectedActionPrice = actionPriceRecords.find(
      (actionPrice) => actionPrice.actionId === actionId && actionPrice.currencyId === currencyId
    )
    if (selectedActionPrice) {
      setCurrentActionPrice(selectedActionPrice)
      setOpenActionPricePopup(true)
    }
  }

  const handleCreateOrUpdateActionPrice = async (params: any) => {
    try {
      const res = editMode ? updateActionPriceRecord(params) : createActionPriceRecord(params)
      const response = await res
      const data = response.data

      if (response.message === 'SUCCESSFULL') {
        if (editMode) {
          let updateActionPrices = actionPriceRecords.map((actionPrice: IActionPrice) => {
            if (
              actionPrice.actionId === data.actionId &&
              actionPrice.currencyId === data.currencyId
            ) {
              return data
            }
            return actionPrice
          })

          setActionPriceRecords(updateActionPrices)
        } else {
          setActionPriceRecords([data, ...actionPriceRecords])
        }
        return response.message
      }

      return response.data
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      return 'FALSE'
    }
  }

  const handleDeleteActionPrice = async (actionId: number, currencyId: number) => {
    try {
      const res = deleteActionPrice(actionId, currencyId)
      const response = await res

      if (response.message === 'SUCCESSFULL') {
        setActionPriceRecords(
          actionPriceRecords.filter((actionPrice) => {
            return actionPrice.actionId !== actionId && actionPrice.currencyId !== currencyId
          })
        )
      }

      return response.message
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      return 'FALSE'
    }
  }

  const searchActionPrice = (value: string) => {
    const trimmedValue = value.trim()
    fetchActionPriceRecords(getParams(1, trimmedValue))
    setValueSearch(trimmedValue)
  }

  useEffect(() => {
    fetchActionPriceRecords(getParams(currentPage, valueSearch))
  }, [currentPage])

  useEffect(() => {
    if (sortAction.sortBy.length > 0) {
      fetchActionPriceRecords(getParams(currentPage, valueSearch))
    }
  }, [sortAction])

  const getParams = (page: number = 1, value: string) => {
    return {
      pageNo: page,
      pageSize: PERPAGE_DEFAULT,
      ...(value.trim() && { keyWord: value }),
      ...(sortAction.sortBy.length > 0 && sortAction)
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Action Price Record index' pageTitle='Action Price Record' />
          <ActionPricesTable
            actionPriceRecords={actionPriceRecords}
            setOpenActionPricePopup={setOpenActionPricePopup}
            handleOpenDetails={handleOpenDetails}
            setEditMode={setEditMode}
            handleDeleteActionPrice={handleDeleteActionPrice}
            searchActionPrice={searchActionPrice}
            fetching={fetching}
            sortAction={sortAction}
            setSortAction={setSortAction}
            setCanEdit={setCanEdit}
          />
          <ActionPriceModal
            isOpen={openActionPricePopup}
            setOpenPopup={setOpenActionPricePopup}
            handleCreateOrUpdateActionPrice={handleCreateOrUpdateActionPrice}
            actionPriceRecord={currentActionPrice}
            editMode={editMode}
            actionOptions={actions}
            currencyOptions={currencies}
            canEdit={canEdit}
          />
          {totalPage > 1 && (
            <Pagination
              perPageData={PERPAGE_DEFAULT}
              data={actionPriceRecords}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={totalPage}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default ActionPrices
