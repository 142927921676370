import React, { useEffect, useState } from 'react'
import {
  searchBoothRecords,
  searchResources,
  createBoothRecord,
  updateBoothRecord,
  deleteBoothRecord
} from 'helpers/backend_helper'
import { Container } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import BoothsTable from './BoothsTable'
import BoothPopup from './BoothModal'
import { toast } from 'react-toastify'
import Pagination from 'Components/Common/Pagination'
import { PERPAGE_DEFAULT } from 'lib/constant'
import { ISort } from 'lib/type'

export interface IBoothRecord {
  id?: number
  name: string
  locationId: number
  location: Location
  isAct1: boolean
  isAct2: boolean
  isAct3: boolean
  isAct4: boolean
  isAct5: boolean
  isCash: boolean
  isCreditCard: boolean
  isQrcode: boolean
  currencyId: number
  currency: Currency
  languageId: number
  language: Language
  camType: number
  isPrinterActive: boolean
  isActive: boolean
  iPAddress: string
}

export interface Location {
  id: number
  name: string
  city: string
  address: string
  info1: string
  info2: string
}

export interface Currency {
  id: number
  name: string
  symbol: string
}

export interface Language {
  id: number
  name: string
  orgName: string
}

const Booths = () => {
  document.title = 'Booth Record | Photo Booth Admin Dashboard'

  //state data
  const [fetching, setFetching] = useState<boolean>(false)
  const [boothRecords, setBoothRecords] = useState<IBoothRecord[]>([])
  const [openBoothPopup, setOpenBoothPopup] = useState<boolean>(false)
  const [currentBooth, setCurrentBooth] = useState<IBoothRecord>()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [actions, setActions] = useState<any[]>([])
  const [currencies, setCurrencies] = useState<any[]>([])
  const [languages, setLanguages] = useState<any[]>([])
  const [locations, setLocations] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [valueSearch, setValueSearch] = useState<string>('')
  const [sortAction, setSortAction] = useState<ISort>({ sortBy: '', sortDirection: 'ACS' })
  const [canEdit, setCanEdit] = useState<boolean>(false)

  const fetchBoothRecords = async (params: any) => {
    try {
      setFetching(true)
      const res = searchBoothRecords(params)
      const response = await res
      const data = response.data

      setBoothRecords(data)
      setTotalPage(response.totalPages)
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
    }

    setFetching(false)
  }

  useEffect(() => {
    const fetchResources = async (type: string, callback: (arg: any) => void) => {
      try {
        const res = searchResources(type, {})
        const response = await res
        const data = response.data
        if (data.length) {
          callback(data)
        }
      } catch (e) {
        const errMsg = e as string
        toast.error(errMsg)
      }
    }

    fetchResources('action', setActions)
    fetchResources('currency', setCurrencies)
    fetchResources('language', setLanguages)
    fetchResources('location', setLocations)
    fetchBoothRecords({ pageNo: currentPage, pageSize: PERPAGE_DEFAULT })
  }, [])

  const handleOpenDetails = (id: number) => {
    const selectedBooth = boothRecords.find((b) => b.id === id)
    if (selectedBooth) {
      setCurrentBooth(selectedBooth)
      setOpenBoothPopup(true)
    }
  }

  const handleCreateOrUpdateBooth = async (params: any) => {
    try {
      const res = editMode ? updateBoothRecord(params, params.id) : createBoothRecord(params)
      const response = await res
      const data = response.data

      if (response.message === 'SUCCESSFULL') {
        if (editMode) {
          let updateBoothRecords = boothRecords.map((boothRecord: IBoothRecord) => {
            if (boothRecord.id === data.id) {
              return data
            }
            return boothRecord
          })

          setBoothRecords(updateBoothRecords)
        } else {
          setBoothRecords([data, ...boothRecords])
        }
        return response.message
      }

      return response.data
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      return 'FALSE'
    }
  }

  const handleDeleteBooth = async (id: number) => {
    try {
      const res = deleteBoothRecord(id)
      const response = await res
      if (response.message === 'SUCCESSFULL') {
        setBoothRecords(
          boothRecords.filter((booth) => {
            return booth.id !== id
          })
        )
      }

      return response.message
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      return 'FALSE'
    }
  }

  const searchBooth = (value: string) => {
    const trimmedValue = value.trim()
    const queryParams = {
      pageNo: 1,
      pageSize: PERPAGE_DEFAULT,
      ...(trimmedValue && { keyWord: trimmedValue })
    }

    fetchBoothRecords(queryParams)
    setValueSearch(trimmedValue)
  }

  useEffect(() => {
    fetchBoothRecords(getParams(currentPage, valueSearch))
  }, [currentPage])

  useEffect(() => {
    if (sortAction.sortBy.length > 0) {
      fetchBoothRecords(getParams(currentPage, valueSearch))
    }
  }, [sortAction])

  const getParams = (page: number = 1, value: string) => {
    return {
      pageNo: page,
      pageSize: PERPAGE_DEFAULT,
      ...(value.trim() && { keyWord: value }),
      ...(sortAction.sortBy.length > 0 && sortAction)
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Booth Record index' pageTitle='Booth Record' />
          <BoothsTable
            boothRecords={boothRecords}
            setOpenBoothPopup={setOpenBoothPopup}
            handleOpenDetails={handleOpenDetails}
            setEditMode={setEditMode}
            actionOptions={actions}
            handleDeleteBooth={handleDeleteBooth}
            searchBooth={searchBooth}
            fetching={fetching}
            sortAction={sortAction}
            setSortAction={setSortAction}
            setCanEdit={setCanEdit}
          />
          <BoothPopup
            isOpen={openBoothPopup}
            actionOptions={actions}
            currencyOptions={currencies}
            languageOptions={languages}
            locationOptions={locations}
            setOpenPopup={setOpenBoothPopup}
            handleCreateOrUpdateBooth={handleCreateOrUpdateBooth}
            boothRecord={currentBooth}
            editMode={editMode}
            canEdit={canEdit}
          />
          {totalPage > 1 && (
            <Pagination
              perPageData={PERPAGE_DEFAULT}
              data={boothRecords}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={totalPage}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Booths
