import React from 'react'
import FeatherIcon from 'feather-icons-react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

const FeatherIcons = () => {
  document.title = 'Feather Icons | Photo Booth Admin Dashboard'
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Feather' pageTitle='Icons' />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h4 className='card-title'>Examples</h4>
                  <p className='text-muted mb-0'>
                    Use <code>&lt;FeatherIcon icon="**"/&gt;</code> class.
                  </p>
                </CardHeader>
                <CardBody className='pt-0'>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='activity' /> activity
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='airplay' /> airplay
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='alert-circle' /> alert-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='alert-octagon' /> alert-octagon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='alert-triangle' /> alert-triangle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='align-center' /> align-center
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='align-justify' /> align-justify
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='align-left' /> align-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='align-right' /> align-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='anchor' /> anchor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='aperture' /> aperture
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='archive' /> archive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='arrow-down-circle' /> arrow-down-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='arrow-down-left' /> arrow-down-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='arrow-down-right' /> arrow-down-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='arrow-down' /> arrow-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='arrow-left-circle' /> arrow-left-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='arrow-left' /> arrow-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='arrow-right-circle' /> arrow-right-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='arrow-right' /> arrow-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='arrow-up-circle' /> arrow-up-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='arrow-up-left' /> arrow-up-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='arrow-up-right' /> arrow-up-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='arrow-up' /> arrow-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='at-sign' /> at-sign
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='award' /> award
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='bar-chart-2' /> bar-chart-2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='bar-chart' /> bar-chart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='battery-charging' /> battery-charging
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='battery' /> battery
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='bell-off' /> bell-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='bell' /> bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='bluetooth' /> bluetooth
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='bold' /> bold
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='book-open' /> book-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='book' /> book
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='bookmark' /> bookmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='box' /> box
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='briefcase' /> briefcase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='calendar' /> calendar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='camera-off' /> camera-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='camera' /> camera
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='cast' /> cast
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='check-circle' /> check-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='check-square' /> check-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='check' /> check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='chevron-down' /> chevron-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='chevron-left' /> chevron-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='chevron-right' /> chevron-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='chevron-up' /> chevron-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='chevrons-down' /> chevrons-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='chevrons-left' /> chevrons-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='chevrons-right' /> chevrons-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='chevrons-up' /> chevrons-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='chrome' /> chrome
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='circle' /> circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='clipboard' /> clipboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='clock' /> clock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='cloud-drizzle' /> cloud-drizzle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='cloud-lightning' /> cloud-lightning
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='cloud-off' /> cloud-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='cloud-rain' /> cloud-rain
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='cloud-snow' /> cloud-snow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='cloud' /> cloud
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='code' /> code
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='codepen' /> codepen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='codesandbox' /> codesandbox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='coffee' /> coffee
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='columns' /> columns
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='command' /> command
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='compass' /> compass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='copy' /> copy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='corner-down-left' /> corner-down-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='corner-down-right' /> corner-down-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='corner-left-down' /> corner-left-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='corner-left-up' /> corner-left-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='corner-right-down' /> corner-right-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='corner-right-up' /> corner-right-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='corner-up-left' /> corner-up-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='corner-up-right' /> corner-up-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='cpu' /> cpu
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='credit-card' /> credit-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='crop' /> crop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='crosshair' /> crosshair
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='database' /> database
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='delete' /> delete
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='disc' /> disc
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='divide-circle' /> divide-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='divide-square' /> divide-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='divide' /> divide
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='dollar-sign' /> dollar-sign
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='download-cloud' /> download-cloud
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='download' /> download
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='dribbble' /> dribbble
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='droplet' /> droplet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='edit-2' /> edit-2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='edit-3' /> edit-3
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='edit' /> edit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='external-link' /> external-link
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='eye-off' /> eye-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='eye' /> eye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='facebook' /> facebook
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='fast-forward' /> fast-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='feather' /> feather
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='figma' /> figma
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='file-minus' /> file-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='file-plus' /> file-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='file-text' /> file-text
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='file' /> file
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='film' /> film
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='filter' /> filter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='flag' /> flag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='folder-minus' /> folder-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='folder-plus' /> folder-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='folder' /> folder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='framer' /> framer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='frown' /> frown
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='gift' /> gift
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='git-branch' /> git-branch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='git-commit' /> git-commit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='git-merge' /> git-merge
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='git-pull-request' /> git-pull-request
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='github' /> github
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='gitlab' /> gitlab
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='globe' /> globe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='grid' /> grid
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='hard-drive' /> hard-drive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='hash' /> hash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='headphones' /> headphones
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='heart' /> heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='help-circle' /> help-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='hexagon' /> hexagon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='home' /> home
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='image' /> image
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='inbox' /> inbox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='info' /> info
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='instagram' /> instagram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='italic' /> italic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='key' /> key
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='layers' /> layers
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='layout' /> layout
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='life-buoy' /> life-buoy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='link-2' /> link-2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='link' /> link
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='linkedin' /> linkedin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='list' /> list
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='loader' /> loader
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='lock' /> lock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='log-in' /> log-in
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='log-out' /> log-out
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='mail' /> mail
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='map-pin' /> map-pin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='map' /> map
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='maximize-2' /> maximize-2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='maximize' /> maximize
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='meh' /> meh
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='menu' /> menu
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='message-circle' /> message-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='message-square' /> message-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='mic-off' /> mic-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='mic' /> mic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='minimize-2' /> minimize-2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='minimize' /> minimize
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='minus-circle' /> minus-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='minus-square' /> minus-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='minus' /> minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='monitor' /> monitor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='moon' /> moon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='more-horizontal' /> more-horizontal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='more-vertical' /> more-vertical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='mouse-pointer' /> mouse-pointer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='move' /> move
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='music' /> music
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='navigation-2' /> navigation-2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='navigation' /> navigation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='octagon' /> octagon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='package' /> package
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='paperclip' /> paperclip
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='pause-circle' /> pause-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='pause' /> pause
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='pen-tool' /> pen-tool
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='percent' /> percent
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='phone-call' /> phone-call
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='phone-forwarded' /> phone-forwarded
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='phone-incoming' /> phone-incoming
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='phone-missed' /> phone-missed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='phone-off' /> phone-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='phone-outgoing' /> phone-outgoing
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='phone' /> phone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='pie-chart' /> pie-chart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='play-circle' /> play-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='play' /> play
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='plus-circle' /> plus-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='plus-square' /> plus-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='plus' /> plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='pocket' /> pocket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='power' /> power
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='printer' /> printer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='radio' /> radio
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='refresh-ccw' /> refresh-ccw
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='refresh-cw' /> refresh-cw
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='repeat' /> repeat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='rewind' /> rewind
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='rotate-ccw' /> rotate-ccw
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='rotate-cw' /> rotate-cw
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='rss' /> rss
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='save' /> save
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='scissors' /> scissors
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='search' /> search
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='send' /> send
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='server' /> server
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='settings' /> settings
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='share-2' /> share-2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='share' /> share
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='shield-off' /> shield-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='shield' /> shield
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='shopping-bag' /> shopping-bag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='shopping-cart' /> shopping-cart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='shuffle' /> shuffle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='sidebar' /> sidebar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='skip-back' /> skip-back
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='skip-forward' /> skip-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='slack' /> slack
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='slash' /> slash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='sliders' /> sliders
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='smartphone' /> smartphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='smile' /> smile
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='speaker' /> speaker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='square' /> square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='star' /> star
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='stop-circle' /> stop-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='sun' /> sun
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='sunrise' /> sunrise
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='sunset' /> sunset
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='tablet' /> tablet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='tag' /> tag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='target' /> target
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='terminal' /> terminal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='thermometer' /> thermometer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='thumbs-down' /> thumbs-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='thumbs-up' /> thumbs-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='toggle-left' /> toggle-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='toggle-right' /> toggle-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='tool' /> tool
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='trash-2' /> trash-2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='trash' /> trash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='trello' /> trello
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='trending-down' /> trending-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='trending-up' /> trending-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='triangle' /> triangle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='truck' /> truck
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='tv' /> tv
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='twitch' /> twitch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='twitter' /> twitter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='type' /> type
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='umbrella' /> umbrella
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='underline' /> underline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='unlock' /> unlock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='upload-cloud' /> upload-cloud
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='upload' /> upload
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='user-check' /> user-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='user-minus' /> user-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='user-plus' /> user-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='user-x' /> user-x
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='user' /> user
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='users' /> users
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='video-off' /> video-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='video' /> video
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='voicemail' /> voicemail
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='volume-1' /> volume-1
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='volume-2' /> volume-2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='volume-x' /> volume-x
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='volume' /> volume
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='watch' /> watch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='wifi-off' /> wifi-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='wifi' /> wifi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='wind' /> wind
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='x-circle' /> x-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='x-octagon' /> x-octagon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='x-square' /> x-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='x' /> x
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='youtube' /> youtube
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='zap-off' /> zap-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='zap' /> zap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='zoom-in' /> zoom-in
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='zoom-out' /> zoom-out
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h4 className='card-title'>Sizes</h4>
                  <p className='text-muted mb-0'>
                    Use <code>&lt;FeatherIcon icon="**"/&gt;</code> class.Available sizes{' '}
                    <code>xs, sm,md,lg,xl,xxl</code>
                  </p>
                </CardHeader>
                <CardBody className='pt-0'>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='airplay' className='icon-xs' /> airplay
                    </Col>

                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='airplay' className='icon-sm' /> airplay
                    </Col>

                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='airplay' className='icon-md' /> airplay
                    </Col>

                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='airplay' className='icon-lg' /> airplay
                    </Col>

                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='airplay' className='icon-xl' /> airplay
                    </Col>

                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='airplay' className='icon-xxl' /> airplay
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h4 className='card-title'>Two Tones Color Icons</h4>
                  <p className='text-muted mb-0'>
                    Use <code>&lt;FeatherIcon icon="**" className="icon-dual-**" /&gt;</code>.
                  </p>
                </CardHeader>
                <CardBody className='pt-0'>
                  <Row className='icon-demo-content'>
                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='airplay' className='icon-dual' /> airplay
                    </Col>

                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='alert-circle' className='icon-dual-primary' /> alert-circle
                    </Col>

                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='alert-octagon' className='icon-dual-success' />{' '}
                      alert-octagon
                    </Col>

                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='alert-triangle' className='icon-dual-info' />{' '}
                      alert-triangle
                    </Col>

                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='anchor' className='icon-dual-warning' /> anchor
                    </Col>

                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='aperture' className='icon-dual-danger' /> aperture
                    </Col>

                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='archive' className='icon-dual-dark' /> archive
                    </Col>

                    <Col xl={3} lg={4} sm={6}>
                      <FeatherIcon icon='clock' className='icon-dual-secondary' /> clock
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FeatherIcons
