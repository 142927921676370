import { Navigate } from 'react-router-dom'

//Dashboard
import DashboardAnalytics from '../pages/DashboardAnalytics'
import DashboardEcommerce from '../pages/DashboardEcommerce'

//login
import Login from '../pages/Authentication/Login'
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'

// User Profile
import UserProfile from '../pages/Authentication/user-profile'

// Booth
import Booths from '../pages/app/Booths'

//Location
import Locations from 'pages/app/Locations'

//Action Price
import ActionPrices from 'pages/app/ActionPrices'

//Event
import Events from 'pages/app/Events'

//Event Booths
import EventBooths from 'pages/app/EventBooths'

//Promotion
import Promotions from 'pages/app/Promotions'

//Promotion Booths
import PromotionBooths from 'pages/app/PromotionBooths'

//Users
import Users from 'pages/app/Users'


import { components } from 'react-select'

const authProtectedRoutes = [
  { path: '/dashboard-analytics', component: <DashboardAnalytics /> },
  { path: '/dashboard', component: <DashboardEcommerce /> },
  { path: '/index', component: <DashboardEcommerce /> },

  //User Profile
  { path: '/profile', component: <UserProfile /> },

  // Booth
  { path: '/booths', component: <Booths /> },

  //Location
  { path: '/locations', component: <Locations /> },

  //Action Price
  { path: '/action-prices', component: <ActionPrices /> },

  //Event
  { path: '/events', component: <Events /> },

  //Event Booths
  { path: '/event-booths', component: <EventBooths /> },

  //Promotion
  { path: '/promotions', component: <Promotions /> },

  //Promotion Booths
  { path: '/promotion-booths', component: <PromotionBooths /> },

  //Users
  { path: '/users', component: <Users /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: <Navigate to='/dashboard' />
  },
  { path: '*', component: <Navigate to='/dashboard' /> }
]

const publicRoutes = [
  // Authentication Page
  { path: '/logout', component: <Logout /> },
  { path: '/login', component: <Login /> },
  { path: '/forgot-password', component: <ForgetPasswordPage /> },
  { path: '/register', component: <Register /> }
]

export { authProtectedRoutes, publicRoutes }
