import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Form,
  FormGroup,
  FormFeedback
} from 'reactstrap'
import { IEvent } from '../Events'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'
import {
  customStylesLabel,
  discountTypeOptions,
  isFinishDateTimeGreater,
  mapSingleEventBooth
} from 'lib/util'
import { isEqual } from 'lodash'

interface IProps {
  eventRecord: IEvent | undefined
  isOpen: boolean
  editMode: boolean
  setOpenPopup: (arg: boolean) => void
  handleCreateOrUpdateEvent: (arg: any) => Promise<string>
  booths: any[]
  canEdit: boolean
}

const EventModal: React.FC<IProps> = ({
  eventRecord,
  isOpen,
  editMode,
  handleCreateOrUpdateEvent,
  setOpenPopup,
  booths,
  canEdit
}) => {
  const [eventBooth, setEventBooth] = useState<any>(null)
  const [initEventBooths, setInitEventBooths] = useState<any>([])
  const [disableSave, setDisableSave] = useState<boolean>(editMode)
  const hasEdit = (editMode && eventRecord) || false

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: hasEdit ? eventRecord?.id : null,
      name: hasEdit ? eventRecord?.name : '',
      startDateTime: hasEdit ? eventRecord?.startDateTime : '',
      finishDateTime: hasEdit ? eventRecord?.finishDateTime : '',
      discountType: hasEdit ? eventRecord?.discountType : '',
      discountValue: hasEdit ? eventRecord?.discountValue : '',
      isActive: hasEdit ? eventRecord?.isActive : false
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Name'),
      startDateTime: Yup.string().required('Please Select Start Date Time'),
      finishDateTime: Yup.string()
        .required('Please Select Finish Date Time')
        .test('is-greater', 'Finish time must be later than start time', function (value) {
          const startDateTime = formik.values.startDateTime
          return isFinishDateTimeGreater(value, startDateTime)
        }),
      discountType: Yup.number().required('Please Select Discount Type'),
      discountValue: Yup.number().required('Please Enter Discount Value')
    }),
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  useEffect(() => {
    if (isOpen) {
      formik.resetForm()
      if (editMode && eventRecord) {
        setInitEventBooths(mapSingleEventBooth(eventRecord))
        setDisableSave(true)
      } else {
        setDisableSave(false)
        setInitEventBooths([])
      }
    } else {
      setEventBooth(null)
    }
  }, [isOpen])

  useEffect(() => {
    setEventBooth(mapSingleEventBooth(eventRecord))
  }, [eventRecord])

  useEffect(() => {
    setDisableSave(isEqual(initEventBooths.booths, eventBooth?.booths))
  }, [eventBooth])

  useEffect(() => {
    formik.validateForm()
  }, [formik.values.finishDateTime, formik.values.startDateTime])

  const togglePopup = () => {
    setOpenPopup(!isOpen)
  }

  const handleOnChange = (e: { target: { name: any; value: any } }) => {
    const name = e.target.name
    let value =
      ['discountValue', 'discountType'].includes(name) && e.target.value
        ? parseInt(e.target.value)
        : e.target.value
    formik.setFieldValue(name, value)
  }

  const handleSubmit = async (values: any) => {
    if (!editMode) {
      delete values.id
    }

    const params = {
      ...values,
      boothIds: eventBooth?.booths ? eventBooth?.booths.map((item: any) => item.value) : []
    }

    const res = handleCreateOrUpdateEvent(params)
    const message = await res
    if (message === 'SUCCESSFULL') {
      toast.success(
        editMode ? 'Updated an Event successfully' : 'Created an new Event successfully'
      )
      setOpenPopup(false)
    } else {
      toast.error(message)
    }
  }

  const handleChangeEventActive = (checked: boolean) => {
    formik.setFieldValue('isActive', checked)
  }

  const handleSelect = (selectValues: any) => {
    setEventBooth({ ...eventRecord, booths: selectValues })
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          togglePopup()
        }}
        scrollable={true}
        id='eventModal'
        className='overflow-auto'
      >
        <ModalHeader
          className='modal-title'
          id='eventModalTitle'
          toggle={() => {
            togglePopup()
          }}
        >
          {editMode ? 'Event Record Details' : 'Create a new Event Record'}
        </ModalHeader>
        <ModalBody className='overflow-visible'>
          <Form
            className='needs-validation'
            onSubmit={(e) => {
              e.preventDefault()
              formik.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col md={12} className='mb-3'>
                <FormGroup>
                  <div>
                    <Label htmlFor='name' className='form-label mb-1 fw-semibold'>
                      Name
                    </Label>
                    <Input
                      type='text'
                      maxlength='50'
                      className='form-control'
                      id='name'
                      name='name'
                      placeholder='Name'
                      value={formik.values.name}
                      onChange={(e) => handleOnChange(e)}
                      invalid={formik.touched.name && formik.errors.name ? true : false}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <FormFeedback type='invalid'>{formik.errors.name}</FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              <Col md={6} className='mb-3'>
                <FormGroup>
                  <div>
                    <Label htmlFor='type' className='form-label mb-1 fw-semibold'>
                      Role
                    </Label>
                    <select
                      className={`form-select ${formik.touched.type && formik.errors.type ? 'is-invalid' : ''}`}
                      aria-label='camera-type'
                      name='type'
                      onChange={(e) => handleOnChange(e)}
                    >
                      {discountTypeOptions.map((dis, index) => {
                        return (
                          <option
                            key={index}
                            value={dis.value}
                            selected={dis.value === formik.values.discountType}
                          >
                            {dis.label}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.discountType && formik.errors.discountType ? (
                      <FormFeedback type='invalid'>{formik.errors.discountType}</FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              <Col md={6} className='mb-3'>
                <FormGroup>
                  <div>
                    <Label htmlFor='ç' className='form-label mb-1 fw-semibold'>
                      Discount Value
                    </Label>
                    <Input
                      type='number'
                      min={0}
                      max={formik.values.discountType === 1 ? 100 : undefined}
                      className='form-control'
                      id='discount-value'
                      placeholder='Discount Value'
                      name='discountValue'
                      onChange={(e) => handleOnChange(e)}
                      value={formik.values.discountValue}
                      invalid={
                        formik.touched.discountValue && formik.errors.discountValue ? true : false
                      }
                    />
                    {formik.touched.discountValue && formik.errors.discountValue ? (
                      <FormFeedback type='invalid'>{formik.errors.discountValue}</FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              <Col md={6} className='mb-3'>
                <FormGroup>
                  <div>
                    <Label htmlFor='start-date' className='form-label mb-1 fw-semibold'>
                      Start Date Time
                    </Label>
                    <DatePicker
                      selected={formik.values.startDateTime ? formik.values.startDateTime : null}
                      onChange={(date) => {
                        formik.setFieldValue('startDateTime', date ? date : null)
                      }}
                      showTimeInput
                      timeInputLabel='Time:'
                      timeFormat='HH:mm'
                      dateFormat='MM/dd/yyyy h:mm aa'
                      className={`form-control ${
                        formik.touched.startDateTime && formik.errors.startDateTime
                          ? 'is-invalid'
                          : ''
                      }`}
                      name='startDateTime'
                      placeholderText='Select a date'
                      showIcon
                    />
                    {formik.touched.startDateTime && formik.errors.startDateTime ? (
                      <FormFeedback type='invalid' className='d-block'>
                        {formik.errors.startDateTime}
                      </FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              <Col md={6} className='mb-3'>
                <FormGroup>
                  <div
                    className={`${
                      formik.touched.finishDateTime && formik.errors.finishDateTime
                        ? 'is-invalid'
                        : ''
                    }`}
                  >
                    <Label htmlFor='finishDateTime' className='form-label mb-1 fw-semibold'>
                      Finish Date Time
                    </Label>
                    <DatePicker
                      selected={formik.values.finishDateTime ? formik.values.finishDateTime : null}
                      onChange={(date) => {
                        formik.setFieldValue('finishDateTime', date ? date : null)
                      }}
                      showTimeInput
                      timeInputLabel='Time:'
                      timeFormat='HH:mm'
                      dateFormat='MM/dd/yyyy h:mm aa'
                      className={`form-control ${
                        formik.touched.finishDateTime && formik.errors.finishDateTime
                          ? 'is-invalid'
                          : ''
                      }`}
                      name='finishDateTime'
                      placeholderText='Select a date'
                      showIcon
                    />
                    {formik.touched.finishDateTime && formik.errors.finishDateTime ? (
                      <FormFeedback type='invalid' className='d-block'>
                        {formik.errors.finishDateTime}
                      </FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              <Col md={12} className='mb-3'>
                <div>
                  <Label htmlFor='booth-value' className='form-label mb-1 fw-semibold'>
                    Booths
                  </Label>
                  <Select
                    value={eventBooth?.booths}
                    isMulti={true}
                    isClearable={true}
                    onChange={(selectedMulti2: any) => {
                      handleSelect(selectedMulti2)
                    }}
                    options={booths}
                    styles={customStylesLabel}
                  />
                </div>
              </Col>
              <Col md={12} className='mb-3'>
                <div className='form-check'>
                  <Input
                    className='form-check-input'
                    type='checkbox'
                    id='event-active'
                    checked={formik.values.isActive}
                    onChange={(e) => handleChangeEventActive(e.target.checked)}
                  />
                  <Label className='form-check-label fw-medium' htmlFor={`event-active`}>
                    Event Active
                  </Label>
                </div>
              </Col>
            </Row>
            <div className='modal-footer'>
              <Button color='light' onClick={() => setOpenPopup(false)}>
                Close
              </Button>
              {
                canEdit &&
                <Button
                  color='primary'
                  type='submit'
                  disabled={editMode && !formik.dirty && disableSave}
                >
                  Save
                </Button>
              }
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default EventModal
