import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'
import { IBoothRecord } from './index'
import { pick } from 'lodash'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isEqual } from 'lodash'

interface IProps {
  boothRecord: IBoothRecord | undefined
  isOpen: boolean
  editMode: boolean
  actionOptions: any[]
  currencyOptions: any[]
  languageOptions: any[]
  locationOptions: any[]
  setOpenPopup: (arg: boolean) => void
  handleCreateOrUpdateBooth: (arg: any) => Promise<string>
  canEdit: boolean
}

interface IBoothRecordState {
  id?: number
  name: string
  locationId: number | undefined | null
  isAct1: boolean
  isAct2: boolean
  isAct3: boolean
  isAct4: boolean
  isAct5: boolean
  isCash: boolean
  isCreditCard: boolean
  isQrcode: boolean
  currencyId: number | null
  languageId: number | null
  camType: number
  isPrinterActive: boolean
  isActive: boolean
  iPAddress: string
}

const initBoothRecord: IBoothRecordState = {
  name: '',
  locationId: null,
  isAct1: false,
  isAct2: false,
  isAct3: false,
  isAct4: false,
  isAct5: false,
  isCash: false,
  isCreditCard: false,
  isQrcode: false,
  languageId: null,
  currencyId: null,
  camType: 0,
  isPrinterActive: false,
  isActive: false,
  iPAddress: ''
}

const BoothPopup: React.FC<IProps> = ({
  boothRecord,
  isOpen,
  actionOptions,
  currencyOptions,
  languageOptions,
  locationOptions,
  editMode,
  handleCreateOrUpdateBooth,
  setOpenPopup,
  canEdit
}) => {
  const [boothRecordState, setBoothRecordState] = useState<IBoothRecordState>(initBoothRecord)
  const [initBoothRecordState, setInitBoothRecordState] =
    useState<IBoothRecordState>(initBoothRecord)
  const [disableSave, setDisableSave] = useState<boolean>(editMode)
  const hasEdit = (editMode && boothRecordState) || false

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: hasEdit ? boothRecordState?.name : '',
      locationId: hasEdit ? boothRecordState?.locationId : null,
      currencyId: hasEdit ? boothRecordState?.currencyId : null,
      languageId: hasEdit ? boothRecordState?.languageId : null,
      camType: hasEdit ? boothRecordState?.camType : null
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Name'),
      locationId: Yup.string().required('Please Select Location'),
      currencyId: Yup.string().required('Please Select Currency'),
      languageId: Yup.string().required('Please Select Language'),
      camType: Yup.string().required('Please Select Camtype')
    }),
    onSubmit: (values) => {
      handleSubmit()
    }
  })

  useEffect(() => {
    if (editMode && boothRecord) {
      setBoothRecordState(boothRecord)
      setInitBoothRecordState(boothRecord)
      setDisableSave(true)
    } else {
      setBoothRecordState(initBoothRecord)
      setDisableSave(false)
    }

    if (isOpen) {
      formik.resetForm()
    }
  }, [isOpen])

  const togglePopup = () => {
    setOpenPopup(!isOpen)
  }

  useEffect(() => {
    setDisableSave(isEqual(boothRecordState, initBoothRecordState))
  }, [boothRecordState])

  const paymentMethodOptions = [
    { label: 'Cash', value: 'Cash' },
    { label: 'Credit Card', value: 'CreditCard' },
    { label: 'QR Code', value: 'Qrcode' }
  ]

  const CameraTypeOptions = [
    { label: 'Unknown', value: 0 },
    { label: 'Canon', value: 1 },
    { label: 'Webcam', value: 2 }
  ]

  const handleOnChange = (e: { target: { name: any; value: any } }) => {
    const name = e.target.name
    let value = ['camType', 'locationId', 'currencyId', 'languageId'].includes(name)
      ? parseInt(e.target.value)
      : e.target.value
    setBoothRecordState({
      ...boothRecordState,
      [name]: value
    })
    formik.setFieldValue(name, value)
  }

  const handleChangeActiveAction = (index: number, checked: any) => {
    setBoothRecordState({
      ...boothRecordState,
      [`isAct${index}`]: Boolean(checked)
    })
  }

  const handleChangePaymentMethod = (value: string, checked: boolean) => {
    setBoothRecordState({
      ...boothRecordState,
      [`is${value}`]: Boolean(checked)
    })
  }

  const handleChangePrinterActive = (checked: boolean) => {
    setBoothRecordState({
      ...boothRecordState,
      isPrinterActive: checked
    })
  }

  const handleChangeBoothActive = (checked: boolean) => {
    setBoothRecordState({
      ...boothRecordState,
      isActive: checked
    })
  }

  const handleSubmit = async () => {
    let bodyParams = editMode
      ? {
          ...pick(boothRecordState, Object.keys(initBoothRecord)),
          id: boothRecordState.id
        }
      : pick(boothRecordState, Object.keys(initBoothRecord))
    const res = handleCreateOrUpdateBooth(bodyParams)
    const message = await res
    if (message === 'SUCCESSFULL') {
      toast.success(editMode ? 'Updated a booth successfully' : 'Created a new booth successfully')
      setOpenPopup(false)
    } else {
      toast.error(message)
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          togglePopup()
        }}
        scrollable={true}
        id='boothModal'
      >
        <ModalHeader
          className='modal-title'
          id='boothModalTitle'
          toggle={() => {
            togglePopup()
          }}
        >
          {editMode ? 'Booth Record Details' : 'Create A New Booth Record'}
        </ModalHeader>
        <ModalBody>
          <Form
            className='needs-validation'
            onSubmit={(e) => {
              e.preventDefault()
              formik.handleSubmit()
              return false
            }}
          >
            <Row>
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div>
                    <Label htmlFor='name' className='form-label mb-1 fw-semibold'>
                      Name
                    </Label>
                    <Input
                      type='text'
                      maxlength='50'
                      className='form-control'
                      id='name'
                      name='name'
                      placeholder='Name'
                      value={boothRecordState?.name}
                      onChange={(e) => handleOnChange(e)}
                      invalid={formik.touched.name && formik.errors.name ? true : false}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <FormFeedback type='invalid'>{formik.errors.name}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </FormGroup>
              {/* Location */}
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div>
                    <Label htmlFor='location' className='form-label mb-1 fw-semibold'>
                      Location
                    </Label>
                    <select
                      className={`form-select ${formik.touched.locationId && formik.errors.locationId ? 'is-invalid' : ''}`}
                      name='locationId'
                      onChange={(e) => handleOnChange(e)}
                    >
                      <option value=''>Select location</option>
                      {locationOptions.map((location, index) => {
                        return (
                          <option
                            key={index}
                            value={parseInt(location.id)}
                            selected={parseInt(location.id) === boothRecordState?.locationId}
                          >
                            {location.name} - {location.address}, {location.city}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.locationId && formik.errors.locationId ? (
                      <FormFeedback type='invalid'>{formik.errors.locationId}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </FormGroup>
              {/* Active Action */}
              <Col md={12} className='mb-3'>
                <div>
                  <Label htmlFor='active-action' className='form-label mb-1 fw-semibold'>
                    Active Action
                  </Label>
                  <Row>
                    {actionOptions.map((action, index) => {
                      const tempState = boothRecordState as any
                      const checked = boothRecordState && tempState[`isAct${index + 1}`]
                      return (
                        <Col md={4} key={index}>
                          <div className='form-check'>
                            <Input
                              id={`active-action-option-${index}`}
                              type='checkbox'
                              className='form-check-input'
                              checked={checked}
                              onChange={(e) =>
                                handleChangeActiveAction(index + 1, e.target.checked)
                              }
                            />
                            <Label
                              className='form-check-label fw-normal'
                              htmlFor={`active-action-option-${index}`}
                            >
                              {action.name}
                            </Label>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              </Col>
              {/* Valid Payment Method */}
              <Col md={12} className='mb-3'>
                <div>
                  <Label htmlFor='active-action' className='form-label mb-1 fw-semibold'>
                    Valid Payment Method
                  </Label>
                  <Row>
                    {paymentMethodOptions.map((method, index) => {
                      const tempState = boothRecordState as any
                      const checked = tempState[`is${method.value}`]
                      return (
                        <Col md={4} key={index}>
                          <div className='form-check'>
                            <Input
                              className='form-check-input'
                              type='checkbox'
                              id={`payment-method-option-${index}`}
                              checked={checked}
                              onChange={(e) =>
                                handleChangePaymentMethod(method.value, e.target.checked)
                              }
                            />
                            <Label
                              className='form-check-label fw-normal'
                              htmlFor={`payment-method-option-${index}`}
                            >
                              {method.label}
                            </Label>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              </Col>
              {/* Default Currency */}
              <Col md={6} className='mb-3'>
                <FormGroup>
                  <div>
                    <Label htmlFor='active-action' className='form-label mb-1 fw-semibold'>
                      Default Currency
                    </Label>
                    <select
                      className={`form-select ${formik.touched.currencyId && formik.errors.currencyId ? 'is-invalid' : ''}`}
                      aria-label='Default currency'
                      name='currencyId'
                      onChange={(e) => handleOnChange(e)}
                    >
                      <option value=''>Select default currentcy </option>
                      {currencyOptions.map((currency, index) => {
                        return (
                          <option
                            key={index}
                            value={currency.id}
                            selected={parseInt(currency.id) === boothRecordState?.currencyId}
                          >
                            {currency.name}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.currencyId && formik.errors.currencyId ? (
                      <FormFeedback type='invalid'>{formik.errors.currencyId}</FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              {/* Default Language */}
              <Col md={6} className='mb-3'>
                <FormGroup>
                  <div>
                    <Label htmlFor='active-action' className='form-label mb-1 fw-semibold'>
                      Default Languge
                    </Label>
                    <select
                      className={`form-select ${formik.touched.languageId && formik.errors.languageId ? 'is-invalid' : ''}`}
                      aria-label='Default language'
                      name='languageId'
                      onChange={(e) => handleOnChange(e)}
                    >
                      <option value=''>Select default language</option>
                      {languageOptions.map((lang, index) => {
                        return (
                          <option
                            key={index}
                            value={lang.id}
                            selected={parseInt(lang.id) === boothRecordState?.languageId}
                          >
                            {lang.name}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.languageId && formik.errors.languageId ? (
                      <FormFeedback type='invalid'>{formik.errors.languageId}</FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              {/* Camera Type */}
              <Col md={6} className='mb-3'>
                <FormGroup>
                  <div>
                    <Label htmlFor='active-action' className='form-label mb-1 fw-semibold'>
                      Camera Type
                    </Label>
                    <select
                      className={`form-select ${formik.touched.camType && formik.errors.camType ? 'is-invalid' : ''}`}
                      aria-label='camera-type'
                      name='camType'
                      onChange={(e) => handleOnChange(e)}
                    >
                      {CameraTypeOptions.map((cam, index) => {
                        return (
                          <option
                            key={index}
                            value={cam.value}
                            selected={cam.value === boothRecordState?.camType}
                          >
                            {cam.label}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.camType && formik.errors.camType ? (
                      <FormFeedback type='invalid'>{formik.errors.camType}</FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              {/* IP Address */}
              <Col md={6} className='mb-3'>
                <div>
                  <Label htmlFor='ip-address' className='form-label mb-1 fw-semibold'>
                    IP Address
                  </Label>
                  <Input
                    type='text'
                    className='form-control'
                    id='ip-address'
                    placeholder='IP Address (e.g: 1.1.1.1)'
                    name='iPAddress'
                    onChange={(e) => handleOnChange(e)}
                    value={boothRecordState.iPAddress}
                  />
                </div>
              </Col>
              <Col md={12} className='mb-3'>
                <div className='form-check'>
                  <Input
                    className='form-check-input'
                    type='checkbox'
                    id='printer-active'
                    checked={boothRecordState.isPrinterActive}
                    onChange={(e) => handleChangePrinterActive(e.target.checked)}
                  />
                  <Label className='form-check-label fw-medium' htmlFor={`printer-active`}>
                    Printer Active
                  </Label>
                </div>
              </Col>
              <Col md={12} className='mb-3'>
                <div className='form-check'>
                  <Input
                    className='form-check-input'
                    type='checkbox'
                    id='booth-active'
                    checked={boothRecordState.isActive}
                    onChange={(e) => handleChangeBoothActive(e.target.checked)}
                  />
                  <Label className='form-check-label fw-medium' htmlFor={`booth-active`}>
                    Booth Active
                  </Label>
                </div>
              </Col>
            </Row>
            <div className='modal-footer'>
              <Button color='light' onClick={() => setOpenPopup(false)}>
                Close
              </Button>
              {
                canEdit &&
                <Button color='primary' type='submit' disabled={disableSave}>
                  Save
                </Button>
              }
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default BoothPopup
