import React from 'react'
import { Navigate } from 'react-router-dom'

//Dashboard
import DashboardAnalytics from '../pages/DashboardAnalytics'
import DashboardCrm from '../pages/DashboardCrm'
import DashboardEcommerce from '../pages/DashboardEcommerce'

import DashboardCrypto from '../pages/DashboardCrypto'
import DashboardProject from '../pages/DashboardProject'
import DashboardNFT from '../pages/DashboardNFT'
import DashboardJobs from '../pages/DashboardJob'

//Calendar
import Calendar from '../pages/Calendar'
import MonthGrid from '../pages/Calendar/monthGrid'

// Email box
import MailInbox from '../pages/EmailInbox'
import BasicAction from '../pages/Email/EmailTemplates/BasicAction'
import EcommerceAction from '../pages/Email/EmailTemplates/EcommerceAction'

//Chat
import Chat from '../pages/Chat'

// Project
import ProjectList from '../pages/Projects/ProjectList'
import ProjectOverview from '../pages/Projects/ProjectOverview'
import CreateProject from '../pages/Projects/CreateProject'

//Task
import TaskDetails from '../pages/Tasks/TaskDetails'
import TaskList from '../pages/Tasks/TaskList'

//Transactions
import Transactions from '../pages/Crypto/Transactions'
import BuySell from '../pages/Crypto/BuySell'
import CryproOrder from '../pages/Crypto/CryptoOrder'
import MyWallet from '../pages/Crypto/MyWallet'
import ICOList from '../pages/Crypto/ICOList'
import KYCVerification from '../pages/Crypto/KYCVerification'

//Crm Pages
import CrmCompanies from '../pages/Crm/CrmCompanies'
import CrmContacts from '../pages/Crm/CrmContacts'
import CrmDeals from '../pages/Crm/CrmDeals/index'
import CrmLeads from '../pages/Crm/CrmLeads/index'

//Invoices
import InvoiceList from '../pages/Invoices/InvoiceList'
import InvoiceCreate from '../pages/Invoices/InvoiceCreate'
import InvoiceDetails from '../pages/Invoices/InvoiceDetails'

// Support Tickets
import ListView from '../pages/SupportTickets/ListView'
import TicketsDetails from '../pages/SupportTickets/TicketsDetails'

// //Ecommerce Pages
import EcommerceProducts from '../pages/Ecommerce/EcommerceProducts/index'
import EcommerceProductDetail from '../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail'
import EcommerceAddProduct from '../pages/Ecommerce/EcommerceProducts/EcommerceAddProduct'
import EcommerceOrders from '../pages/Ecommerce/EcommerceOrders/index'
import EcommerceOrderDetail from '../pages/Ecommerce/EcommerceOrders/EcommerceOrderDetail'
import EcommerceCustomers from '../pages/Ecommerce/EcommerceCustomers/index'
import EcommerceCart from '../pages/Ecommerce/EcommerceCart'
import EcommerceCheckout from '../pages/Ecommerce/EcommerceCheckout'
import EcommerceSellers from '../pages/Ecommerce/EcommerceSellers/index'
import EcommerceSellerDetail from '../pages/Ecommerce/EcommerceSellers/EcommerceSellerDetail'

// NFT Marketplace Pages
import Marketplace from '../pages/NFTMarketplace/Marketplace'
import Collections from '../pages/NFTMarketplace/Collections'
import CreateNFT from '../pages/NFTMarketplace/CreateNFT'
import Creators from '../pages/NFTMarketplace/Creators'
import ExploreNow from '../pages/NFTMarketplace/ExploreNow'
import ItemDetails from '../pages/NFTMarketplace/Itemdetails'
import LiveAuction from '../pages/NFTMarketplace/LiveAuction'
import Ranking from '../pages/NFTMarketplace/Ranking'
import WalletConnect from '../pages/NFTMarketplace/WalletConnect'

// Base Ui
import UiAlerts from '../pages/BaseUi/UiAlerts/UiAlerts'
import UiBadges from '../pages/BaseUi/UiBadges/UiBadges'
import UiButtons from '../pages/BaseUi/UiButtons/UiButtons'
import UiColors from '../pages/BaseUi/UiColors/UiColors'
import UiCards from '../pages/BaseUi/UiCards/UiCards'
import UiCarousel from '../pages/BaseUi/UiCarousel/UiCarousel'
import UiDropdowns from '../pages/BaseUi/UiDropdowns/UiDropdowns'
import UiGrid from '../pages/BaseUi/UiGrid/UiGrid'
import UiImages from '../pages/BaseUi/UiImages/UiImages'
import UiTabs from '../pages/BaseUi/UiTabs/UiTabs'
import UiAccordions from '../pages/BaseUi/UiAccordion&Collapse/UiAccordion&Collapse'
import UiModals from '../pages/BaseUi/UiModals/UiModals'
import UiOffcanvas from '../pages/BaseUi/UiOffcanvas/UiOffcanvas'
import UiPlaceholders from '../pages/BaseUi/UiPlaceholders/UiPlaceholders'
import UiProgress from '../pages/BaseUi/UiProgress/UiProgress'
import UiNotifications from '../pages/BaseUi/UiNotifications/UiNotifications'
import UiMediaobject from '../pages/BaseUi/UiMediaobject/UiMediaobject'
import UiEmbedVideo from '../pages/BaseUi/UiEmbedVideo/UiEmbedVideo'
import UiTypography from '../pages/BaseUi/UiTypography/UiTypography'
import UiList from '../pages/BaseUi/UiLists/UiLists'
import UiLink from '../pages/BaseUi/UiLinks/UiLinks'
import UiGeneral from '../pages/BaseUi/UiGeneral/UiGeneral'
import UiRibbons from '../pages/BaseUi/UiRibbons/UiRibbons'
import UiUtilities from '../pages/BaseUi/UiUtilities/UiUtilities'

// Advance Ui
import UiScrollbar from '../pages/AdvanceUi/UiScrollbar/UiScrollbar'
import UiAnimation from '../pages/AdvanceUi/UiAnimation/UiAnimation'
import UiSwiperSlider from '../pages/AdvanceUi/UiSwiperSlider/UiSwiperSlider'
import UiRatings from '../pages/AdvanceUi/UiRatings/UiRatings'
import UiHighlight from '../pages/AdvanceUi/UiHighlight/UiHighlight'

// Widgets
import Widgets from '../pages/Widgets/Index'

//Forms
import BasicElements from '../pages/Forms/BasicElements/BasicElements'
import FormSelect from '../pages/Forms/FormSelect/FormSelect'
import FormEditor from '../pages/Forms/FormEditor/FormEditor'
import CheckBoxAndRadio from '../pages/Forms/CheckboxAndRadio/CheckBoxAndRadio'
import Masks from '../pages/Forms/Masks/Masks'
import FileUpload from '../pages/Forms/FileUpload/FileUpload'
import FormPickers from '../pages/Forms/FormPickers/FormPickers'
import FormRangeSlider from '../pages/Forms/FormRangeSlider/FormRangeSlider'
import Formlayouts from '../pages/Forms/FormLayouts/Formlayouts'
import FormValidation from '../pages/Forms/FormValidation/FormValidation'
import FormWizard from '../pages/Forms/FormWizard/FormWizard'
import FormAdvanced from '../pages/Forms/FormAdvanced/FormAdvanced'
import Select2 from '../pages/Forms/Select2/Select2'

//Tables
import BasicTables from '../pages/Tables/BasicTables/BasicTables'
import ReactTable from '../pages/Tables/ReactTables'

//Icon pages
import RemixIcons from '../pages/Icons/RemixIcons/RemixIcons'
import BoxIcons from '../pages/Icons/BoxIcons/BoxIcons'
import MaterialDesign from '../pages/Icons/MaterialDesign/MaterialDesign'
import FeatherIcons from '../pages/Icons/FeatherIcons/FeatherIcons'
import LineAwesomeIcons from '../pages/Icons/LineAwesomeIcons/LineAwesomeIcons'
import CryptoIcons from '../pages/Icons/CryptoIcons/CryptoIcons'

//Maps
import GoogleMaps from '../pages/Maps/GoogleMaps/GoogleMaps'

//AuthenticationInner pages
import BasicSignIn from '../pages/AuthenticationInner/Login/BasicSignIn'
import CoverSignIn from '../pages/AuthenticationInner/Login/CoverSignIn'
import BasicSignUp from '../pages/AuthenticationInner/Register/BasicSignUp'
import CoverSignUp from '../pages/AuthenticationInner/Register/CoverSignUp'
import BasicPasswReset from '../pages/AuthenticationInner/PasswordReset/BasicPasswReset'
//pages
import Starter from '../pages/Pages/Starter/Starter'
import SimplePage from '../pages/Pages/Profile/SimplePage/SimplePage'
import Settings from '../pages/Pages/Profile/Settings/Settings'
import Team from '../pages/Pages/Team/Team'
import Timeline from '../pages/Pages/Timeline/Timeline'
import Faqs from '../pages/Pages/Faqs/Faqs'
import Pricing from '../pages/Pages/Pricing/Pricing'
import Gallery from '../pages/Pages/Gallery/Gallery'
import Maintenance from '../pages/Pages/Maintenance/Maintenance'
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon'
import SiteMap from '../pages/Pages/SiteMap/SiteMap'
import SearchResults from '../pages/Pages/SearchResults/SearchResults'

import CoverPasswReset from '../pages/AuthenticationInner/PasswordReset/CoverPasswReset'
import BasicLockScreen from '../pages/AuthenticationInner/LockScreen/BasicLockScr'
import CoverLockScreen from '../pages/AuthenticationInner/LockScreen/CoverLockScr'
import BasicLogout from '../pages/AuthenticationInner/Logout/BasicLogout'
import CoverLogout from '../pages/AuthenticationInner/Logout/CoverLogout'
import BasicSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg'
import CoverSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg'
import BasicTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify'
import CoverTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify'
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404'
import Cover404 from '../pages/AuthenticationInner/Errors/Cover404'
import Alt404 from '../pages/AuthenticationInner/Errors/Alt404'
import Error500 from '../pages/AuthenticationInner/Errors/Error500'

import BasicPasswCreate from '../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate'
import CoverPasswCreate from '../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate'
import Offlinepage from '../pages/AuthenticationInner/Errors/Offlinepage'

//login
import Login from '../pages/Authentication/Login'
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'

//Charts
import LineCharts from '../pages/Charts/ApexCharts/LineCharts'
import AreaCharts from '../pages/Charts/ApexCharts/AreaCharts'
import ColumnCharts from '../pages/Charts/ApexCharts/ColumnCharts'
import BarCharts from '../pages/Charts/ApexCharts/BarCharts'
import MixedCharts from '../pages/Charts/ApexCharts/MixedCharts'
import TimelineCharts from '../pages/Charts/ApexCharts/TimelineCharts'
import CandlestickChart from '../pages/Charts/ApexCharts/CandlestickChart'
import BoxplotCharts from '../pages/Charts/ApexCharts/BoxplotCharts'
import BubbleChart from '../pages/Charts/ApexCharts/BubbleChart'
import ScatterCharts from '../pages/Charts/ApexCharts/ScatterCharts'
import HeatmapCharts from '../pages/Charts/ApexCharts/HeatmapCharts'
import TreemapCharts from '../pages/Charts/ApexCharts/TreemapCharts'
import PieCharts from '../pages/Charts/ApexCharts/PieCharts'
import RadialbarCharts from '../pages/Charts/ApexCharts/RadialbarCharts'
import RadarCharts from '../pages/Charts/ApexCharts/RadarCharts'
import PolarCharts from '../pages/Charts/ApexCharts/PolarCharts'
import RangeArea from '../pages/Charts/ApexCharts/RangeAreaCharts'
import FunnelChart from '../pages/Charts/ApexCharts/FunnelCharts'

import ChartsJs from '../pages/Charts/ChartsJs/index'
import Echarts from '../pages/Charts/ECharts/index'

// Landing Index
import OnePage from '../pages/Landing/OnePage'
import NFTLanding from '../pages/Landing/NFTLanding'

// User Profile
import UserProfile from '../pages/Authentication/user-profile'

import FileManager from '../pages/FileManager'
import ToDoList from '../pages/ToDo'
import Statistics from '../pages/Jobs/Statistics'
import JobList from '../pages/Jobs/JobList/List'
import JobGrid from '../pages/Jobs/JobList/Grid'
import JobOverview from '../pages/Jobs/JobList/Overview'
import CandidateList from '../pages/Jobs/CandidateList/ListView'
import CandidateGrid from '../pages/Jobs/CandidateList/GridView'
import NewJobs from '../pages/Jobs/NewJob'
import JobCategories from '../pages/Jobs/JobCategories'
import Application from '../pages/Jobs/Application'
import CompaniesList from '../pages/Jobs/CompaniesList'
import APIKey from '../pages/APIKey'
import PrivacyPolicy from '../pages/Pages/PrivacyPolicy'
import TermsCondition from '../pages/Pages/TermsCondition'
import JobLanding from '../pages/Landing/Job'
import Kanbanboard from 'pages/Tasks/KanbanBoard'

const themeAuthProtectedRoutes = [
  { path: '/themes/dashboard-analytics', component: <DashboardAnalytics /> },
  { path: '/themes/dashboard-crm', component: <DashboardCrm /> },
  { path: '/themes/dashboard', component: <DashboardEcommerce /> },
  { path: '/themes/index', component: <DashboardEcommerce /> },
  { path: '/themes/dashboard-crypto', component: <DashboardCrypto /> },
  { path: '/themes/dashboard-projects', component: <DashboardProject /> },
  { path: '/themes/dashboard-nft', component: <DashboardNFT /> },
  { path: '/themes/apps-calendar', component: <Calendar /> },
  { path: '/themes/apps-calendar-month-grid', component: <MonthGrid /> },
  { path: '/themes/apps-ecommerce-products', component: <EcommerceProducts /> },
  { path: '/themes/dashboard-job', component: <DashboardJobs /> },
  {
    path: '/themes/apps-ecommerce-product-details',
    component: <EcommerceProductDetail />
  },
  { path: '/themes/apps-ecommerce-add-product', component: <EcommerceAddProduct /> },
  { path: '/themes/apps-ecommerce-orders', component: <EcommerceOrders /> },
  {
    path: '/themes/apps-ecommerce-order-details',
    component: <EcommerceOrderDetail />
  },
  { path: '/themes/apps-ecommerce-customers', component: <EcommerceCustomers /> },
  { path: '/themes/apps-ecommerce-cart', component: <EcommerceCart /> },
  { path: '/themes/apps-ecommerce-checkout', component: <EcommerceCheckout /> },
  { path: '/themes/apps-ecommerce-sellers', component: <EcommerceSellers /> },
  {
    path: '/themes/apps-ecommerce-seller-details',
    component: <EcommerceSellerDetail />
  },

  { path: '/themes/apps-file-manager', component: <FileManager /> },
  { path: '/themes/apps-todo', component: <ToDoList /> },
  { path: '/themes/apps-job-statistics', component: <Statistics /> },
  { path: '/themes/apps-job-lists', component: <JobList /> },
  { path: '/themes/apps-job-grid-lists', component: <JobGrid /> },
  { path: '/themes/apps-job-details', component: <JobOverview /> },
  { path: '/themes/apps-job-candidate-lists', component: <CandidateList /> },
  { path: '/themes/apps-job-candidate-grid', component: <CandidateGrid /> },
  { path: '/themes/apps-job-application', component: <Application /> },
  { path: '/themes/apps-job-new', component: <NewJobs /> },
  { path: '/themes/apps-job-companies-lists', component: <CompaniesList /> },
  { path: '/themes/apps-job-categories', component: <JobCategories /> },
  { path: '/themes/apps-api-key', component: <APIKey /> },

  //Chat
  { path: '/themes/apps-chat', component: <Chat /> },

  //EMail
  { path: '/themes/apps-mailbox', component: <MailInbox /> },
  { path: '/themes/apps-email-basic', component: <BasicAction /> },
  { path: '/themes/apps-email-ecommerce', component: <EcommerceAction /> },

  //Projects
  { path: '/themes/apps-projects-list', component: <ProjectList /> },
  { path: '/themes/apps-projects-overview', component: <ProjectOverview /> },
  { path: '/themes/apps-projects-create', component: <CreateProject /> },

  //Task
  { path: '/themes/apps-tasks-kanban', component: <Kanbanboard /> },
  { path: '/themes/apps-tasks-list-view', component: <TaskList /> },
  { path: '/themes/apps-tasks-details', component: <TaskDetails /> },

  //Crm
  { path: '/themes/apps-crm-contacts', component: <CrmContacts /> },
  { path: '/themes/apps-crm-companies', component: <CrmCompanies /> },
  { path: '/themes/apps-crm-deals', component: <CrmDeals /> },
  { path: '/themes/apps-crm-leads', component: <CrmLeads /> },

  //Invoices
  { path: '/themes/apps-invoices-list', component: <InvoiceList /> },
  { path: '/themes/apps-invoices-details', component: <InvoiceDetails /> },
  { path: '/themes/apps-invoices-create', component: <InvoiceCreate /> },

  //Supports Tickets
  { path: '/themes/apps-tickets-list', component: <ListView /> },
  { path: '/themes/apps-tickets-details', component: <TicketsDetails /> },

  //transactions
  { path: '/themes/apps-crypto-transactions', component: <Transactions /> },
  { path: '/themes/apps-crypto-buy-sell', component: <BuySell /> },
  { path: '/themes/apps-crypto-orders', component: <CryproOrder /> },
  { path: '/themes/apps-crypto-wallet', component: <MyWallet /> },
  { path: '/themes/apps-crypto-ico', component: <ICOList /> },
  { path: '/themes/apps-crypto-kyc', component: <KYCVerification /> },

  // NFT Marketplace
  { path: '/themes/apps-nft-marketplace', component: <Marketplace /> },
  { path: '/themes/apps-nft-collections', component: <Collections /> },
  { path: '/themes/apps-nft-create', component: <CreateNFT /> },
  { path: '/themes/apps-nft-creators', component: <Creators /> },
  { path: '/themes/apps-nft-explore', component: <ExploreNow /> },
  { path: '/themes/apps-nft-item-details', component: <ItemDetails /> },
  { path: '/themes/apps-nft-auction', component: <LiveAuction /> },
  { path: '/themes/apps-nft-ranking', component: <Ranking /> },
  { path: '/themes/apps-nft-wallet', component: <WalletConnect /> },

  //charts
  { path: '/themes/charts-apex-line', component: <LineCharts /> },
  { path: '/themes/charts-apex-area', component: <AreaCharts /> },
  { path: '/themes/charts-apex-column', component: <ColumnCharts /> },
  { path: '/themes/charts-apex-bar', component: <BarCharts /> },
  { path: '/themes/charts-apex-mixed', component: <MixedCharts /> },
  { path: '/themes/charts-apex-timeline', component: <TimelineCharts /> },
  { path: '/themes/charts-apex-candlestick', component: <CandlestickChart /> },
  { path: '/themes/charts-apex-boxplot', component: <BoxplotCharts /> },
  { path: '/themes/charts-apex-bubble', component: <BubbleChart /> },
  { path: '/themes/charts-apex-scatter', component: <ScatterCharts /> },
  { path: '/themes/charts-apex-heatmap', component: <HeatmapCharts /> },
  { path: '/themes/charts-apex-treemap', component: <TreemapCharts /> },
  { path: '/themes/charts-apex-pie', component: <PieCharts /> },
  { path: '/themes/charts-apex-radialbar', component: <RadialbarCharts /> },
  { path: '/themes/charts-apex-radar', component: <RadarCharts /> },
  { path: '/themes/charts-apex-polar', component: <PolarCharts /> },
  { path: '/themes//charts-apex-range-area', component: <RangeArea /> },
  { path: '/themes/charts-apex-funnel', component: <FunnelChart /> },

  { path: '/themes/charts-chartjs', component: <ChartsJs /> },
  { path: '/themes/charts-echarts', component: <Echarts /> },

  // Base Ui
  { path: '/themes/ui-alerts', component: <UiAlerts /> },
  { path: '/themes/ui-badges', component: <UiBadges /> },
  { path: '/themes/ui-buttons', component: <UiButtons /> },
  { path: '/themes/ui-colors', component: <UiColors /> },
  { path: '/themes/ui-cards', component: <UiCards /> },
  { path: '/themes/ui-carousel', component: <UiCarousel /> },
  { path: '/themes/ui-dropdowns', component: <UiDropdowns /> },
  { path: '/themes/ui-grid', component: <UiGrid /> },
  { path: '/themes/ui-images', component: <UiImages /> },
  { path: '/themes/ui-tabs', component: <UiTabs /> },
  { path: '/themes/ui-accordions', component: <UiAccordions /> },
  { path: '/themes/ui-modals', component: <UiModals /> },
  { path: '/themes/ui-offcanvas', component: <UiOffcanvas /> },
  { path: '/themes/ui-placeholders', component: <UiPlaceholders /> },
  { path: '/themes/ui-progress', component: <UiProgress /> },
  { path: '/themes/ui-notifications', component: <UiNotifications /> },
  { path: '/themes/ui-media', component: <UiMediaobject /> },
  { path: '/themes/ui-embed-video', component: <UiEmbedVideo /> },
  { path: '/themes/ui-typography', component: <UiTypography /> },
  { path: '/themes/ui-lists', component: <UiList /> },
  { path: '/themes/ui-links', component: <UiLink /> },
  { path: '/themes/ui-general', component: <UiGeneral /> },
  { path: '/themes/ui-ribbons', component: <UiRibbons /> },
  { path: '/themes/ui-utilities', component: <UiUtilities /> },

  // Advance Ui
  { path: '/themes/advance-ui-scrollbar', component: <UiScrollbar /> },
  { path: '/themes/advance-ui-animation', component: <UiAnimation /> },
  { path: '/themes/advance-ui-swiper', component: <UiSwiperSlider /> },
  { path: '/themes/advance-ui-ratings', component: <UiRatings /> },
  { path: '/themes/advance-ui-highlight', component: <UiHighlight /> },

  // Widgets
  { path: '/themes/widgets', component: <Widgets /> },

  // Forms
  { path: '/themes/forms-elements', component: <BasicElements /> },
  { path: '/themes/forms-select', component: <FormSelect /> },
  { path: '/themes/forms-editors', component: <FormEditor /> },
  { path: '/themes/forms-checkboxes-radios', component: <CheckBoxAndRadio /> },
  { path: '/themes/forms-masks', component: <Masks /> },
  { path: '/themes/forms-file-uploads', component: <FileUpload /> },
  { path: '/themes/forms-pickers', component: <FormPickers /> },
  { path: '/themes/forms-range-sliders', component: <FormRangeSlider /> },
  { path: '/themes/forms-layouts', component: <Formlayouts /> },
  { path: '/themes/forms-validation', component: <FormValidation /> },
  { path: '/themes/forms-wizard', component: <FormWizard /> },
  { path: '/themes/forms-advanced', component: <FormAdvanced /> },
  { path: '/themes/forms-select2', component: <Select2 /> },

  //Tables
  { path: '/themes/tables-basic', component: <BasicTables /> },
  { path: '/themes/tables-react', component: <ReactTable /> },
  //Icons
  { path: '/themes/icons-remix', component: <RemixIcons /> },
  { path: '/themes/icons-boxicons', component: <BoxIcons /> },
  { path: '/themes/icons-materialdesign', component: <MaterialDesign /> },
  { path: '/themes/icons-feather', component: <FeatherIcons /> },
  { path: '/themes/icons-lineawesome', component: <LineAwesomeIcons /> },
  { path: '/themes/icons-crypto', component: <CryptoIcons /> },

  //Maps
  { path: '/themes/maps-google', component: <GoogleMaps /> },

  //Pages
  { path: '/themes/pages-starter', component: <Starter /> },
  { path: '/themes/pages-profile', component: <SimplePage /> },
  { path: '/themes/pages-profile-settings', component: <Settings /> },
  { path: '/themes/pages-team', component: <Team /> },
  { path: '/themes/pages-timeline', component: <Timeline /> },
  { path: '/themes/pages-faqs', component: <Faqs /> },
  { path: '/themes/pages-gallery', component: <Gallery /> },
  { path: '/themes/pages-pricing', component: <Pricing /> },
  { path: '/themes/pages-sitemap', component: <SiteMap /> },
  { path: '/themes/pages-search-results', component: <SearchResults /> },
  { path: '/themes/pages-privacy-policy', component: <PrivacyPolicy /> },
  { path: '/themes/pages-term-conditions', component: <TermsCondition /> },

  //User Profile
  { path: '/themes/profile', component: <UserProfile /> }
]

const themePublicRoutes = [
  // Authentication Page
  { path: '/themes/logout', component: <Logout /> },
  { path: '/themes/login', component: <Login /> },
  { path: '/themes/forgot-password', component: <ForgetPasswordPage /> },
  { path: '/themes/register', component: <Register /> },

  //AuthenticationInner pages
  { path: '/themes/auth-signin-basic', component: <BasicSignIn /> },
  { path: '/themes/auth-signin-cover', component: <CoverSignIn /> },
  { path: '/themes/auth-signup-basic', component: <BasicSignUp /> },
  { path: '/themes/auth-signup-cover', component: <CoverSignUp /> },
  { path: '/themes/auth-pass-reset-basic', component: <BasicPasswReset /> },
  { path: '/themes/auth-pass-reset-cover', component: <CoverPasswReset /> },
  { path: '/themes/auth-lockscreen-basic', component: <BasicLockScreen /> },
  { path: '/themes/auth-lockscreen-cover', component: <CoverLockScreen /> },
  { path: '/themes/auth-logout-basic', component: <BasicLogout /> },
  { path: '/themes/auth-logout-cover', component: <CoverLogout /> },
  { path: '/themes/auth-success-msg-basic', component: <BasicSuccessMsg /> },
  { path: '/themes/auth-success-msg-cover', component: <CoverSuccessMsg /> },
  { path: '/themes/auth-twostep-basic', component: <BasicTwosVerify /> },
  { path: '/themes/auth-twostep-cover', component: <CoverTwosVerify /> },
  { path: '/themes/auth-404-basic', component: <Basic404 /> },
  { path: '/themes/auth-404-cover', component: <Cover404 /> },
  { path: '/themes/auth-404-alt', component: <Alt404 /> },
  { path: '/themes/auth-500', component: <Error500 /> },
  { path: '/themes/pages-maintenance', component: <Maintenance /> },
  { path: '/themes/pages-coming-soon', component: <ComingSoon /> },

  { path: '/themes/landing', component: <OnePage /> },
  { path: '/themes/nft-landing', component: <NFTLanding /> },
  { path: '/themes/job-landing', component: <JobLanding /> },

  { path: '/themes/auth-pass-change-basic', component: <BasicPasswCreate /> },
  { path: '/themes/auth-pass-change-cover', component: <CoverPasswCreate /> },
  { path: '/themes/auth-offline', component: <Offlinepage /> }
]

export { themeAuthProtectedRoutes, themePublicRoutes }
