import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap'
import { IPromotion } from './index'
import { toast } from 'react-toastify'
import DeleteModal from 'Components/Common/DeleteModal'
import { useDebouncedCallback } from 'use-debounce'
import { Spinner } from 'reactstrap'
import { ISort } from 'lib/type'
import { showDiscountType, formatTime } from 'lib/util'

interface IProds {
  fetching: boolean
  promotionRecords: IPromotion[]
  handleOpenDetails: (arg: number) => void
  setOpenPromotionPopup: (arg: boolean) => void
  setEditMode: (arg: boolean) => void
  handleDeletePromotion: (arg: number) => Promise<string>
  searchPromotion: (arg: string) => void
  sortAction: ISort
  setSortAction: (arg: ISort) => void
  setCanEdit: (arg: boolean) => void
}

const PromotionsTable: React.FC<IProds> = ({
  promotionRecords,
  handleOpenDetails,
  setOpenPromotionPopup,
  setEditMode,
  fetching,
  handleDeletePromotion,
  searchPromotion,
  sortAction,
  setSortAction,
  setCanEdit
}) => {
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
  const [promotionSelectedId, setPromotionSelectedId] = useState<number>()
  const [query, setQuery] = useState('')
  const [permissions, setPermissions] = useState({
    canCreate: false,
    canEdit: false,
    canDelete: false,
  })

  const handleAddPromotion = () => {
    setEditMode(false)
    setOpenPromotionPopup(true)
  }

  const handleEditPromotion = (id: number) => {
    setEditMode(true)
    setOpenPromotionPopup(true)
    handleOpenDetails(id)
  }

  const handleDeletePromotionRecord = async (id: number) => {
    const res = handleDeletePromotion(id)

    const message = await res
    if (message === 'SUCCESSFULL') {
      toast.success('Deleted a Promotion successfully')
    }
  }

  const handleShowModalDelete = (id: number) => {
    setShowModalDelete(true)
    setPromotionSelectedId(id)
  }

  const handleDelete = (id: number) => {
    handleDeletePromotionRecord(id)
    setShowModalDelete(false)
  }

  const debouncedSearch = useDebouncedCallback((searchTerm: string) => {
    searchPromotion(searchTerm)
  }, 1000)

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const value = e.target.value
    setQuery(value)
    debouncedSearch(value)
  }

  const handleActionSort = (sortBy: string) => {
    if (sortAction.sortBy === sortBy) {
      setSortAction({
        ...sortAction,
        sortDirection: sortAction.sortDirection === 'DESC' ? 'ASC' : 'DESC'
      })
    } else {
      setSortAction({
        sortDirection: 'DESC',
        sortBy: sortBy
      })
    }
  }

  const showBooths = (data: any) => {
    let results = !data ? [] : Object.values(data).map((boothEvent: any) => boothEvent.booth.name)

    return results.join(', ')
  }

  useEffect(() => {
    const userRoles = sessionStorage.getItem('userRoles') ? JSON.parse(atob(sessionStorage.getItem('userRoles')!)) : null
    userRoles.forEach((role: any) => {
      if (role.page.name === 'Promotions') {
        setPermissions({
          canCreate: role.canCreate,
          canEdit: role.canEdit,
          canDelete: role.canDelete,
        })
      }
    })
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader className='align-items-center d-flex justify-content-between'>
              <div className='search-box d-inline-block col-8 d-flex align-items-center'>
                <input
                  id='search-bar-action-price'
                  className='form-control search'
                  placeholder='Search Promotions'
                  onChange={(e) => handleInputChange(e)}
                  value={query}
                />
                <i className='bx bx-search-alt search-icon'></i>
              </div>
              {
                permissions.canCreate &&
                <Button className='p-2' color='primary' onClick={(e) => handleAddPromotion()}>
                  <div className='flex align-items-center'>
                    {fetching ? (
                      <Spinner size='sm'> Loading... </Spinner>
                    ) : (
                      <>
                        <i className='mdi mdi-plus'></i>
                        <span className='fw-semibold ms-1'>New Promotion</span>
                      </>
                    )}
                  </div>
                </Button>
              }
            </CardHeader>
            <CardBody>
              <div className='table-responsive table-card'>
                <table className='table align-middle table-nowrap table-striped-columns mb-0'>
                  <thead className='table-light'>
                    <tr>
                      <th scope='col' style={{ width: '46px' }}>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={query}
                            id='cardtableCheck'
                          />
                          <label className='form-check-label' htmlFor='cardtableCheck'></label>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('id')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>ID</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'id' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'id' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('name')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Name</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'name' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'name' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('code')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Code</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'code' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'code' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('startDateTime')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Start Date Time</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'startDateTime' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'startDateTime' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('finishDateTime')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Finish Date Time</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'finishDateTime' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'finishDateTime' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('discountType')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Discount Type</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'discountType' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'discountType' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('discountValue')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Discount Value</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'discountValue' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'discountValue' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col'>Active</th>
                      <th scope='col'>Booth</th>
                      <th scope='col' style={{ width: '150px' }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {promotionRecords.map((promotion, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value=''
                                id={`check-${promotion.id}`}
                              />
                              <label
                                className='form-check-label'
                                htmlFor={`check-${promotion.id}`}
                              ></label>
                            </div>
                          </td>
                          <td>
                            <Link to='#' className='fw-medium'>
                              {promotion.id}
                            </Link>
                          </td>
                          <td>{promotion.name}</td>
                          <td>{promotion.code}</td>
                          <td>
                            {promotion.startDateTime ? formatTime(promotion.startDateTime) : ''}
                          </td>
                          <td>
                            {promotion.finishDateTime ? formatTime(promotion.finishDateTime) : ''}
                          </td>
                          <td>{showDiscountType(promotion.discountType)}</td>
                          <td>{promotion.discountValue}</td>
                          <td>
                            <Input
                              checked={promotion.isActive || false}
                              className='form-check-input code-switcher'
                              type='checkbox'
                            />
                          </td>
                          <td>{showBooths(promotion.boothPromotions)}</td>
                          <td>
                            <button
                              type='button'
                              className='btn btn-sm btn-light'
                              onClick={(e) => handleEditPromotion(promotion?.id as number)}
                            >
                              Details
                            </button>
                            {
                              permissions.canDelete &&
                              <button
                                type='button'
                                className='btn btn-sm btn-danger ms-2'
                                onClick={(e) => handleShowModalDelete(promotion?.id as number)}
                              >
                                Delete
                              </button>
                            }
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={showModalDelete}
        onDeleteClick={() => handleDelete(promotionSelectedId as number)}
        onCloseClick={() => setShowModalDelete(false)}
      />
    </React.Fragment>
  )
}
export default PromotionsTable
