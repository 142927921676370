import React, { useEffect, useState } from 'react'
import { searchBoothRecords, fetchEvents, updateEventBooths } from 'helpers/backend_helper'
import { Container } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { toast } from 'react-toastify'
import Pagination from 'Components/Common/Pagination'
import { PERPAGE_DEFAULT } from 'lib/constant'
import EventBoothsTable from './EventBoothsTable'
import { ISort } from 'lib/type'
import { mapBoothOptions, mapEventBooths } from 'lib/util'

export interface IEvent {
  id: number
  name: string
  booths: any[]
}

export interface IDataSubmit {
  eventId: number
  boothIds: number[]
}

const EventBooths = () => {
  document.title = 'Booth Event Record | Photo Booth Admin Dashboard'

  //state data
  const [fetching, setFetching] = useState<boolean>(false)
  const [events, setEvents] = useState<IEvent[]>([])
  const [booths, setBooths] = useState<any[]>([])

  const [sortAction, setSortAction] = useState<ISort>({ sortBy: '', sortDirection: 'ACS' })

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [valueSearch, setValueSearch] = useState<string>('')
  const [canEdit, setCanEdit] = useState<boolean>(false)

  const fetchEventsRecord = async (params: any) => {
    setFetching(true)
    try {
      const res = fetchEvents(params)
      const response = await res
      const data = response.data

      setEvents(mapEventBooths(data))
      setTotalPage(response.totalPages)
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
    }
    setFetching(false)
  }

  const handleSubmit = async (params: IDataSubmit[]) => {
    setFetching(true)
    try {
      const res = updateEventBooths(params)
      const response = await res
      const message = response.message

      if (message == 'SUCCESSFULL') {
        toast.success('Updated event booths successfully')
      } else {
        toast.error(message)
        callBackEvent()
      }
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      callBackEvent()
    }
    setFetching(false)
  }

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const res = searchBoothRecords({})
        const response = await res
        const data = response.data

        setBooths(mapBoothOptions(data))
      } catch (e) {
        const errMsg = e as string
        toast.error(errMsg)
      }
    }

    fetchResources()
  }, [])

  const callBackEvent = () => {
    const queryParams = {
      pageNo: currentPage,
      pageSize: PERPAGE_DEFAULT,
      ...(valueSearch.trim() && { keyWord: valueSearch })
    }

    fetchEventsRecord(queryParams)
  }

  const searchEvent = (value: string) => {
    const trimmedValue = value.trim()
    fetchEventsRecord(getParams(1, trimmedValue))
    setValueSearch(trimmedValue)
  }

  useEffect(() => {
    fetchEventsRecord(getParams(currentPage, valueSearch))
  }, [currentPage])

  useEffect(() => {
    if (sortAction.sortBy.length > 0) {
      fetchEventsRecord(getParams(currentPage, valueSearch))
    }
  }, [sortAction])

  const getParams = (page: number = 1, value: string) => {
    return {
      pageNo: page,
      pageSize: PERPAGE_DEFAULT,
      ...(value.trim() && { keyWord: value }),
      ...(sortAction.sortBy.length > 0 && sortAction)
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Booth Event Record index' pageTitle='Booth Event Record' />
          <EventBoothsTable
            events={events}
            fetching={fetching}
            sortAction={sortAction}
            setSortAction={setSortAction}
            booths={booths}
            handleSubmit={handleSubmit}
            searchEvent={searchEvent}
            setCanEdit={setCanEdit}
          />
          {totalPage > 1 && (
            <Pagination
              perPageData={PERPAGE_DEFAULT}
              data={events}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={totalPage}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default EventBooths
