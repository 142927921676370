import React, { useState } from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Label,
  FormFeedback,
  Form
} from 'reactstrap'

// Redux
import { useDispatch } from 'react-redux'
import { addNewProduct as onAddNewProduct } from '../../../slices/thunks'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import classnames from 'classnames'
import Dropzone from 'react-dropzone'
import { Link, useNavigate } from 'react-router-dom'

//formik
import { useFormik } from 'formik'
import * as Yup from 'yup'

// Import React FilePond
import { registerPlugin } from 'react-filepond'
import Flatpickr from 'react-flatpickr'
import Select from 'react-select'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const EcommerceAddProduct = (props: any) => {
  document.title = 'Create Product | Photo Booth Admin Dashboard'

  const history = useNavigate()
  const dispatch: any = useDispatch()

  const [customActiveTab, setcustomActiveTab] = useState<any>('1')
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  const [selectedFiles, setselectedFiles] = useState<any>([])
  const [selectedVisibility, setselectedVisibility] = useState<any>(null)

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )
    setselectedFiles(files)
  }

  function handleSelectVisibility(selectedVisibility: any) {
    setselectedVisibility(selectedVisibility)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  const productCategory = [
    {
      options: [
        { label: 'All', value: 'All' },
        { label: 'Appliances', value: 'Kitchen Storage & Containers' },
        { label: 'Fashion', value: 'Clothes' },
        { label: 'Electronics', value: 'Electronics' },
        { label: 'Grocery', value: 'Grocery' },
        { label: 'Home & Furniture', value: 'Furniture' },
        { label: 'Kids', value: 'Kids' },
        { label: 'Mobiles', value: 'Mobiles' }
      ]
    }
  ]

  const dateFormat = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let h = d.getHours() % 12 || 12
    let ampm = d.getHours() < 12 ? 'AM' : 'PM'
    return (
      d.getDate() +
      ' ' +
      months[d.getMonth()] +
      ', ' +
      d.getFullYear() +
      ', ' +
      h +
      ':' +
      d.getMinutes() +
      ' ' +
      ampm
    ).toString()
  }

  const [date, setDate] = useState<any>(dateFormat())

  const dateformate = (e: any) => {
    const dateString = e.toString().split(' ')
    let time = dateString[4]
    let H = +time.substr(0, 2)
    let h: any = H % 12 || 12
    h = h <= 9 ? (h = '0' + h) : h
    let ampm = H < 12 ? 'AM' : 'PM'
    time = h + time.substr(2, 3) + ' ' + ampm

    const date = dateString[2] + ' ' + dateString[1] + ', ' + dateString[3]
    const orderDate = (date + ', ' + time).toString()
    setDate(orderDate)
  }

  const productStatus = [
    {
      options: [
        { label: 'Draft', value: 'draft' },
        { label: 'Published', value: 'published' },
        { label: 'Scheduled', value: 'scheduled' }
      ]
    }
  ]

  const productVisibility = [
    {
      options: [
        { label: 'Hidden', value: 'Hidden' },
        { label: 'Public', value: 'Public' }
      ]
    }
  ]

  // image
  const [selectedImage, setSelectedImage] = useState<any>()

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (e: any) => {
      validation.setFieldValue('image', e.target.result)
      setSelectedImage(e.target.result)
    }
    reader.readAsDataURL(file)
  }

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: '',
      price: '',
      stock: '',
      orders: '',
      category: '',
      publishedDate: '',
      status: '',
      rating: 4.5,
      manufacturer_name: '',
      manufacturer_brand: '',
      product_discount: '',
      product_tags: '',
      image: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter a Product Title'),
      price: Yup.string().required('Please Enter a Product Price'),
      stock: Yup.string().required('Please Enter a Product stock'),
      orders: Yup.string().required('Please Enter a Product orders'),
      category: Yup.string().required('Please Enter a Product category'),
      // status: Yup.string().required("Please Enter a Product status"),
      manufacturer_name: Yup.string().required('Please Enter a Manufacturer Name'),
      manufacturer_brand: Yup.string().required('Please Enter a Manufacturer Brand'),
      product_discount: Yup.string().required('Please Enter a Product Discount'),
      product_tags: Yup.string().required('Please Enter a Product Tags'),
      image: Yup.string().required('Please add an image')
    }),
    onSubmit: (values) => {
      const newProduct = {
        id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        name: values.name,
        price: values.price,
        stock: values.stock,
        orders: values.orders,
        category: values.category,
        publishedDate: date,
        status: values.status,
        rating: 4.5,
        image: selectedImage
      }
      // save new product
      dispatch(onAddNewProduct(newProduct))
      history('/apps-ecommerce-products')
      validation.resetForm()
    }
  })
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Create Product' pageTitle='Ecommerce' />

        <Row>
          <Col lg={8}>
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Card>
                <CardBody>
                  <div className='mb-3'>
                    <Label className='form-label' htmlFor='product-title-input'>
                      Product Title
                    </Label>
                    <Input
                      type='text'
                      className='form-control'
                      id='product-title-input'
                      placeholder='Enter product title'
                      name='name'
                      value={validation.values.name || ''}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={validation.errors.name && validation.touched.name ? true : false}
                    />
                    {validation.errors.name && validation.touched.name ? (
                      <FormFeedback type='invalid'>{validation.errors.name}</FormFeedback>
                    ) : null}
                  </div>
                  <div>
                    <Label>Product Description</Label>

                    <CKEditor
                      editor={ClassicEditor}
                      data='<p>
                      Tommy Hilfiger men striped pink sweatshirt. Crafted with
                      cotton. Material composition is 100% organic cotton.
                      This is one of the world’s leading designer lifestyle
                      brands and is internationally recognized for celebrating
                      the essence of classic American cool style, featuring
                      preppy with a twist designs.
                    </p>
                    <ul>
                      <li>Full Sleeve</li>
                      <li>Cotton</li>
                      <li>All Sizes available</li>
                      <li>4 Different Color</li>
                    </ul>'
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                      }}
                    />
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className='card-title mb-0'>Product Gallery</h5>
                </CardHeader>
                <CardBody>
                  <div className='mb-4'>
                    <h5 className='fs-14 mb-1'>Product Image</h5>
                    <p className='text-muted'>Add Product main Image.</p>
                    <div className='text-center'>
                      <div className='position-relative d-inline-block'>
                        <div className='position-absolute top-100 start-100 translate-middle'>
                          <Label
                            htmlFor='customer-image-input'
                            className='mb-0'
                            data-bs-toggle='tooltip'
                            data-bs-placement='right'
                            title='Select Image'
                          >
                            <div className='avatar-xs cursor-pointer'>
                              <div className='avatar-title bg-light border rounded-circle text-muted'>
                                <i className='ri-image-fill'></i>
                              </div>
                            </div>
                          </Label>
                          <Input
                            className='form-control d-none'
                            id='customer-image-input'
                            type='file'
                            accept='image/png, image/gif, image/jpeg'
                            onChange={handleImageChange}
                            invalid={
                              validation.touched.image && validation.errors.image ? true : false
                            }
                          />
                        </div>
                        <div className='avatar-lg'>
                          <div className='avatar-title bg-light rounded'>
                            <img
                              src={selectedImage}
                              id='product-img'
                              alt=''
                              className='avatar-md h-auto'
                            />
                          </div>
                        </div>
                      </div>
                      {validation.errors.image && validation.touched.image ? (
                        <FormFeedback type='invalid'> {validation.errors.image} </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <h5 className='fs-14 mb-1'>Product Gallery</h5>
                    <p className='text-muted'>Add Product Gallery Images.</p>

                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className='dropzone dz-clickable'>
                          <div className='dz-message needsclick' {...getRootProps()}>
                            <div className='mb-3 mt-5'>
                              <i className='display-4 text-muted ri-upload-cloud-2-fill' />
                            </div>
                            <h5>Drop files here or click to upload.</h5>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className='list-unstyled mb-0' id='file-previews'>
                      {selectedFiles.map((f: any, i: any) => {
                        return (
                          <Card
                            className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                            key={i + '-file'}
                          >
                            <div className='p-2'>
                              <Row className='align-items-center'>
                                <Col className='col-auto'>
                                  <img
                                    data-dz-thumbnail=''
                                    height='80'
                                    className='avatar-sm rounded bg-light'
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link to='#' className='text-muted font-weight-bold'>
                                    {f.name}
                                  </Link>
                                  <p className='mb-0'>
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <Nav className='nav-tabs-custom card-header-tabs border-bottom-0'>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: customActiveTab === '1'
                        })}
                        onClick={() => {
                          toggleCustom('1')
                        }}
                      >
                        General Info
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>

                <CardBody>
                  <TabContent activeTab={customActiveTab}>
                    <TabPane id='addproduct-general-info' tabId='1'>
                      <Row>
                        <Col lg={6}>
                          <div className='mb-3'>
                            <label className='form-label' htmlFor='manufacturer-name-input'>
                              Manufacturer Name
                            </label>
                            <Input
                              type='text'
                              className='form-control'
                              id='manufacturer-name-input'
                              name='manufacturer_name'
                              placeholder='Enter manufacturer name'
                              value={validation.values.manufacturer_name || ''}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.manufacturer_name &&
                                validation.touched.manufacturer_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.manufacturer_name &&
                            validation.touched.manufacturer_name ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.manufacturer_name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className='mb-3'>
                            <label className='form-label' htmlFor='manufacturer-brand-input'>
                              Manufacturer Brand
                            </label>
                            <Input
                              type='text'
                              className='form-control'
                              id='manufacturer-brand-input'
                              name='manufacturer_brand'
                              placeholder='Enter manufacturer brand'
                              value={validation.values.manufacturer_brand || ''}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.manufacturer_brand &&
                                validation.touched.manufacturer_brand
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.manufacturer_brand &&
                            validation.touched.manufacturer_brand ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.manufacturer_brand}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={3}>
                          <div className='mb-3'>
                            <label className='form-label' htmlFor='product-stock-input'>
                              Stocks
                            </label>
                            <div className='input-group mb-3'>
                              <Input
                                type='text'
                                className='form-control'
                                id='product-stock-input'
                                placeholder='Enter Stocks'
                                name='stock'
                                value={validation.values.stock || ''}
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                invalid={
                                  validation.errors.stock && validation.touched.stock ? true : false
                                }
                              />
                              {validation.errors.stock && validation.touched.stock ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.stock}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </Col>

                        <Col sm={3}>
                          <div className='mb-3'>
                            <label className='form-label' htmlFor='product-price-input'>
                              Price
                            </label>
                            <div className='input-group mb-3'>
                              <span className='input-group-text' id='product-price-addon'>
                                $
                              </span>
                              <Input
                                type='text'
                                className='form-control'
                                id='product-price-input'
                                placeholder='Enter price'
                                name='price'
                                aria-label='Price'
                                aria-describedby='product-price-addon'
                                value={validation.values.price || ''}
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                invalid={
                                  validation.errors.price && validation.touched.price ? true : false
                                }
                              />
                              {validation.errors.price && validation.touched.price ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.price}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </Col>

                        <Col sm={3}>
                          <div className='mb-3'>
                            <label className='form-label' htmlFor='product-Discount-input'>
                              Discount
                            </label>
                            <div className='input-group mb-3'>
                              <span className='input-group-text' id='product-Discount-addon'>
                                %
                              </span>
                              <Input
                                type='text'
                                className='form-control'
                                id='product-Discount-input'
                                placeholder='Enter Discount'
                                name='product_discount'
                                aria-label='product_discount'
                                aria-describedby='product-orders-addon'
                                value={validation.values.product_discount || ''}
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                invalid={
                                  validation.errors.product_discount &&
                                  validation.touched.product_discount
                                    ? true
                                    : false
                                }
                              />
                              {validation.errors.product_discount &&
                              validation.touched.product_discount ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.product_discount}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </Col>

                        <Col sm={3}>
                          <div className='mb-3'>
                            <label className='form-label' htmlFor='product-orders-input'>
                              Orders
                            </label>
                            <div className='input-group mb-3'>
                              <Input
                                type='text'
                                className='form-control'
                                id='product-orders-input'
                                placeholder='Enter orders'
                                name='orders'
                                aria-label='orders'
                                aria-describedby='product-orders-addon'
                                value={validation.values.orders || ''}
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                invalid={
                                  validation.errors.orders && validation.touched.orders
                                    ? true
                                    : false
                                }
                              />
                              {validation.errors.orders && validation.touched.orders ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.orders}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>

              <div className='text-end mb-3'>
                <button type='submit' className='btn btn-success w-sm'>
                  Submit
                </button>
              </div>
            </Form>
          </Col>

          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className='card-title mb-0'>Publish</h5>
              </CardHeader>
              <CardBody>
                <div className='mb-3'>
                  <Label htmlFor='choices-publish-status-input' className='form-label'>
                    Status
                  </Label>
                  <Input
                    name='status'
                    type='select'
                    className='form-select'
                    id='choices-publish-status-input'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.status || ''}
                  >
                    {productStatus.map((item, key) => (
                      <React.Fragment key={key}>
                        {item.options.map((item, key) => (
                          <option value={item.value} key={key}>
                            {item.label}
                          </option>
                        ))}
                      </React.Fragment>
                    ))}
                  </Input>
                  {validation.touched.status && validation.errors.status ? (
                    <FormFeedback type='invalid'>{validation.errors.status}</FormFeedback>
                  ) : null}
                </div>

                <div>
                  <Label htmlFor='choices-publish-visibility-input' className='form-label'>
                    Visibility
                  </Label>

                  <Select
                    value={selectedVisibility}
                    onChange={(selectedVisibility: any) => {
                      handleSelectVisibility(selectedVisibility)
                    }}
                    options={productVisibility}
                    name='choices-publish-visibility-input'
                    classNamePrefix='select2-selection form-select'
                  />
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className='card-title mb-0'>Publish Schedule</h5>
              </CardHeader>

              <CardBody>
                <div>
                  <label htmlFor='datepicker-publish-input' className='form-label'>
                    Publish Date & Time
                  </label>
                  <Flatpickr
                    name='publishedDate'
                    id='publishedDate-field'
                    className='form-control'
                    placeholder='Select a date'
                    options={{
                      enableTime: true,
                      altInput: true,
                      altFormat: 'd M, Y, G:i K',
                      dateFormat: 'd M, Y, G:i K'
                    }}
                    onChange={(e: any) => dateformate(e)}
                    value={validation.values.publishedDate || ''}
                  />
                  {validation.touched.publishedDate && validation.errors.publishedDate ? (
                    <FormFeedback type='invalid'>{validation.errors.publishedDate}</FormFeedback>
                  ) : null}
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className='card-title mb-0'>Product Categories</h5>
              </CardHeader>
              <CardBody>
                <p className='text-muted mb-2'>
                  {' '}
                  <Link to='#' className='float-end text-decoration-underline'>
                    Add New
                  </Link>
                  Select product category
                </p>

                <Input
                  name='category'
                  type='select'
                  className='form-select'
                  id='category-field'
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.category || ''}
                >
                  {productCategory.map((item, key) => (
                    <React.Fragment key={key}>
                      {item.options.map((item, key) => (
                        <option value={item.value} key={key}>
                          {item.label}
                        </option>
                      ))}
                    </React.Fragment>
                  ))}
                </Input>
                {validation.touched.category && validation.errors.category ? (
                  <FormFeedback type='invalid'>{validation.errors.category}</FormFeedback>
                ) : null}
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className='card-title mb-0'>Product Tags</h5>
              </CardHeader>
              <CardBody>
                <div className='hstack gap-3 align-items-start'>
                  <div className='flex-grow-1'>
                    <Input
                      className='form-control'
                      placeholder='Enter tags'
                      type='text'
                      name='product_tags'
                      value={validation.values.product_tags || ''}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={
                        validation.errors.product_tags && validation.touched.product_tags
                          ? true
                          : false
                      }
                    />
                    {validation.errors.product_tags && validation.touched.product_tags ? (
                      <FormFeedback type='invalid'>{validation.errors.product_tags}</FormFeedback>
                    ) : null}
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className='card-title mb-0'>Product Short Description</h5>
              </CardHeader>
              <CardBody>
                <p className='text-muted mb-2'>Add short description for product</p>
                <textarea
                  className='form-control'
                  placeholder='Must enter minimum of a 100 characters'
                  rows={3}
                ></textarea>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EcommerceAddProduct
