import React from 'react'
import ReactApexChart from 'react-apexcharts'

// Import Images
import comingSoon from '../../../../assets/images/comingsoon.png'
import Img4 from '../../../../assets/images/small/img-4.jpg'

import getChartColorsArray from '../../../../Components/Common/ChartsDynamicColor'

const SimpleRadialbar = ({ dataColors }: any) => {
  var chartRadialbarBasicColors = getChartColorsArray(dataColors)
  const series = [70]
  var options: any = {
    chart: {
      height: 350,
      type: 'radialBar'
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%'
        }
      }
    },
    labels: ['Cricket'],
    colors: chartRadialbarBasicColors
  }
  return (
    <ReactApexChart
      dir='ltr'
      className='apex-charts'
      series={series}
      options={options}
      type='radialBar'
      height={350}
    />
  )
}

const MultipleRadialbar = ({ dataColors }: any) => {
  var chartRadialbarMultipleColors = getChartColorsArray(dataColors)
  const series = [44, 55, 67, 83]
  var options: any = {
    chart: {
      height: 350,
      type: 'radialBar'
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '22px'
          },
          value: {
            fontSize: '16px'
          },
          total: {
            show: true,
            label: 'Total',
            formatter: function (w: any) {
              return 249
            }
          }
        }
      }
    },
    labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
    colors: chartRadialbarMultipleColors
  }
  return (
    <ReactApexChart
      dir='ltr'
      className='apex-charts'
      series={series}
      options={options}
      type='radialBar'
      height={350}
    />
  )
}

const CircleRadialbar = ({ dataColors }: any) => {
  var chartRadialbarCircleColors = getChartColorsArray(dataColors)
  const series = [76, 67, 61, 55]
  var options: any = {
    chart: {
      height: 350,
      type: 'radialBar'
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
          image: undefined
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            show: false
          }
        }
      }
    },
    colors: chartRadialbarCircleColors,
    labels: ['Vimeo', 'Messenger', 'Facebook', 'LinkedIn'],
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: 160,
      offsetY: 15,
      labels: {
        useSeriesColors: true
      },
      markers: {
        size: 0
      },
      formatter: function (seriesName: any, opts: any) {
        return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex]
      },
      itemMargin: {
        vertical: 3
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false
          }
        }
      }
    ]
  }
  return (
    <ReactApexChart
      dir='ltr'
      className='apex-charts'
      series={series}
      options={options}
      type='radialBar'
      height={350}
    />
  )
}

const GradientCircleRadialbar = ({ dataColors }: any) => {
  var chartRadialbarGradientColors = getChartColorsArray(dataColors)
  const series = [75]
  var options: any = {
    chart: {
      height: 350,
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: '70%',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front'
        },
        track: {
          strokeWidth: '67%',
          margin: 0 // margin is in pixels
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '17px'
          },
          value: {
            formatter: function (val: any) {
              return parseInt(val)
            },
            color: '#111',
            fontSize: '36px',
            show: true
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: chartRadialbarGradientColors,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: ['Percent']
  }
  return (
    <ReactApexChart
      dir='ltr'
      className='apex-charts'
      series={series}
      options={options}
      type='radialBar'
      height={350}
    />
  )
}

const ImageRadialbar = () => {
  const series = [67]
  var options: any = {
    chart: {
      height: 315,
      type: 'radialBar'
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: '65%',
          image: comingSoon,
          imageWidth: 56,
          imageHeight: 56,
          imageClipped: false
        },
        dataLabels: {
          name: {
            show: false,
            color: '#fff'
          },
          value: {
            show: true,
            color: '#333',
            offsetY: 65,
            fontSize: '22px'
          }
        }
      }
    },
    fill: {
      type: 'image',
      image: {
        src: [Img4]
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: ['Volatility']
  }
  return (
    <ReactApexChart
      dir='ltr'
      className='apex-charts'
      series={series}
      options={options}
      type='radialBar'
      height={315}
    />
  )
}

const StrokedCircleRadial = ({ dataColors }: any) => {
  var chartStorkeRadialbarColors = getChartColorsArray(dataColors)
  const series = [67]
  var options: any = {
    chart: {
      height: 326,
      type: 'radialBar',
      offsetY: -10
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: '16px',
            color: undefined,
            offsetY: 120
          },
          value: {
            offsetY: 76,
            fontSize: '22px',
            color: undefined,
            formatter: function (val: any) {
              return val + '%'
            }
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.15,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91]
      }
    },
    stroke: {
      dashArray: 4
    },
    labels: ['Median Ratio'],
    colors: chartStorkeRadialbarColors
  }
  return (
    <ReactApexChart
      dir='ltr'
      className='apex-charts'
      series={series}
      options={options}
      type='radialBar'
      height={326}
    />
  )
}

const SemiCircularRadial = ({ dataColors }: any) => {
  var chartStorkeRadialbarColors = getChartColorsArray(dataColors)
  const series = [76]
  var options: any = {
    chart: {
      type: 'radialBar',
      height: 350,
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2
          }
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '22px'
          }
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91]
      }
    },
    labels: ['Average Results'],
    colors: chartStorkeRadialbarColors
  }
  return (
    <ReactApexChart
      dir='ltr'
      className='apex-charts'
      series={series}
      options={options}
      type='radialBar'
      height={350}
    />
  )
}

export {
  SimpleRadialbar,
  MultipleRadialbar,
  CircleRadialbar,
  GradientCircleRadialbar,
  StrokedCircleRadial,
  SemiCircularRadial,
  ImageRadialbar
}
