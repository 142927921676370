import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'

//Simple bar
import SimpleBar from 'simplebar-react'

import BreadCrumb from '../../../Components/Common/BreadCrumb'

import product1 from '../../../assets/images/products/img-1.png'
import product6 from '../../../assets/images/products/img-6.png'
import product8 from '../../../assets/images/products/img-8.png'

import { productDetailsWidgets, reviews } from '../../../common/data/ecommerce'

import classnames from 'classnames'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

import { FreeMode, Navigation } from 'swiper/modules'
import { Link } from 'react-router-dom'
import { Swiper } from 'swiper/react'
import { SwiperSlide } from 'swiper/react'

const ProductReview = (props: any) => {
  return (
    <React.Fragment>
      <li className='py-2'>
        <div className='border border-dashed rounded p-3'>
          <div className='d-flex align-items-start mb-3'>
            <div className='hstack gap-3'>
              <div className='badge rounded-pill bg-success mb-0'>
                <i className='mdi mdi-star'></i> {props.review.rating}
              </div>
              <div className='vr'></div>
              <div className='flex-grow-1'>
                <p className='text-muted mb-0'>{props.review.comment}</p>
              </div>
            </div>
          </div>
          {props.review.subItems && (
            <React.Fragment>
              <div className='d-flex flex-grow-1 gap-2 mb-3'>
                {props.review.subItems.map((subItem: any, key: any) => (
                  <React.Fragment key={key}>
                    <Link to='#' className='d-block'>
                      <img
                        src={subItem.img}
                        alt=''
                        className='avatar-sm rounded object-fit-cover'
                      />
                    </Link>
                  </React.Fragment>
                ))}
              </div>
            </React.Fragment>
          )}

          <div className='d-flex align-items-end'>
            <div className='flex-grow-1'>
              <h5 className='fs-15 mb-0'>{props.review.name}</h5>
            </div>

            <div className='flex-shrink-0'>
              <p className='text-muted mb-0'>{props.review.date}</p>
            </div>
          </div>
        </div>
      </li>
    </React.Fragment>
  )
}

const PricingWidgetList = (props: any) => {
  return (
    <React.Fragment>
      <Col lg={3} sm={6}>
        <div className='p-2 border border-dashed rounded'>
          <div className='d-flex align-items-center'>
            <div className='avatar-sm me-2'>
              <div className='avatar-title rounded bg-transparent text-success fs-24'>
                <i className={props.pricingDetails.icon}></i>
              </div>
            </div>
            <div className='flex-grow-1'>
              <p className='text-muted mb-1'>{props.pricingDetails.label} :</p>
              <h5 className='mb-0'>{props.pricingDetails.labelDetail}</h5>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}

function EcommerceProductDetail(props: any) {
  document.title = 'Product Details | Photo Booth Admin Dashboard'

  const [ttop, setttop] = useState<boolean>(false)
  const [ssize, setssize] = useState<boolean>(false)
  const [msize, setmsize] = useState<boolean>(false)
  const [lsize, setlsize] = useState<boolean>(false)
  const [xlsize, setxlsize] = useState<boolean>(false)
  const [customActiveTab, setcustomActiveTab] = useState<any>('1')
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Product Details' pageTitle='Ecommerce' />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className='gx-lg-5'>
                  <Col xl={4} md={8} className='mx-auto'>
                    <div className='product-img-slider sticky-side-div'>
                      {/* <Swiper
                      modules={[Thumbs, Navigation, FreeMode]}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        className="swiper product-thumbnail-slider p-2 rounded bg-light"
                      >
                        <div className="swiper-wrapper">
                          <SwiperSlide>
                            <img
                              src={product8}
                              alt=""
                              className="img-fluid d-block"
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={product6}
                              alt=""
                              className="img-fluid d-block"
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={product1}
                              alt=""
                              className="img-fluid d-block"
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={product8}
                              alt=""
                              className="img-fluid d-block"
                            />
                          </SwiperSlide>
                        </div>
                      </Swiper> */}

                      <Swiper
                        modules={[Navigation, FreeMode]}
                        navigation={true}
                        className='swiper product-thumbnail-slider p-2 rounded bg-light'
                      >
                        <div className='swiper-wrapper'>
                          <SwiperSlide>
                            <img src={product8} alt='' className='img-fluid d-block' />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img src={product6} alt='' className='img-fluid d-block' />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img src={product1} alt='' className='img-fluid d-block' />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img src={product8} alt='' className='img-fluid d-block' />
                          </SwiperSlide>
                        </div>
                      </Swiper>
                      <div className='product-nav-slider mt-2'>
                        {/* <Swiper
                          onSwiper={setThumbsSwiper}
                          slidesPerView={4}
                          freeMode={true}
                          watchSlidesProgress={true}
                          spaceBetween={10}
                          className="swiper product-nav-slider mt-2 overflow-hidden"
                        >
                          <div className="swiper-wrapper">
                            <SwiperSlide className="rounded">
                              <div className="nav-slide-item">
                                <img
                                  src={product8}
                                  alt=""
                                  className="img-fluid d-block rounded"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="nav-slide-item">
                                <img
                                  src={product6}
                                  alt=""
                                  className="img-fluid d-block rounded"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="nav-slide-item">
                                <img
                                  src={product1}
                                  alt=""
                                  className="img-fluid d-block rounded"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="nav-slide-item">
                                <img
                                  src={product8}
                                  alt=""
                                  className="img-fluid d-block rounded"
                                />
                              </div>
                            </SwiperSlide>
                          </div>
                        </Swiper> */}
                        <Swiper
                          // onSwiper={setThumbsSwiper}
                          slidesPerView={4}
                          freeMode={true}
                          watchSlidesProgress={true}
                          spaceBetween={10}
                          className='swiper product-nav-slider mt-2 overflow-hidden'
                        >
                          <div className='swiper-wrapper'>
                            <SwiperSlide className='rounded'>
                              <div className='nav-slide-item'>
                                <img src={product8} alt='' className='img-fluid d-block rounded' />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className='nav-slide-item'>
                                <img src={product6} alt='' className='img-fluid d-block rounded' />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className='nav-slide-item'>
                                <img src={product1} alt='' className='img-fluid d-block rounded' />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className='nav-slide-item'>
                                <img src={product8} alt='' className='img-fluid d-block rounded' />
                              </div>
                            </SwiperSlide>
                          </div>
                        </Swiper>
                      </div>
                    </div>
                  </Col>

                  <Col xl={8}>
                    <div className='mt-xl-0 mt-5'>
                      <div className='d-flex'>
                        <div className='flex-grow-1'>
                          <h4>Full Sleeve Sweatshirt for Men (Pink)</h4>
                          <div className='hstack gap-3 flex-wrap'>
                            <div>
                              <Link to='#' className='text-primary d-block'>
                                Tommy Hilfiger
                              </Link>
                            </div>
                            <div className='vr'></div>
                            <div className='text-muted'>
                              Seller : <span className='text-body fw-medium'>Zoetic Fashion</span>
                            </div>
                            <div className='vr'></div>
                            <div className='text-muted'>
                              Published : <span className='text-body fw-medium'>26 Mar, 2021</span>
                            </div>
                          </div>
                        </div>
                        <div className='flex-shrink-0'>
                          <div>
                            <Tooltip
                              placement='top'
                              isOpen={ttop}
                              target='TooltipTop'
                              toggle={() => {
                                setttop(!ttop)
                              }}
                            >
                              Edit
                            </Tooltip>
                            <a
                              href='apps-ecommerce-add-product'
                              id='TooltipTop'
                              className='btn btn-light'
                            >
                              <i className='ri-pencil-fill align-bottom'></i>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex flex-wrap gap-2 align-items-center mt-3'>
                        <div className='text-muted fs-16'>
                          <span className='mdi mdi-star text-warning'></span>
                          <span className='mdi mdi-star text-warning'></span>
                          <span className='mdi mdi-star text-warning'></span>
                          <span className='mdi mdi-star text-warning'></span>
                          <span className='mdi mdi-star text-warning'></span>
                        </div>
                        <div className='text-muted'>( 5.50k Customer Review )</div>
                      </div>

                      <Row className='mt-4'>
                        {productDetailsWidgets.map((pricingDetails, key) => (
                          <PricingWidgetList pricingDetails={pricingDetails} key={key} />
                        ))}
                      </Row>

                      <Row>
                        <Col xl={6}>
                          <div className=' mt-4'>
                            <h5 className='fs-15'>Sizes :</h5>
                            <div className='d-flex flex-wrap gap-2'>
                              <Tooltip
                                placement='top'
                                isOpen={ssize}
                                target='TooltipSSize'
                                toggle={() => {
                                  setssize(!ssize)
                                }}
                              >
                                Out of Stock
                              </Tooltip>
                              <Tooltip
                                placement='top'
                                isOpen={msize}
                                target='TooltipMSize'
                                toggle={() => {
                                  setmsize(!msize)
                                }}
                              >
                                04 Items Available
                              </Tooltip>
                              <Tooltip
                                placement='top'
                                isOpen={lsize}
                                target='TooltipLSize'
                                toggle={() => {
                                  setlsize(!lsize)
                                }}
                              >
                                06 Items Available
                              </Tooltip>
                              <Tooltip
                                placement='top'
                                isOpen={xlsize}
                                target='TooltipXlSize'
                                toggle={() => {
                                  setxlsize(!xlsize)
                                }}
                              >
                                Out of Stock
                              </Tooltip>

                              <Input type='radio' className='btn-check' name='productsize-radio' />
                              <Label
                                className='btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center'
                                id='TooltipSSize'
                              >
                                S
                              </Label>

                              <Input type='radio' className='btn-check' name='productsize-radio' />
                              <Label
                                className='btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center'
                                id='TooltipMSize'
                              >
                                M
                              </Label>

                              <Input type='radio' className='btn-check' name='productsize-radio' />
                              <Label
                                className='btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center'
                                id='TooltipLSize'
                              >
                                L
                              </Label>

                              <Input type='radio' className='btn-check' name='productsize-radio' />
                              <Label
                                className='btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center'
                                id='TooltipXlSize'
                              >
                                XL
                              </Label>
                            </div>
                          </div>
                        </Col>

                        <Col xl={6}>
                          <div className=' mt-4'>
                            <h5 className='fs-15'>Colors :</h5>
                            <div className='d-flex flex-wrap gap-2'>
                              <div
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                data-bs-placement='top'
                                title='Out of Stock'
                              >
                                <button
                                  type='button'
                                  className='btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-primary'
                                  disabled
                                >
                                  <i className='ri-checkbox-blank-circle-fill'></i>
                                </button>
                              </div>
                              <div
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                data-bs-placement='top'
                                title='03 Items Available'
                              >
                                <button
                                  type='button'
                                  className='btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-secondary'
                                >
                                  <i className='ri-checkbox-blank-circle-fill'></i>
                                </button>
                              </div>
                              <div
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                data-bs-placement='top'
                                title='03 Items Available'
                              >
                                <button
                                  type='button'
                                  className='btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-success'
                                >
                                  <i className='ri-checkbox-blank-circle-fill'></i>
                                </button>
                              </div>
                              <div
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                data-bs-placement='top'
                                title='02 Items Available'
                              >
                                <button
                                  type='button'
                                  className='btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-info'
                                >
                                  <i className='ri-checkbox-blank-circle-fill'></i>
                                </button>
                              </div>
                              <div
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                data-bs-placement='top'
                                title='01 Items Available'
                              >
                                <button
                                  type='button'
                                  className='btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-warning'
                                >
                                  <i className='ri-checkbox-blank-circle-fill'></i>
                                </button>
                              </div>
                              <div
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                data-bs-placement='top'
                                title='04 Items Available'
                              >
                                <button
                                  type='button'
                                  className='btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-danger'
                                >
                                  <i className='ri-checkbox-blank-circle-fill'></i>
                                </button>
                              </div>
                              <div
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                data-bs-placement='top'
                                title='03 Items Available'
                              >
                                <button
                                  type='button'
                                  className='btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-light'
                                >
                                  <i className='ri-checkbox-blank-circle-fill'></i>
                                </button>
                              </div>
                              <div
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                data-bs-placement='top'
                                title='04 Items Available'
                              >
                                <button
                                  type='button'
                                  className='btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-body'
                                >
                                  <i className='ri-checkbox-blank-circle-fill'></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className='mt-4 text-muted'>
                        <h5 className='fs-15'>Description :</h5>
                        <p>
                          Tommy Hilfiger men striped pink sweatshirt. Crafted with cotton. Material
                          composition is 100% organic cotton. This is one of the world’s leading
                          designer lifestyle brands and is internationally recognized for
                          celebrating the essence of classic American cool style, featuring preppy
                          with a twist designs.
                        </p>
                      </div>

                      <Row>
                        <Col sm={6}>
                          <div className='mt-3'>
                            <h5 className='fs-15'>Features :</h5>
                            <ul className='list-unstyled'>
                              <li className='py-1'>
                                <i className='mdi mdi-circle-medium me-1 text-muted align-middle'></i>{' '}
                                Full Sleeve
                              </li>
                              <li className='py-1'>
                                <i className='mdi mdi-circle-medium me-1 text-muted align-middle'></i>{' '}
                                Cotton
                              </li>
                              <li className='py-1'>
                                <i className='mdi mdi-circle-medium me-1 text-muted align-middle'></i>{' '}
                                All Sizes available
                              </li>
                              <li className='py-1'>
                                <i className='mdi mdi-circle-medium me-1 text-muted align-middle'></i>{' '}
                                4 Different Color
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className='mt-3'>
                            <h5 className='fs-15'>Services :</h5>
                            <ul className='list-unstyled product-desc-list'>
                              <li className='py-1'>10 Days Replacement</li>
                              <li className='py-1'>Cash on Delivery available</li>
                            </ul>
                          </div>
                        </Col>
                      </Row>

                      <div className='product-content mt-5'>
                        <h5 className='fs-15 mb-3'>Product Description :</h5>
                        <Nav tabs className='nav-tabs-custom nav-success'>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: customActiveTab === '1'
                              })}
                              onClick={() => {
                                toggleCustom('1')
                              }}
                            >
                              Specification
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: customActiveTab === '2'
                              })}
                              onClick={() => {
                                toggleCustom('2')
                              }}
                            >
                              Details
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className='border border-top-0 p-4'
                          id='nav-tabContent'
                        >
                          <TabPane id='nav-speci' tabId='1'>
                            <div className='table-responsive'>
                              <table className='table mb-0'>
                                <tbody>
                                  <tr>
                                    <th scope='row' style={{ width: '200px' }}>
                                      Category
                                    </th>
                                    <td>T-Shirt</td>
                                  </tr>
                                  <tr>
                                    <th scope='row'>Brand</th>
                                    <td>Tommy Hilfiger</td>
                                  </tr>
                                  <tr>
                                    <th scope='row'>Color</th>
                                    <td>Blue</td>
                                  </tr>
                                  <tr>
                                    <th scope='row'>Material</th>
                                    <td>Cotton</td>
                                  </tr>
                                  <tr>
                                    <th scope='row'>Weight</th>
                                    <td>140 Gram</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </TabPane>
                          <TabPane id='nav-detail' tabId='2'>
                            <div>
                              <h5 className='mb-3'>Tommy Hilfiger Sweatshirt for Men (Pink)</h5>
                              <p>
                                Tommy Hilfiger men striped pink sweatshirt. Crafted with cotton.
                                Material composition is 100% organic cotton. This is one of the
                                world’s leading designer lifestyle brands and is internationally
                                recognized for celebrating the essence of classic American cool
                                style, featuring preppy with a twist designs.
                              </p>
                              <div>
                                <p className='mb-2'>
                                  <i className='mdi mdi-circle-medium me-1 text-muted align-middle'></i>{' '}
                                  Machine Wash
                                </p>
                                <p className='mb-2'>
                                  <i className='mdi mdi-circle-medium me-1 text-muted align-middle'></i>{' '}
                                  Fit Type: Regular
                                </p>
                                <p className='mb-2'>
                                  <i className='mdi mdi-circle-medium me-1 text-muted align-middle'></i>{' '}
                                  100% Cotton
                                </p>
                                <p className='mb-0'>
                                  <i className='mdi mdi-circle-medium me-1 text-muted align-middle'></i>{' '}
                                  Long sleeve
                                </p>
                              </div>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>

                      <div className='mt-5'>
                        <div>
                          <h5 className='fs-15 mb-3'>Ratings & Reviews</h5>
                        </div>
                        <Row className='gy-4 gx-0'>
                          <Col lg={4}>
                            <div>
                              <div className='pb-3'>
                                <div className='bg-light px-3 py-2 rounded-2 mb-2'>
                                  <div className='d-flex align-items-center'>
                                    <div className='flex-grow-1'>
                                      <div className='fs-16 align-middle text-warning'>
                                        <i className='ri-star-fill'></i>{' '}
                                        <i className='ri-star-fill'></i>{' '}
                                        <i className='ri-star-fill'></i>{' '}
                                        <i className='ri-star-fill'></i>{' '}
                                        <i className='ri-star-half-fill'></i>
                                      </div>
                                    </div>
                                    <div className='flex-shrink-0'>
                                      <h6 className='mb-0'>4.5 out of 5</h6>
                                    </div>
                                  </div>
                                </div>
                                <div className='text-center'>
                                  <div className='text-muted'>
                                    Total <span className='fw-medium'>5.50k</span> reviews
                                  </div>
                                </div>
                              </div>

                              <div className='mt-3'>
                                <Row className='align-items-center g-2'>
                                  <div className='col-auto'>
                                    <div className='p-2'>
                                      <h6 className='mb-0'>5 star</h6>
                                    </div>
                                  </div>
                                  <div className='col'>
                                    <div className='p-2'>
                                      <div className='progress animated-progess progress-sm'>
                                        <div
                                          className='progress-bar bg-success'
                                          role='progressbar'
                                          style={{ width: '50.16%' }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-auto'>
                                    <div className='p-2'>
                                      <h6 className='mb-0 text-muted'>2758</h6>
                                    </div>
                                  </div>
                                </Row>

                                <Row className='align-items-center g-2'>
                                  <div className='col-auto'>
                                    <div className='p-2'>
                                      <h6 className='mb-0'>4 star</h6>
                                    </div>
                                  </div>
                                  <div className='col'>
                                    <div className='p-2'>
                                      <div className='progress animated-progess progress-sm'>
                                        <div
                                          className='progress-bar bg-success'
                                          role='progressbar'
                                          style={{ width: '19.32%' }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-auto'>
                                    <div className='p-2'>
                                      <h6 className='mb-0 text-muted'>1063</h6>
                                    </div>
                                  </div>
                                </Row>

                                <Row className='align-items-center g-2'>
                                  <div className='col-auto'>
                                    <div className='p-2'>
                                      <h6 className='mb-0'>3 star</h6>
                                    </div>
                                  </div>
                                  <div className='col'>
                                    <div className='p-2'>
                                      <div className='progress animated-progess progress-sm'>
                                        <div
                                          className='progress-bar bg-success'
                                          role='progressbar'
                                          style={{ width: '18.12%' }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-auto'>
                                    <div className='p-2'>
                                      <h6 className='mb-0 text-muted'>997</h6>
                                    </div>
                                  </div>
                                </Row>

                                <Row className='align-items-center g-2'>
                                  <div className='col-auto'>
                                    <div className='p-2'>
                                      <h6 className='mb-0'>2 star</h6>
                                    </div>
                                  </div>
                                  <div className='col'>
                                    <div className='p-2'>
                                      <div className='progress animated-progess progress-sm'>
                                        <div
                                          className='progress-bar bg-warning'
                                          role='progressbar'
                                          style={{ width: '7.42%' }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='col-auto'>
                                    <div className='p-2'>
                                      <h6 className='mb-0 text-muted'>408</h6>
                                    </div>
                                  </div>
                                </Row>

                                <Row className='align-items-center g-2'>
                                  <div className='col-auto'>
                                    <div className='p-2'>
                                      <h6 className='mb-0'>1 star</h6>
                                    </div>
                                  </div>
                                  <div className='col'>
                                    <div className='p-2'>
                                      <div className='progress animated-progess progress-sm'>
                                        <div
                                          className='progress-bar bg-danger'
                                          role='progressbar'
                                          style={{ width: '4.98%' }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-auto'>
                                    <div className='p-2'>
                                      <h6 className='mb-0 text-muted'>274</h6>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </div>
                          </Col>

                          <Col lg={8}>
                            <div className='ps-lg-4'>
                              <div className='d-flex flex-wrap align-items-start gap-3'>
                                <h5 className='fs-15'>Reviews: </h5>
                              </div>

                              <SimpleBar
                                className='me-lg-n3 pe-lg-4'
                                style={{ maxHeight: '225px' }}
                              >
                                <ul className='list-unstyled mb-0'>
                                  {reviews.map((review, key) => (
                                    <React.Fragment key={key}>
                                      <ProductReview review={review} />
                                    </React.Fragment>
                                  ))}
                                </ul>
                              </SimpleBar>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EcommerceProductDetail
