import React, { useEffect, useState } from 'react'
import { searchBoothRecords, fetchPromotions, updatePromotionBooths } from 'helpers/backend_helper'
import { Container } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { toast } from 'react-toastify'
import Pagination from 'Components/Common/Pagination'
import { PERPAGE_DEFAULT } from 'lib/constant'
import PromotionBoothsTable from './PromotionBoothsTable'
import { ISort } from 'lib/type'
import { mapBoothOptions, mapPromotionBooths } from 'lib/util'

export interface IPromotion {
  id: number
  name: string
  booths: any[]
}

export interface IDataSubmit {
  promotionId: number
  boothIds: number[]
}

const PromotionBooths = () => {
  document.title = 'Booth Promotion Record | Photo Booth Admin Dashboard'

  //state data
  const [fetching, setFetching] = useState<boolean>(false)
  const [promotions, setPromotions] = useState<IPromotion[]>([])
  const [booths, setBooths] = useState<any[]>([])

  const [sortAction, setSortAction] = useState<ISort>({ sortBy: '', sortDirection: 'ACS' })

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [valueSearch, setValueSearch] = useState<string>('')
  const [canEdit, setCanEdit] = useState<boolean>(false)

  const fetchPromotionsRecord = async (params: any) => {
    setFetching(true)
    try {
      const res = fetchPromotions(params)
      const response = await res
      const data = response.data

      setPromotions(mapPromotionBooths(data))
      setTotalPage(response.totalPages)
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
    }
    setFetching(false)
  }

  const handleSubmit = async (params: IDataSubmit[]) => {
    setFetching(true)
    try {
      const res = updatePromotionBooths(params)
      const response = await res
      const message = response.message

      if (message == 'SUCCESSFULL') {
        toast.success('Updated promotion booths successfully')
      } else {
        toast.error(message)
        callBackPromotion()
      }
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      callBackPromotion()
    }
    setFetching(false)
  }

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const res = searchBoothRecords({})
        const response = await res
        const data = response.data

        setBooths(mapBoothOptions(data))
      } catch (e) {
        const errMsg = e as string
        toast.error(errMsg)
      }
    }

    fetchResources()
  }, [])

  const callBackPromotion = () => {
    const queryParams = {
      pageNo: currentPage,
      pageSize: PERPAGE_DEFAULT,
      ...(valueSearch.trim() && { keyWord: valueSearch })
    }

    fetchPromotionsRecord(queryParams)
  }

  const searchPromotion = (value: string) => {
    const trimmedValue = value.trim()
    fetchPromotionsRecord(getParams(1, trimmedValue))
    setValueSearch(trimmedValue)
  }

  useEffect(() => {
    fetchPromotionsRecord(getParams(currentPage, valueSearch))
  }, [currentPage])

  useEffect(() => {
    if (sortAction.sortBy.length > 0) {
      fetchPromotionsRecord(getParams(currentPage, valueSearch))
    }
  }, [sortAction])

  const getParams = (page: number = 1, value: string) => {
    return {
      pageNo: page,
      pageSize: PERPAGE_DEFAULT,
      ...(value.trim() && { keyWord: value }),
      ...(sortAction.sortBy.length > 0 && sortAction)
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Booth Promotion Record index' pageTitle='Booth Promotion Record' />
          <PromotionBoothsTable
            promotions={promotions}
            fetching={fetching}
            sortAction={sortAction}
            setSortAction={setSortAction}
            booths={booths}
            handleSubmit={handleSubmit}
            searchPromotion={searchPromotion}
            setCanEdit={setCanEdit}
          />
          {totalPage > 1 && (
            <Pagination
              perPageData={PERPAGE_DEFAULT}
              data={promotions}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={totalPage}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default PromotionBooths
