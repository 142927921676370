import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { IUser } from './index'
import { toast } from 'react-toastify'
import DeleteModal from 'Components/Common/DeleteModal'
import { useDebouncedCallback } from 'use-debounce'
import { Spinner } from 'reactstrap'
import { ISort } from 'lib/type'

interface IProds {
  fetching: boolean
  userRecords: IUser[]
  handleOpenDetails: (arg: number) => void
  setOpenUserPopup: (arg: boolean) => void
  setEditMode: (arg: boolean) => void
  handleDeleteUser: (arg: number) => Promise<string>
  searchUser: (arg: string) => void
  sortAction: ISort
  setSortAction: (arg: ISort) => void
  setCanEdit: (arg: boolean) => void
}

const UsersTable: React.FC<IProds> = ({
  userRecords,
  handleOpenDetails,
  setOpenUserPopup,
  setEditMode,
  fetching,
  handleDeleteUser,
  searchUser,
  sortAction,
  setSortAction,
  setCanEdit
}) => {
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
  const [userSelectedId, setUserSelectedId] = useState<number | undefined>(undefined)
  const [query, setQuery] = useState('')
  const [permissions, setPermissions] = useState({
    canCreate: false,
    canEdit: false,
    canDelete: false,
  })

  const handleAddUser = () => {
    setEditMode(false)
    setOpenUserPopup(true)
  }

  const handleEditUser = (userId: number | undefined, canEdit: boolean) => {
    const id = userId as number
    setEditMode(true)
    setOpenUserPopup(true)
    handleOpenDetails(id)
    setCanEdit(canEdit)
  }

  const handleDeletUserRecord = async (boothId: number | undefined) => {
    const id = boothId as number
    const res = handleDeleteUser(id)

    const message = await res
    if (message === 'SUCCESSFULL') {
      toast.success('Deleted a user successfully')
    }
  }

  const handleShowModalDelete = (userId: number | undefined) => {
    const id = userId as number
    setShowModalDelete(true)
    setUserSelectedId(id)
  }

  const handleDelete = () => {
    handleDeletUserRecord(userSelectedId)
    setShowModalDelete(false)
  }

  const debouncedSearch = useDebouncedCallback((searchTerm: string) => {
    searchUser(searchTerm)
  }, 1000)

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const value = e.target.value
    setQuery(value)
    debouncedSearch(value)
  }

  const handleActionSort = (sortBy: string) => {
    if (sortAction.sortBy === sortBy) {
      setSortAction({
        ...sortAction,
        sortDirection: sortAction.sortDirection === 'DESC' ? 'ASC' : 'DESC'
      })
    } else {
      setSortAction({
        sortDirection: 'DESC',
        sortBy: sortBy
      })
    }
  }

  useEffect(() => {
    const userRoles = sessionStorage.getItem('userRoles') ? JSON.parse(atob(sessionStorage.getItem('userRoles')!)) : null
    userRoles.forEach((role: any) => {
      if (role.page.name === 'Users') {
        setPermissions({
          canCreate: role.canCreate,
          canEdit: role.canEdit,
          canDelete: role.canDelete,
        })
      }
    })
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader className='align-items-center d-flex justify-content-between'>
              <div className='search-box d-inline-block col-8 d-flex align-items-center'>
                <input
                  id='search-bar-user'
                  className='form-control search'
                  placeholder='Search users'
                  onChange={(e) => handleInputChange(e)}
                  value={query}
                />
                <i className='bx bx-search-alt search-icon'></i>
              </div>
              {
                permissions.canCreate &&
                <Button className='p-2' color='primary' onClick={(e) => handleAddUser()}>
                  <div className='flex align-items-center'>
                    {fetching ? (
                      <Spinner size='sm'> Loading... </Spinner>
                    ) : (
                      <>
                        <i className='mdi mdi-plus'></i>
                        <span className='fw-semibold ms-1'>New User</span>
                      </>
                    )}
                  </div>
                </Button>
              }
            </CardHeader>
            <CardBody>
              <div className='table-responsive table-card'>
                <table className='table align-middle table-nowrap table-striped-columns mb-0'>
                  <thead className='table-light'>
                    <tr>
                      <th scope='col' style={{ width: '46px' }}>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={query}
                            id='cardtableCheck'
                          />
                          <label className='form-check-label' htmlFor='cardtableCheck'></label>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('id')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>ID</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'id' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'id' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('fullName')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Full Name</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'name' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'name' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('email')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Email</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'city' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'city' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('gender')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Gender</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'address' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'address' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('dOB')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Date of Birth</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'info1' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'info1' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' onClick={() => handleActionSort('phoneNumber')}>
                        <div className='w-100 d-flex justify-content-between'>
                          <span>Phone Number</span>
                          <i
                            className={`mdi ${sortAction.sortDirection === 'ASC' && sortAction.sortBy === 'info2' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin'}
                            ${sortAction.sortBy !== 'info2' && 'opacity-25'}`}
                          ></i>
                        </div>
                      </th>
                      <th scope='col' style={{ width: '150px' }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userRecords.map((user, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value=''
                                id={`check-${user.id}`}
                              />
                              <label
                                className='form-check-label'
                                htmlFor={`check-${user.id}`}
                              ></label>
                            </div>
                          </td>
                          <td>
                            <Link to='#' className='fw-medium'>
                              {user.id}
                            </Link>
                          </td>
                          <td>{user.fullName}</td>
                          <td>{user.email}</td>
                          <td>{user.gender ? 'male' : 'female'}</td>
                          <td>{user.dOB}</td>
                          <td>{user.phoneNumber}</td>
                          <td>
                            <button
                              type='button'
                              className='btn btn-sm btn-light'
                              onClick={(e) => handleEditUser(user?.id, permissions.canEdit)}
                            >
                              Details
                            </button>
                            { permissions.canDelete &&
                              <button
                                type='button'
                                className='btn btn-sm btn-danger ms-2'
                                onClick={(e) => handleShowModalDelete(user?.id)}
                              >
                                Delete
                              </button>
                            }
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={showModalDelete}
        onDeleteClick={() => handleDelete()}
        onCloseClick={() => setShowModalDelete(false)}
      />
    </React.Fragment>
  )
}
export default UsersTable
