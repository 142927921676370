import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Navdata = () => {
  const history = useNavigate()
  //state data
  const [isDashboard, setIsDashboard] = useState<boolean>(false)

  const [iscurrentState, setIscurrentState] = useState('Dashboard')

  const [filteredMenuItems, setFilteredMenuItems] = useState([])

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute('sub-items')) {
      const ul: any = document.getElementById('two-column-menu')
      const iconItems = ul.querySelectorAll('.nav-icon.active')
      let activeIconItems = [...iconItems]
      activeIconItems.forEach((item) => {
        item.classList.remove('active')
        var id = item.getAttribute('sub-items')
        const getID = document.getElementById(id) as HTMLElement
        if (getID) getID.classList.remove('show')
      })
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel')
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false)
    }
  }, [history, iscurrentState, isDashboard])

  const menuItems: any = [
    {
      label: 'Menu',
      isHeader: true
    },
    {
      id: 'dashboard',
      label: 'Dashboards',
      icon: 'mdi mdi-speedometer',
      link: '/#',
      stateVariables: isDashboard,
      click: function (e: any) {
        e.preventDefault()
        setIsDashboard(!isDashboard)
        setIscurrentState('Dashboard')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'analytics',
          label: 'Analytics',
          link: '/dashboard-analytics',
          parentId: 'dashboard'
        },
        {
          id: 'ecommerce',
          label: 'Ecommerce',
          link: '/dashboard',
          parentId: 'dashboard'
        }
      ]
    },
    {
      id: 'users',
      label: 'Users',
      icon: 'mdi mdi-account-group',
      link: '/users',
      click: function (e: any) {
        e.preventDefault()
        setIscurrentState('Users')
        updateIconSidebar(e)
      }
    },
    {
      id: 'booths',
      label: 'Booths',
      icon: 'mdi mdi-hubspot',
      link: '/booths',
      click: function (e: any) {
        e.preventDefault()
        setIscurrentState('Booths')
        updateIconSidebar(e)
      }
    },
    {
      id: 'locations',
      label: 'Locations',
      icon: 'mdi mdi-map-marker',
      link: '/locations',
      click: function (e: any) {
        e.preventDefault()
        setIscurrentState('Locations')
        updateIconSidebar(e)
      }
    },
    {
      id: 'action-prices',
      label: 'Action Prices',
      icon: 'mdi mdi-currency-usd',
      link: '/action-prices',
      click: function (e: any) {
        e.preventDefault()
        setIscurrentState('Action Prices')
        updateIconSidebar(e)
      }
    },
    {
      id: 'events',
      label: 'Events',
      icon: 'mdi mdi-timetable',
      link: '/events',
      click: function (e: any) {
        e.preventDefault()
        setIscurrentState('Events')
        updateIconSidebar(e)
      }
    },
    {
      id: 'event-booths',
      label: 'Event Booths',
      icon: 'mdi mdi-file-table-box-outline',
      link: '/event-booths',
      click: function (e: any) {
        e.preventDefault()
        setIscurrentState('Event Booths')
        updateIconSidebar(e)
      }
    },
    {
      id: 'promotions',
      label: 'Promotions',
      icon: 'mdi mdi-ticket-percent',
      link: '/promotions',
      click: function (e: any) {
        e.preventDefault()
        setIscurrentState('Promotions')
        updateIconSidebar(e)
      }
    },
    {
      id: 'promotion-booths',
      label: 'Promotion Booths',
      icon: 'mdi mdi-file-table-box-multiple-outline',
      link: '/promotion-booths',
      click: function (e: any) {
        e.preventDefault()
        setIscurrentState('Promotion Booths')
        updateIconSidebar(e)
      }
    },
    {
      id: 'themes',
      label: 'Themes',
      icon: 'mdi mdi-view-dashboard',
      link: '/themes/dashboard',
      click: function (e: any) {
        e.preventDefault()
        setIscurrentState('Themes')
        updateIconSidebar(e)
      }
    }
  ]

  const userRoles = sessionStorage.getItem('userRoles') ? JSON.parse(atob(sessionStorage.getItem('userRoles')!)) : null

  useEffect(() => {
    const roleActive: any = ['Dashboards', 'Themes']
    const filteredMenuItems: any = []
    userRoles.forEach((role: any) =>
      role.canView && roleActive.push(role.page.name)
    )

    menuItems.filter((item: any) => roleActive.includes(item.label) && filteredMenuItems.push(item))
    setFilteredMenuItems(filteredMenuItems)
  }, [])

  return <React.Fragment>{filteredMenuItems}</React.Fragment>
}
export default Navdata
