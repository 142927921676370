export const discountTypeOptions = [
  { label: 'Unknown', value: '' },
  { label: 'Percentage', value: 1 },
  { label: 'Amount', value: 2 }
]

export const showDiscountType = (type: number) => {
  switch (type) {
    case 1:
      return 'Percentage'
    case 2:
      return 'Amount'
    default:
      return 'Unknown'
  }
}

export const formatTime = (date: any) => {
  const utcDate = new Date(date)
  const localDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }).format(utcDate)
  return localDate
}

export const isFinishDateTimeGreater = (
  finishDateTime: string | null,
  startDateTime: string | null
): boolean => {
  if (!finishDateTime || !startDateTime) {
    return false
  }

  const finishDate = new Date(finishDateTime)
  const startDate = new Date(startDateTime)

  if (isNaN(finishDate.getTime()) || isNaN(startDate.getTime())) {
    return false
  }

  return finishDate > startDate
}

export const customStylesLabel = {
  menu: (provided: any) => ({
    ...provided,
    maxHeight: 400,
    overflowY: 'auto'
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: 'white'
  })
}

export const mapBoothOptions = (data: any): any[] => {
  if (!data) {
    return []
  }
  return Object.values(data).map((booth: any) => ({
    value: booth.id,
    label: booth.name
  }))
}

export const mapEventBooths = (data: any): any[] => {
  if (!data) {
    return []
  }

  return data.map((event: any) => mapSingleEventBooth(event))
}

export const mapPromotionBooths = (data: any): any[] => {
  if (!data) {
    return []
  }
  return data.map((booth: any) => mapSinglePromotionBooth(booth))
}

export const mapSingleEventBooth = (data: any): any => {
  if (!data) {
    return null
  }

  return {
    id: data.id,
    name: data.name,
    booths: data.boothEvents.map((booth: any) => ({
      value: booth.booth.id,
      label: booth.booth.name
    }))
  }
}

export const mapSinglePromotionBooth = (data: any): any => {
  if (!data) {
    return null
  }
  return {
    id: data.id,
    name: data.name,
    booths: data.boothPromotions.map((booth: any) => ({
      value: booth.booth.id,
      label: booth.booth.name
    }))
  }
}

export const showBooths = (data: any) => {
  let results = !data ? [] : Object.values(data).map((booth: any) => booth.booth.name)

  return results.join(', ')
}
