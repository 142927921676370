import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Navdata = () => {
  const history = useNavigate()
  //state data
  const [isDashboard, setIsDashboard] = useState<boolean>(false)
  const [isApps, setIsApps] = useState<boolean>(false)
  const [isAuth, setIsAuth] = useState<boolean>(false)
  const [isPages, setIsPages] = useState<boolean>(false)
  const [isBaseUi, setIsBaseUi] = useState<boolean>(false)
  const [isAdvanceUi, setIsAdvanceUi] = useState<boolean>(false)
  const [isForms, setIsForms] = useState<boolean>(false)
  const [isTables, setIsTables] = useState<boolean>(false)
  const [isCharts, setIsCharts] = useState<boolean>(false)
  const [isIcons, setIsIcons] = useState<boolean>(false)
  const [isMaps, setIsMaps] = useState<boolean>(false)
  const [isMultiLevel, setIsMultiLevel] = useState<boolean>(false)

  // Apps
  const [isCalendar, setCalendar] = useState<boolean>(false)
  const [isEmail, setEmail] = useState<boolean>(false)
  const [isSubEmail, setSubEmail] = useState<boolean>(false)
  const [isEcommerce, setIsEcommerce] = useState<boolean>(false)
  const [isProjects, setIsProjects] = useState<boolean>(false)
  const [isTasks, setIsTasks] = useState<boolean>(false)
  const [isCRM, setIsCRM] = useState<boolean>(false)
  const [isCrypto, setIsCrypto] = useState<boolean>(false)
  const [isInvoices, setIsInvoices] = useState<boolean>(false)
  const [isSupportTickets, setIsSupportTickets] = useState<boolean>(false)
  const [isNFTMarketplace, setIsNFTMarketplace] = useState<boolean>(false)
  const [isJobs, setIsJobs] = useState<boolean>(false)
  const [isJobList, setIsJobList] = useState<boolean>(false)
  const [isCandidateList, setIsCandidateList] = useState<boolean>(false)

  // Authentication
  const [isSignIn, setIsSignIn] = useState<boolean>(false)
  const [isSignUp, setIsSignUp] = useState<boolean>(false)
  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false)
  const [isPasswordCreate, setIsPasswordCreate] = useState<boolean>(false)
  const [isLockScreen, setIsLockScreen] = useState<boolean>(false)
  const [isLogout, setIsLogout] = useState<boolean>(false)
  const [isSuccessMessage, setIsSuccessMessage] = useState<boolean>(false)
  const [isVerification, setIsVerification] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  // Pages
  const [isProfile, setIsProfile] = useState<boolean>(false)
  const [isLanding, setIsLanding] = useState<boolean>(false)

  // Charts
  const [isApex, setIsApex] = useState<boolean>(false)

  // Multi Level
  const [isLevel1, setIsLevel1] = useState<boolean>(false)
  const [isLevel2, setIsLevel2] = useState<boolean>(false)

  const [iscurrentState, setIscurrentState] = useState('Dashboard')

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute('sub-items')) {
      const ul: any = document.getElementById('two-column-menu')
      const iconItems = ul.querySelectorAll('.nav-icon.active')
      let activeIconItems = [...iconItems]
      activeIconItems.forEach((item) => {
        item.classList.remove('active')
        var id = item.getAttribute('sub-items')
        const getID = document.getElementById(id) as HTMLElement
        if (getID) getID.classList.remove('show')
      })
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel')
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false)
    }
    if (iscurrentState !== 'Apps') {
      setIsApps(false)
    }
    if (iscurrentState !== 'Auth') {
      setIsAuth(false)
    }
    if (iscurrentState !== 'Pages') {
      setIsPages(false)
    }
    if (iscurrentState !== 'BaseUi') {
      setIsBaseUi(false)
    }
    if (iscurrentState !== 'AdvanceUi') {
      setIsAdvanceUi(false)
    }
    if (iscurrentState !== 'Forms') {
      setIsForms(false)
    }
    if (iscurrentState !== 'Tables') {
      setIsTables(false)
    }
    if (iscurrentState !== 'Charts') {
      setIsCharts(false)
    }
    if (iscurrentState !== 'Icons') {
      setIsIcons(false)
    }
    if (iscurrentState !== 'Maps') {
      setIsMaps(false)
    }
    if (iscurrentState !== 'MuliLevel') {
      setIsMultiLevel(false)
    }
    if (iscurrentState === 'Widgets') {
      history('/widgets')
      document.body.classList.add('twocolumn-panel')
    }
    if (iscurrentState !== 'Landing') {
      setIsLanding(false)
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel
  ])

  const menuItems: any = [
    {
      id: 'back',
      label: 'Back to App',
      icon: 'mdi mdi-arrow-left-thin',
      link: '/'
    },
    {
      label: 'Menu',
      isHeader: true
    },
    {
      id: 'dashboard',
      label: 'Dashboards',
      icon: 'mdi mdi-speedometer',
      link: '/#',
      stateVariables: isDashboard,
      click: function (e: any) {
        e.preventDefault()
        setIsDashboard(!isDashboard)
        setIscurrentState('Dashboard')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'analytics',
          label: 'Analytics',
          link: '/themes/dashboard-analytics',
          parentId: 'dashboard'
        },
        {
          id: 'crm',
          label: 'CRM',
          link: '/themes/dashboard-crm',
          parentId: 'dashboard'
        },
        {
          id: 'ecommerce',
          label: 'Ecommerce',
          link: '/themes/dashboard',
          parentId: 'dashboard'
        },
        {
          id: 'crypto',
          label: 'Crypto',
          link: '/themes/dashboard-crypto',
          parentId: 'dashboard'
        },
        {
          id: 'projects',
          label: 'Projects',
          link: '/themes/dashboard-projects',
          parentId: 'dashboard'
        },
        {
          id: 'nft',
          label: 'NFT',
          link: '/themes/dashboard-nft',
          parentId: 'dashboard'
        },
        {
          id: 'job',
          label: 'Job',
          link: '/themes/dashboard-job',
          parentId: 'dashboard'
        }
      ]
    },
    {
      id: 'apps',
      label: 'Apps',
      icon: 'mdi mdi-view-grid-plus-outline',
      link: '/#',
      click: function (e: any) {
        e.preventDefault()
        setIsApps(!isApps)
        setIscurrentState('Apps')
        updateIconSidebar(e)
      },
      stateVariables: isApps,
      subItems: [
        {
          id: 'calendar',
          label: 'Calendar',
          link: '/#',
          parentId: 'apps',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setCalendar(!isCalendar)
          },
          stateVariables: isCalendar,
          childItems: [
            {
              id: 1,
              label: 'Main Calendar',
              link: '/themes/apps-calendar',
              parentId: 'apps'
            },
            {
              id: 2,
              label: 'Month Grid',
              link: '/themes/apps-calendar-month-grid',
              parentId: 'apps'
            }
          ]
        },
        {
          id: 'chat',
          label: 'Chat',
          link: '/themes/apps-chat',
          parentId: 'apps'
        },
        {
          id: 'mailbox',
          label: 'Email',
          link: '/#',
          parentId: 'apps',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setEmail(!isEmail)
          },
          stateVariables: isEmail,
          childItems: [
            {
              id: 1,
              label: 'Mailbox',
              link: '/themes/apps-mailbox',
              parentId: 'apps'
            },
            {
              id: 2,
              label: 'Email Templates',
              link: '/#',
              parentId: 'apps',
              isChildItem: true,
              stateVariables: isSubEmail,
              click: function (e: any) {
                e.preventDefault()
                setSubEmail(!isSubEmail)
              },
              childItems: [
                {
                  id: 2,
                  label: 'Basic Action',
                  link: '/themes/apps-email-basic',
                  parentId: 'apps'
                },
                {
                  id: 3,
                  label: 'Ecommerce Action',
                  link: '/themes/apps-email-ecommerce',
                  parentId: 'apps'
                }
              ]
            }
          ]
        },
        {
          id: 'appsecommerce',
          label: 'Ecommerce',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsEcommerce(!isEcommerce)
          },
          parentId: 'apps',
          stateVariables: isEcommerce,
          childItems: [
            {
              id: 1,
              label: 'Products',
              link: '/themes/apps-ecommerce-products',
              parentId: 'apps'
            },
            {
              id: 2,
              label: 'Product Details',
              link: '/themes/apps-ecommerce-product-details',
              parentId: 'apps'
            },
            {
              id: 3,
              label: 'Create Product',
              link: '/themes/apps-ecommerce-add-product',
              parentId: 'apps'
            },
            {
              id: 4,
              label: 'Orders',
              link: '/themes/apps-ecommerce-orders',
              parentId: 'apps'
            },
            {
              id: 5,
              label: 'Order Details',
              link: '/themes/apps-ecommerce-order-details',
              parentId: 'apps'
            },
            {
              id: 6,
              label: 'Customers',
              link: '/themes/apps-ecommerce-customers',
              parentId: 'apps'
            },
            {
              id: 7,
              label: 'Shopping Cart',
              link: '/themes/apps-ecommerce-cart',
              parentId: 'apps'
            },
            {
              id: 8,
              label: 'Checkout',
              link: '/themes/apps-ecommerce-checkout',
              parentId: 'apps'
            },
            {
              id: 9,
              label: 'Sellers',
              link: '/themes/apps-ecommerce-sellers',
              parentId: 'apps'
            },
            {
              id: 10,
              label: 'Seller Details',
              link: '/themes/apps-ecommerce-seller-details',
              parentId: 'apps'
            }
          ]
        },
        {
          id: 'appsprojects',
          label: 'Projects',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsProjects(!isProjects)
          },
          parentId: 'apps',
          stateVariables: isProjects,
          childItems: [
            {
              id: 1,
              label: 'List',
              link: '/themes/apps-projects-list',
              parentId: 'apps'
            },
            {
              id: 2,
              label: 'Overview',
              link: '/themes/apps-projects-overview',
              parentId: 'apps'
            },
            {
              id: 3,
              label: 'Create Project',
              link: '/themes/apps-projects-create',
              parentId: 'apps'
            }
          ]
        },
        {
          id: 'tasks',
          label: 'Tasks',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsTasks(!isTasks)
          },
          parentId: 'apps',
          stateVariables: isTasks,
          childItems: [
            {
              id: 1,
              label: 'Kanban Board',
              link: '/themes/apps-tasks-kanban',
              parentId: 'apps'
            },
            {
              id: 2,
              label: 'List View',
              link: '/themes/apps-tasks-list-view',
              parentId: 'apps'
            },
            {
              id: 3,
              label: 'Task Details',
              link: '/themes/apps-tasks-details',
              parentId: 'apps'
            }
          ]
        },
        {
          id: 'appscrm',
          label: 'CRM',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsCRM(!isCRM)
          },
          parentId: 'apps',
          stateVariables: isCRM,
          childItems: [
            { id: 1, label: 'Contacts', link: '/themes/apps-crm-contacts' },
            { id: 2, label: 'Companies', link: '/themes/apps-crm-companies' },
            { id: 3, label: 'Deals', link: '/themes/apps-crm-deals' },
            { id: 4, label: 'Leads', link: '/themes/apps-crm-leads' }
          ]
        },
        {
          id: 'appscrypto',
          label: 'Crypto',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsCrypto(!isCrypto)
          },
          parentId: 'apps',
          stateVariables: isCrypto,
          childItems: [
            { id: 1, label: 'Transactions', link: '/themes/apps-crypto-transactions' },
            { id: 2, label: 'Buy & Sell', link: '/themes/apps-crypto-buy-sell' },
            { id: 3, label: 'Orders', link: '/themes/apps-crypto-orders' },
            { id: 4, label: 'My Wallet', link: '/themes/apps-crypto-wallet' },
            { id: 5, label: 'ICO List', link: '/themes/apps-crypto-ico' },
            { id: 6, label: 'KYC Application', link: '/themes/apps-crypto-kyc' }
          ]
        },
        {
          id: 'invoices',
          label: 'Invoices',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsInvoices(!isInvoices)
          },
          parentId: 'apps',
          stateVariables: isInvoices,
          childItems: [
            { id: 1, label: 'List View', link: '/themes/apps-invoices-list' },
            { id: 2, label: 'Details', link: '/themes/apps-invoices-details' },
            { id: 3, label: 'Create Invoice', link: '/themes/apps-invoices-create' }
          ]
        },
        {
          id: 'supportTickets',
          label: 'Support Tickets',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsSupportTickets(!isSupportTickets)
          },
          parentId: 'apps',
          stateVariables: isSupportTickets,
          childItems: [
            { id: 1, label: 'List View', link: '/themes/apps-tickets-list' },
            { id: 2, label: 'Ticket Details', link: '/themes/apps-tickets-details' }
          ]
        },
        {
          id: 'NFTMarketplace',
          label: 'NFT Marketplace',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsNFTMarketplace(!isNFTMarketplace)
          },
          parentId: 'apps',
          stateVariables: isNFTMarketplace,
          childItems: [
            { id: 1, label: 'Marketplace', link: '/themes/apps-nft-marketplace' },
            { id: 2, label: 'Explore Now', link: '/themes/apps-nft-explore' },
            { id: 3, label: 'Live Auction', link: '/themes/apps-nft-auction' },
            { id: 4, label: 'Item Details', link: '/themes/apps-nft-item-details' },
            { id: 5, label: 'Collections', link: '/themes/apps-nft-collections' },
            { id: 6, label: 'Creators', link: '/themes/apps-nft-creators' },
            { id: 7, label: 'Ranking', link: '/themes/apps-nft-ranking' },
            { id: 8, label: 'Wallet Connect', link: '/themes/apps-nft-wallet' },
            { id: 9, label: 'Create NFT', link: '/themes/apps-nft-create' }
          ]
        },
        {
          id: 'filemanager',
          label: 'File Manager',
          link: '/themes/apps-file-manager',
          parentId: 'apps'
        },
        {
          id: 'todo',
          label: 'To Do',
          link: '/themes/apps-todo',
          parentId: 'apps'
        },
        {
          id: 'job',
          label: 'Jobs',
          link: '/#',
          parentId: 'apps',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsJobs(!isJobs)
          },
          stateVariables: isJobs,
          childItems: [
            {
              id: 1,
              label: 'Statistics',
              link: '/themes/apps-job-statistics',
              parentId: 'apps'
            },
            {
              id: 2,
              label: 'Job Lists',
              link: '/#',
              parentId: 'apps',
              isChildItem: true,
              stateVariables: isJobList,
              click: function (e: any) {
                e.preventDefault()
                setIsJobList(!isJobList)
              },
              childItems: [
                {
                  id: 1,
                  label: 'List',
                  link: '/themes/apps-job-lists',
                  parentId: 'apps'
                },
                {
                  id: 2,
                  label: 'Grid',
                  link: '/themes/apps-job-grid-lists',
                  parentId: 'apps'
                },
                {
                  id: 3,
                  label: 'Overview',
                  link: '/themes/apps-job-details',
                  parentId: 'apps'
                }
              ]
            },
            {
              id: 3,
              label: 'Candidate Lists',
              link: '/#',
              parentId: 'apps',
              isChildItem: true,
              stateVariables: isCandidateList,
              click: function (e: any) {
                e.preventDefault()
                setIsCandidateList(!isCandidateList)
              },
              childItems: [
                {
                  id: 1,
                  label: 'List View',
                  link: '/themes/apps-job-candidate-lists',
                  parentId: 'apps'
                },
                {
                  id: 2,
                  label: 'Grid View',
                  link: '/themes/apps-job-candidate-grid',
                  parentId: 'apps'
                }
              ]
            },
            {
              id: 4,
              label: 'Application',
              link: '/themes/apps-job-application',
              parentId: 'apps'
            },
            {
              id: 5,
              label: 'New Job',
              link: '/themes/apps-job-new',
              parentId: 'apps'
            },
            {
              id: 6,
              label: 'Companies List',
              link: '/themes/apps-job-companies-lists',
              parentId: 'apps'
            },
            {
              id: 7,
              label: 'Job Categories',
              link: '/themes/apps-job-categories',
              parentId: 'apps'
            }
          ]
        },
        {
          id: 'apiKey',
          label: 'API Key',
          link: '/themes/apps-api-key',
          parentId: 'apps'
        }
      ]
    },
    {
      label: 'pages',
      isHeader: true
    },
    {
      id: 'authentication',
      label: 'Authentication',
      icon: 'bx bx-user-circle',
      link: '/#',
      click: function (e: any) {
        e.preventDefault()
        setIsAuth(!isAuth)
        setIscurrentState('Auth')
        updateIconSidebar(e)
      },
      stateVariables: isAuth,
      subItems: [
        {
          id: 'signIn',
          label: 'Sign In',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsSignIn(!isSignIn)
          },
          parentId: 'authentication',
          stateVariables: isSignIn,
          childItems: [
            { id: 1, label: 'Basic', link: '/themes/auth-signin-basic' },
            { id: 2, label: 'Cover', link: '/themes/auth-signin-cover' }
          ]
        },
        {
          id: 'signUp',
          label: 'Sign Up',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsSignUp(!isSignUp)
          },
          parentId: 'authentication',
          stateVariables: isSignUp,
          childItems: [
            { id: 1, label: 'Basic', link: '/themes/auth-signup-basic' },
            { id: 2, label: 'Cover', link: '/themes/auth-signup-cover' }
          ]
        },
        {
          id: 'passwordReset',
          label: 'Password Reset',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsPasswordReset(!isPasswordReset)
          },
          parentId: 'authentication',
          stateVariables: isPasswordReset,
          childItems: [
            { id: 1, label: 'Basic', link: '/themes/auth-pass-reset-basic' },
            { id: 2, label: 'Cover', link: '/themes/auth-pass-reset-cover' }
          ]
        },
        {
          id: 'passwordCreate',
          label: 'Password Create',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsPasswordCreate(!isPasswordCreate)
          },
          parentId: 'authentication',
          stateVariables: isPasswordCreate,
          childItems: [
            { id: 1, label: 'Basic', link: '/themes/auth-pass-change-basic' },
            { id: 2, label: 'Cover', link: '/themes/auth-pass-change-cover' }
          ]
        },
        {
          id: 'lockScreen',
          label: 'Lock Screen',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsLockScreen(!isLockScreen)
          },
          parentId: 'authentication',
          stateVariables: isLockScreen,
          childItems: [
            { id: 1, label: 'Basic', link: '/themes/auth-lockscreen-basic' },
            { id: 2, label: 'Cover', link: '/themes/auth-lockscreen-cover' }
          ]
        },
        {
          id: 'logout',
          label: 'Logout',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsLogout(!isLogout)
          },
          parentId: 'authentication',
          stateVariables: isLogout,
          childItems: [
            { id: 1, label: 'Basic', link: '/themes/auth-logout-basic' },
            { id: 2, label: 'Cover', link: '/themes/auth-logout-cover' }
          ]
        },
        {
          id: 'successMessage',
          label: 'Success Message',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsSuccessMessage(!isSuccessMessage)
          },
          parentId: 'authentication',
          stateVariables: isSuccessMessage,
          childItems: [
            { id: 1, label: 'Basic', link: '/themes/auth-success-msg-basic' },
            { id: 2, label: 'Cover', link: '/themes/auth-success-msg-cover' }
          ]
        },
        {
          id: 'twoStepVerification',
          label: 'Two Step Verification',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsVerification(!isVerification)
          },
          parentId: 'authentication',
          stateVariables: isVerification,
          childItems: [
            { id: 1, label: 'Basic', link: '/themes/auth-twostep-basic' },
            { id: 2, label: 'Cover', link: '/themes/auth-twostep-cover' }
          ]
        },
        {
          id: 'errors',
          label: 'Errors',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsError(!isError)
          },
          parentId: 'authentication',
          stateVariables: isError,
          childItems: [
            { id: 1, label: '404 Basic', link: '/themes/auth-404-basic' },
            { id: 2, label: '404 Cover', link: '/themes/auth-404-cover' },
            { id: 3, label: '404 Alt', link: '/themes/auth-404-alt' },
            { id: 4, label: '500', link: '/themes/auth-500' },
            { id: 5, label: 'Offline Page', link: '/themes/auth-offline' }
          ]
        }
      ]
    },
    {
      id: 'pages',
      label: 'Pages',
      icon: 'bx bx-file',
      link: '/#',
      click: function (e: any) {
        e.preventDefault()
        setIsPages(!isPages)
        setIscurrentState('Pages')
        updateIconSidebar(e)
      },
      stateVariables: isPages,
      subItems: [
        {
          id: 'starter',
          label: 'Starter',
          link: '/themes/pages-starter',
          parentId: 'pages'
        },
        {
          id: 'profile',
          label: 'Profile',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsProfile(!isProfile)
          },
          parentId: 'pages',
          stateVariables: isProfile,
          childItems: [
            {
              id: 1,
              label: 'Simple Page',
              link: '/themes/pages-profile',
              parentId: 'pages'
            },
            {
              id: 2,
              label: 'Settings',
              link: '/themes/pages-profile-settings',
              parentId: 'pages'
            }
          ]
        },
        { id: 'team', label: 'Team', link: '/themes/pages-team', parentId: 'pages' },
        {
          id: 'timeline',
          label: 'Timeline',
          link: '/themes/pages-timeline',
          parentId: 'pages'
        },
        { id: 'faqs', label: 'FAQs', link: '/themes/pages-faqs', parentId: 'pages' },
        {
          id: 'pricing',
          label: 'Pricing',
          link: '/themes/pages-pricing',
          parentId: 'pages'
        },
        {
          id: 'gallery',
          label: 'Gallery',
          link: '/themes/pages-gallery',
          parentId: 'pages'
        },
        {
          id: 'maintenance',
          label: 'Maintenance',
          link: '/themes/pages-maintenance',
          parentId: 'pages'
        },
        {
          id: 'comingSoon',
          label: 'Coming Soon',
          link: '/themes/pages-coming-soon',
          parentId: 'pages'
        },
        {
          id: 'sitemap',
          label: 'Sitemap',
          link: '/themes/pages-sitemap',
          parentId: 'pages'
        },
        {
          id: 'searchResults',
          label: 'Search Results',
          link: '/themes/pages-search-results',
          parentId: 'pages'
        },
        {
          id: 'privacyPolicy',
          label: 'Privacy Policy',
          link: '/themes/pages-privacy-policy'
        },
        {
          id: 'termsConditions',
          label: 'Term & Conditions',
          link: '/themes/pages-term-conditions'
        }
      ]
    },
    {
      id: 'landing',
      label: 'Landing',
      icon: 'ri-rocket-line',
      link: '/#',
      stateVariables: isLanding,
      click: function (e: any) {
        e.preventDefault()
        setIsLanding(!isLanding)
        setIscurrentState('Landing')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'onePage',
          label: 'One Page',
          link: '/themes/landing',
          parentId: 'landing'
        },
        {
          id: 'nftLanding',
          label: 'NFT Landing',
          link: '/themes/nft-landing',
          parentId: 'landing'
        },
        {
          id: 'job',
          label: 'Jobs',
          link: '/themes/job-landing',
          parentId: 'landing'
        }
      ]
    },
    {
      label: 'Components',
      isHeader: true
    },
    {
      id: 'baseUi',
      label: 'Base UI',
      icon: 'mdi mdi-cube-outline',
      link: '/#',
      click: function (e: any) {
        e.preventDefault()
        setIsBaseUi(!isBaseUi)
        setIscurrentState('BaseUi')
        updateIconSidebar(e)
      },
      stateVariables: isBaseUi,
      subItems: [
        {
          id: 'alerts',
          label: 'Alerts',
          link: '/themes/ui-alerts',
          parentId: 'baseUi'
        },
        {
          id: 'badges',
          label: 'Badges',
          link: '/themes/ui-badges',
          parentId: 'baseUi'
        },
        {
          id: 'buttons',
          label: 'Buttons',
          link: '/themes/ui-buttons',
          parentId: 'baseUi'
        },
        {
          id: 'colors',
          label: 'Colors',
          link: '/themes/ui-colors',
          parentId: 'baseUi'
        },
        { id: 'cards', label: 'Cards', link: '/themes/ui-cards', parentId: 'baseUi' },
        {
          id: 'carousel',
          label: 'Carousel',
          link: '/themes/ui-carousel',
          parentId: 'baseUi'
        },
        {
          id: 'dropdowns',
          label: 'Dropdowns',
          link: '/themes/ui-dropdowns',
          parentId: 'baseUi'
        },
        { id: 'grid', label: 'Grid', link: '/themes/ui-grid', parentId: 'baseUi' },
        {
          id: 'images',
          label: 'Images',
          link: '/themes/ui-images',
          parentId: 'baseUi'
        },
        { id: 'tabs', label: 'Tabs', link: '/themes/ui-tabs', parentId: 'baseUi' },
        {
          id: 'accordions',
          label: 'Accordion & Collapse',
          link: '/themes/ui-accordions',
          parentId: 'baseUi'
        },
        {
          id: 'modals',
          label: 'Modals',
          link: '/themes/ui-modals',
          parentId: 'baseUi'
        },
        {
          id: 'offcanvas',
          label: 'Offcanvas',
          link: '/themes/ui-offcanvas',
          parentId: 'baseUi'
        },
        {
          id: 'placeholders',
          label: 'Placeholders',
          link: '/themes/ui-placeholders',
          parentId: 'baseUi'
        },
        {
          id: 'progress',
          label: 'Progress',
          link: '/themes/ui-progress',
          parentId: 'baseUi'
        },
        {
          id: 'notifications',
          label: 'Notifications',
          link: '/themes/ui-notifications',
          parentId: 'baseUi'
        },
        {
          id: 'media',
          label: 'Media object',
          link: '/themes/ui-media',
          parentId: 'baseUi'
        },
        {
          id: 'embedvideo',
          label: 'Embed Video',
          link: '/themes/ui-embed-video',
          parentId: 'baseUi'
        },
        {
          id: 'typography',
          label: 'Typography',
          link: '/themes/ui-typography',
          parentId: 'baseUi'
        },
        { id: 'lists', label: 'Lists', link: '/themes/ui-lists', parentId: 'baseUi' },
        {
          id: 'links',
          label: 'Links',
          link: '/themes/ui-links',
          parentId: 'baseUi',
          badgeName: 'New',
          badgeColor: 'success'
        },
        {
          id: 'general',
          label: 'General',
          link: '/themes/ui-general',
          parentId: 'baseUi'
        },
        {
          id: 'ribbons',
          label: 'Ribbons',
          link: '/themes/ui-ribbons',
          parentId: 'baseUi'
        },
        {
          id: 'utilities',
          label: 'Utilities',
          link: '/themes/ui-utilities',
          parentId: 'baseUi'
        }
      ]
    },
    {
      id: 'advanceUi',
      label: 'Advance UI',
      icon: 'mdi mdi-layers-triple-outline',
      link: '/#',
      click: function (e: any) {
        e.preventDefault()
        setIsAdvanceUi(!isAdvanceUi)
        setIscurrentState('AdvanceUi')
        updateIconSidebar(e)
      },
      stateVariables: isAdvanceUi,
      subItems: [
        {
          id: 'scrollbar',
          label: 'Scrollbar',
          link: '/themes/advance-ui-scrollbar',
          parentId: 'advanceUi'
        },
        {
          id: 'animation',
          label: 'Animation',
          link: '/themes/advance-ui-animation',
          parentId: 'advanceUi'
        },
        {
          id: 'swiperslider',
          label: 'Swiper Slider',
          link: '/themes/advance-ui-swiper',
          parentId: 'advanceUi'
        },
        {
          id: 'ratings',
          label: 'Ratings',
          link: '/themes/advance-ui-ratings',
          parentId: 'advanceUi'
        },
        {
          id: 'highlight',
          label: 'Highlight',
          link: '/themes/advance-ui-highlight',
          parentId: 'advanceUi'
        }
      ]
    },
    {
      id: 'widgets',
      label: 'Widgets',
      icon: 'mdi mdi-puzzle-outline',
      link: '/themes/widgets',
      click: function (e: any) {
        e.preventDefault()
        setIscurrentState('Widgets')
      }
    },
    {
      id: 'forms',
      label: 'Forms',
      icon: 'mdi mdi-form-select',
      link: '/#',
      click: function (e: any) {
        e.preventDefault()
        setIsForms(!isForms)
        setIscurrentState('Forms')
        updateIconSidebar(e)
      },
      stateVariables: isForms,
      subItems: [
        {
          id: 'basicelements',
          label: 'Basic Elements',
          link: '/themes/forms-elements',
          parentId: 'forms'
        },
        {
          id: 'formselect',
          label: 'Form Select',
          link: '/themes/forms-select',
          parentId: 'forms'
        },
        {
          id: 'checkboxsradios',
          label: 'Checkboxs & Radios',
          link: '/themes/forms-checkboxes-radios',
          parentId: 'forms'
        },
        {
          id: 'pickers',
          label: 'Pickers',
          link: '/themes/forms-pickers',
          parentId: 'forms'
        },
        {
          id: 'inputmasks',
          label: 'Input Masks',
          link: '/themes/forms-masks',
          parentId: 'forms'
        },
        {
          id: 'advanced',
          label: 'Advanced',
          link: '/themes/forms-advanced',
          parentId: 'forms'
        },
        {
          id: 'rangeslider',
          label: 'Range Slider',
          link: '/themes/forms-range-sliders',
          parentId: 'forms'
        },
        {
          id: 'validation',
          label: 'Validation',
          link: '/themes/forms-validation',
          parentId: 'forms'
        },
        {
          id: 'wizard',
          label: 'Wizard',
          link: '/themes/forms-wizard',
          parentId: 'forms'
        },
        {
          id: 'editors',
          label: 'Editors',
          link: '/themes/forms-editors',
          parentId: 'forms'
        },
        {
          id: 'fileuploads',
          label: 'File Uploads',
          link: '/themes/forms-file-uploads',
          parentId: 'forms'
        },
        {
          id: 'formlayouts',
          label: 'Form Layouts',
          link: '/themes/forms-layouts',
          parentId: 'forms'
        },
        {
          id: 'select2',
          label: 'Select2',
          link: '/themes/forms-select2',
          parentId: 'forms'
        }
      ]
    },
    {
      id: 'tables',
      label: 'Tables',
      icon: 'mdi mdi-grid-large',
      link: '/#',
      click: function (e: any) {
        e.preventDefault()
        setIsTables(!isTables)
        setIscurrentState('Tables')
        updateIconSidebar(e)
      },
      stateVariables: isTables,
      subItems: [
        {
          id: 'basictables',
          label: 'Basic Tables',
          link: '/themes/tables-basic',
          parentId: 'tables'
        },
        {
          id: 'reactdatatables',
          label: 'React Datatables',
          link: '/themes/tables-react',
          parentId: 'tables'
        }
      ]
    },
    {
      id: 'charts',
      label: 'Charts',
      icon: 'mdi mdi-chart-donut',
      link: '/#',
      click: function (e: any) {
        e.preventDefault()
        setIsCharts(!isCharts)
        setIscurrentState('Charts')
        updateIconSidebar(e)
      },
      stateVariables: isCharts,
      subItems: [
        {
          id: 'apexcharts',
          label: 'Apexcharts',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsApex(!isApex)
          },
          stateVariables: isApex,
          childItems: [
            { id: 1, label: 'Line', link: '/themes/charts-apex-line' },
            { id: 2, label: 'Area', link: '/themes/charts-apex-area' },
            { id: 3, label: 'Column', link: '/themes/charts-apex-column' },
            { id: 4, label: 'Bar', link: '/themes/charts-apex-bar' },
            { id: 5, label: 'Mixed', link: '/themes/charts-apex-mixed' },
            { id: 6, label: 'Timeline', link: '/themes/charts-apex-timeline' },
            {
              id: 7,
              label: 'Range Area',
              link: '/themes/charts-apex-range-area',
              badgeName: 'New',
              badgeColor: 'success'
            },
            {
              id: 8,
              label: 'Funnel',
              link: '/themes/charts-apex-funnel',
              badgeName: 'New',
              badgeColor: 'success'
            },
            { id: 9, label: 'Candlstick', link: '/themes/charts-apex-candlestick' },
            { id: 10, label: 'Boxplot', link: '/themes/charts-apex-boxplot' },
            { id: 11, label: 'Bubble', link: '/themes/charts-apex-bubble' },
            { id: 12, label: 'Scatter', link: '/themes/charts-apex-scatter' },
            { id: 13, label: 'Heatmap', link: '/themes/charts-apex-heatmap' },
            { id: 14, label: 'Treemap', link: '/themes/charts-apex-treemap' },
            { id: 15, label: 'Pie', link: '/themes/charts-apex-pie' },
            { id: 16, label: 'Radialbar', link: '/themes/charts-apex-radialbar' },
            { id: 17, label: 'Radar', link: '/themes/charts-apex-radar' },
            { id: 18, label: 'Polar Area', link: '/themes/charts-apex-polar' }
          ]
        },
        {
          id: 'chartjs',
          label: 'Chartjs',
          link: '/themes/charts-chartjs',
          parentId: 'charts'
        },
        {
          id: 'echarts',
          label: 'Echarts',
          link: '/themes/charts-echarts',
          parentId: 'charts'
        }
      ]
    },
    {
      id: 'icons',
      label: 'Icons',
      icon: 'mdi mdi-android-studio',
      link: '/#',
      click: function (e: any) {
        e.preventDefault()
        setIsIcons(!isIcons)
        setIscurrentState('Icons')
        updateIconSidebar(e)
      },
      stateVariables: isIcons,
      subItems: [
        {
          id: 'remix',
          label: 'Remix',
          link: '/themes/icons-remix',
          parentId: 'icons'
        },
        {
          id: 'boxicons',
          label: 'Boxicons',
          link: '/themes/icons-boxicons',
          parentId: 'icons'
        },
        {
          id: 'materialdesign',
          label: 'Material Design',
          link: '/themes/icons-materialdesign',
          parentId: 'icons'
        },
        {
          id: 'lineawesome',
          label: 'Line Awesome',
          link: '/themes/icons-lineawesome',
          parentId: 'icons'
        },
        {
          id: 'feather',
          label: 'Feather',
          link: '/themes/icons-feather',
          parentId: 'icons'
        },
        {
          id: 'crypto',
          label: 'Crypto SVG',
          link: '/themes/icons-crypto',
          parentId: 'icons'
        }
      ]
    },
    {
      id: 'maps',
      label: 'Maps',
      icon: 'mdi mdi-map-marker-outline',
      link: '/#',
      click: function (e: any) {
        e.preventDefault()
        setIsMaps(!isMaps)
        setIscurrentState('Maps')
        updateIconSidebar(e)
      },
      stateVariables: isMaps,
      subItems: [
        {
          id: 'google',
          label: 'Google',
          link: '/themes/maps-google',
          parentId: 'maps'
        }
      ]
    },
    {
      id: 'multilevel',
      label: 'Multi Level',
      icon: 'mdi mdi-share-variant-outline',
      link: '/#',
      click: function (e: any) {
        e.preventDefault()
        setIsMultiLevel(!isMultiLevel)
        setIscurrentState('MuliLevel')
        updateIconSidebar(e)
      },
      stateVariables: isMultiLevel,
      subItems: [
        {
          id: 'level1.1',
          label: 'Level 1.1',
          link: '/#',
          parentId: 'multilevel'
        },
        {
          id: 'level1.2',
          label: 'Level 1.2',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsLevel1(!isLevel1)
          },
          stateVariables: isLevel1,
          childItems: [
            { id: 1, label: 'Level 2.1', link: '/#' },
            {
              id: 'level2.2',
              label: 'Level 2.2',
              link: '/#',
              isChildItem: true,
              click: function (e: any) {
                e.preventDefault()
                setIsLevel2(!isLevel2)
              },
              stateVariables: isLevel2,
              childItems: [
                { id: 1, label: 'Level 3.1', link: '/#' },
                { id: 2, label: 'Level 3.2', link: '/#' }
              ]
            }
          ]
        }
      ]
    }
  ]

  return <React.Fragment>{menuItems}</React.Fragment>
}
export default Navdata
