import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Footer = () => {
  return (
    <React.Fragment>
      <footer className='footer'></footer>
    </React.Fragment>
  )
}

export default Footer
