import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import config from '../config'

const { api } = config

// default
axios.defaults.baseURL = api.API_URL
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json'

// content type
const token: any = sessionStorage.getItem('jwtToken')

if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message
    switch (error.response?.status) {
      case 500:
        message = error.response?.data?.data ?? 'Internal Server Error'
        break
      case 401:
        message = 'Invalid credentials'
        break
      case 404:
        message = 'Sorry! the data you are looking for could not be found'
        break
      default:
        message = error.message || error
    }
    return Promise.reject(message)
  }
)
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export interface AxiosRes extends AxiosResponse {
  statusCode: number | string
  success: string | boolean | undefined
  message: string
  data: any
  totalPages: number
}

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url: any, params: any): Promise<AxiosRes> => {
    let response
    let paramKeys: any = []
    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + '=' + params[key])
        return paramKeys
      })

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : ''
      return axios.get(`${url}?${queryString}`, params)
    } else {
      return axios.get(`${url}`, params)
    }
  }
  /**
   * post given data to url
   */
  create = (url: any, data: any): Promise<AxiosRes> => {
    return axios.post(url, data)
  }
  /**
   * Updates data
   */
  update = (url: any, data: any): Promise<AxiosRes> => {
    return axios.patch(url, data)
  }

  put = (url: any, data: any): Promise<AxiosRes> => {
    return axios.put(url, data)
  }
  /**
   * Delete
   */
  delete = (url: string, config?: AxiosRequestConfig): Promise<AxiosRes> => {
    return axios.delete(url, { ...config })
  }
}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem('authUser')
  if (!user) {
    return null
  } else {
    return JSON.parse(user)
  }
}

export { APIClient, setAuthorization, getLoggedinUser }
