const apiKey = [
  {
    id: '1',
    name: 'Streamlab',
    createBy: 'Michael Morris',
    apikey: 'fef67078-6fb6-4689-b342-6ddc24481728',
    status: 'Disable',
    color: 'danger',
    create_date: '24 Sep 2022',
    expiry_date: '24 Jan 2023'
  },
  {
    id: '2',
    name: 'Streamlab',
    createBy: 'Zynthia Marrow',
    apikey: 'ed4c0d11-7d49-4c94-aae8-83fafb226ee9',
    status: 'Active',
    color: 'success',
    create_date: '24 Sep 2022',
    expiry_date: '24 Jan 2023'
  },
  {
    id: '3',
    name: 'Streamlab',
    createBy: 'Philippa Santiago',
    apikey: '0b53e8e2-e53d-4067-8be0-9cddea19e45e',
    status: 'Active',
    color: 'success',
    create_date: '24 Sep 2022',
    expiry_date: '24 Jan 2023'
  },
  {
    id: '4',
    name: 'Streamlab',
    createBy: 'Elizabeth Allen',
    apikey: 'b69ee258-1053-4e08-adbd-d37837f9c558',
    status: 'Active',
    color: 'success',
    create_date: '24 Sep 2022',
    expiry_date: '24 Jan 2023'
  },
  {
    id: '5',
    name: 'Streamlab',
    createBy: 'Cassian Jenning',
    apikey: '33ec3a35-8b44-48f3-ba68-f3ea1e9ef214',
    status: 'Active',
    color: 'success',
    create_date: '24 Sep 2022',
    expiry_date: '24 Jan 2023'
  },
  {
    id: '6',
    name: 'Streamlab',
    createBy: 'Harley Watkins',
    apikey: '84540348-f97d-41de-87c6-f5eae32aecc5',
    status: 'Disable',
    color: 'danger',
    create_date: '24 Sep 2022',
    expiry_date: '24 Jan 2023'
  },
  {
    id: '7',
    name: 'Streamlab',
    createBy: 'Addison Black',
    apikey: 'aecc1ede-f613-48d5-8140-7108b324f0bd',
    status: 'Active',
    color: 'success',
    create_date: '24 Sep 2022',
    expiry_date: '24 Jan 2023'
  },
  {
    id: '8',
    name: 'Streamlab',
    createBy: 'Nicki Butler',
    apikey: '8abba6e5-9622-46d2-8c52-c937e1d20ba2',
    status: 'Active',
    color: 'success',
    create_date: '24 Sep 2022',
    expiry_date: '24 Jan 2023'
  },
  {
    id: '9',
    name: 'Streamlab',
    createBy: 'Tara Hawkins',
    apikey: '9e6d336a-2f98-43e9-9fa6-11b4d5cdaf62',
    status: 'Disable',
    color: 'danger',
    create_date: '24 Sep 2022',
    expiry_date: '24 Jan 2023'
  }
]

export { apiKey }
