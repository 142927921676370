const ticketsWidgets = [
  {
    id: 1,
    title: 'Total Tickets',
    counter: '547',
    arrowIcon: 'ri-arrow-up-line',
    percentage: '17.32 %',
    percentageClass: 'success',
    icon: 'ri-ticket-2-line',
    decimals: 0,
    prefix: '',
    suffix: 'k'
  },
  {
    id: 2,
    title: 'Pending Tickets',
    counter: '124',
    arrowIcon: 'ri-arrow-down-line',
    percentage: '0.96 %',
    percentageClass: 'danger',
    icon: 'mdi mdi-timer-sand',
    decimals: 0,
    prefix: '',
    suffix: 'k'
  },
  {
    id: 3,
    title: 'Closed Tickets',
    counter: '107',
    arrowIcon: 'ri-arrow-down-line',
    percentage: '3.87 %',
    percentageClass: 'danger',
    icon: 'ri-shopping-bag-line',
    decimals: 0,
    prefix: '',
    suffix: 'K'
  },
  {
    id: 4,
    title: 'Deleted Tickets',
    counter: '15.95',
    arrowIcon: 'ri-arrow-up-line',
    percentage: '1.09 %',
    percentageClass: 'success',
    icon: 'ri-delete-bin-line',
    decimals: 2,
    prefix: '',
    suffix: '%'
  }
]

const ticketsTable = [
  {
    id: 1,
    ticketId: '#VLZ452',
    title: 'Error message when placing an orders?',
    client: 'Tonya Noble',
    assigned: 'James Morris',
    createDate: '08 Dec, 2021',
    dueDate: '25 Jan, 2022',
    status: 'Inprogress',
    statusClass: 'warning',
    priority: 'High',
    priorityClass: 'danger'
  },
  {
    id: 2,
    ticketId: '#VLZ453',
    title: 'Issue with finding information about order ?',
    client: 'Mary Rucker',
    assigned: 'David Nichols',
    createDate: '24 Oct, 2021',
    dueDate: '20 Dec, 2021',
    status: 'New',
    statusClass: 'info',
    priority: 'Low',
    priorityClass: 'success'
  },
  {
    id: 3,
    ticketId: '#VLZ454',
    title: 'Apologize for shopping Error!',
    client: 'Nathan Cole',
    assigned: 'Nancy Martino',
    createDate: '17 Oct, 2021',
    dueDate: '23 Oct, 2021',
    status: 'Open',
    statusClass: 'success',
    priority: 'Medium',
    priorityClass: 'warning'
  },
  {
    id: 4,
    ticketId: '#VLZ455',
    title: 'Post launch reminder/ post list',
    client: 'Joseph Parker',
    assigned: 'Alexis Clarke',
    createDate: '03 Oct, 2021',
    dueDate: '05 Oct, 2021',
    status: 'Closed',
    statusClass: 'danger',
    priority: 'High',
    priorityClass: 'danger'
  },
  {
    id: 5,
    ticketId: '#VLZ456',
    title: 'Make a creating an account profile',
    client: 'Henry Baird',
    assigned: 'David Nichols',
    createDate: '09 Oct, 2021',
    dueDate: '17 Oct, 2021',
    status: 'Inprogress',
    statusClass: 'warning',
    priority: 'Medium',
    priorityClass: 'warning'
  },
  {
    id: 6,
    ticketId: '#VLZ457',
    title: 'Change email option process',
    client: 'Tonya Noble',
    assigned: 'Nancy Martino',
    createDate: '27 Oct, 2021',
    dueDate: '04 Dec, 2021',
    status: 'Open',
    statusClass: 'success',
    priority: 'High',
    priorityClass: 'danger'
  },
  {
    id: 7,
    ticketId: '#VLZ458',
    title: 'User research',
    client: 'Donald Palmer',
    assigned: 'James Morris',
    createDate: '05 Oct, 2021',
    dueDate: '11 Oct, 2021',
    status: 'New',
    statusClass: 'info',
    priority: 'Low',
    priorityClass: 'success'
  },
  {
    id: 8,
    ticketId: '#VLZ459',
    title: 'Benner design for FB & Twitter',
    client: 'Mary Rucker',
    assigned: 'Jennifer Carter',
    createDate: '09 Dec, 2021',
    dueDate: '16 Dec, 2021',
    status: 'Closed',
    statusClass: 'danger',
    priority: 'Medium',
    priorityClass: 'warning'
  },
  {
    id: 9,
    ticketId: '#VLZ460',
    title: 'Brand logo design',
    client: 'Tonya Noble',
    assigned: 'Alexis Clarke',
    createDate: '24 Dec, 2021',
    dueDate: '29 Dec, 2021',
    status: 'Closed',
    statusClass: 'danger',
    priority: 'High',
    priorityClass: 'danger'
  },
  {
    id: 10,
    ticketId: '#VLZ461',
    title: 'Additional Calendar',
    client: 'Diana Kohler',
    assigned: 'David Nichols',
    createDate: '04 Oct, 2021',
    dueDate: '13 Oct, 2021',
    status: 'New',
    statusClass: 'info',
    priority: 'Low',
    priorityClass: 'success'
  },
  {
    id: 11,
    ticketId: '#VLZ462',
    title: 'Edit customer testimonial',
    client: 'Nathan Cole',
    assigned: 'Nancy Martino',
    createDate: '21 Dec, 2021',
    dueDate: '02 Jan, 2021',
    status: 'Inprogress',
    statusClass: 'warning',
    priority: 'Medium',
    priorityClass: 'warning'
  }
]

export { ticketsWidgets, ticketsTable }
