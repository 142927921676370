import PrismCode from '../../../Components/Common/Prism'

// Fade Up

const fadeUpCode = `
<div data-aos="fade-up"></div>
`

const FadeUpExample = () => (
  <PrismCode code={fadeUpCode} language={'html'} plugins={['line-numbers']} />
)

// Fade Down
const fadeDownCode = `
<div data-aos="fade-down"></div>
`

const FadeDownExample = () => (
  <PrismCode code={fadeDownCode} language={'html'} plugins={['line-numbers']} />
)

// Fade Right
const fadeRightCode = `
<div data-aos="fade-right"></div>
`

const FadeRightExample = () => (
  <PrismCode code={fadeRightCode} language={'html'} plugins={['line-numbers']} />
)

// Fade Left
const fadeLeftCode = `
<div data-aos="fade-left"></div>
`

const FadeLeftExample = () => (
  <PrismCode code={fadeLeftCode} language={'html'} plugins={['line-numbers']} />
)

// Fade Up Right
const fadeUpRightCode = `
<div data-aos="fade-up-right"></div>
`

const FadeUpRightExample = () => (
  <PrismCode code={fadeUpRightCode} language={'html'} plugins={['line-numbers']} />
)

// Fade Up Left
const fadeUpLeftCode = `
<div data-aos="fade-up-left"></div>
`

const FadeUpLeftExample = () => (
  <PrismCode code={fadeUpLeftCode} language={'html'} plugins={['line-numbers']} />
)

// Fade Down Right
const fadeDownRightCode = `
<div data-aos="fade-down-right"></div>
`

const FadeDownRightExample = () => (
  <PrismCode code={fadeDownRightCode} language={'html'} plugins={['line-numbers']} />
)

// Fade Down Left
const fadeDownLeftCode = `
<div data-aos="fade-down-left"></div>
`

const FadeDownLeftExample = () => (
  <PrismCode code={fadeDownLeftCode} language={'html'} plugins={['line-numbers']} />
)

// Flip Left
const flipLeftCode = `
<div data-aos="flip-left"></div>
`

const FlipLeftExample = () => (
  <PrismCode code={flipLeftCode} language={'html'} plugins={['line-numbers']} />
)

// Flip Right
const flipRightCode = `
<div data-aos="flip-right"></div>
`

const FlipRightExample = () => (
  <PrismCode code={flipRightCode} language={'html'} plugins={['line-numbers']} />
)

// Flip Up
const filpUpCode = `
<div data-aos="flip-up"></div>
`

const FilpUpExample = () => (
  <PrismCode code={filpUpCode} language={'html'} plugins={['line-numbers']} />
)

// Flip Down
const filpDownCode = `
<div data-aos="flip-down"></div>
`

const FilpDownExample = () => (
  <PrismCode code={filpDownCode} language={'html'} plugins={['line-numbers']} />
)
// Zoom In
const zoomInCode = `
<div data-aos="zoom-in"></div>
`

const ZoomInExample = () => (
  <PrismCode code={zoomInCode} language={'html'} plugins={['line-numbers']} />
)
// Zoom Out
const zoomOutCode = `
<div data-aos="zoom-out"></div>
`

const ZoomOutExample = () => (
  <PrismCode code={zoomOutCode} language={'html'} plugins={['line-numbers']} />
)

// Zoom In Up
const zoomInUpCode = `
<div data-aos="zoom-in-up"></div>
`

const ZoomInUpExample = () => (
  <PrismCode code={zoomInUpCode} language={'html'} plugins={['line-numbers']} />
)

// Zoom Out Up
const zoomOutUpCode = `
<div data-aos="zoom-out-up"></div>
`

const ZoomOutUpExample = () => (
  <PrismCode code={zoomOutUpCode} language={'html'} plugins={['line-numbers']} />
)

// Zoom In Down
const zoomInDownCode = `
<div data-aos="zoom-in-down"></div>
`

const ZoomInDownExample = () => (
  <PrismCode code={zoomInDownCode} language={'html'} plugins={['line-numbers']} />
)

// Zoom Out Down
const zoomOutDownCode = `
<div data-aos="zoom-out-down"></div>
`

const ZoomOutDownExample = () => (
  <PrismCode code={zoomOutDownCode} language={'html'} plugins={['line-numbers']} />
)

// Zoom In Right
const zoomInRightCode = `
<div data-aos="zoom-in-right"></div>
`

const ZoomInRightExample = () => (
  <PrismCode code={zoomInRightCode} language={'html'} plugins={['line-numbers']} />
)

// Zoom Out Right
const zoomOutRightCode = `
<div data-aos="zoom-out-right"></div>
`

const ZoomOutRightExample = () => (
  <PrismCode code={zoomOutRightCode} language={'html'} plugins={['line-numbers']} />
)

// Zoom In Left
const zoomInLeftCode = `
<div data-aos="zoom-in-left"></div>
`

const ZoomInLeftExample = () => (
  <PrismCode code={zoomInLeftCode} language={'html'} plugins={['line-numbers']} />
)

// Zoom Out Left
const zoomOutLeftCode = `
<div data-aos="zoom-out-left"></div>
`

const ZoomOutLeftExample = () => (
  <PrismCode code={zoomOutLeftCode} language={'html'} plugins={['line-numbers']} />
)

// Duration
const durationCode = `
<div data-aos="fade-up" data-aos-duration="3000"></div>
`

const DurationExample = () => (
  <PrismCode code={durationCode} language={'html'} plugins={['line-numbers']} />
)

// Easing="linear"
const easingLinearCode = `
<div data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500"></div>
`

const EasingLinearExample = () => (
  <PrismCode code={easingLinearCode} language={'html'} plugins={['line-numbers']} />
)

// Offset
const offset1Code = `
<div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"></div>
`

const Offset1Example = () => (
  <PrismCode code={offset1Code} language={'html'} plugins={['line-numbers']} />
)

// Offset
const offset2Code = `
<div data-aos="fade-left" data-aos-offset="500" data-aos-duration="500"></div>
`

const Offset2Example = () => (
  <PrismCode code={offset2Code} language={'html'} plugins={['line-numbers']} />
)

// Easing="ease-in-back"
const easeInBackCode = `
<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0"></div>
`

const EaseInBackExample = () => (
  <PrismCode code={easeInBackCode} language={'html'} plugins={['line-numbers']} />
)

// Easing="ease-out-cubic"
const easeOutCubicCode = `
<div data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"></div>
`

const EaseOutCubicExample = () => (
  <PrismCode code={easeOutCubicCode} language={'html'} plugins={['line-numbers']} />
)

export {
  FadeUpExample,
  FadeDownExample,
  FadeRightExample,
  FadeLeftExample,
  FadeUpRightExample,
  FadeUpLeftExample,
  FadeDownRightExample,
  FadeDownLeftExample,
  FlipLeftExample,
  FlipRightExample,
  FilpUpExample,
  FilpDownExample,
  ZoomInExample,
  ZoomOutExample,
  ZoomInUpExample,
  ZoomOutUpExample,
  ZoomInDownExample,
  ZoomOutDownExample,
  ZoomInRightExample,
  ZoomOutRightExample,
  ZoomInLeftExample,
  ZoomOutLeftExample,
  DurationExample,
  EasingLinearExample,
  Offset1Example,
  Offset2Example,
  EaseInBackExample,
  EaseOutCubicExample
}
