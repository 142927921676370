const recentFile = [
  {
    id: 1,
    icon: 'ri-gallery-fill',
    iconClass: 'success',
    fileName: 'logo-dark.png',
    fileType: 'Media',
    fileItem: '01',
    size: '1.3 MB',
    createDate: '24 May, 2022'
  },
  {
    id: 2,
    icon: 'ri-file-pdf-fill',
    iconClass: 'danger',
    fileName: 'velzon-invoice.pdf',
    fileType: 'Documents',
    fileItem: '01',
    size: '1.1 MB',
    createDate: '05 May, 2022'
  },
  {
    id: 3,
    icon: 'ri-folder-2-fill',
    iconClass: 'warning',
    fileName: 'Velzon React',
    fileType: 'Media',
    fileItem: '367',
    size: '934 MB',
    createDate: '28 Apr, 2022'
  },
  {
    id: 4,
    icon: 'ri-file-text-fill',
    iconClass: 'secondary',
    fileName: 'html.docx',
    fileType: 'Documents',
    fileItem: '01',
    size: '0.3 KB',
    createDate: '19 Apr, 2022'
  },
  {
    id: 5,
    icon: 'ri-gallery-fill',
    iconClass: 'success',
    fileName: 'default.jpg',
    fileType: 'Media',
    fileItem: '01',
    size: '1.7 MB',
    createDate: '06 Apr, 2022'
  }
]

const folderList = [
  { id: 1, folderName: 'Projects', folderFile: '349', size: '4.10' },
  { id: 2, folderName: 'Documents', folderFile: '2349', size: '27.01' },
  { id: 3, folderName: 'Media', folderFile: '12480', size: '20.87' },
  { id: 4, folderName: 'Velzon v1.7.0', folderFile: '180', size: '478.65' }
]

export { recentFile, folderList }
