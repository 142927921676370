import React, { useEffect } from 'react'
import withRouter from '../Components/Common/withRouter'

//redux
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const NonAuthLayout = ({ children }: any) => {
  const nonauthData = createSelector(
    (state: any) => state.Layout.layoutModeType,
    (layoutModeType: any) => layoutModeType
  )
  // Inside your component
  const layoutModeType = useSelector(nonauthData)

  useEffect(() => {
    if (layoutModeType === 'dark') {
      document.body.setAttribute('data-bs-theme', 'dark')
    } else {
      document.body.setAttribute('data-bs-theme', 'light')
    }
    return () => {
      document.body.removeAttribute('data-bs-theme')
    }
  }, [layoutModeType])
  return (
    <div>
      {children}
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
        stacked
      />
    </div>
  )
}

export default withRouter(NonAuthLayout)
