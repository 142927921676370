import React from 'react'
import { Routes, Route } from 'react-router-dom'

//Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout'
import Layout from '../Layouts/index'
import AuthProtected from './AuthProtected'

//routes
import { themeAuthProtectedRoutes, themePublicRoutes } from './themeRoutes'
import { authProtectedRoutes, publicRoutes } from './appRoutes'

const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route>
          {[...themePublicRoutes, ...publicRoutes].map((route: any, idx: any) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              // exact={true}
            />
          ))}
        </Route>

        <Route>
          {[...themeAuthProtectedRoutes, ...authProtectedRoutes].map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected>
                  <Layout>{route.component}</Layout>
                </AuthProtected>
              }
              key={idx}
              // exact={true}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  )
}

export default Index
