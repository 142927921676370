import React, { useEffect, useState } from 'react'
import {
  fetchUsers,
  deleteUser,
  updateUserRecord,
  createUserRecord
} from 'helpers/backend_helper'
import { Container } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { toast } from 'react-toastify'
import Pagination from 'Components/Common/Pagination'
import UsersTable from './UsersTable'
import UserModal from './UserModal'
import { PERPAGE_DEFAULT } from 'lib/constant'
import { ISort } from 'lib/type'

export interface PagePermission {
  pageId: string;
  canView: boolean;
  canCreate: boolean;
  canEdit: boolean;
  canDelete: boolean;
}

export interface IUser {
  id?: number
  email: string;
  password: string;
  phoneNumber: string;
  type: number;
  fullName: string;
  dOB: string;
  gender: boolean;
  isActive: boolean;
  pagePermissions: PagePermission[];
}

export interface IPage {
  id: number
  name: string
}

const Users = () => {
  document.title = 'User Record | Photo Booth Admin Dashboard'

  //state data
  const [fetching, setFetching] = useState<boolean>(false)
  const [userRecords, setUserRecords] = useState<IUser[]>([])
  const [openUserPopup, setOpenUserPopup] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<IUser>()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [sortAction, setSortAction] = useState<ISort>({ sortBy: '', sortDirection: 'ACS' })

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [valueSearch, setValueSearch] = useState<string>('')
  const [canEdit, setCanEdit] = useState<boolean>(false)

  const fetchUserRecords = async (params: any) => {
    try {
      setFetching(true)
      const res = fetchUsers(params)
      const response = await res
      const data = response.data

      setUserRecords(data)
      setFetching(false)
      setTotalPage(response.totalPages)
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
    }
  }

  useEffect(() => {
    fetchUserRecords({ pageNo: currentPage, pageSize: PERPAGE_DEFAULT })
  }, [])

  const handleOpenDetails = (id: number) => {
    const selectedUser = userRecords.find((user) => user.id === id)
    if (selectedUser) {
      setCurrentUser(selectedUser)
      setOpenUserPopup(true)
    }
  }

  const handleCreateOrUpdateUser = async (params: any) => {
    try {
      const res = editMode ? updateUserRecord(params, params.id) : createUserRecord(params)
      const response = await res
      const data = response.data

      if (response.message === 'SUCCESSFULL') {
        if (editMode) {
          let updateUsers = userRecords.map((user: IUser) => {
            if (user.id === data.id) {
              return data
            }
            return user
          })

          setUserRecords(updateUsers)
        } else {
          setUserRecords([data, ...userRecords])
        }
        return response.message
      }

      return response.data
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      return 'FALSE'
    }
  }

  const handleDeleteUser = async (id: number) => {
    try {
      const res = deleteUser(id)
      const response = await res

      if (response.message === 'SUCCESSFULL') {
        setUserRecords(
          userRecords.filter((user) => {
            return user.id !== id
          })
        )
      }

      return response.message
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
      return 'FALSE'
    }
  }

  const searchUser = (value: string) => {
    const trimmedValue = value.trim()
    const queryParams = {
      pageNo: 1,
      pageSize: PERPAGE_DEFAULT,
      ...(trimmedValue && { keyWord: trimmedValue })
    }

    fetchUserRecords(queryParams)
    setValueSearch(trimmedValue)
  }

  useEffect(() => {
    fetchUserRecords(getParams(currentPage, valueSearch))
  }, [currentPage])

  useEffect(() => {
    if (sortAction.sortBy.length > 0) {
      fetchUserRecords(getParams(currentPage, valueSearch))
    }
  }, [sortAction])

  const getParams = (page: number = 1, value: string) => {
    return {
      pageNo: page,
      pageSize: PERPAGE_DEFAULT,
      ...(value.trim() && { keyWord: value }),
      ...(sortAction.sortBy.length > 0 && sortAction)
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='User Record index' pageTitle='User Record' />
          <UsersTable
            userRecords={userRecords}
            setOpenUserPopup={setOpenUserPopup}
            handleOpenDetails={handleOpenDetails}
            setEditMode={setEditMode}
            handleDeleteUser={handleDeleteUser}
            searchUser={searchUser}
            fetching={fetching}
            sortAction={sortAction}
            setSortAction={setSortAction}
            setCanEdit={setCanEdit}
          />
          <UserModal
            isOpen={openUserPopup}
            setOpenPopup={setOpenUserPopup}
            handleCreateOrUpdateUser={handleCreateOrUpdateUser}
            userRecord={currentUser}
            editMode={editMode}
            canEdit={canEdit}
          />
          {totalPage > 1 && (
            <Pagination
              perPageData={PERPAGE_DEFAULT}
              data={userRecords}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={totalPage}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Users
