import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Form,
  FormGroup,
  FormFeedback,
  Card,
  CardBody
} from 'reactstrap'
import { IUser, PagePermission } from './index'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import { fetchPagePermissions } from 'helpers/backend_helper'

interface IProps {
  userRecord: IUser | undefined
  isOpen: boolean
  editMode: boolean
  setOpenPopup: (arg: boolean) => void
  handleCreateOrUpdateUser: (arg: any) => Promise<string>
  canEdit: boolean
}

const initPagePermission: PagePermission = {
  pageId: '',
  canView: true,
  canCreate: true,
  canEdit: true,
  canDelete: true
}

const initUser: IUser = {
  fullName: '',
  email: '',
  password: '',
  phoneNumber: '',
  dOB: '',
  gender: true,
  isActive: true,
  type: 0,
  pagePermissions: [initPagePermission],
}

const UserModal: React.FC<IProps> = ({
  userRecord,
  isOpen,
  editMode,
  handleCreateOrUpdateUser,
  setOpenPopup,
  canEdit
}) => {
  const hasEdit = (editMode && userRecord) || false
  const [pagePermissions, setPagePermissions] = useState<PagePermission[]>([])
  const yupValidates = hasEdit ? Yup.object({
    fullName: Yup.string().required('Please Enter Full Name'),
    email: Yup.string().required('Please Enter Email'),
    phoneNumber: Yup.string().required('Please Enter Phone Number'),
    dOB: Yup.string().required('Please Enter Date of Birth')
  }) : Yup.object({
    fullName: Yup.string().required('Please Enter Full Name'),
    email: Yup.string().required('Please Enter Email'),
    phoneNumber: Yup.string().required('Please Enter Phone Number'),
    dOB: Yup.string().required('Please Enter Date of Birth'),
    password: Yup.string().required('Please Enter Password')
  })
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: hasEdit ? userRecord?.id : '',
      fullName: hasEdit ? userRecord?.fullName : '',
      email: hasEdit ? userRecord?.email : '',
      phoneNumber: hasEdit ? userRecord?.phoneNumber : '',
      gender: hasEdit ? userRecord?.gender : true,
      type: hasEdit ? userRecord?.type : 1,
      pagePermissions: hasEdit ? userRecord?.pagePermissions : [],
      isActive: hasEdit ? userRecord?.isActive : false,
      dOB: hasEdit ? userRecord?.dOB : '',
      password: hasEdit ? userRecord?.password : '',
    },
    validationSchema: yupValidates,
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const fetchPagePermissionRecords = async () => {
    try {
      const res = fetchPagePermissions()
      const response = await res
      const data = response.data

      setPagePermissions(data)
    } catch (e) {
      const errMsg = e as string
      toast.error(errMsg)
    }
  }

  useEffect(() => {
    fetchPagePermissionRecords();
  }, [])

  useEffect(() => {

    if (isOpen) {
      formik.resetForm()
    }
  }, [isOpen])

  const togglePopup = () => {
    setOpenPopup(!isOpen)
  }

  const handleOnChange = (e: { target: { name: any; value: any } }) => {
    const name = e.target.name
    let value = e.target.value
    formik.setFieldValue(name, value)
  }

  const handleSubmit = async (values: any) => {
    editMode && delete values.password
    const res = handleCreateOrUpdateUser(values)
    const message = await res
    if (message === 'SUCCESSFULL') {
      toast.success(
        editMode ? 'Updated a user successfully' : 'Created a new user successfully'
      )
      setOpenPopup(false)
    } else {
      toast.error(message)
    }
  }

  const handleChangeIsActive = (checked: boolean) => {
    formik.setFieldValue('isActive', checked)
  }

  const TypeOptions = [
    { label: 'Admin', value: 1 },
    { label: 'User', value: 2 }
  ]

  const pagePermissionOptions = [
    { name: 'Can View', value: 'canView' },
    { name: 'Can Create', value: 'canCreate' },
    { name: 'Can Edit', value: 'canEdit' },
    { name: 'Can Delete', value: 'canDelete' }
  ]

  const handlePermissionChange = (id: string, option: any, value: boolean) => {
    const updatedPermissions = formik.values.pagePermissions.map((permission: PagePermission) =>
      permission.pageId === id ? { ...permission, [option]: value } : permission
    )
    const existingPermission = updatedPermissions.find((permission: PagePermission) => permission.pageId === id)

    if (!existingPermission) {
      updatedPermissions.push({
        pageId: id,
        canView: false,
        canCreate: false,
        canEdit: false,
        canDelete: false,
        [option]: value
      })
    }

    formik.setFieldValue('pagePermissions', updatedPermissions)
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          togglePopup()
        }}
        scrollable={true}
        id='userModal'
      >
        <ModalHeader
          className='modal-title'
          id='userModalTitle'
          toggle={() => {
            togglePopup()
          }}
        >
          {editMode ? 'User Record Details' : 'Create a new User Record'}
        </ModalHeader>
        <ModalBody>
          <Form
            className='needs-validation'
            onSubmit={(e) => {
              e.preventDefault()
              formik.handleSubmit()
              return false
            }}
          >
            <Row>
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div>
                    <Label htmlFor='full-name' className='form-label mb-1 fw-semibold'>
                      Full Name
                      </Label>
                    <Input
                      type='text'
                      maxLength={50}
                      className='form-control'
                      id='full-name'
                      name='fullName'
                      placeholder='Full Name'
                      value={formik.values.fullName}
                      onChange={(e) => handleOnChange(e)}
                      invalid={formik.touched.fullName && formik.errors.fullName ? true : false}
                    />
                    {formik.touched.fullName && formik.errors.fullName ? (
                      <FormFeedback type='invalid'>{formik.errors.fullName}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div>
                    <Label htmlFor='email' className='form-label mb-1 fw-semibold'>
                      Email
                    </Label>
                    <Input
                      type='text'
                      maxLength={50}
                      className='form-control'
                      id='email'
                      name='email'
                      placeholder='Email'
                      value={formik.values.email}
                      onChange={(e) => handleOnChange(e)}
                      invalid={formik.touched.email && formik.errors.email ? true : false}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <FormFeedback type='invalid'>{formik.errors.email}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div>
                    <Label htmlFor='type' className='form-label mb-1 fw-semibold'>
                      Role
                    </Label>
                    <select
                      className={`form-select ${formik.touched.type && formik.errors.type ? 'is-invalid' : ''}`}
                      aria-label='type'
                      name='type'
                      onChange={(e) => handleOnChange(e)}
                    >
                      {TypeOptions.map((type, index) => {
                        return (
                          <option
                            key={index}
                            value={type.value}
                            selected={type.value === formik.values.type}
                          >
                            {type.label}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.type && formik.errors.type ? (
                      <FormFeedback type='invalid'>{formik.errors.type}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div className='user-date-of-birth'>
                    <Label htmlFor='dob' className='form-label mb-1 fw-semibold w-100'>
                      Date of Birth
                    </Label>
                    <DatePicker
                      selected={formik.values.dOB ? formik.values.dOB : null}
                      onChange={(date) => {
                        formik.setFieldValue('dOB', date ? date : null)

                      }}
                      dateFormat='MM/dd/yyyy'
                      className={`form-control ${
                        formik.touched.dOB && formik.errors.dOB
                          ? 'is-invalid'
                          : ''
                      }`}
                      name='dOB'
                      placeholderText='Select date of birth'
                      showIcon
                    />
                    {formik.touched.dOB && formik.errors.dOB ? (
                      <FormFeedback type='invalid'>{formik.errors.dOB}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div>
                    <Label htmlFor='phone-number' className='form-label mb-1 fw-semibold'>
                      Phone Number
                    </Label>
                    <Input
                      type='number'
                      className='form-control'
                      id='phone-number'
                      name='phoneNumber'
                      placeholder='Phone Number'
                      maxLength={50}
                      value={formik.values.phoneNumber}
                      onChange={(e) => handleOnChange(e)}
                      invalid={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                      <FormFeedback type='invalid'>{formik.errors.phoneNumber}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </FormGroup>
              {
                !editMode &&
                <FormGroup>
                  <Col md={12} className='mb-3'>
                    <div>
                      <Label htmlFor='password' className='form-label mb-1 fw-semibold'>
                        Password
                      </Label>
                      <Input
                        type='text'
                        className='form-control'
                        id='password'
                        name='password'
                        placeholder='Password'
                        maxLength={50}
                        value={formik.values.password}
                        onChange={(e) => handleOnChange(e)}
                        invalid={formik.touched.password && formik.errors.password ? true : false}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <FormFeedback type='invalid'>{formik.errors.password}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </FormGroup>
              }
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div className='form-check'>
                    <Input
                      className='form-check-input'
                      type='checkbox'
                      id='is-active'
                      checked={formik.values.isActive}
                      onChange={(e) => handleChangeIsActive(e.target.checked)}
                    />
                    <Label className='form-check-label fw-medium' htmlFor={`is-active`}>
                      is Active
                    </Label>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col md={12} className='mb-3'>
                  <div>
                    <Label htmlFor='password' className='form-label mb-1 fw-semibold'>
                      Page Permisssons
                    </Label>
                    <Card>
                      <CardBody>
                        <Row>
                          {pagePermissions.map((permission: any, index: number) => (
                              <Col md={6} className='mb-2' key={index} >
                                <div><strong>{permission.name}</strong></div>
                                {pagePermissionOptions.map((option, optionIndex) => (
                                  <div key={optionIndex} className='form-check'>
                                    <Input
                                      className='form-check-input'
                                      type='checkbox'
                                      checked={formik.values.pagePermissions.find((perms: PagePermission) => 
                                                perms.pageId === permission.id && perms[option.value as keyof PagePermission]) ?? false
                                              }
                                      onChange={(e) => handlePermissionChange(permission.id, option.value, e.target.checked)}
                                      />
                                    <Label
                                      className='form-check-label'
                                      htmlFor={`${permission.id}-${option}`}
                                      >
                                      {option.name}
                                    </Label>
                                  </div>
                                ))}
                              </Col>
                            ))
                          }
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </FormGroup>
            </Row>
            <div className='modal-footer'>
              <Button color='light' onClick={() => setOpenPopup(false)}>
                Close
              </Button>
              {
                canEdit &&
                <Button color='primary' type='submit' disabled={!formik.dirty && editMode}>
                  Save
                </Button>
              }
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default UserModal
