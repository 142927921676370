import React from 'react'
import ReactApexChart from 'react-apexcharts'
import getChartColorsArray from '../../Components/Common/ChartsDynamicColor'

const AudiencesMetricsCharts = ({ dataColors }: any) => {
  var chartAudienceColumnChartsColors = getChartColorsArray(dataColors)
  const series = [
    {
      name: 'Last Year',
      data: [25.3, 12.5, 20.2, 18.5, 40.4, 25.4, 15.8, 22.3, 19.2, 25.3, 12.5, 20.2]
    },
    {
      name: 'Current Year',
      data: [36.2, 22.4, 38.2, 30.5, 26.4, 30.4, 20.2, 29.6, 10.9, 36.2, 22.4, 38.2]
    }
  ]
  var options: any = {
    chart: {
      type: 'bar',
      height: 309,
      stacked: true,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        borderRadius: 6
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontWeight: 400,
      fontSize: '8px',
      offsetX: 0,
      offsetY: 0,
      markers: {
        width: 9,
        height: 9,
        radius: 4
      }
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent']
    },
    grid: {
      show: false
    },
    colors: chartAudienceColumnChartsColors,
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: true,
        strokeDashArray: 1,
        height: 1,
        width: '100%',
        offsetX: 0,
        offsetY: 0
      }
    },
    yaxis: {
      show: false
    },
    fill: {
      opacity: 1
    }
  }
  return (
    <React.Fragment>
      <ReactApexChart
        dir='ltr'
        options={options}
        series={series}
        type='bar'
        height='309'
        className='apex-charts'
      />
    </React.Fragment>
  )
}

const MyPortfolioCharts = ({ dataColors }: any) => {
  var donutchartportfolioColors = getChartColorsArray(dataColors)
  const series = [19405, 40552, 15824, 30635]
  var options: any = {
    labels: ['Bitcoin', 'Ethereum', 'Litecoin', 'Dash'],
    chart: {
      type: 'donut',
      height: 224
    },

    plotOptions: {
      pie: {
        size: 100,
        offsetX: 0,
        offsetY: 0,
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '18px',
              offsetY: -5
            },
            value: {
              show: true,
              fontSize: '20px',
              color: '#343a40',
              fontWeight: 500,
              offsetY: 5,
              formatter: function (val: any) {
                return '$' + val
              }
            },
            total: {
              show: true,
              fontSize: '13px',
              label: 'Total value',
              color: '#9599ad',
              fontWeight: 500,
              formatter: function (w: any) {
                return (
                  '$' +
                  w.globals.seriesTotals.reduce(function (a: any, b: any) {
                    return a + b
                  }, 0)
                )
              }
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    yaxis: {
      labels: {
        formatter: function (value: any) {
          return '$' + value
        }
      }
    },
    stroke: {
      lineCap: 'round',
      width: 2
    },
    colors: donutchartportfolioColors
  }
  return (
    <React.Fragment>
      <ReactApexChart
        dir='ltr'
        options={options}
        series={series}
        type='donut'
        height='210'
        className='apex-charts'
      />
    </React.Fragment>
  )
}

const SessionsByCountriesCharts = ({ dataColors }: any) => {
  var barchartCountriesColors = getChartColorsArray(dataColors)
  const series = [
    {
      data: [1010, 1640, 490, 1255, 1050, 689, 800, 420, 1085, 589],
      name: 'Sessions'
    }
  ]
  var options: any = {
    chart: {
      type: 'bar',
      height: 436,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        distributed: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    colors: barchartCountriesColors,
    dataLabels: {
      enabled: true,
      offsetX: 32,
      style: {
        fontSize: '12px',
        fontWeight: 400,
        colors: ['#adb5bd']
      }
    },

    legend: {
      show: false
    },
    grid: {
      show: false
    },
    xaxis: {
      categories: [
        'India',
        'United States',
        'China',
        'Indonesia',
        'Russia',
        'Bangladesh',
        'Canada',
        'Brazil',
        'Vietnam',
        'UK'
      ]
    }
  }
  return (
    <React.Fragment>
      <ReactApexChart
        dir='ltr'
        options={options}
        series={series}
        type='bar'
        height='436'
        className='apex-charts'
      />
    </React.Fragment>
  )
}

const TopReferralsPagesCharts = ({ dataColors }: any) => {
  var chartHeatMapColors = getChartColorsArray(dataColors)

  function generateData(count: any, yrange: any) {
    var i = 0
    var series = []
    while (i < count) {
      var x = 'w' + (i + 1).toString()
      var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

      series.push({
        x: x,
        y: y
      })
      i++
    }
    return series
  }

  const dataChart: any = {
    series: [
      {
        name: 'Jan',
        data: generateData(20, {
          min: -30,
          max: 55
        })
      },
      {
        name: 'Feb',
        data: generateData(20, {
          min: -30,
          max: 55
        })
      },
      {
        name: 'Mar',
        data: generateData(20, {
          min: -30,
          max: 55
        })
      },
      {
        name: 'Apr',
        data: generateData(20, {
          min: -30,
          max: 55
        })
      },
      {
        name: 'May',
        data: generateData(20, {
          min: -30,
          max: 55
        })
      },
      {
        name: 'Jun',
        data: generateData(20, {
          min: -30,
          max: 55
        })
      },
      {
        name: 'Jul',
        data: generateData(20, {
          min: -30,
          max: 55
        })
      },
      {
        name: 'Aug',
        data: generateData(20, {
          min: -30,
          max: 55
        })
      },
      {
        name: 'Sep',
        data: generateData(20, {
          min: -30,
          max: 55
        })
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'heatmap',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      stroke: {
        width: 1
      },
      title: {
        text: 'HeatMap Chart with Color Range',
        style: {
          fontWeight: 500
        }
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.5,
          radius: 0,
          useFillColorAsStroke: true,
          dataLabels: {
            enabled: false
          },
          colorScale: {
            ranges: [
              {
                from: -30,
                to: 5,
                name: 'Low',
                color: chartHeatMapColors[0]
              },
              {
                from: 6,
                to: 20,
                name: 'Medium',
                color: chartHeatMapColors[1]
              },
              {
                from: 21,
                to: 45,
                name: 'High',
                color: chartHeatMapColors[2]
              },
              {
                from: 46,
                to: 55,
                name: 'Extreme',
                color: chartHeatMapColors[3]
              }
            ]
          }
        }
      }
    }
  }

  return (
    <React.Fragment>
      <ReactApexChart
        dir='ltr'
        options={dataChart.options}
        series={dataChart.series}
        type='heatmap'
        height={350}
        className='apex-charts'
      />
    </React.Fragment>
  )
}

const OtherWidgetsCharts = ({ dataColors, seriesData }: any) => {
  var areachartbitcoinColors = getChartColorsArray(dataColors)
  var options: any = {
    chart: {
      width: 140,
      type: 'area',
      sparkline: {
        enabled: true
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 1.5
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [50, 100, 100, 100]
      }
    },
    colors: areachartbitcoinColors
  }
  return (
    <React.Fragment>
      <ReactApexChart
        dir='ltr'
        options={options}
        series={[...seriesData]}
        type='area'
        width='140'
        // height="46"
        className='apex-charts'
      />
    </React.Fragment>
  )
}

export {
  AudiencesMetricsCharts,
  MyPortfolioCharts,
  SessionsByCountriesCharts,
  TopReferralsPagesCharts,
  OtherWidgetsCharts
}
