import React from 'react'
import { Row, Col, Card, CardBody, Container, CardHeader } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

const IconBoxicons = () => {
  document.title = 'Box Icons | Photo Booth Admin Dashboard'
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Box Icons' pageTitle='Icons' />
          <Row>
            <Col className='col-12'>
              <Card>
                <CardHeader>
                  <h4 className='card-title'>Examples</h4>
                  <p className='text-muted mb-0'>
                    Use{' '}
                    <code>
                      &lt;i className="bx bx-**"{'>'}&lt;/i{'>'}
                    </code>{' '}
                    class.
                  </p>
                </CardHeader>
                <CardBody>
                  <h5>Regular</h5>
                  <Row className='icon-demo-content'>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-lemon'></i> bx bx-lemon
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cable-car'></i> bx bx-cable-car
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cricket-ball'></i> bx bx-cricket-ball
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-male-female'></i> bx bx-male-female
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-baguette'></i> bx bx-baguette
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-fork'></i> bx bx-fork
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-knife'></i> bx bx-knife
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-circle-half'></i> bx bx-circle-half
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-circle-three-quarter'></i> bx bx-circle-three-quarter
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-circle-quarter'></i> bx bx-circle-quarter
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bowl-rice'></i> bx bx-bowl-rice
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bowl-hot'></i> bx bx-bowl-hot
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-popsicle'></i> bx bx-popsicle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cross'></i> bx bx-cross
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-scatter-chart'></i> bx bx-scatter-chart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-money-withdraw'></i> bx bx-money-withdraw
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-candles'></i> bx bx-candles
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-math'></i> bx bx-math
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-party'></i> bx bx-party
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-leaf'></i> bx bx-leaf
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-injection'></i> bx bx-injection
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-expand-vertical'></i> bx bx-expand-vertical
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-expand-horizontal'></i> bx bx-expand-horizontal
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-collapse-vertical'></i> bx bx-collapse-vertical
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-collapse-horizontal'></i> bx bx-collapse-horizontal
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-collapse-alt'></i> bx bx-collapse-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-qr'></i> bx bx-qr
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-qr-scan'></i> bx bx-qr-scan
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-podcast'></i> bx bx-podcast
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-checkbox-minus'></i> bx bx-checkbox-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-speaker'></i> bx bx-speaker
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-registered'></i> bx bx-registered
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-phone-off'></i> bx bx-phone-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-buildings'></i> bx bx-buildings
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-store-alt'></i> bx bx-store-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bar-chart-alt-2'></i> bx bx-bar-chart-alt-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-dots'></i> bx bx-message-dots
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-rounded-dots'></i> bx bx-message-rounded-dots
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-memory-card'></i> bx bx-memory-card
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-wallet-alt'></i> bx bx-wallet-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-slideshow'></i> bx bx-slideshow
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-square'></i> bx bx-message-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-square-dots'></i> bx bx-message-square-dots
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-book-content'></i> bx bx-book-content
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chat'></i> bx bx-chat
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-edit-alt'></i> bx bx-edit-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-mouse-alt'></i> bx bx-mouse-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bug-alt'></i> bx bx-bug-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-notepad'></i> bx bx-notepad
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-video-recording'></i> bx bx-video-recording
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-shape-square'></i> bx bx-shape-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-shape-triangle'></i> bx bx-shape-triangle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-ghost'></i> bx bx-ghost
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-mail-send'></i> bx bx-mail-send
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-code-alt'></i> bx bx-code-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-grid'></i> bx bx-grid
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-user-pin'></i> bx bx-user-pin
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-run'></i> bx bx-run
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-copy-alt'></i> bx bx-copy-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-transfer-alt'></i> bx bx-transfer-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-book-open'></i> bx bx-book-open
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-landscape'></i> bx bx-landscape
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-comment'></i> bx bx-comment
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-comment-dots'></i> bx bx-comment-dots
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-pyramid'></i> bx bx-pyramid"
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cylinder'></i> bx bx-cylinder
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-lock-alt'></i> bx bx-lock-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-lock-open-alt'></i> bx bx-lock-open-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-left-arrow-alt'></i> bx bx-left-arrow-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-right-arrow-alt'></i> bx bx-right-arrow-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-up-arrow-alt'></i> bx bx-up-arrow-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-down-arrow-alt'></i> bx bx-down-arrow-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-shape-circle'></i> bx bx-shape-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cycling'></i> bx bx-cycling
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dna'></i> bx bx-dna
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bowling-ball'></i> bx bx-bowling-ball
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-search-alt-2'></i> bx bx-search-alt-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-plus-medical'></i> bx bx-plus-medical
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-street-view'></i> bx bx-street-view
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-droplet'></i> bx bx-droplet
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-paint-roll'></i> bx bx-paint-roll
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-shield-alt-2'></i> bx bx-shield-alt-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-error-alt'></i> bx bx-error-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-square'></i> bx bx-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-square-rounded'></i> bx bx-square-rounded
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-polygon'></i> bx bx-polygon
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cube-alt'></i> bx bx-cube-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cuboid'></i> bx bx-cuboid
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-user-voice'></i> bx bx-user-voice
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-accessibility'></i> bx bx-accessibility
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-building-house'></i> bx bx-building-house
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-doughnut-chart'></i> bx bx-doughnut-chart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-log-in-circle'></i> bx bx-log-in-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-log-out-circle'></i> bx bx-log-out-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-check-square'></i> bx bx-check-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-alt'></i> bx bx-message-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-alt-dots'></i> bx bx-message-alt-dots
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-no-entry'></i> bx bx-no-entry
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-palette'></i> bx bx-palette
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-basket'></i> bx bx-basket
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-purchase-tag-alt'></i> bx bx-purchase-tag-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-receipt'></i> bx bx-receipt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-line-chart'></i> bx bx-line-chart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-map-pin'></i> bx bx-map-pin
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-hive'></i> bx bx-hive
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-band-aid'></i> bx bx-band-aid
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-credit-card-alt'></i> bx bx-credit-card-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-wifi-off'></i> bx bx-wifi-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-brightness-half'></i> bx bx-brightness-half
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-brightness'></i> bx bx-brightness
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-filter-alt'></i> bx bx-filter-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dialpad-alt'></i> bx bx-dialpad-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-border-right'></i> bx bx-border-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-border-left'></i> bx bx-border-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-border-top'></i> bx bx-border-top
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-border-bottom'></i> bx bx-border-bottom
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-border-all'></i> bx bx-border-all
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-mobile-landscape'></i> bx bx-mobile-landscape
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-mobile-vibration'></i> bx bx-mobile-vibration
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-gas-pump'></i> bx bx-gas-pump
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-pie-chart-alt-2'></i> bx bx-pie-chart-alt-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-time-five'></i> bx bx-time-five
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-briefcase-alt-2'></i> bx bx-briefcase-alt-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-brush-alt'></i> bx bx-brush-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-customize'></i> bx bx-customize
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-radio'></i> bx bx-radio
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-printer'></i> bx bx-printer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-sort-a-z'></i> bx bx-sort-a-z
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-sort-z-a'></i> bx bx-sort-z-a
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-conversation'></i> bx bx-conversation
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-exit'></i> bx bx-exit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-extension'></i> bx bx-extension
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-face'></i> bx bx-face
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-file-find'></i> bx bx-file-find
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-label'></i> bx bx-label
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-check-shield'></i> bx bx-check-shield
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-border-radius'></i> bx bx-border-radius
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-add-to-queue'></i> bx bx-add-to-queue
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-archive-in'></i> bx bx-archive-in
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-archive-out'></i> bx bx-archive-out
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-alarm-add'></i> bx bx-alarm-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-space-bar'></i> bx bx-space-bar
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-image-alt'></i> bx bx-image-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-image-add'></i> bx bx-image-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-fridge'></i> bx bx-fridge
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dish'></i> bx bx-dish
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-spa'></i> bx bx-spa
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cake'></i> bx bx-cake
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bolt-circle'></i> bx bx-bolt-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-tone'></i> bx bx-tone
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bitcoin'></i> bx bx-bitcoin
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-lira'></i> bx bx-lira
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-ruble'></i> bx bx-ruble
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-rupee'></i> bx bx-rupee
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-euro'></i> bx bx-euro
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-pound'></i> bx bx-pound
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-won'></i> bx bx-won
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-yen'></i> bx bx-yen
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-shekel'></i> bx bx-shekel
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-health'></i> bx bx-health
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-clinic'></i> bx bx-clinic
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-male'></i> bx bx-male
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-female'></i> bx bx-female
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-male-sign'></i> bx bx-male-sign
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-female-sign'></i> bx bx-female-sign
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-food-tag'></i> bx bx-food-tag
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-food-menu'></i> bx bx-food-menu
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-meh-alt'></i> bx bx-meh-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-wink-tongue'></i> bx bx-wink-tongue
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-happy-alt'></i> bx bx-happy-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cool'></i> bx bx-cool
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-tired'></i> bx bx-tired
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-smile'></i> bx bx-smile
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-angry'></i> bx bx-angry
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-happy-heart-eyes'></i> bx bx-happy-heart-eyes
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dizzy'></i> bx bx-dizzy
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-wink-smile'></i> bx bx-wink-smile
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-confused'></i> bx bx-confused
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-sleepy'></i> bx bx-sleepy
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-shocked'></i> bx bx-shocked
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-happy-beaming'></i> bx bx-happy-beaming
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-meh-blank'></i> bx bx-meh-blank
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-laugh'></i> bx bx-laugh
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-upside-down'></i> bx bx-upside-down
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-diamond'></i> bx bx-diamond
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-align-left'></i> bx bx-align-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-align-middle'></i> bx bx-align-middle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-align-right'></i> bx bx-align-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-arrow-back'></i> bx bx-arrow-back
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bell-minus'></i> bx bx-bell-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bell-off'></i> bx bx-bell-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bell-plus'></i> bx bx-bell-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bell'></i> bx bx-bell
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bookmark'></i> bx bx-bookmark
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bookmarks'></i> bx bx-bookmarks
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bullseye'></i> bx bx-bullseye
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-camera-off'></i> bx bx-camera-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-camera'></i> bx bx-camera
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-captions'></i> bx bx-captions
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-checkbox-checked'></i> bx bx-checkbox-checked
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-checkbox'></i> bx bx-checkbox
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-checkbox-square'></i> bx bx-checkbox-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevron-down'></i> bx bx-chevron-down
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevron-up'></i> bx bx-chevron-up
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevron-left'></i> bx bx-chevron-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevron-right'></i> bx bx-chevron-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevrons-down'></i> bx bx-chevrons-down
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevrons-up'></i> bx bx-chevrons-up
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevrons-right'></i> bx bx-chevrons-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevrons-left'></i> bx bx-chevrons-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-clipboard'></i> bx bx-clipboard
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-code-curly'></i> bx bx-code-curly
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-code'></i> bx bx-code
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-coffee'></i> bx bx-coffee
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-copy'></i> bx bx-copy
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-copyright'></i> bx bx-copyright
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-down-arrow-circle'></i> bx bx-down-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-error-circle'></i> bx bx-error-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-error'></i> bx bx-error
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-exit-fullscreen'></i> bx bx-exit-fullscreen
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-fast-forward-circle'></i> bx bx-fast-forward-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-fast-forward'></i> bx bx-fast-forward
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-first-page'></i> bx bx-first-page
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-folder-minus'></i> bx bx-folder-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-folder-plus'></i> bx bx-folder-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-folder'></i> bx bx-folder
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-fullscreen'></i> bx bx-fullscreen
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-hide'></i> bx bx-hide
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-image'></i> bx bx-image
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-info-circle'></i> bx bx-info-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-align-justify'></i> bx bx-align-justify
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-key'></i> bx bx-key
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-last-page'></i> bx bx-last-page
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-left-arrow-circle'></i> bx bx-left-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-left-down-arrow-circle'></i> bx bx-left-down-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-left-indent'></i> bx bx-left-indent
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-left-top-arrow-circle'></i> bx bx-left-top-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-menu'></i> bx bx-menu
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-microphone'></i> bx bx-microphone
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-minus-circle'></i> bx bx-minus-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-moon'></i> bx bx-moon
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-pause-circle'></i> bx bx-pause-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-pause'></i> bx bx-pause
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-play-circle'></i> bx bx-play-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-play'></i> bx bx-play
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-plus-circle'></i> bx bx-plus-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-question-mark'></i> bx bx-question-mark"
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-radio-circle-marked'></i> bx bx-radio-circle-marked
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-radio-circle'></i> bx bx-radio-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-rectangle'></i> bx bx-rectangle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-rewind'></i> bx bx-rewind
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-reset'></i> bx bx-reset
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-right-arrow-circle'></i> bx bx-right-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-right-down-arrow-circle'></i> bx
                      bx-right-down-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-right-indent'></i> bx bx-right-indent
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-right-top-arrow-circle'></i> bx bx-right-top-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-rss'></i> bx bx-rss
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-search'></i> bx bx-search
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-show'></i> bx bx-show
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-skip-next'></i> bx bx-skip-next
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-skip-previous'></i> bx bx-skip-previous
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-stop-circle'></i> bx bx-stop-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-stop'></i> bx bx-stop
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-stopwatch'></i> bx bx-stopwatch
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-sync'></i> bx bx-sync
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-time'></i> bx bx-time
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-toggle-left'></i> bx bx-toggle-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-toggle-right'></i> bx bx-toggle-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-trending-down'></i> bx bx-trending-down
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-trending-up'></i> bx bx-trending-up
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-up-arrow-circle'></i> bx bx-up-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-vertical-center'></i> bx bx-vertical-center
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-video'></i> bx bx-video
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-volume-full'></i> bx bx-volume-full
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-volume-low'></i> bx bx-volume-low
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-volume-mute'></i> bx bx-volume-mute
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-volume'></i> bx bx-volume
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-x-circle'></i> bx bx-x-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-zoom-in'></i> bx bx-zoom-in
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-zoom-out'></i> bx bx-zoom-out
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-archive'></i> bx bx-archive
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-at'></i> bx bx-at
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bar-chart-alt'></i> bx bx-bar-chart-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bar-chart-square'></i> bx bx-bar-chart-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bar-chart'></i> bx bx-bar-chart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-basketball'></i> bx bx-basketball
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-block'></i> bx bx-block
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-book-bookmark'></i> bx bx-book-bookmark
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-book'></i> bx bx-book
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bookmark-minus'></i> bx bx-bookmark-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bookmark-plus'></i> bx bx-bookmark-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-briefcase'></i> bx bx-briefcase
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-broadcast'></i> bx bx-broadcast
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-building'></i> bx bx-building
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bug'></i> bx bx-bug
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bluetooth'></i> bx bx-bluetooth
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bulb'></i> bx bx-bulb
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-buoy'></i> bx bx-buoy
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-calendar-plus'></i> bx bx-calendar-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-calendar-check'></i> bx bx-calendar-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-calendar-minus'></i> bx bx-calendar-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-calendar-x'></i> bx bx-calendar-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-calendar'></i> bx bx-calendar
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chart'></i> bx bx-chart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cloud-download'></i> bx bx-cloud-download
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cloud-upload'></i> bx bx-cloud-upload
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cloud'></i> bx bx-cloud
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-terminal'></i> bx bx-terminal
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-crosshair'></i> bx bx-crosshair
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-compass'></i> bx bx-compass
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-data'></i> bx bx-data
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-desktop'></i> bx bx-desktop
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-directions'></i> bx bx-directions
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dollar'></i> bx bx-dollar
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dots-horizontal-rounded'></i> bx
                      bx-dots-horizontal-rounded
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dots-horizontal'></i> bx bx-dots-horizontal
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dots-vertical-rounded'></i> bx bx-dots-vertical-rounded
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dots-vertical'></i> bx bx-dots-vertical
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-download'></i> bx bx-download
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-envelope'></i> bx bx-envelope
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-gift'></i> bx bx-gift
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-globe'></i> bx bx-globe
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-devices'></i> bx bx-devices
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-headphone'></i> bx bx-headphone
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-heart'></i> bx bx-heart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-home'></i> bx bx-home
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-laptop'></i> bx bx-laptop
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-layer'></i> bx bx-layer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-link-alt'></i> bx bx-link-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-link'></i> bx bx-link
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-list-plus'></i> bx bx-list-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-list-ul'></i> bx bx-list-ul
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-list-minus'></i> bx bx-list-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-lock-open'></i> bx bx-lock-open
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-lock'></i> bx bx-lock
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-map-alt'></i> bx bx-map-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-map'></i> bx bx-map
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-rounded'></i> bx bx-message-rounded
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message'></i> bx bx-message
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-mobile-alt'></i> bx bx-mobile-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-mobile'></i> bx bx-mobile
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-navigation'></i> bx bx-navigation
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-phone'></i> bx bx-phone
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-pie-chart'></i> bx bx-pie-chart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-send'></i> bx bx-send
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-sidebar'></i> bx bx-sidebar
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-sitemap'></i> bx bx-sitemap
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-spreadsheet'></i> bx bx-spreadsheet
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-tab'></i> bx bx-tab
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-tag'></i> bx bx-tag
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-target-lock'></i> bx bx-target-lock
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-tennis-ball'></i> bx bx-tennis-ball
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-alarm'></i> bx bx-alarm
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-upload'></i> bx bx-upload
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-usb'></i> bx bx-usb
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-video-off'></i> bx bx-video-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-voicemail'></i> bx bx-voicemail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-wifi'></i> bx bx-wifi
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-window-open'></i> bx bx-window-open
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-window'></i> bx bx-window
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-windows'></i> bx bx-windows
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-duplicate'></i> bx bx-duplicate
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-table'></i> bx bx-table
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-x'></i> bx bx-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-adjust'></i> bx bx-adjust
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-album'></i> bx bx-album
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-anchor'></i> bx bx-anchor
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-award'></i> bx bx-award
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bold'></i> bx bx-bold
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-calculator'></i> bx bx-calculator
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cart'></i> bx bx-cart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-check'></i> bx bx-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cloud-drizzle'></i> bx bx-cloud-drizzle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cloud-light-rain'></i> bx bx-cloud-light-rain
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cloud-lightning'></i> bx bx-cloud-lightning
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cloud-rain'></i> bx bx-cloud-rain
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cloud-snow'></i> bx bx-cloud-snow
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cog'></i> bx bx-cog
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-columns'></i> bx bx-columns
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-credit-card'></i> bx bx-credit-card
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-crop'></i> bx bx-crop
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cube'></i> bx bx-cube
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cut'></i> bx bx-cut
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-detail'></i> bx bx-detail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-shield-quarter'></i> bx bx-shield-quarter
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-edit'></i> bx bx-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-file'></i> bx bx-file
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-filter'></i> bx bx-filter
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-font'></i> bx bx-font
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-git-branch'></i> bx bx-git-branch
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-git-commit'></i> bx bx-git-commit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-git-compare'></i> bx bx-git-compare
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-git-merge'></i> bx bx-git-merge
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-git-pull-request'></i> bx bx-git-pull-request
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-git-repo-forked'></i> bx bx-git-repo-forked
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-group'></i> bx bx-group
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-hash'></i> bx bx-hash
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-heading'></i> bx bx-heading
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-home-alt'></i> bx bx-home-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-italic'></i> bx bx-italic
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-joystick'></i> bx bx-joystick
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-link-external'></i> bx bx-link-external
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-log-in'></i> bx bx-log-in
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-log-out'></i> bx bx-log-out
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-microphone-off'></i> bx bx-microphone-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-minus'></i> bx bx-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-mouse'></i> bx bx-mouse
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-move'></i> bx bx-move
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-music'></i> bx bx-music
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-notification'></i> bx bx-notification
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-package'></i> bx bx-package
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-paragraph'></i> bx bx-paragraph
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-paste'></i> bx bx-paste
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-pencil'></i> bx bx-pencil
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-pin'></i> bx bx-pin
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-plus'></i> bx bx-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-power-off'></i> bx bx-power-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-pulse'></i> bx bx-pulse
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-save'></i> bx bx-save
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-screenshot'></i> bx bx-screenshot
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-select-multiple'></i> bx bx-select-multiple
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-share-alt'></i> bx bx-share-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-share'></i> bx bx-share
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-shield-alt'></i> bx bx-shield-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-shield'></i> bx bx-shield
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-shopping-bag'></i> bx bx-shopping-bag
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-shuffle'></i> bx bx-shuffle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-sort'></i> bx bx-sort
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-star'></i> bx bx-star
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-sun'></i> bx bx-sun
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-text'></i> bx bx-text
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-trash'></i> bx bx-trash
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-trophy'></i> bx bx-trophy
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-underline'></i> bx bx-underline
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-user-check'></i> bx bx-user-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-user-circle'></i> bx bx-user-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-user-minus'></i> bx bx-user-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-user-plus'></i> bx bx-user-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-user-x'></i> bx bx-user-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-user'></i> bx bx-user
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-barcode'></i> bx bx-barcode
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-crown'></i> bx bx-crown
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dislike'></i> bx bx-dislike
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-down-arrow'></i> bx bx-down-arrow
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-export'></i> bx bx-export
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-first-aid'></i> bx bx-first-aid
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-flag'></i> bx bx-flag
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-history'></i> bx bx-history
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-joystick-alt'></i> bx bx-joystick-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-left-arrow'></i> bx bx-left-arrow
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-like'></i> bx bx-like
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-list-check'></i> bx bx-list-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-poll'></i> bx bx-poll
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-radar'></i> bx bx-radar
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-redo'></i> bx bx-redo
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-reply-all'></i> bx bx-reply-all
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-reply'></i> bx bx-reply
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-repost'></i> bx bx-repost
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-revision'></i> bx bx-revision
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-right-arrow'></i> bx bx-right-arrow
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-subdirectory-left'></i> bx bx-subdirectory-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-subdirectory-right'></i> bx bx-subdirectory-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-support'></i> bx bx-support
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-timer'></i> bx bx-timer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-undo'></i> bx bx-undo
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-up-arrow'></i> bx bx-up-arrow
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-phone-call'></i> bx bx-phone-call
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-aperture'></i> bx bx-aperture
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-film'></i> bx bx-film
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-folder-open'></i> bx bx-folder-open
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-task'></i> bx bx-task
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-server'></i> bx bx-server
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-battery'></i> bx bx-battery
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-calendar-alt'></i> bx bx-calendar-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-import'></i> bx bx-import
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-ruler'></i> bx bx-ruler
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-horizontal-center'></i> bx bx-horizontal-center
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-rotate-right'></i> bx bx-rotate-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-rename'></i> bx bx-rename
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-collapse'></i> bx bx-collapse
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-phone-incoming'></i> bx bx-phone-incoming
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-phone-outgoing'></i> bx bx-phone-outgoing
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-body'></i> bx bx-body
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cast'></i> bx bx-cast
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chip'></i> bx bx-chip
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-skip-next-circle'></i> bx bx-skip-next-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-skip-previous-circle'></i> bx bx-skip-previous-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-hdd'></i> bx bx-hdd
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-store'></i> bx bx-store
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-globe-alt'></i> bx bx-globe-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-upvote'></i> bx bx-upvote
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-downvote'></i> bx bx-downvote
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-news'></i> bx bx-news
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-pie-chart-alt'></i> bx bx-pie-chart-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-images'></i> bx bx-images
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-purchase-tag'></i> bx bx-purchase-tag
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-pen'></i> bx bx-pen
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-expand'></i> bx bx-expand
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-paperclip'></i> bx bx-paperclip
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-closet'></i> bx bx-closet
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-tv'></i> bx bx-tv
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-collection'></i> bx bx-collection
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-station'></i> bx bx-station
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-wallet'></i> bx bx-wallet
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-briefcase-alt'></i> bx bx-briefcase-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-hourglass'></i> bx bx-hourglass
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-carousel'></i> bx bx-carousel
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-infinite'></i> bx bx-infinite
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-plug'></i> bx bx-plug
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-notification-off'></i> bx bx-notification-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-window-close'></i> bx bx-window-close
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-command'></i> bx bx-command
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-grid-alt'></i> bx bx-grid-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-trash-alt'></i> bx bx-trash-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chalkboard'></i> bx bx-chalkboard
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-loader'></i> bx bx-loader
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-slider'></i> bx bx-slider
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-paper-plane'></i> bx bx-paper-plane
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-selection'></i> bx bx-selection
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-world'></i> bx bx-world
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dock-bottom'></i> bx bx-dock-bottom
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dock-right'></i> bx bx-dock-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dock-top'></i> bx bx-dock-top
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dock-left'></i> bx bx-dock-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-layout'></i> bx bx-layout
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-alarm-off'></i> bx bx-alarm-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-wrench'></i> bx bx-wrench
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-loader-circle'></i> bx bx-loader-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-loader-alt'></i> bx bx-loader-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-car'></i> bx bx-car
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cart-alt'></i> bx bx-cart-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-happy'></i> bx bx-happy
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-meh'></i> bx bx-meh
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-sad'></i> bx bx-sad
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-slider-alt'></i> bx bx-slider-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-certification'></i> bx bx-certification
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-rocket'></i> bx bx-rocket
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-check-circle'></i> bx bx-check-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bus'></i> bx bx-bus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-check-double'></i> bx bx-check-double
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dumbbell'></i> bx bx-dumbbell
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bot'></i> bx bx-bot
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-area'></i> bx bx-area
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bed'></i> bx bx-bed
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bath'></i> bx bx-bath
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-train'></i> bx bx-train
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-taxi'></i> bx bx-taxi
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-movie'></i> bx bx-movie
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-hotel'></i> bx bx-hotel
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-planet'></i> bx bx-planet
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-list-ol'></i> bx bx-list-ol
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-video-plus'></i> bx bx-video-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-menu-alt-left'></i> bx bx-menu-alt-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-menu-alt-right'></i> bx bx-menu-alt-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-box'></i> bx bx-box
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-restaurant'></i> bx bx-restaurant
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-swim'></i> bx bx-swim
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-water'></i> bx bx-water
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-wind'></i> bx bx-wind
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dialpad'></i> bx bx-dialpad
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-handicap'></i> bx bx-handicap
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-font-size'></i> bx bx-font-size
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-code-block'></i> bx bx-code-block
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-photo-album'></i> bx bx-photo-album
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-strikethrough'></i> bx bx-strikethrough
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-file-blank'></i> bx bx-file-blank
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-highlight'></i> bx bx-highlight
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-font-color'></i> bx bx-font-color
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-fingerprint'></i> bx bx-fingerprint
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-transfer'></i> bx bx-transfer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-circle'></i> bx bx-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-ball'></i> bx bx-ball
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-football'></i> bx bx-football
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dollar-circle'></i> bx bx-dollar-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-search-alt'></i> bx bx-search-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-analyse'></i> bx bx-analyse
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-disc'></i> bx bx-disc
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-equalizer'></i> bx bx-equalizer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-stats'></i> bx bx-stats
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-move-horizontal'></i> bx bx-move-horizontal
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-move-vertical'></i> bx bx-move-vertical
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-grid-horizontal'></i> bx bx-grid-horizontal
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-grid-vertical'></i> bx bx-grid-vertical
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-grid-small'></i> bx bx-grid-small
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-badge'></i> bx bx-badge
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-id-card'></i> bx bx-id-card
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-sort-up'></i> bx bx-sort-up
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-sort-down'></i> bx bx-sort-down
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-note'></i> bx bx-note
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-test-tube'></i> bx bx-test-tube
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-help-circle'></i> bx bx-help-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-card'></i> bx bx-card
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-rewind-circle'></i> bx bx-rewind-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-magnet'></i> bx bx-magnet
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-calendar-event'></i> bx bx-calendar-event
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-caret-left'></i> bx bx-caret-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-caret-up'></i> bx bx-caret-up
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-caret-right'></i> bx bx-caret-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-caret-down'></i> bx bx-caret-down
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-show-alt'></i> bx bx-show-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-badge-check'></i> bx bx-badge-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-rotate-left'></i> bx bx-rotate-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-brush'></i> bx bx-brush
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-unlink'></i> bx bx-unlink
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-paint'></i> bx bx-paint
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-joystick-button'></i> bx bx-joystick-button
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-font-family'></i> bx bx-font-family
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-repeat'></i> bx bx-repeat
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-walk'></i> bx bx-walk
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-money'></i> bx bx-money
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-home-circle'></i> bx bx-home-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-location-plus'></i> bx bx-location-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-arch'></i> bx bx-arch
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-atom'></i> bx bx-atom
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-baseball'></i> bx bx-baseball
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-beer'></i> bx bx-beer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bible'></i> bx bx-bible
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bomb'></i> bx bx-bomb
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bus-school'></i> bx bx-bus-school
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cabinet'></i> bx bx-cabinet
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-calendar-edit'></i> bx bx-calendar-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-coffee-togo'></i> bx bx-coffee-togo
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-pointer'></i> bx bx-pointer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-microchip'></i> bx bx-microchip
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-heart-circle'></i> bx bx-heart-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-heart-square'></i> bx bx-heart-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-home-heart'></i> bx bx-home-heart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-info-square'></i> bx bx-info-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-layer-plus'></i> bx bx-layer-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-layer-minus'></i> bx bx-layer-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-recycle'></i> bx bx-recycle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-traffic-cone'></i> bx bx-traffic-cone
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-wifi-2'></i> bx bx-wifi-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-wifi-1'></i> bx bx-wifi-1
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-wifi-0'></i> bx bx-wifi-0
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-mask'></i> bx bx-mask
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-low-vision'></i> bx bx-low-vision
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-been-here'></i> bx bx-been-here
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-current-location'></i> bx bx-current-location
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-arrow-from-top'></i> bx bx-arrow-from-top
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-arrow-from-bottom'></i> bx bx-arrow-from-bottom
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-arrow-from-left'></i> bx bx-arrow-from-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-arrow-from-right'></i> bx bx-arrow-from-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-arrow-to-right'></i> bx bx-arrow-to-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-arrow-to-left'></i> bx bx-arrow-to-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-arrow-to-top'></i> bx bx-arrow-to-top
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-arrow-to-bottom'></i> bx bx-arrow-to-bottom
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-book-reader'></i> bx bx-book-reader
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-scan'></i> bx bx-scan
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-calendar-week'></i> bx bx-calendar-week
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-glasses'></i> bx bx-glasses
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-glasses-alt'></i> bx bx-glasses-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-border-none'></i> bx bx-border-none
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-border-inner'></i> bx bx-border-inner
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dice-1'></i> bx bx-dice-1
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dice-2'></i> bx bx-dice-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dice-3'></i> bx bx-dice-3
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dice-4'></i> bx bx-dice-4
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dice-5'></i> bx bx-dice-5
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-dice-6'></i> bx bx-dice-6
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-webcam'></i> bx bx-webcam
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-spray-can'></i> bx bx-spray-can
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-sticker'></i> bx bx-sticker
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-tachometer'></i> bx bx-tachometer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-game'></i> bx bx-game
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-abacus'></i> bx bx-abacus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-alarm-snooze'></i> bx bx-alarm-snooze
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-alarm-exclamation'></i> bx bx-alarm-exclamation
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-medal'></i> bx bx-medal
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-task-x'></i> bx bx-task-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-barcode-reader'></i> bx bx-barcode-reader
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-blanket'></i> bx bx-blanket
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bone'></i> bx bx-bone
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bong'></i> bx bx-bong
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-book-alt'></i> bx bx-book-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-book-heart'></i> bx bx-book-heart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-book-add'></i> bx bx-book-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bracket'></i> bx bx-bracket
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-brain'></i> bx bx-brain
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-border-outer'></i> bx bx-border-outer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-braille'></i> bx bx-braille
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-window-alt'></i> bx bx-window-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-calendar-heart'></i> bx bx-calendar-heart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-wine'></i> bx bx-wine
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-vial'></i> bx bx-vial
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-color-fill'></i> bx bx-color-fill
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-capsule'></i> bx bx-capsule
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-eraser'></i> bx bx-eraser
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-drink'></i> bx bx-drink
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cctv'></i> bx bx-cctv
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chair'></i> bx bx-chair
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-network-chart'></i> bx bx-network-chart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-vector'></i> bx bx-vector
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-calendar-exclamation'></i> bx bx-calendar-exclamation
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-calendar-star'></i> bx bx-calendar-star
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-camera-home'></i> bx bx-camera-home
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-camera-movie'></i> bx bx-camera-movie
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-caret-right-circle'></i> bx bx-caret-right-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-caret-left-circle'></i> bx bx-caret-left-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-caret-up-circle'></i> bx bx-caret-up-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-caret-down-circle'></i> bx bx-caret-down-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-caret-right-square'></i> bx bx-caret-right-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-caret-up-square'></i> bx bx-caret-up-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-caret-left-square'></i> bx bx-caret-left-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-caret-down-square'></i> bx bx-caret-down-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-shield-x'></i> bx bx-shield-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-line-chart-down'></i> bx bx-line-chart-down
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevron-down-circle'></i> bx bx-chevron-down-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevron-up-circle'></i> bx bx-chevron-up-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevron-left-circle'></i> bx bx-chevron-left-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevron-right-circle'></i> bx bx-chevron-right-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevron-down-square'></i> bx bx-chevron-down-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevron-up-square'></i> bx bx-chevron-up-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevron-left-square'></i> bx bx-chevron-left-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-chevron-right-square'></i> bx bx-chevron-right-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-church'></i> bx bx-church
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-coin'></i> bx bx-coin
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-coin-stack'></i> bx bx-coin-stack
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-unite'></i> bx bx-unite
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-minus-front'></i> bx bx-minus-front
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-intersect'></i> bx bx-intersect
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-exclude'></i> bx bx-exclude
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-minus-back'></i> bx bx-minus-back
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-merge'></i> bx bx-merge
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-trim'></i> bx bx-trim
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-outline'></i> bx bx-outline
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-meteor'></i> bx bx-meteor
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-refresh'></i> bx bx-refresh
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-home-smile'></i> bx bx-home-smile
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-envelope-open'></i> bx bx-envelope-open
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-alt-add'></i> bx bx-message-alt-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-alt-check'></i> bx bx-message-alt-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-alt-error'></i> bx bx-message-alt-error
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-alt-x'></i> bx bx-message-alt-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-alt-minus'></i> bx bx-message-alt-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-alt-edit'></i> bx bx-message-alt-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-alt-detail'></i> bx bx-message-alt-detail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-rounded-check'></i> bx bx-message-rounded-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-rounded-error'></i> bx bx-message-rounded-error
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-rounded-x'></i> bx bx-message-rounded-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-rounded-minus'></i> bx bx-message-rounded-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-rounded-edit'></i> bx bx-message-rounded-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-rounded-add'></i> bx bx-message-rounded-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-rounded-detail'></i> bx bx-message-rounded-detail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-check'></i> bx bx-message-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-error'></i> bx bx-message-error
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-x'></i> bx bx-message-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-minus'></i> bx bx-message-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-edit'></i> bx bx-message-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-add'></i> bx bx-message-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-detail'></i> bx bx-message-detail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-square-check'></i> bx bx-message-square-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-square-error'></i> bx bx-message-square-error
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-square-x'></i> bx bx-message-square-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-square-minus'></i> bx bx-message-square-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-square-edit'></i> bx bx-message-square-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-square-add'></i> bx bx-message-square-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-message-square-detail'></i> bx bx-message-square-detail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-comment-check'></i> bx bx-comment-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-comment-error'></i> bx bx-comment-error
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-comment-x'></i> bx bx-comment-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-comment-edit'></i> bx bx-comment-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-comment-minus'></i> bx bx-comment-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-comment-add'></i> bx bx-comment-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-comment-detail'></i> bx bx-comment-detail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-cookie'></i> bx bx-cookie
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-credit-card-front'></i> bx bx-credit-card-front
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-door-open'></i> bx bx-door-open
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-donate-heart'></i> bx bx-donate-heart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-donate-blood'></i> bx bx-donate-blood
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-shape-polygon'></i> bx bx-shape-polygon
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bookmark-heart'></i> bx bx-bookmark-heart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-sort-alt-2'></i> bx bx-sort-alt-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-category'></i> bx bx-category
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-category-alt'></i> bx bx-category-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bookmark-alt'></i> bx bx-bookmark-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bookmark-alt-plus'></i> bx bx-bookmark-alt-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-bookmark-alt-minus'></i> bx bx-bookmark-alt-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-tag-alt'></i> bx bx-tag-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-movie-play'></i> bx bx-movie-play
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-expand-alt'></i> bx bx-expand-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-library'></i> bx bx-library
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bx-trip'></i> bx bx-trip
                    </div>
                  </Row>

                  {/* <!--Regular end row --> */}

                  <h5 className='mt-5'>Solid Icons</h5>
                  <p className='text-muted mb-0'>
                    Use class{' '}
                    <code>
                      &lt;i className="bx bxs-**"{'>'}&lt;/i{'>'}
                    </code>
                  </p>

                  <Row className='icon-demo-content'>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-lemon'></i> bx bxs-lemon
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cable-car'></i> bx bxs-cable-car
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cricket-ball'></i> bx bxs-cricket-ball
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-tree-alt'></i> bx bxs-tree-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-invader'></i> bx bxs-invader
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-baguette'></i> bx bxs-baguette
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-circle-half'></i> bx bxs-circle-half
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-circle-three-quarter'></i> bx bxs-circle-three-quarter
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-circle-quarter'></i> bx bxs-circle-quarter
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bowl-rice'></i> bx bxs-bowl-rice
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bowl-hot'></i> bx bxs-bowl-hot
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-popsicle'></i> bx bxs-popsicle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-party'></i> bx bxs-party
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-leaf'></i> bx bxs-leaf
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-injection'></i> bx bxs-injection
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dog'></i> bx bxs-dog
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cat'></i> bx bxs-cat
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-hand'></i> bx bxs-hand
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-checkbox-minus'></i> bx bxs-checkbox-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-speaker'></i> bx bxs-speaker
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-registered'></i> bx bxs-registered
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-phone-off'></i> bx bxs-phone-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-buildings'></i> bx bxs-buildings
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-store-alt'></i> bx bxs-store-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bar-chart-alt-2'></i> bx bxs-bar-chart-alt-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-dots'></i> bx bxs-message-dots
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-rounded-dots'></i> bx bxs-message-rounded-dots
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-devices'></i> bx bxs-devices
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-memory-card'></i> bx bxs-memory-card
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-wallet-alt'></i> bx bxs-wallet-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bank'></i> bx bxs-bank
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-slideshow'></i> bx bxs-slideshow
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-square'></i> bx bxs-message-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-square-dots'></i> bx bxs-message-square-dots
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-book-content'></i> bx bxs-book-content
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chat'></i> bx bxs-chat
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-edit-alt'></i> bx bxs-edit-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-mouse-alt'></i> bx bxs-mouse-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bug-alt'></i> bx bxs-bug-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-notepad'></i> bx bxs-notepad
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-video-recording'></i> bx bxs-video-recording
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-direction-left'></i> bx bxs-direction-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-ghost'></i> bx bxs-ghost
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-quote-single-left'></i> bx bxs-quote-single-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-quote-single-right'></i> bx bxs-quote-single-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-user-pin'></i> bx bxs-user-pin
                    </div>

                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-copy-alt'></i> bx bxs-copy-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-doc'></i> bx bxs-file-doc
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-html'></i> bx bxs-file-html
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-comment-detail'></i> bx bxs-comment-detail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-comment-add'></i> bx bxs-comment-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-css'></i> bx bxs-file-css
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-js'></i> bx bxs-file-js
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-json'></i> bx bxs-file-json
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-md'></i> bx bxs-file-md
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-txt'></i> bx bxs-file-txt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-png'></i> bx bxs-file-png
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-jpg'></i> bx bxs-file-jpg
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-gif'></i> bx bxs-file-gif
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-analyse'></i> bx bxs-analyse
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-plane-take-off'></i> bx bxs-plane-take-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-plane-land'></i> bx bxs-plane-land
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-parking'></i> bx bxs-parking
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-id-card'></i> bx bxs-id-card
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-adjust-alt'></i> bx bxs-adjust-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-landscape'></i> bx bxs-landscape
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-traffic'></i> bx bxs-traffic
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-comment'></i> bx bxs-comment
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-comment-dots'></i> bx bxs-comment-dots
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-wine'></i> bx bxs-wine
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-pyramid'></i> bx bxs-pyramid
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cylinder'></i> bx bxs-cylinder
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-graduation'></i> bx bxs-graduation
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-lock-alt'></i> bx bxs-lock-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-lock-open-alt'></i> bx bxs-lock-open-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-hourglass-top'></i> bx bxs-hourglass-top
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-hourglass-bottom'></i> bx bxs-hourglass-bottom
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bowling-ball'></i> bx bxs-bowling-ball
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-search-alt-2'></i> bx bxs-search-alt-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-droplet-half'></i> bx bxs-droplet-half
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-paint-roll'></i> bx bxs-paint-roll
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-shield-alt-2'></i> bx bxs-shield-alt-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-error-alt'></i> bx bxs-error-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-square'></i> bx bxs-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-square-rounded'></i> bx bxs-square-rounded
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-polygon'></i> bx bxs-polygon
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cube-alt'></i> bx bxs-cube-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cuboid'></i> bx bxs-cuboid
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-user-voice'></i> bx bxs-user-voice
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-building-house'></i> bx bxs-building-house
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-doughnut-chart'></i> bx bxs-doughnut-chart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-circle'></i> bx bxs-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-log-in-circle'></i> bx bxs-log-in-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-log-out-circle'></i> bx bxs-log-out-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-log-in'></i> bx bxs-log-in
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-log-out'></i> bx bxs-log-out
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-notification'></i> bx bxs-notification
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-notification-off'></i> bx bxs-notification-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-check-square'></i> bx bxs-check-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-alt'></i> bx bxs-message-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-alt-dots'></i> bx bxs-message-alt-dots
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-no-entry'></i> bx bxs-no-entry
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-traffic-barrier'></i> bx bxs-traffic-barrier
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-component'></i> bx bxs-component
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-plane-alt'></i> bx bxs-plane-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-palette'></i> bx bxs-palette
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-basket'></i> bx bxs-basket
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-purchase-tag-alt'></i> bx bxs-purchase-tag-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-receipt'></i> bx bxs-receipt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-map-pin'></i> bx bxs-map-pin
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-band-aid'></i> bx bxs-band-aid
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-credit-card-alt'></i> bx bxs-credit-card-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-credit-card'></i> bx bxs-credit-card
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-paint'></i> bx bxs-paint
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-brightness-half'></i> bx bxs-brightness-half
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-brightness'></i> bx bxs-brightness
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-rectangle'></i> bx bxs-rectangle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-right-arrow'></i> bx bxs-right-arrow
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-left-arrow'></i> bx bxs-left-arrow
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-up-arrow'></i> bx bxs-up-arrow
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-down-arrow'></i> bx bxs-down-arrow
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-right-top-arrow-circle'></i> bx
                      bxs-right-top-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-right-down-arrow-circle'></i> bx
                      bxs-right-down-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-left-top-arrow-circle'></i> bx bxs-left-top-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-left-down-arrow-circle'></i> bx
                      bxs-left-down-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-institution'></i> bx bxs-institution
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-school'></i> bx bxs-school
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chalkboard'></i> bx bxs-chalkboard
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-skip-previous-circle'></i> bx bxs-skip-previous-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-skip-next-circle'></i> bx bxs-skip-next-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-data'></i> bx bxs-data
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-mobile'></i> bx bxs-mobile
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-folder-minus'></i> bx bxs-folder-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bell-plus'></i> bx bxs-bell-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bell-minus'></i> bx bxs-bell-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-search'></i> bx bxs-search
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-zoom-in'></i> bx bxs-zoom-in
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-zoom-out'></i> bx bxs-zoom-out
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-grid'></i> bx bxs-grid
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-user-x'></i> bx bxs-user-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-user-check'></i> bx bxs-user-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-compass'></i> bx bxs-compass
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-stopwatch'></i> bx bxs-stopwatch
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-timer'></i> bx bxs-timer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-time'></i> bx bxs-time
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-pie-chart-alt-2'></i> bx bxs-pie-chart-alt-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-time-five'></i> bx bxs-time-five
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bookmarks'></i> bx bxs-bookmarks
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bookmark-minus'></i> bx bxs-bookmark-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-briefcase-alt-2'></i> bx bxs-briefcase-alt-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-calendar'></i> bx bxs-calendar
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-calendar-alt'></i> bx bxs-calendar-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-calendar-plus'></i> bx bxs-calendar-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-calendar-minus'></i> bx bxs-calendar-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-calendar-x'></i> bx bxs-calendar-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-calendar-check'></i> bx bxs-calendar-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-calendar-event'></i> bx bxs-calendar-event
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-customize'></i> bx bxs-customize
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-carousel'></i> bx bxs-carousel
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-rewind-circle'></i> bx bxs-rewind-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-fast-forward-circle'></i> bx bxs-fast-forward-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-mobile-vibration'></i> bx bxs-mobile-vibration
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-quote-alt-left'></i> bx bxs-quote-alt-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-quote-alt-right'></i> bx bxs-quote-alt-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-layout'></i> bx bxs-layout
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-brush-alt'></i> bx bxs-brush-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-exit'></i> bx bxs-exit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-extension'></i> bx bxs-extension
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-find'></i> bx bxs-file-find
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-face'></i> bx bxs-face
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-label'></i> bx bxs-label
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-check-shield'></i> bx bxs-check-shield
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-add-to-queue'></i> bx bxs-add-to-queue
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-archive-in'></i> bx bxs-archive-in
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-archive-out'></i> bx bxs-archive-out
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-alarm-add'></i> bx bxs-alarm-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-image-add'></i> bx bxs-image-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-fridge'></i> bx bxs-fridge
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dish'></i> bx bxs-dish
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-spa'></i> bx bxs-spa
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cake'></i> bx bxs-cake
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-city'></i> bx bxs-city
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bolt-circle'></i> bx bxs-bolt-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-caret-up-circle'></i> bx bxs-caret-up-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-caret-down-circle'></i> bx bxs-caret-down-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-caret-left-circle'></i> bx bxs-caret-left-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-caret-right-circle'></i> bx bxs-caret-right-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-tone'></i> bx bxs-tone
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-baby-carriage'></i> bx bxs-baby-carriage
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-hand-up'></i> bx bxs-hand-up
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-hand-right'></i> bx bxs-hand-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-hand-down'></i> bx bxs-hand-down
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-hand-left'></i> bx bxs-hand-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-clinic'></i> bx bxs-clinic
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-offer'></i> bx bxs-offer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-food-menu'></i> bx bxs-food-menu
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-camera-plus'></i> bx bxs-camera-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-business'></i> bx bxs-business
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-angry'></i> bx bxs-angry
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-happy-heart-eyes'></i> bx bxs-happy-heart-eyes
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dizzy'></i> bx bxs-dizzy
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-wink-smile'></i> bx bxs-wink-smile
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-smile'></i> bx bxs-smile
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-meh'></i> bx bxs-meh
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-meh-alt'></i> bx bxs-meh-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-confused'></i> bx bxs-confused
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-sleepy'></i> bx bxs-sleepy
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-sad'></i> bx bxs-sad
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-happy'></i> bx bxs-happy
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-shocked'></i> bx bxs-shocked
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-happy-beaming'></i> bx bxs-happy-beaming
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-tired'></i> bx bxs-tired
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cool'></i> bx bxs-cool
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-meh-blank'></i> bx bxs-meh-blank
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-laugh'></i> bx bxs-laugh
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-happy-alt'></i> bx bxs-happy-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-upside-down'></i> bx bxs-upside-down
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-wink-tongue'></i> bx bxs-wink-tongue
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-comment-error'></i> bx bxs-comment-error
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-vial'></i> bx bxs-vial
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-adjust'></i> bx bxs-adjust
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-alarm'></i> bx bxs-alarm
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-alarm-off'></i> bx bxs-alarm-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-album'></i> bx bxs-album
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-archive'></i> bx bxs-archive
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-camera'></i> bx bxs-camera
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-camera-off'></i> bx bxs-camera-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-folder'></i> bx bxs-folder
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-folder-plus'></i> bx bxs-folder-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-award'></i> bx bxs-award
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bar-chart-square'></i> bx bxs-bar-chart-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-barcode'></i> bx bxs-barcode
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-battery'></i> bx bxs-battery
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-battery-charging'></i> bx bxs-battery-charging
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-battery-full'></i> bx bxs-battery-full
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bell'></i> bx bxs-bell
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bell-off'></i> bx bxs-bell-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bolt'></i> bx bxs-bolt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-book'></i> bx bxs-book
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-book-bookmark'></i> bx bxs-book-bookmark
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bookmark'></i> bx bxs-bookmark
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bookmark-plus'></i> bx bxs-bookmark-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-book-open'></i> bx bxs-book-open
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bookmark-star'></i> bx bxs-bookmark-star
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-briefcase'></i> bx bxs-briefcase
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-briefcase-alt'></i> bx bxs-briefcase-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bug'></i> bx bxs-bug
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-building'></i> bx bxs-building
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bulb'></i> bx bxs-bulb
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-buoy'></i> bx bxs-buoy
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-calculator'></i> bx bxs-calculator
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-captions'></i> bx bxs-captions
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-car'></i> bx bxs-car
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cart-alt'></i> bx bxs-cart-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cart'></i> bx bxs-cart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chart'></i> bx bxs-chart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chip'></i> bx bxs-chip
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cloud-download'></i> bx bxs-cloud-download
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cloud-upload'></i> bx bxs-cloud-upload
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cloud'></i> bx bxs-cloud
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-coffee'></i> bx bxs-coffee
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cog'></i> bx bxs-cog
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-collection'></i> bx bxs-collection
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-contact'></i> bx bxs-contact
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-copy'></i> bx bxs-copy
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-coupon'></i> bx bxs-coupon
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-crown'></i> bx bxs-crown
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cube'></i> bx bxs-cube
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-detail'></i> bx bxs-detail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-discount'></i> bx bxs-discount
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dislike'></i> bx bxs-dislike
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dock-bottom'></i> bx bxs-dock-bottom
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dock-left'></i> bx bxs-dock-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dock-right'></i> bx bxs-dock-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dock-top'></i> bx bxs-dock-top
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-down-arrow-circle'></i> bx bxs-down-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-download'></i> bx bxs-download
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-downvote'></i> bx bxs-downvote
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-drink'></i> bx bxs-drink
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-droplet'></i> bx bxs-droplet
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-duplicate'></i> bx bxs-duplicate
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-eject'></i> bx bxs-eject
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-envelope'></i> bx bxs-envelope
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-error-circle'></i> bx bxs-error-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-error'></i> bx bxs-error
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-image'></i> bx bxs-file-image
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file'></i> bx bxs-file
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-filter-alt'></i> bx bxs-filter-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-first-aid'></i> bx bxs-first-aid
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-flag-alt'></i> bx bxs-flag-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-flag'></i> bx bxs-flag
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-gift'></i> bx bxs-gift
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-grid-alt'></i> bx bxs-grid-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-group'></i> bx bxs-group
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-hdd'></i> bx bxs-hdd
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-heart'></i> bx bxs-heart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-hide'></i> bx bxs-hide
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-home'></i> bx bxs-home
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-hot'></i> bx bxs-hot
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-hourglass'></i> bx bxs-hourglass
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-image'></i> bx bxs-image
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-inbox'></i> bx bxs-inbox
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-info-circle'></i> bx bxs-info-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-joystick-alt'></i> bx bxs-joystick-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-joystick'></i> bx bxs-joystick
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-layer'></i> bx bxs-layer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-left-arrow-circle'></i> bx bxs-left-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-like'></i> bx bxs-like
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-lock-open'></i> bx bxs-lock-open
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-lock'></i> bx bxs-lock
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-map-alt'></i> bx bxs-map-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-map'></i> bx bxs-map
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-rounded'></i> bx bxs-message-rounded
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message'></i> bx bxs-message
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-microphone-off'></i> bx bxs-microphone-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-microphone'></i> bx bxs-microphone
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-minus-circle'></i> bx bxs-minus-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-moon'></i> bx bxs-moon
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-mouse'></i> bx bxs-mouse
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-music'></i> bx bxs-music
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-navigation'></i> bx bxs-navigation
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-news'></i> bx bxs-news
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-package'></i> bx bxs-package
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-paper-plane'></i> bx bxs-paper-plane
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-paste'></i> bx bxs-paste
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-pen'></i> bx bxs-pen
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-pencil'></i> bx bxs-pencil
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-phone-call'></i> bx bxs-phone-call
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-phone-incoming'></i> bx bxs-phone-incoming
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-phone-outgoing'></i> bx bxs-phone-outgoing
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-phone'></i> bx bxs-phone
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-pie-chart-alt'></i> bx bxs-pie-chart-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-pie-chart'></i> bx bxs-pie-chart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-pin'></i> bx bxs-pin
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-playlist'></i> bx bxs-playlist
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-plug'></i> bx bxs-plug
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-plus-circle'></i> bx bxs-plus-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-printer'></i> bx bxs-printer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-purchase-tag'></i> bx bxs-purchase-tag
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-quote-left'></i> bx bxs-quote-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-quote-right'></i> bx bxs-quote-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-radio'></i> bx bxs-radio
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-rename'></i> bx bxs-rename
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-report'></i> bx bxs-report
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-right-arrow-circle'></i> bx bxs-right-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-ruler'></i> bx bxs-ruler
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-save'></i> bx bxs-save
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-sort-alt'></i> bx bxs-sort-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-select-multiple'></i> bx bxs-select-multiple
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-send'></i> bx bxs-send
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-server'></i> bx bxs-server
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-share-alt'></i> bx bxs-share-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-share'></i> bx bxs-share
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-shield'></i> bx bxs-shield
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-shopping-bag-alt'></i> bx bxs-shopping-bag-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-shopping-bag'></i> bx bxs-shopping-bag
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-show'></i> bx bxs-show
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-spreadsheet'></i> bx bxs-spreadsheet
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-star'></i> bx bxs-star
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-store'></i> bx bxs-store
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-sun'></i> bx bxs-sun
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-t-shirt'></i> bx bxs-t-shirt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-tag-x'></i> bx bxs-tag-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-tag'></i> bx bxs-tag
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-tennis-ball'></i> bx bxs-tennis-ball
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-terminal'></i> bx bxs-terminal
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-to-top'></i> bx bxs-to-top
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-toggle-left'></i> bx bxs-toggle-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-toggle-right'></i> bx bxs-toggle-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-torch'></i> bx bxs-torch
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-trash-alt'></i> bx bxs-trash-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-trash'></i> bx bxs-trash
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-trophy'></i> bx bxs-trophy
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-truck'></i> bx bxs-truck
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-up-arrow-circle'></i> bx bxs-up-arrow-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-upvote'></i> bx bxs-upvote
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-user-circle'></i> bx bxs-user-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-user-detail'></i> bx bxs-user-detail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-user-minus'></i> bx bxs-user-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-user-plus'></i> bx bxs-user-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-user'></i> bx bxs-user
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-video-off'></i> bx bxs-video-off
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-video'></i> bx bxs-video
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-videos'></i> bx bxs-videos
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-volume-full'></i> bx bxs-volume-full
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-volume-low'></i> bx bxs-volume-low
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-volume-mute'></i> bx bxs-volume-mute
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-volume'></i> bx bxs-volume
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-wallet'></i> bx bxs-wallet
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-watch-alt'></i> bx bxs-watch-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-watch'></i> bx bxs-watch
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-widget'></i> bx bxs-widget
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-wrench'></i> bx bxs-wrench
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-x-circle'></i> bx bxs-x-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-zap'></i> bx bxs-zap
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-folder-open'></i> bx bxs-folder-open
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-battery-low'></i> bx bxs-battery-low
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-conversation'></i> bx bxs-conversation
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dashboard'></i> bx bxs-dashboard
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-plus'></i> bx bxs-file-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-certification'></i> bx bxs-certification
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-rocket'></i> bx bxs-rocket
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-check-circle'></i> bx bxs-check-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-checkbox'></i> bx bxs-checkbox
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-checkbox-checked'></i> bx bxs-checkbox-checked
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-star-half'></i> bx bxs-star-half
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bus'></i> bx bxs-bus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bot'></i> bx bxs-bot
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-area'></i> bx bxs-area
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bed'></i> bx bxs-bed
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bath'></i> bx bxs-bath
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-train'></i> bx bxs-train
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-taxi'></i> bx bxs-taxi
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-movie'></i> bx bxs-movie
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-planet'></i> bx bxs-planet
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-video-plus'></i> bx bxs-video-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-box'></i> bx bxs-box
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-key'></i> bx bxs-key
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-photo-album'></i> bx bxs-photo-album
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bell-ring'></i> bx bxs-bell-ring
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-blank'></i> bx bxs-file-blank
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-edit'></i> bx bxs-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-ball'></i> bx bxs-ball
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-film'></i> bx bxs-film
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dollar-circle'></i> bx bxs-dollar-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-skull'></i> bx bxs-skull
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-image-alt'></i> bx bxs-image-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-microphone-alt'></i> bx bxs-microphone-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-x-square'></i> bx bxs-x-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-plus-square'></i> bx bxs-plus-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-minus-square'></i> bx bxs-minus-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-disc'></i> bx bxs-disc
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-flame'></i> bx bxs-flame
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-badge'></i> bx bxs-badge
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-note'></i> bx bxs-note
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-help-circle'></i> bx bxs-help-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-card'></i> bx bxs-card
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-magnet'></i> bx bxs-magnet
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-ambulance'></i> bx bxs-ambulance
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-left-arrow-square'></i> bx bxs-left-arrow-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-up-arrow-square'></i> bx bxs-up-arrow-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-down-arrow-square'></i> bx bxs-down-arrow-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-right-arrow-square'></i> bx bxs-right-arrow-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-user-badge'></i> bx bxs-user-badge
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-gas-pump'></i> bx bxs-gas-pump
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-landmark'></i> bx bxs-landmark
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-badge-check'></i> bx bxs-badge-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-coffee-alt'></i> bx bxs-coffee-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-brush'></i> bx bxs-brush
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-keyboard'></i> bx bxs-keyboard
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-megaphone'></i> bx bxs-megaphone
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-directions'></i> bx bxs-directions
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-direction-right'></i> bx bxs-direction-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-joystick-button'></i> bx bxs-joystick-button
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-flask'></i> bx bxs-flask
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-capsule'></i> bx bxs-capsule
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-color-fill'></i> bx bxs-color-fill
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-hotel'></i> bx bxs-hotel
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-magic-wand'></i> bx bxs-magic-wand
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-eraser'></i> bx bxs-eraser
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cloud-rain'></i> bx bxs-cloud-rain
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cloud-lightning'></i> bx bxs-cloud-lightning
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-eyedropper'></i> bx bxs-eyedropper
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-user-rectangle'></i> bx bxs-user-rectangle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-plane'></i> bx bxs-plane
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-tree'></i> bx bxs-tree
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-factory'></i> bx bxs-factory
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-ship'></i> bx bxs-ship
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-yin-yang'></i> bx bxs-yin-yang
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-pdf'></i> bx bxs-file-pdf
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-home-circle'></i> bx bxs-home-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-location-plus'></i> bx bxs-location-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-arch'></i> bx bxs-arch
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-badge-dollar'></i> bx bxs-badge-dollar
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-beer'></i> bx bxs-beer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bible'></i> bx bxs-bible
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bomb'></i> bx bxs-bomb
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bus-school'></i> bx bxs-bus-school
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cabinet'></i> bx bxs-cabinet
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-calendar-edit'></i> bx bxs-calendar-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-car-wash'></i> bx bxs-car-wash
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-car-garage'></i> bx bxs-car-garage
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-car-mechanic'></i> bx bxs-car-mechanic
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-car-crash'></i> bx bxs-car-crash
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-coffee-togo'></i> bx bxs-coffee-togo
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chess'></i> bx bxs-chess
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dryer'></i> bx bxs-dryer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-washer'></i> bx bxs-washer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-pointer'></i> bx bxs-pointer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-microchip'></i> bx bxs-microchip
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-piano'></i> bx bxs-piano
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-export'></i> bx bxs-file-export
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-import'></i> bx bxs-file-import
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-flag-checkered'></i> bx bxs-flag-checkered
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-heart-circle'></i> bx bxs-heart-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-heart-square'></i> bx bxs-heart-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-home-heart'></i> bx bxs-home-heart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-info-square'></i> bx bxs-info-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-layer-plus'></i> bx bxs-layer-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-layer-minus'></i> bx bxs-layer-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-traffic-cone'></i> bx bxs-traffic-cone
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-mask'></i> bx bxs-mask
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-low-vision'></i> bx bxs-low-vision
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-radiation'></i> bx bxs-radiation
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-been-here'></i> bx bxs-been-here
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-arrow-from-top'></i> bx bxs-arrow-from-top
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-arrow-from-bottom'></i> bx bxs-arrow-from-bottom
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-arrow-from-left'></i> bx bxs-arrow-from-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-arrow-from-right'></i> bx bxs-arrow-from-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-arrow-to-right'></i> bx bxs-arrow-to-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-arrow-to-left'></i> bx bxs-arrow-to-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-arrow-to-top'></i> bx bxs-arrow-to-top
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-arrow-to-bottom'></i> bx bxs-arrow-to-bottom
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-book-reader'></i> bx bxs-book-reader
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-edit-location'></i> bx bxs-edit-location
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-ev-station'></i> bx bxs-ev-station
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-shapes'></i> bx bxs-shapes
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-florist'></i> bx bxs-florist
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-pizza'></i> bx bxs-pizza
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-calendar-week'></i> bx bxs-calendar-week
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dice-1'></i> bx bxs-dice-1
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dice-2'></i> bx bxs-dice-2
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dice-3'></i> bx bxs-dice-3
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dice-4'></i> bx bxs-dice-4
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dice-5'></i> bx bxs-dice-5
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-dice-6'></i> bx bxs-dice-6
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-webcam'></i> bx bxs-webcam
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-spray-can'></i> bx bxs-spray-can
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-file-archive'></i> bx bxs-file-archive
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-sticker'></i> bx bxs-sticker
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-tachometer'></i> bx bxs-tachometer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-thermometer'></i> bx bxs-thermometer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-game'></i> bx bxs-game
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-alarm-snooze'></i> bx bxs-alarm-snooze
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-alarm-exclamation'></i> bx bxs-alarm-exclamation
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevrons-left'></i> bx bxs-chevrons-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevrons-right'></i> bx bxs-chevrons-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevrons-up'></i> bx bxs-chevrons-up
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevrons-down'></i> bx bxs-chevrons-down
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevron-down'></i> bx bxs-chevron-down
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevron-up'></i> bx bxs-chevron-up
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevron-right'></i> bx bxs-chevron-right
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevron-left'></i> bx bxs-chevron-left
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-guitar-amp'></i> bx bxs-guitar-amp
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-up-arrow-alt'></i> bx bxs-up-arrow-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-down-arrow-alt'></i> bx bxs-down-arrow-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-left-arrow-alt'></i> bx bxs-left-arrow-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-right-arrow-alt'></i> bx bxs-right-arrow-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-medal'></i> bx bxs-medal
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-shopping-bags'></i> bx bxs-shopping-bags
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-baseball'></i> bx bxs-baseball
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-basketball'></i> bx bxs-basketball
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-blanket'></i> bx bxs-blanket
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-binoculars'></i> bx bxs-binoculars
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bone'></i> bx bxs-bone
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bong'></i> bx bxs-bong
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-book-alt'></i> bx bxs-book-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-book-heart'></i> bx bxs-book-heart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-book-add'></i> bx bxs-book-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-brain'></i> bx bxs-brain
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-window-alt'></i> bx bxs-window-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-calendar-heart'></i> bx bxs-calendar-heart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cctv'></i> bx bxs-cctv
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-network-chart'></i> bx bxs-network-chart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-vector'></i> bx bxs-vector
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-calendar-exclamation'></i> bx bxs-calendar-exclamation
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-calendar-star'></i> bx bxs-calendar-star
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-camera-home'></i> bx bxs-camera-home
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-camera-movie'></i> bx bxs-camera-movie
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-backpack'></i> bx bxs-backpack
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cart-download'></i> bx bxs-cart-download
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cart-add'></i> bx bxs-cart-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-car-battery'></i> bx bxs-car-battery
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-caret-right-square'></i> bx bxs-caret-right-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-caret-up-square'></i> bx bxs-caret-up-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-caret-left-square'></i> bx bxs-caret-left-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-caret-down-square'></i> bx bxs-caret-down-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-shield-x'></i> bx bxs-shield-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevron-down-circle'></i> bx bxs-chevron-down-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevron-up-circle'></i> bx bxs-chevron-up-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevron-left-circle'></i> bx bxs-chevron-left-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevron-right-circle'></i> bx bxs-chevron-right-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevron-down-square'></i> bx bxs-chevron-down-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevron-up-square'></i> bx bxs-chevron-up-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevron-left-square'></i> bx bxs-chevron-left-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-chevron-right-square'></i> bx bxs-chevron-right-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-church'></i> bx bxs-church
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-coin'></i> bx bxs-coin
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-coin-stack'></i> bx bxs-coin-stack
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bullseye'></i> bx bxs-bullseye
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-meteor'></i> bx bxs-meteor
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-home-smile'></i> bx bxs-home-smile
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-envelope-open'></i> bx bxs-envelope-open
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-alt-add'></i> bx bxs-message-alt-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-alt-check'></i> bx bxs-message-alt-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-alt-error'></i> bx bxs-message-alt-error
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-alt-x'></i> bx bxs-message-alt-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-alt-minus'></i> bx bxs-message-alt-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-alt-edit'></i> bx bxs-message-alt-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-alt-detail'></i> bx bxs-message-alt-detail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-rounded-check'></i> bx bxs-message-rounded-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-rounded-error'></i> bx bxs-message-rounded-error
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-rounded-x'></i> bx bxs-message-rounded-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-rounded-minus'></i> bx bxs-message-rounded-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-rounded-edit'></i> bx bxs-message-rounded-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-rounded-add'></i> bx bxs-message-rounded-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-rounded-detail'></i> bx
                      bxs-message-rounded-detail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-check'></i> bx bxs-message-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-error'></i> bx bxs-message-error
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-x'></i> bx bxs-message-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-minus'></i> bx bxs-message-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-edit'></i> bx bxs-message-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-add'></i> bx bxs-message-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-detail'></i> bx bxs-message-detail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-square-check'></i> bx bxs-message-square-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-square-error'></i> bx bxs-message-square-error
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-square-x'></i> bx bxs-message-square-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-square-minus'></i> bx bxs-message-square-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-square-edit'></i> bx bxs-message-square-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-square-add'></i> bx bxs-message-square-add
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-message-square-detail'></i> bx bxs-message-square-detail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-comment-check'></i> bx bxs-comment-check
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-comment-x'></i> bx bxs-comment-x
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-comment-edit'></i> bx bxs-comment-edit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-comment-minus'></i> bx bxs-comment-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-cookie'></i> bx bxs-cookie
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-copyright'></i> bx bxs-copyright
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-credit-card-front'></i> bx bxs-credit-card-front
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-crop'></i> bx bxs-crop
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-diamond'></i> bx bxs-diamond
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-door-open'></i> bx bxs-door-open
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-donate-heart'></i> bx bxs-donate-heart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-donate-blood'></i> bx bxs-donate-blood
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bookmark-heart'></i> bx bxs-bookmark-heart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-category'></i> bx bxs-category
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-category-alt'></i> bx bxs-category-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bookmark-alt'></i> bx bxs-bookmark-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bookmark-alt-plus'></i> bx bxs-bookmark-alt-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-bookmark-alt-minus'></i> bx bxs-bookmark-alt-minus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-face-mask'></i> bx bxs-face-mask
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-tv'></i> bx bxs-tv
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-tag-alt'></i> bx bxs-tag-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-movie-play'></i> bx bxs-movie-play
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-user-account'></i> bx bxs-user-account
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-virus'></i> bx bxs-virus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxs-virus-block'></i> bx bxs-virus-block
                    </div>
                  </Row>

                  {/* <!--Solid Icons end row --> */}

                  <h4 className='mt-5'>Logos</h4>
                  <p className='text-muted mb-0'>
                    Use class{' '}
                    <code>
                      &lt;i className="bx bl-**"{'>'}&lt;/i{'>'}
                    </code>
                  </p>
                  <Row className='icon-demo-content'>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-meta'></i> bx bxl-meta
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-upwork'></i> bx bxl-upwork
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-netlify'></i> bx bxl-netlify
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-java'></i> bx bxl-java
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-heroku'></i> bx bxl-heroku
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-go-lang'></i> bx bxl-go-lang
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-gmail'></i> bx bxl-gmail
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-flask'></i> bx bxl-flask
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-99designs'></i> bx bxl-99designs
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-venmo'></i> bx bxl-venmo
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-docker'></i> bx bxl-docker
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-aws'></i> bx bxl-aws
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-tiktok'></i> bx bxl-tiktok
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-sketch'></i> bx bxl-sketch
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-steam'></i> bx bxl-steam
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-trip-advisor'></i> bx bxl-trip-advisor
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-visual-studio'></i> bx bxl-visual-studio
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-unity'></i> bx bxl-unity
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-php'></i> bx bxl-php
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-discord-alt'></i> bx bxl-discord-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-flutter'></i> bx bxl-flutter
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-mastodon'></i> bx bxl-mastodon
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-tailwind-css'></i> bx bxl-tailwind-css
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-instagram-alt'></i> bx bxl-instagram-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-facebook-circle'></i> bx bxl-facebook-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-jquery'></i> bx bxl-jquery
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-imdb'></i> bx bxl-imdb
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-pinterest-alt'></i> bx bxl-pinterest-alt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-adobe'></i> bx bxl-adobe
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-algolia'></i> bx bxl-algolia
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-audible'></i> bx bxl-audible
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-figma'></i> bx bxl-figma
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-etsy'></i> bx bxl-etsy
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-gitlab'></i> bx bxl-gitlab
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-patreon'></i> bx bxl-patreon
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-redbubble'></i> bx bxl-redbubble
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-facebook'></i> bx bxl-facebook
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-github'></i> bx bxl-github
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-google'></i> bx bxl-google
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-instagram'></i> bx bxl-instagram
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-twitter'></i> bx bxl-twitter
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-youtube'></i> bx bxl-youtube
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-whatsapp'></i> bx bxl-whatsapp
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-tumblr'></i> bx bxl-tumblr
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-behance'></i> bx bxl-behance
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-dribbble'></i> bx bxl-dribbble
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-vimeo'></i> bx bxl-vimeo
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-linkedin'></i> bx bxl-linkedin
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-bitcoin'></i> bx bxl-bitcoin
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-facebook-square'></i> bx bxl-facebook-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-google-plus'></i> bx bxl-google-plus
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-google-plus-circle'></i> bx bxl-google-plus-circle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-linkedin-square'></i> bx bxl-linkedin-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-medium'></i> bx bxl-medium
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-medium-square'></i> bx bxl-medium-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-skype'></i> bx bxl-skype
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-slack-old'></i> bx bxl-slack-old
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-slack'></i> bx bxl-slack
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-twitch'></i> bx bxl-twitch
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-discord'></i> bx bxl-discord
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-reddit'></i> bx bxl-reddit
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-pinterest'></i> bx bxl-pinterest
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-blogger'></i> bx bxl-blogger
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-apple'></i> bx bxl-apple
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-android'></i> bx bxl-android
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-play-store'></i> bx bxl-play-store
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-windows'></i> bx bxl-windows
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-vk'></i> bx bxl-vk
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-pocket'></i> bx bxl-pocket
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-messenger'></i> bx bxl-messenger
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-500px'></i> bx bxl-500px
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-angular'></i> bx bxl-angular
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-codepen'></i> bx bxl-codepen
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-creative-commons'></i> bx bxl-creative-commons
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-digitalocean'></i> bx bxl-digitalocean
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-deviantart'></i> bx bxl-deviantart
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-discourse'></i> bx bxl-discourse
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-dropbox'></i> bx bxl-dropbox
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-drupal'></i> bx bxl-drupal
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-ebay'></i> bx bxl-ebay
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-amazon'></i> bx bxl-amazon
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-digg'></i> bx bxl-digg
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-unsplash'></i> bx bxl-unsplash
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-wikipedia'></i> bx bxl-wikipedia
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-sass'></i> bx bxl-sass
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-foursquare'></i> bx bxl-foursquare
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-invision'></i> bx bxl-invision
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-opera'></i> bx bxl-opera
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-airbnb'></i> bx bxl-airbnb
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-yelp'></i> bx bxl-yelp
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-quora'></i> bx bxl-quora
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-git'></i> bx bxl-git
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-html5'></i> bx bxl-html5
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-product-hunt'></i> bx bxl-product-hunt
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-magento'></i> bx bxl-magento
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-stack-overflow'></i> bx bxl-stack-overflow
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-firefox'></i> bx bxl-firefox
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-javascript'></i> bx bxl-javascript
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-nodejs'></i> bx bxl-nodejs
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-kickstarter'></i> bx bxl-kickstarter
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-vuejs'></i> bx bxl-vuejs
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-bing'></i> bx bxl-bing
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-react'></i> bx bxl-react
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-periscope'></i> bx bxl-periscope
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-wordpress'></i> bx bxl-wordpress
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-telegram'></i> bx bxl-telegram
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-stripe'></i> bx bxl-stripe
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-edge'></i> bx bxl-edge
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-paypal'></i> bx bxl-paypal
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-internet-explorer'></i> bx bxl-internet-explorer
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-joomla'></i> bx bxl-joomla
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-dailymotion'></i> bx bxl-dailymotion
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-chrome'></i> bx bxl-chrome
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-baidu'></i> bx bxl-baidu
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-visa'></i> bx bxl-visa
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-mastercard'></i> bx bxl-mastercard
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-redux'></i> bx bxl-redux
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-bootstrap'></i> bx bxl-bootstrap
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-yahoo'></i> bx bxl-yahoo
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-microsoft'></i> bx bxl-microsoft
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-css3'></i> bx bxl-css3
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-jsfiddle'></i> bx bxl-jsfiddle
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-shopify'></i> bx bxl-shopify
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-flickr'></i> bx bxl-flickr
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-less'></i> bx bxl-less
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-snapchat'></i> bx bxl-snapchat
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-soundcloud'></i> bx bxl-soundcloud
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-spotify'></i> bx bxl-spotify
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-trello'></i> bx bxl-trello
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-wix'></i> bx bxl-wix
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-mailchimp'></i> bx bxl-mailchimp
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-medium-old'></i> bx bxl-medium-old
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-squarespace'></i> bx bxl-squarespace
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-whatsapp-square'></i> bx bxl-whatsapp-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-flickr-square'></i> bx bxl-flickr-square
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-dev-to'></i> bx bxl-dev-to
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-zoom'></i> bx bxl-zoom
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-microsoft-teams'></i> bx bxl-microsoft-teams
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-blender'></i> bx bxl-blender
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-kubernetes'></i> bx bxl-kubernetes
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-google-cloud'></i> bx bxl-google-cloud
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-django'></i> bx bxl-django
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-spring-boot'></i> bx bxl-spring-boot
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-tux'></i> bx bxl-tux
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-markdown'></i> bx bxl-markdown
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-python'></i> bx bxl-python
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-ok-ru'></i> bx bxl-ok-ru
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-firebase'></i> bx bxl-firebase
                    </div>
                    <div className='col-xl-3 col-lg-4 col-sm-6'>
                      <i className='bx bxl-c-plus-plus'></i> bx bxl-c-plus-plus
                    </div>
                  </Row>

                  {/* <!--Logos end row --> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default IconBoxicons
